import Entity from "../../../class/abstract/Entity";
import Tag from "../Tags/Tag";

class Contact extends Entity {
    skytill_id = null;
    partnership_id = null;
    firstname = "";
    lastname = "";
    gender = "";
    email = "";
    phone = "";
    mobile = "";
    companies_list = "";
    tags = [];

    constructor(datas = {}) {
        super(datas);

        this.setAttributes(datas);
    }

    setAttributes(datas) {
        this.id = datas.id !== undefined ? datas.id : 0;
        this.skytill_id = datas.skytill_id !== undefined ? datas.skytill_id : null;
        this.partnership_id = datas.partnership_id !== undefined ? datas.partnership_id : null;
        this.lastname = datas.lastname !== undefined ? datas.lastname : "";
        this.firstname = datas.firstname !== undefined ? datas.firstname : "";
        this.gender = datas.gender !== undefined ? datas.gender : "";
        this.email = datas.email !== undefined ? datas.email : "";
        this.phone = datas.phone !== undefined ? datas.phone : "";
        this.mobile = datas.mobile !== undefined ? datas.mobile : "";
        this.companies_list = datas.companies_list !== undefined ? datas.companies_list : "";
        this.updated_at = datas.updated_at !== undefined ? datas.updated_at : "";
        this.created_at = datas.created_at !== undefined ? datas.created_at : "";
        this.tags = [];

        if(datas.tags !== undefined)
            this.initTags(datas.tags);
    }

    initTags(tags) {
        for(var i in tags)
            this.tags.push(new Tag(tags[i]));
    }
}

export default Contact;
