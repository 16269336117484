import React from "react";
import { Link } from "react-router-dom";
import DashboardIcon from "../../icons/DashboardIcon";
import ClientIcon from "../../icons/ClientIcon";
import CatalogIcon from "../../icons/CatalogIcon";
import AccountantIcon from "../../icons/AccountantIcon";
import SettingIcon from "../../icons/SettingIcon";
import ProjectIcon from "../../icons/ProjectIcon";
import "../../../css/page/menu/Item.css";

function Item (props) {
    const { type, handleSetTypeSubMenu, handleHideSubMenu, selected } = props;

    const selectSVG = type => {
        switch (type) {
            case "dashboard": return <DashboardIcon />;
            case "client": return <ClientIcon />;
            case "catalog": return <CatalogIcon />;
            case "accountant": return <AccountantIcon />;
            case "project": return <ProjectIcon />;
            case "setting": return <SettingIcon />;
            default: break;
        }
    }

    const link = () => {
        switch (type) {
            case "dashboard": return "/dashboard";
            case "client": return "/client";
            case "catalog": return "/product";
            case "accountant": return "/bill";
            case "project": return "https://project.hi-vy.com/";
            case "setting": return "/setting/information";
            default: return "";
        }
    }

    const buildRender = () => {
        if (type !== "project") {
            return <Link to={ link() }>
                <div className={ "item " + (selected === "true" ? "selected" : "") + (type === "project" ? " project" : "") } onMouseOver={ setTypeSubMenu } onMouseOut={ hideSubMenu } onClick={ hideSubMenu }>
                    {
                        selectSVG(type)
                    }
                </div>
            </Link>
        }
        else {
            return <a href={ link() }>
                <div className={ "item " + (selected === "true" ? "selected" : "") + (type === "project" ? " project" : "") } onMouseOver={ setTypeSubMenu } onMouseOut={ hideSubMenu } onClick={ hideSubMenu }>
                    {
                        selectSVG(type)
                    }
                </div>
            </a>
        }
    }

    const setTypeSubMenu = () => {
        if (handleSetTypeSubMenu)
            handleSetTypeSubMenu(type);
    }
    const hideSubMenu = () => {
        if (handleHideSubMenu)
            handleHideSubMenu();
    }

    return (
        buildRender()
    );
}

export default Item;
