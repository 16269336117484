import React, {useState} from "react";
import LoaderCircle from "../../loader/LoaderCircle";
import InputRadio from "../../form/input/InputRadio";

function WhatDateOfInstallation (props) {
    const { sending, handleSetDateSetup } = props;
    const [ choice, setChoice ] = useState(1);
    const [ inputVal, setInputVal ] = useState("");

    const handleChange = (value, input) => {
        setChoice(value);
        setInputVal(input);
    }

    const save = () => {
        handleSetDateSetup(choice, inputVal);
    }

    return (
        <div className="wrapOverbox">
            <div className="overbox radio">
                <div className="form margin noOverflow">
                    <p className="titleForm center">Quelle est la date prévue pour l'installation ?</p>
                    <InputRadio value={ 1 } text="__/__/____" input="date" selected={ choice === 1 } handleChange={ handleChange } />
                    <InputRadio value={ 2 } text="Date non planifiée" selected={ choice === 2 } handleChange={ handleChange } />
                    <InputRadio value={ 3 } text="Déjà installée" selected={ choice === 3 } handleChange={ handleChange } />
                    <div className="marge" />
                    <button className="submit" onClick={ save }>
                        {sending ? "Enregistrement..." : "Enregistrer"}
                        <LoaderCircle display="loader submitLogin" hide={!sending ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                    </button>
                    <div className="clearing" />
                </div>
            </div>
        </div>
    );
}

export default WhatDateOfInstallation;
