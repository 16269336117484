import React from "react";

function NewProspectStep2 (props) {
    const { fill, classname } = props;

    return (
        <svg viewBox="0 0 512 512" width="1em" height="1em" fill={ fill !== undefined ? fill : "" } className={ classname !== undefined ? classname : "" }>
            <path d="M448 64h-48V16c0-8.832-7.168-16-16-16H96C69.536 0 48 21.536 48 48v400c0 35.296 28.704 64 64 64h336c8.832 0 16-7.168 16-16V80c0-8.832-7.168-16-16-16zm-80 336c0 8.832-7.168 16-16 16H160c-8.832 0-16-7.168-16-16v-32c0-44.128 35.904-80 80-80h64c44.128 0 80 35.872 80 80v32zM192 192c0-35.296 28.704-64 64-64s64 28.704 64 64-28.704 64-64 64-64-28.704-64-64zM368 64H96c-8.832 0-16-7.168-16-16s7.168-16 16-16h272v32z" />
        </svg>
    );
}

export default NewProspectStep2;
