import React, { useContext } from "react";
import ProviderTabs from "./ProviderTabs";
import LoaderCircle from "../../../../loader/LoaderCircle";
import ProviderCardInformations from "./ProviderCardInformations";
import ProviderContext from "../../../../../context/ProviderContext";
import "../../../../../css/page/cards/Card.css";
import Error from "../../../../error/Error";

function ProviderCard (props) {
    const { tab, handleAskDelete, handleGet, sending, setSending, handleManage, show, handleDelete } = props;
    const { provider, generalError } = useContext(ProviderContext);

    const handleUpdateSending = bool => {
        setSending(bool);
    }

    const goContentCard = () => {
        switch (tab) {
            case "information": return <ProviderCardInformations handleUpdateSending={ handleUpdateSending } handleAskDeleteProspect={ handleAskDelete } handleGet={ handleGet } />
            default: return <div />
        }
    }

    return (
        <div className="card">
            {
                provider !== null
                ? <ProviderTabs tab={ tab } sending={ sending } handleManage={ handleManage } show={ show } handleDelete={ handleDelete } />
                : generalError === "" && <LoaderCircle display="loader logWait" hide="" strokeWidth="5" stroke="#008C4F" />
            }
            {
                provider !== null
                ? goContentCard()
                : generalError !== "" && <Error text={ generalError } />
            }
        </div>
    );
}

export default ProviderCard;
