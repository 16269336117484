import React, {useContext} from "react";
import PhaseInProgress from "./PhaseInProgress";

import StoreContext from "../../../../../context/StoreContext";

import "../../../../../css/page/cards/ProspectPhaseInProgress.css";

function ProspectPhaseInProgress (props) {
    const { handleAdd } = props;
    const { store } = useContext(StoreContext);

    return (
        <div className="prospectPhaseInProgress form">
            {
                store.prospectingPhases.map(phase => (
                    <PhaseInProgress key={ phase.id } phase={ phase } />
                ))
            }
            <button className="add marginTop" onClick={ handleAdd }>Ajouter une étape</button>
        </div>
    );
}

export default ProspectPhaseInProgress;
