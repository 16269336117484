import React, {useContext} from "react";
import StoreContext from "../../../../../context/StoreContext";
import LoaderCircle from "../../../../loader/LoaderCircle";
import SetupView from "../../setup/view/SetupView";
import "../../../../../css/form/Form.css";
import "../../../../../css/page/setup/ClientSetup.css"
import EmptyBox from "../../../../icons/EmptyBox";

function ClientSetup (props) {
    const colorEmptyBox = window.matchMedia('(prefers-color-scheme: dark)').matches ? "#444444" : "#1D1D1D";

    const { listSetups, setup, loading } = props;
    const { store } = useContext(StoreContext);

    return (
        <div className="clientSetup">
            {
                listSetups.length <= 1
                    && <div className="absoluteCenter">
                        <EmptyBox fill={ colorEmptyBox } classname="emptyBox" />
                    </div>
            }
            {
                loading
                    ? <LoaderCircle display="loader logWait" hide="" strokeWidth="5" stroke="#008C4F" />
                    : setup.id > 0 && <SetupView store={ store } setup={ setup } />
            }
        </div>
    );
}

export default ClientSetup;
