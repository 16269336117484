import Entity from "../../../class/abstract/Entity";

class TicketState extends Entity {
    name = "";
    reference = "";
    color = "";
    created_at = "";

    constructor(datas = {}) {
        super(datas);
        this.setAttributes(datas);
    }

    setAttributes(datas) {
        var keys = Object.keys(this);
        var key = "";
        var i = 0;

        for(i in keys) {
            key = keys[i];

            if (datas[key] !== undefined)
                Reflect.set(this, key, datas[key]);
        }
    }
}

export default TicketState;
