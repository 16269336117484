import React from "react";
import BrandForm from "../../form/brand/BrandForm";

function FormBrand (props) {
    const { newItem, brand, handleClose, handleDelete, handleRefresh } = props;

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox newItemSmall">
                    <BrandForm handleClose={ handleClose } handleDelete={ handleDelete } newItem={ newItem } handleRefresh={ handleRefresh } brand={ brand } />
                </div>
            </div>
        </div>
    );
}

export default FormBrand;
