import React from "react";
import LoaderCircle from "../../loader/LoaderCircle";

function ConfirmInstallLicense (props) {
    const { license, handleClose, handleInstall, saving } = props;

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox yesOrNo">
                    <div className="form margin">
                        <p className="title">
                            {
                                saving === undefined
                                    ? "êtes-vous sûr de vouloir installer cette licence ?"
                                    : "Vous allez installer cette licence"
                            }
                        </p>
                        {
                            saving !== undefined
                            && <p className="text">
                                <strong>ATTENTION</strong> : Si cette licence est en cours d'utilisation, les échanges avec le serveur seront définitivement interrompus et les données non envoyées seront perdues.
                            </p>
                        }
                        {
                            saving === undefined
                                ? <button className="submit block" onClick={ () => { handleInstall(license) } }>Continuer</button>
                                : <button className="delete block" onClick={ () => { handleInstall(license) } }>
                                    { saving ? "En cours..." : "Oui, j'ai compris !" }
                                    <LoaderCircle display="loader submitLogin" hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                                </button>
                        }
                        <button className="cancel" onClick={ handleClose }>Annuler</button>
                        <div className="clearing" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConfirmInstallLicense;
