import React, {useContext, useEffect, useState} from "react";
import LoaderCircle from "../../loader/LoaderCircle";
import SetupTypeController from "../../../stories/_setup/SetupTypes/SetupTypeController";
import SessionContext from "../../../context/SessionContext";
import StoreContext from "../../../context/StoreContext";

function WhatTypeOfInstallation (props) {
    const { handleLogout } = useContext(SessionContext);
    const { store, setGeneralError } = useContext(StoreContext);
    const { sending, handleClose, handleCreateSetup } = props;
    const [ first, setFirst ] = useState(true);
    const [ loading, setLoading ] = useState(true);
    const [ list, setList ] = useState([]);

    const getSetupTypes = () => {
        setFirst(false);

        var controller = new SetupTypeController();
        controller._callback = handleGetSetupTypes;
        controller.index(store.partnership_id);
    }
    const handleGetSetupTypes = (list, error) => {
        if(error)
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();

        setLoading(false);
        setList(list);
    }

    useEffect(() => {
        if(first)
            getSetupTypes();
    });

    return (
        <div className="wrapOverbox">
            <div className="overbox choices">
                <div className="form margin">
                    {
                        !loading
                        ? <div>
                            <p className="titleForm center">Quel système de caisse souhaitez-vous ajouter ?</p>
                            {
                                list.map(setupType => (
                                    <button key={ setupType.id } className="buttonChoice" onClick={ () => { handleCreateSetup(setupType.id, setupType.name) } }>
                                        { sending === setupType.id ? "Création..." : setupType.name }
                                        <LoaderCircle display="loader submitLogin" hide={ sending !== setupType.id ? "hide" : "" } strokeWidth="8" stroke="#FFFFFF" />
                                    </button>
                                ))
                            }
                            <button className="cancel block" onClick={ handleClose }>Annuler</button>
                        </div>
                        : <LoaderCircle display="loader logWait" hide="" strokeWidth="5" stroke="#008C4F" />
                    }
                </div>
            </div>
        </div>
    );
}

export default WhatTypeOfInstallation;
