import Entity from "../../../class/abstract/Entity";

class Tag extends Entity {
    name = "";
    color = "";
    type_name = "";
    partnership_name = "";

    constructor(datas = {}) {
        super(datas);

        this.setAttributes(datas);
    }

    setAttributes(datas) {
        this.id = datas.id !== undefined ? datas.id : 0;
        this.name = datas.name !== undefined ? datas.name : "";
        this.color = datas.color !== undefined ? datas.color : "";
        this.type_name = datas.type_name !== undefined ? datas.type_name : "";
        this.partnership_name = datas.partnership_name !== undefined ? datas.partnership_name : "";
        this.updated_at = datas.updated_at !== undefined ? datas.updated_at : "";
        this.created_at = datas.created_at !== undefined ? datas.created_at : "";
    }
}

export default Tag;
