import React from "react"

export default React.createContext({
    handleLogout: () => {},
    handleCheckBigBisoux: () => {},
    globalError: "",
    setGlobalError: () => {},
    globalWarning: "",
    setGlobalWarning: () => {},
    globalValid: "",
    setGlobalValid: () => {}
})
