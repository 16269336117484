import React from "react";

function VerticalMove (props) {
    const { fill } = props;

    return (
        <svg height="1em" viewBox="0 0 24 24" width="1em" fill={ fill }>
            <circle cx={12} cy={12} r={3} />
            <path d="M16 17H8c-.551 0-1 .449-1 1a1.038 1.038 0 00.214.628l4.058 5.064c.2.199.458.308.728.308s.528-.109.786-.371l4-5A1.044 1.044 0 0017 18c0-.551-.449-1-1-1zM8 7h8c.551 0 1-.449 1-1a1.038 1.038 0 00-.214-.628L12.728.308c-.4-.396-.998-.46-1.514.064l-4 5A1.038 1.038 0 007 6c0 .551.449 1 1 1z" />
        </svg>
    );
}

export default VerticalMove;
