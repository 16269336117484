import React from "react"
import LoaderCircle from "../../../../loader/LoaderCircle"
import '../../../../../css/page/Options.css'

const OptionAccount = props => {
    const { object, show, idSelect, loadingState, loadingRemove, handleUpdate, handleUpdateStoreRules, handleUpdateCompanyRules, handleState, handleRemove } = props

    return (
        <div className={"overOptions accountOptions " + (show ? " show" : "")}>
            <nav className="options">
                <p className="item" onClick={ handleUpdate }>Modifier les informations</p>
                <p className="item" onClick={ handleUpdateStoreRules }>Droits sur la boutique</p>
                <p className="item" onClick={ handleUpdateCompanyRules }>Droits sur le groupe</p>
                {
                    object.onlyHivy === 0
                        && <div className="clearing">
                            {
                                object.status === 200
                                    ? <p className="item" onClick={ () => { handleState(427) } }>
                                        Suspendre le compte
                                        <LoaderCircle display="loader optionLoader" hide={!loadingState ? "hide" : ""} strokeWidth="8" stroke="#004B2A" />
                                    </p>
                                    : <p className="item" onClick={ () => { handleState(200) } }>
                                        Activé le compte
                                        <LoaderCircle display="loader optionLoader" hide={!loadingState ? "hide" : ""} strokeWidth="8" stroke="#004B2A" />
                                    </p>
                            }
                        </div>
                }
                <p className="item red" onClick={ () => { handleRemove(idSelect) } }>
                    Dissocier le compte
                    <LoaderCircle display="loader optionLoader" hide={!loadingRemove ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                </p>
            </nav>
        </div>
    )
}

export default OptionAccount
