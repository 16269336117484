import React from "react";
import Switch from "react-switch";

function InputSwitch (props) {
    const {
        attribute,
        returnType,
        label,
        marginTop,
        marginBottom,
        classname,
        value,
        handleChange
    } = props;

    const changeEvent = checked => {
        if (handleChange === null) return;
        handleChange(attribute, returnType, checked ? 1 : 0);
    }

    return (
        <div className={ "inputSwitch" + (classname !== undefined ? " " + classname : "") + (marginTop !== undefined ? " marginTop" : "") + (marginBottom !== undefined ? " marginBottom" : "") }>
            <label>
                <span>{ label }</span>
                <Switch
                    checked={ value === 1 }
                    onChange={ changeEvent }
                    onColor="#004B2A"
                    onHandleColor="#008C4F"
                    handleDiameter={ 20 }
                    uncheckedIcon={ false }
                    checkedIcon={ false }
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={ 12 }
                    width={ 30 }
                    className="react-switch"
                    id="material-switch"
                />
            </label>
        </div>
    );
}

export default InputSwitch;
