import React from "react";
import PhaseForm from "../../../form/setup/phase/PhaseForm";

function FormSetupPhase (props) {
    const { newItem, phase, handleClose, handleDelete } = props;

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox newItemSmall">
                    <PhaseForm handleClose={ handleClose } handleDelete={ handleDelete } newItem={ newItem } phase={ phase } />
                </div>
            </div>
        </div>
    );
}

export default FormSetupPhase;
