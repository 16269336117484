import React, {useContext} from "react";
import SessionContext from "../../../context/SessionContext";
import IconUser from "../../../image/ressource/bee.png";
import "../../../css/page/menu/User.css";

function User (props) {
    const { onShowOptionUser } = props;
    const { user } = useContext(SessionContext);

    return (
        <div className="user" onClick={ onShowOptionUser }>
            <img src={IconUser} alt="user" />
        </div>
    );
}

export default User;
