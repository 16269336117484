import Entity from "../../../class/abstract/Entity";

class InstallationPhase extends Entity {
    name = "";
    description = "";
    color = "";
    partnership_id = 0;
    partnership_name = "";
    planned_date = "";
    planned_by = "";
    execution_date = "";
    execution_by = "";

    constructor(datas = {}) {
        super(datas);

        this.setAttributes(datas);
    }

    setAttributes(datas) {
        this.id = datas.id !== undefined ? datas.id : 0;
        this.name = datas.name !== undefined ? datas.name : "";
        this.description = datas.description !== undefined ? datas.description : "";
        this.color = datas.color !== undefined ? datas.color : "";
        this.partnership_id = datas.partnerships_id !== undefined ? datas.partnerships_id : 0;
        this.partnership_name = datas.partnership_name !== undefined ? datas.partnership_name : "";

        if (datas.pivot !== undefined) {
            this.planned_date = datas.pivot.planned_date !== undefined ? datas.pivot.planned_date : "";
            this.planned_by = datas.pivot.planned_by !== undefined ? datas.pivot.planned_by : 0;
            this.execution_date = datas.pivot.execution_date !== undefined ? datas.pivot.execution_date : "";
            this.execution_by = datas.pivot.execution_by !== undefined ? datas.pivot.execution_by : 0;
        }
    }
}

export default InstallationPhase;
