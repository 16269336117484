import React from "react";

function SeeIcon (props) {
    const { fill, classname, click } = props;

    return (
        <svg height="1em" viewBox="0 0 32 32" width="1em" xmlns="http://www.w3.org/2000/svg" fill={ fill } className={ classname } onClick={ click }>
            <path d="M16 5.5C9.24 5.5 3 9.44.1 15.55c-.13.29-.13.63 0 .91C3.01 22.56 9.25 26.5 16 26.5s12.99-3.94 15.9-10.04c.13-.28.13-.62 0-.91C29 9.44 22.76 5.5 16 5.5zm0 16.84c-3.49 0-6.34-2.85-6.34-6.34S12.51 9.66 16 9.66s6.34 2.85 6.34 6.34-2.85 6.34-6.34 6.34z" />
            <circle cx={16} cy={16} r={4.2} />
        </svg>
    );
}

export default SeeIcon;
