import React from "react";
import AccessIcon from "../../../../icons/AccessIcon";

function PartnershipStorageLine (props) {
    const isDark = window.matchMedia('(prefers-color-scheme: dark)');
    const color = isDark.matches ? "#FFFFFF" : "#1D1D1D";
    const { storage, handleShowFormUpdateStorage } = props;

    return (
        <tr className="line">
            <td className="city left">{ storage.city }</td>
            <td className="postalCode left">{ storage.postalCode }</td>
            <td className="country left">{ storage.country }</td>
            <td className="action">
                <div className="access" onClick={ () => { handleShowFormUpdateStorage(storage.id) } }>
                    <AccessIcon fill={ color } />
                </div>
            </td>
        </tr>
    );
}

export default PartnershipStorageLine;
