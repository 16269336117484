import React from "react";
import TypeForm from "../../../form/setup/type/TypeForm";

function FormSetupType (props) {
    const { newItem, type, handleClose, handleDelete } = props;

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox newItemSmall">
                    <TypeForm handleClose={ handleClose } handleDelete={ handleDelete } newItem={ newItem } type={ type } />
                </div>
            </div>
        </div>
    );
}

export default FormSetupType;
