import React, {useContext, useEffect, useState} from "react";
import NoInput from "../input/NoInput";
import InputText from "../input/InputText";
import LoaderCircle from "../../loader/LoaderCircle";
import SessionContext from "../../../context/SessionContext";
import BrandController from "../../../stories/_catalog/Brands/BrandController";
import Brand from "../../../stories/_catalog/Brands/Brand";
import InputMultiLinesChoices from "../input/InputMultiLinesChoices";

function BrandForm (props) {
    const color = window.matchMedia('(prefers-color-scheme: dark)').matches ? "#FFFFFF" : "#1D1D1D";

    const { newItem, handleClose, handleDelete, handleRefresh, brand } = props;
    const { handleLogout } = useContext(SessionContext);
    const [ globalError, setGlobalError ] = useState("");
    const [ partnerships, setPartnerships ] = useState([]);
    const [ first, setFirst ] = useState(true);
    const [ saving, setSaving ] = useState(false);
    const [ brandObject, setBrandObject ] = useState(brand !== null ? brand : new Brand());
    const [ name, setName ] = useState(brand !== null ? brand.name : "");
    const [ editName, setEditName ] = useState(false);
    const [ errorName, setErrorName ] = useState("");
    const [ reference, setReference ] = useState(brand !== null ? brand.reference : "");
    const [ editReference, setEditReference ] = useState(false);
    const [ errorReference, setErrorReference ] = useState("");
    const [ partnershipsSelected, setPartnershipsSelected ] = useState([]);
    const [ errorPartnership, setErrorPartnership ] = useState("");

    const listPartnerships = () => {
        setFirst(false);

        const user = JSON.parse(localStorage.getItem("user"))
        let partnershipsList = [];

        user.partnerships.map(object => {
            partnershipsList.push({
                id: object.id,
                main: object.company
            });
        });

        setPartnerships(partnershipsList);
    }

    const buildListIDsPartnership = () => {
        var listIDs = [];

        for (var i in partnershipsSelected)
            listIDs.push(partnershipsSelected[i].id);

        return listIDs;
    }

    // HANDLES EDITION

    const handleEditName = () => {
        if(processEdit(editName))
            setEditName(!editName);
    }
    const handleChangeName = event => {
        setName(event.currentTarget.value);
    }
    const checkName = () => {
        const regxp = /^[\w ]{2,50}/g;

        if(name === "") {
            setErrors("name", true);
            return false;
        }

        if (!regxp.test(name.removeDiacritics())) {
            setErrors("name", false);
            return false;
        }

        setErrorName("");

        return true;
    }

    const handleEditReference = () => {
        if(processEdit(editReference))
            setEditReference(!editReference);
    }
    const handleChangeReference = event => {
        setReference(event.currentTarget.value);
    }
    const checkReference = () => {
        const regxp = /^[\w]{2,50}/g;

        if (!regxp.test(reference.removeDiacritics())) {
            setErrors("reference", false);
            return false;
        }

        setErrorReference("");

        return true;
    }

    const handleSelectPartnership = id => {
        var listDatas = partnershipsSelected.slice();
        var idToRemove = -1;

        for (var item in partnerships) {
            if(partnerships[item].id === id) {
                for (var i = 0; i < partnershipsSelected.length; i++) {
                    if(partnershipsSelected[i].id === id) {
                        idToRemove = i;
                        break;
                    }
                }

                if(idToRemove >= 0)
                    listDatas.splice(idToRemove, 1);
                else
                    listDatas.push({
                        id: partnerships[item].id,
                        main: partnerships[item].company
                    });

                break;
            }
        }

        setPartnershipsSelected(listDatas);
    }
    const checkPartnership = () => {
        if(!newItem) return true;

        if(partnershipsSelected.length === 0) {
            setErrors("partnership", true);
            return false;
        }

        setErrorPartnership("");

        return true;
    }

    // CONTROL & SAVE

    const processEdit = edit => {
        if(edit) {
            if(checkName() && checkReference() && checkPartnership() && !newItem)
                save();
            else
                return false;
        }

        return true;
    }
    const reinitAllEdits = () => {
        setEditName(false);
        setEditReference(false);
    }
    const reinitAllErrors = () => {
        setGlobalError("");
        setErrorName("");
        setErrorReference("");
        setErrorPartnership("");
    }

    const check422Errors = errorDatas => {
        setGlobalError("Certaines données sont invalides");

        if(errorDatas !== undefined) {
            var keys = Object.keys(errorDatas);
            var fields = ["name", "reference", "partnership_id"];

            for(var item in fields)
                if(keys.includes(fields[item]))
                    setErrors(fields[item], false);
        }
    }
    const setErrors = (type, empty) => {
        switch (type) {
            case "name":
                if(empty) setErrorName("Vous devez saisir un nom");
                else setErrorName("Ce nom n'est pas valide");
                break;
            case "reference":
                if(empty) setErrorName("Vous devez saisir une référence");
                else setErrorName("Cette référence n'est pas valide");
                break;
            case "partnership_ids":
                setErrorPartnership("Vous devez sélectionner au moins un partenaire");
                break;
            default: break;
        }
    }

    const handleKeyEnter = event => {
        if (event.key === 'Enter')
            if(processEdit(true))
                reinitAllEdits();
    }

    const save = () => {
        setSaving(true);

        reinitAllErrors();

        const controller = new BrandController();
        controller._callback = handleReturnSave;

        const brand = Object.assign({}, brandObject);
        brand.name = name;
        brand.reference = reference;

        if(brand.id === 0)
            brand.partnership_ids = buildListIDsPartnership();

        if (newItem)
            controller.post(brand);
        else
            controller.put(brand);
    }
    const handleReturnSave = (response, error) => {
        setSaving(false);

        if(error) {
            if(error.response !== undefined) {
                if(error.response.status === 401)
                    handleLogout();
                else if(error.response.status === 422)
                    check422Errors(error.response.data);
                else if(error.response.status === 403)
                    setGlobalError("Vous n'avez pas les droits d'effectuer cette action");
                else
                    setGlobalError("Une erreur s'est produite lors de l'enregistrement");
            }
            else
                setGlobalError("Une erreur s'est produite lors de l'enregistrement");
        }
        else {
            const brand = Object.assign({}, brandObject);

            if(brand.id === 0)
                handleClose(true);
            else {
                brand.name = name;
                brand.reference = reference;
                setBrandObject(brand);

                handleRefresh();
            }
        }
    }

    useEffect(() => {
        if(first && newItem)
            listPartnerships();
    })

    // RENDER

    return (
        <div className="contForm">
            <div className={ "form " + (newItem ? "margin" : "") }>
                {
                    newItem && <p className="titleForm center">AJOUTER UNE MARQUE</p>
                }
                {
                    globalError !== ""
                        ? <p className="globalError">{ globalError }</p>
                        : ""
                }
                <label className="label">Nom</label>
                {
                    !editName && !newItem
                        ? <NoInput value={ brandObject.name !== "" ? brandObject.name : "Aucun" } color={ color } edit={ true } copy={ brandObject.name !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditName } />
                        : <InputText color={ color } classError={ errorName !== "" ? "wrong" : "" } value={ name } placeholder="Nom" newItem={ newItem } handleChange={ handleChangeName } handleBlur={ handleEditName } handleKeyEnter={ handleKeyEnter } />
                }
                {
                    errorName !== ""
                        ? <p className="error">{ errorName }</p>
                        : ""
                }
                <label className="label">Référence</label>
                {
                    !editReference && !newItem
                        ? <NoInput value={ brandObject.reference !== "" ? brandObject.reference : "Aucun" } color={ color } edit={ true } copy={ brandObject.reference !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditReference } />
                        : <InputText color={ color } classError={ errorReference !== "" ? "wrong" : "" } value={ reference } placeholder="Référence" newItem={ newItem } handleChange={ handleChangeReference } handleBlur={ handleEditReference } handleKeyEnter={ handleKeyEnter } />
                }
                {
                    errorReference !== ""
                        ? <p className="error">{ errorReference }</p>
                        : ""
                }
                {
                    (newItem && partnerships.length > 1) &&
                    <div className="clearing">
                        <label className="label">Partenaire</label>
                        <InputMultiLinesChoices color={ color } list={ partnerships } listSelected={ partnershipsSelected } handleSelectChoiceList={ handleSelectPartnership } />
                    </div>
                }
                {
                    errorPartnership !== "" && <p className="error">{ errorPartnership }</p>
                }
                {
                    newItem
                        ? <div>
                            <button className="submit" onClick={ save }>
                                {saving ? "Enregistrement..." : "Enregistrer"}
                                <LoaderCircle display="loader submitLogin" hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                            </button>
                            <button className="cancel" onClick={ handleClose }>Annuler</button>
                            <div className="clearing" />
                        </div>
                        : <div>
                            <button className="delete" onClick={ () => { handleDelete(brandObject.id) } }>Supprimer la marque</button>
                            <button className="cancel block" onClick={ () => { handleClose(false) } }>Fermer</button>
                        </div>
                }
            </div>
        </div>
    );
}

export default BrandForm;
