import React, {useState} from "react";
import { ChromePicker } from 'react-color';
import CheckIcon from "../../icons/CheckIcon";

function InputColor (props) {
    const { color, classname, value, edit, handleClick, handleChange, handleBlur } = props;
    const [ box, setBox ] = useState(false);
    const [ select, setSelect ] = useState(false);

    const showBoxColor = () => {
        setBox(true);
        setSelect(true);
    }

    const handleClose = () => {
        setBox(false);
        setSelect(false);
        handleBlur();
    }

    return (
        <div className={ "inputColor " + (classname !== undefined ? classname : "") + (edit ? " edit" : "") } onClick={handleClick}>
            <p className={ "color" + (select ? " select" : "") } onClick={ showBoxColor }>
                { value }
                <span className="puce" style={value !== "" ? {backgroundColor: value} : {}} />
            </p>
            {
                edit && <CheckIcon fill={ color } handleClick={ handleClose } />
            }
            <div className="clearing" />
            {
                box
                && <div className="popover">
                    <div className="cover" onClick={ handleClose }/>
                    <ChromePicker
                        color={ value }
                        onChange={ handleChange }
                        disableAlpha={ true }
                    />
                </div>
            }
        </div>
    );
}

export default InputColor;
