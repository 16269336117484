import React from "react";
import DeleteIcon from "../../icons/DeleteIcon";

function ChoiceSelected (props) {
    const { object, dictionary, locked, color, handleUnselect } = props;

    return (
        <div className="choice">
            <p>{ dictionary !== undefined ? dictionary[object.name] : object.name }</p>
            {
                !locked && <div className="contDelete" onClick={ () => { handleUnselect(object.id) } }>
                    <DeleteIcon fill={ color } />
                </div>
            }
            <div className="clearing" />
        </div>
    );
}

export default ChoiceSelected;
