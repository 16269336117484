import Rest from "../../../class/tool/Rest";
import Storage from "./Storage";

class StorageController {
    _rest = new Rest();
    _callback = null;
    _baseURL = "partnerships/{idPartnership}/storages";

    index(partnership, input= "") {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idPartnership}", partnership.id) + (input !== "" ? "?input=" + input : ""),
            {},
            this.handleIndex);
    }
    handleIndex = (response, error) => {
        let listObjects = [];

        if(response.data !== undefined) {
            const data = response.data.data;

            for(var item in data)
                listObjects.push(new Storage(data[item]));
        }

        this._callback(listObjects, error);
    }

    show(partnership, id) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idPartnership}", partnership.id) + '/' + id,
            {},
            this.handleShow);
    }
    handleShow = (response, error) => {
        const object = !error ? new Storage(response.data) : null;
        this._callback(object, error);
    }

    post(partnership, object) {
        const objectToSend = JSON.parse(JSON.stringify(object));
        delete objectToSend.partnership_type_id;

        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURL.replace("{idPartnership}", partnership.id),
            objectToSend,
            this.handlePost
        );
    }
    handlePost = (response, error) => {
        this._callback(response, error);
    }

    put(partnership, object) {
        const objectToSend = JSON.parse(JSON.stringify(object));

        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'PUT',
            '/' + this._baseURL.replace("{idPartnership}", partnership.id) + '/' + objectToSend.id,
            objectToSend,
            this.handlePut
        );
    }
    handlePut = (response, error) => {
        this._callback(response, error);
    }

    delete(partnership, object) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'DELETE',
            '/' + this._baseURL.replace("{idPartnership}", partnership.id) + '/' + object.id,
            {},
            this.handleDelete);
    }
    handleDelete = (response, error) => {
        this._callback(response, error);
    }
}

export default StorageController;
