import React, {useContext, useEffect, useState} from "react";
import StorageController from "../../../stories/_auth/Storages/StorageController";
import LoaderCircle from "../../loader/LoaderCircle";
import PartnershipContext from "../../../context/PartnershipContext";
import StorageForm from "../../form/storage/StorageForm";
import SessionContext from "../../../context/SessionContext";

function FormStorage (props) {
    const { handleClose, handleDelete, newItem, idStorage, setError, handleRefresh } = props;
    const { handleLogout } = useContext(SessionContext);
    const { partnership } = useContext(PartnershipContext);
    const [ first, setFirst ] = useState(true);
    const [ storage, setStorage ] = useState(null);

    const getStorage = () => {
        const controller = new StorageController();
        controller._callback = handleGetStorage;
        controller.show(partnership, idStorage);
    }
    const handleGetStorage = (object, error) => {
        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else {
                setError("Impossible de récupérer les données");
                handleClose();
            }
        }

        setFirst(false);
        setStorage(object);
    }

    useEffect(() => {
        if(first && !newItem && idStorage !== undefined)
            getStorage();
    });

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox newItem">
                    {
                        (newItem || (!newItem && storage !== null))
                        ? <StorageForm handleClose={ handleClose } handleDelete={ handleDelete } newItem={ newItem } storage={ storage } handleRefresh={ handleRefresh } />
                        : <LoaderCircle display="loader logWait" hide="" strokeWidth="5" stroke="#008C4F" />
                    }
                </div>
            </div>
        </div>
    );
}

export default FormStorage;
