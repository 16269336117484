import React from "react";

function ToBold (props) {
    const { fill, classname, handleClick } = props;

    return (
        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" fill={ fill } className={ classname } onClick={ handleClick }>
            <path d="M440.89 0H71.11C31.843.016.015 31.844 0 71.11v369.78c.016 39.266 31.844 71.094 71.11 71.11h369.78c39.266-.016 71.094-31.844 71.11-71.11V71.11C511.984 31.843 480.156.015 440.89 0zm-85.335 305.777c-.051 35.325-28.676 63.95-64 64h-92.446c-7.843-.023-14.199-6.375-14.218-14.222v-199.11c.02-7.847 6.375-14.199 14.218-14.222h92.446c35.324.05 63.949 28.675 64 64A63.433 63.433 0 01331.235 256a63.433 63.433 0 0124.32 49.777zm0 0" />
            <path d="M291.555 270.223h-78.223v71.109h78.223c19.636 0 35.554-15.918 35.554-35.555 0-19.636-15.918-35.554-35.554-35.554zm0 0M291.555 170.668h-78.223v71.11h78.223c19.636 0 35.554-15.919 35.554-35.555 0-19.637-15.918-35.555-35.554-35.555zm0 0" />
        </svg>
    );
}

export default ToBold;
