import React, {useEffect, useState} from "react";
import DatePicker from 'react-date-picker/dist/entry.nostyle';
import "../../../css/form/DatePicker.css";
import "../../../css/form/Calendar.css";

function InputRadio (props) {
    const { classname, value, text, input, selected, handleChange } = props;
    const [ first, setFirst ] = useState(true);
    const [ valInput, setValInput ] = useState("");
    const [ boxSearchDate, setBoxSearchDate ] = useState(false);

    const buildInput = () => {
        switch ( input ) {
            case "date" : return <DatePicker
                onChange={ setValInput }
                value={ valInput }
                format="dd/MM/yyyy"
                locale="fr-FR"
                dayPlaceholder="__"
                monthPlaceholder="__"
                yearPlaceholder="____"
                className="input"
                calendarIcon={ null }
                clearIcon={ null }
                onCalendarClose={ () => selectDate(value) }
            />
            default: break;
        }
    }

    const initDate = () => {
        setFirst(false);
        setValInput(new Date());
        handleChange(value, new Date());
    }

    const selectDate = val => {
        handleChange(val, valInput);
    }

    const updateValInput = event => {
        setValInput(event.currentTarget.value);
    }

    useEffect(() => {
        if (first && input === "date")
            initDate();
    })

    return (
        <div className={ "inputRadio " + (classname !== undefined && classname) + (selected && " selected") } onClick={ () => { handleChange(value, valInput) } }>
            <div className="puce" />
            {
                input !== undefined
                    ? buildInput()
                    : <p className="textRadio">{ text }</p>
            }
            {
                boxSearchDate && <DatePicker />
            }
            <div className="clearing" />
        </div>
    );
}

export default InputRadio;
