import React, {useEffect, useRef, useState} from "react";
import Search2Icon from "../icons/Search2Icon";
import NotificationIcon from "../icons/NotificationIcon";
import DeleteIcon from "../icons/DeleteIcon";
import "../../css/page/Header.css";

function Header (props) {
    const { page } = props;
    const [ inSearch, setInSearch ] = useState(false);
    const [ searchInput, setSearchInput ] = useState("");
    const searchBar = useRef(null);

    const handleInSearch = () => {
        if(inSearch)
            setSearchInput("");

        setInSearch(!inSearch);
    }

    const handleChangeSearchInput = event => {
        setSearchInput(event.currentTarget.value);
    }

    const titlePage = () => {
        switch (page) {
            case "dashboard": return "Tableau de bord";
            case "prospect":
            case "prospectCard": return "Prospects";
            case "client":
            case "clientCard": return "Clients";
            case "quote": return "Devis";
            case "voucher": return "Bons en attente";
            case "bill": return "Factures";
            case "sample": return "Prélèvements";
            case "partnership":
            case "partnershipCard": return "Partenaires";
            case "setting": return "Mes informations";
            case "setup": return "Systèmes de caisse";
            case "prospecting": return "Prospection";
            case "settingTicket": return "Reglages des tickets";
            case "category": return "Catégories";
            case "brand": return "Marques";
            case "provider":
            case "providerCard": return "Fournisseurs";
            case "tax": return "TVA";
            case "product":
            case "productCard": return "Produits";
            default: return "";
        }
    }

    useEffect(() =>{
        if(inSearch)
            searchBar.current.focus();
    });

    return (
        <div className="header">
            <p className="title">{ titlePage() }</p>
            <div className="actions">
                <div className="icon show">
                    <NotificationIcon />
                </div>
                <div className={ "icon " + (!inSearch ? "show" : "") } onClick={ handleInSearch }>
                    <Search2Icon />
                </div>
                <div className={ "icon close " + (inSearch ? "show" : "") } onClick={ handleInSearch }>
                    <DeleteIcon />
                </div>
                <input className={ "searchBar " + (inSearch ? "show" : "") } type="text" value={ searchInput } onChange={ handleChangeSearchInput } placeholder="Rechercher" ref={searchBar} />
            </div>
        </div>
    );
}

export default Header;
