import React, {useContext, useEffect, useState} from "react"
import ContactController from "../../../stories/_client/Contacts/ContactController"
import LoaderCircle from "../../loader/LoaderCircle"
import InputText from "../../form/input/InputText"
import InputMultiLinesChoices from "../../form/input/InputMultiLinesChoices"
import SessionContext from "../../../context/SessionContext"
import StoreContext from "../../../context/StoreContext"
import StoreController from "../../../stories/_client/Stores/StoreController"
import FormBuilder from "../../../class/tool/FormBuilder";

function SearchContact (props) {
    const rules = [
        { type: "text", value: "ADMIN", master: true },
        { type: "text", value: "MANAGER" },
        { type: "text", value: "ACCOUNTANT" },
        { type: "text", value: "ANALYST" },
        { type: "text", value: "PRODUCTS" },
        { type: "text", value: "TECH" }
    ]
    const rulesDictionary = {
        "ADMIN": "Administrateur",
        "MANAGER": "Manager",
        "ACCOUNTANT": "Comptable",
        "ANALYST": "Analyste",
        "PRODUCTS": "Catalogue",
        "TECH": "Technicien"
    }

    const { handleClose, handleAttach, saving, multi, inStore, title, noRule } = props
    const { handleLogout, setGlobalError } = useContext(SessionContext)
    const { store } = useContext(StoreContext)
    const [ loading, setLoading ] = useState(true)
    const [ listContacts, setListContacts ] = useState([])
    const [ listSelected, setlistSelected ] = useState([])
    const [ input, setInput ] = useState("")
    const [ values, setValues ] = useState(null)
    const [ errors, setErrors ] = useState([])
    const [ rows, setRows ] = useState([
        {
            label: "Droits sur le groupe",
            attribute: "companyRule",
            inputType: "tag",
            returnType: "array",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucun droit",
            list: rules,
            dictionary: rulesDictionary,
            titleButton: "Modifier"
        },
        {
            label: "Droits sur la boutique",
            attribute: "rule",
            inputType: "tag",
            returnType: "array",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucun droit",
            list: rules,
            dictionary: rulesDictionary,
            titleButton: "Modifier"
        }
    ])

    const handleChangeInput = event => {
        let inputVal = event.currentTarget.value

        setInput(inputVal)
        getContacts(inputVal)
    }
    const handleSelectChoiceList = id => {
        let listDatas = multi ? listSelected.slice() : []
        let idToRemove = -1

        for (let item in listContacts) {
            if(listContacts[item].id === id) {
                if(!multi) {
                    listDatas.push({
                        id: listContacts[item].id,
                        main: listContacts[item].lastname + " " + listContacts[item].firstname,
                        secondary: listContacts[item].companies_list
                    })

                    break
                }

                for (let i = 0; i < listSelected.length; i++) {
                    if(listSelected[i].id === id) {
                        idToRemove = i
                        break
                    }
                }

                if(idToRemove >= 0)
                    listDatas.splice(idToRemove, 1)
                else
                    listDatas.push({
                        id: listContacts[item].id,
                        main: listContacts[item].lastname + " " + listContacts[item].firstname,
                        secondary: listContacts[item].companies_list
                    })

                break
            }
        }

        setlistSelected(listDatas)
    }
    const getContacts = input => {
        setLoading(true)
        let controller

        if (inStore) {
            controller = new StoreController()
            controller._callback = handleGetContacts
            controller.indexContact(store, input)
        }
        else {
            controller = new ContactController()
            controller._callback = handleGetContacts
            controller.index(input)
        }
    }
    const handleGetContacts = (list, error) => {
        if(error)
            if(error.response !== undefined && error.response.status === 401)
                handleLogout()

        let listDatas = []

        for (let item in list)
            listDatas.push({
                id: list[item].id,
                main: list[item].lastname + " " + list[item].firstname,
                secondary: list[item].companies_list
            })

        setListContacts(listDatas)
        setLoading(false)
    }
    const saveAttachUser = () => {
        if (saving) return

        handleAttach(listSelected, values)
    }
    const initValues = () => {
        setValues(prev => ({
            ...prev,
            companyRule: [],
            rule: []
        }))
    }
    const handleChange = (attribute, returnType, val, strict = false) => {
        let value = FormBuilder.buildVal(returnType, val)
        let filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "int")
        let index = value

        if (!strict && filtered.length > 0 && filtered[0].list.length > 0) {
            value = parseInt(filtered[0].list[index].id)

            if (filtered[0].list[index].type !== undefined && values[attribute.replace("_id", "_type")] !== undefined) {
                setValues(prev => ({
                    ...prev,
                    [attribute]: value,
                    [attribute.replace("_id", "_type")]: filtered[0].list[index].type
                }))
            }
            else {
                setValues(prev => ({
                    ...prev,
                    [attribute]: value
                }))
            }
        }
        else {
            setValues(prev => ({
                ...prev,
                [attribute]: value
            }))
        }
    }

    useEffect(() => {
        initValues()
        getContacts("")
    }, [])

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox search">
                    <div className="contForm">
                        <div className="form">
                            {
                                title !== undefined && <p className="titleForm center">{ title }</p>
                            }
                            <InputText classError="" value={ input } placeholder="Recherche..." newItem={ true } handleChange={ handleChangeInput } />
                            {
                                loading && <LoaderCircle display="loader searchBarLoading lessMarginRight" hide="" strokeWidth="5" stroke="#008C4F" />
                            }
                            <InputMultiLinesChoices list={ listContacts } listSelected={ listSelected } handleSelectChoiceList={ handleSelectChoiceList } />
                            {
                                (noRule !== undefined && !noRule)
                                    && <div className="clearing">
                                        {
                                            values !== null
                                                ?  rows.map((row, index) => (
                                                    <div key={ index } className="clearing">
                                                        {
                                                            row.noLabel === undefined
                                                            && <label className={ row.classnameLabel }>{ row.label }</label>
                                                        }
                                                        {
                                                            FormBuilder.buildInputByType(row, values, errors, handleChange, null, null)
                                                        }
                                                    </div>
                                                ))
                                                : <LoaderCircle display="loader formWait" hide="" strokeWidth="8" stroke="#008C4F" />
                                        }
                                    </div>
                            }
                            <div className="marge" />
                            <button className="submit" onClick={ saveAttachUser }>
                                {saving ? "Ajout en cours..." : "Ajouter"}
                                <LoaderCircle display="loader submitLogin" hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                            </button>
                            <button className="cancel" onClick={ handleClose }>Annuler</button>
                            <div className="clearing" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchContact
