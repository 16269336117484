import React, {useContext} from "react";
import ProductLine from "./ProductLine";
import LoaderCircle from "../../../../loader/LoaderCircle";
import Error from "../../../../error/Error";

import SearchContext from "../../../../../context/SearchContext";

import "../../../../../css/page/listingTables/Table.css";
import "../../../../../css/page/listingTables/Line.css";
import "../../../../../css/page/listingTables/ProductLine.css";

function ProductTable (props) {
    const { handleUpdate } = props;
    const { generalError, initialLoading, list } = useContext(SearchContext);

    return (
        <div className="wrapTable productwrap">
            <table className="table product borderBottom">
                <tbody>
                    <tr className="line">
                        <th className="name left">PRODUIT</th>
                        <th className="reference left">RÉFÉRENCE</th>
                        <th className="category left">CATEGORIE</th>
                        <th className="brand left">MARQUE</th>
                        <th className="partnership left">PARTENAIRE</th>
                        <th className="action">
                            <div className="action" />
                        </th>
                    </tr>
                </tbody>
            </table>
            <div className="list">
                {
                    !initialLoading
                    ? (
                        generalError === ""
                        ? <table className="table product">
                                <tbody>
                                {
                                    list.length > 0
                                    ? list.map(product => (
                                        <ProductLine key={ product.id } product={ product } />
                                    ))
                                    : <tr className="line">
                                        <td className="left" colSpan="6">Aucun produit</td>
                                    </tr>
                                }
                                </tbody>
                            </table>
                        : <Error text={ generalError } />
                    )
                    : <LoaderCircle display="loader logWait" hide="" strokeWidth="5" stroke="#008C4F" />
                }

            </div>
        </div>
    );
}

export default ProductTable;
