import React from "react";
import Error from "../../../error/Error";
import TicketsLine from "./TicketsLine";

import "../../../../css/page/ticket/Tickets.css";
import "../../../../css/page/listingTables/Table.css";
import "../../../../css/page/listingTables/Line.css";
import "../../../../css/page/listingTables/TicketsLine.css";

function TicketsTable (props) {
    const { tickets, error } = props;

    return (
        <div className="wrapTable tickets">
            {
                error !== "" && <Error text={ error } classname="noMargin" />
            }
            <table className="table tickets borderBottom">
                <tbody>
                    <tr className="line">
                        <th className="number left">#</th>
                        <th className="title left">TITRE</th>
                        <th className="type left">TYPE</th>
                        <th className="priority center">PRIORITE</th>
                        <th className="state center">ETAT</th>
                        <th className="date left">DATE</th>
                        <th className="action">
                            <div className="action" />
                        </th>
                    </tr>
                </tbody>
            </table>
            <div className="list">
                {
                    <table className="table tickets">
                        <tbody>
                        {
                            tickets.length > 0
                                ? tickets.map(object => (
                                    <TicketsLine key={ object.id } object={ object } />
                                ))
                                : <tr className="line">
                                    <td className="left" colSpan="6">Aucun ticket</td>
                                </tr>
                        }
                        </tbody>
                    </table>
                }
            </div>
        </div>
    );
}

export default TicketsTable;
