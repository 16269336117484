import React, {useEffect, useState} from "react";
import PartnershipController from "../../../stories/_auth/Partnerships/PartnershipController";

function InputFile (props) {
    const { color, idname, classname, file, setFile, filePath, progress, handleSubmit } = props;
    const [ selected, setSelected ] = useState(false);

    const selectFile = () => {
        document.getElementById(idname).click();
    }

    const attachFile = event => {
        setFile(event.currentTarget.files[0].name);
        setSelected(true);
    }

    const submitForm = () => {
        setSelected(false);
        handleSubmit();
    }

    useEffect(() => {
        if (selected && file !== "")
            submitForm();
    })

    return (
        <form id="form" method="POST" encType="multipart/form-data">
            <div className={ "inputFile" + (classname !== undefined ? " " + classname : "") + (color === "#FFFFFF" ? " white" : " black") } onClick={ selectFile }>
                <input type="file" id={ idname } name={ idname } onChange={ attachFile } />
                {
                    filePath.length > 0 && <img className="img" src={ filePath } alt="logo" />
                }
                <div className="progressBar" style={{width: (progress < 100 ? progress : 0) + '%'}} />
            </div>
        </form>
    );
}

export default InputFile;
