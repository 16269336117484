import React, {useContext, useEffect, useState} from "react";
import PartnershipUserGroupsSearchBar from "../table/PartnershipUserGroupsSearchBar";
import PartnershipUserGroupsTable from "../table/PartnershipUserGroupsTable";
import FormUserGroup from "../../../../overbox/userGroup/FormUserGroup";
import DeleteUserGroup from "../../../../overbox/userGroup/DeleteUserGroup";

import PartnershipController from "../../../../../stories/_auth/Partnerships/PartnershipController";
import UserGroupController from "../../../../../stories/_auth/UserGroups/UserGroupController";

import SearchContext from "../../../../../context/SearchContext";
import SessionContext from "../../../../../context/SessionContext";
import PartnershipContext from "../../../../../context/PartnershipContext";

import "../../../../../css/page/cards/CardUsers.css";

function PartnershipCardGroups () {
    const { handleLogout } = useContext(SessionContext);
    const { partnership } = useContext(PartnershipContext);
    const [ first, setFirst ] = useState(true);
    const [ generalError, setGeneralError ] = useState("");
    const [ list, setList ] = useState([]);
    const [ inputSearch, setInputSearch ] = useState("");
    const [ initialLoading, setInitialLoading ] = useState(true);
    const [ loading, setLoading ] = useState(true);
    const [ formUpdateUserGroup, setFormUpdateUserGroup ] = useState(false);
    const [ idUserGroupToUpdate, setIdUserGroupToUpdate ] = useState(0);
    const [ boxDeleteUserGroup, setBoxDeleteUserGroup ] = useState(false);
    const [ userGroupToDelete, setUserGroupToDelete ] = useState({});
    const [ deleting, setDeleting ] = useState(false);
    const valueSearchContext = {
        generalError,
        list,
        inputSearch,
        initialLoading,
        loading
    };

    const handleChangeInputSearch = event => {
        let input = event.currentTarget.value;
        setInputSearch(input);
        setLoading(true);
        getPartnershipUserGroups(input);
    }

    const showFormUpdateUserGroup = id => {
        setFormUpdateUserGroup(true);
        setIdUserGroupToUpdate(id);
    }
    const closeFormUpdateUserGroup = () => {
        setFormUpdateUserGroup(false);
    }

    const getPartnershipUserGroups = (input = "") => {
        const controller = new PartnershipController();
        controller._callback = handleGetPartnershipUserGroups;
        controller.indexUserGroups(partnership, input);
    }
    const handleGetPartnershipUserGroups = (list, error) => {
        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else
                setGeneralError("Impossible de récupérer les données");
        }
        console.log("LIST => ", list);
        setFirst(false);
        setInitialLoading(false);
        setLoading(false);
        setList(list);
    }

    const handleBoxDeleteUserGroup = idUserGroup => {
        for (var item in list) {
            if (list[item].id === idUserGroup) {
                setUserGroupToDelete(list[item]);
                break;
            }
        }

        setFormUpdateUserGroup(false);
        setBoxDeleteUserGroup(true);
    }
    const handleCloseBoxDeleteUserGroup = () => {
        setBoxDeleteUserGroup(false);
        setIdUserGroupToUpdate(0);
        setDeleting(false);
    }
    const handleDeleteUserGroup = () => {
        setDeleting(true);

        const controller = new UserGroupController();
        controller._callback = handleReturnDeleteUserGroup;
        controller.delete(userGroupToDelete);
    }
    const handleReturnDeleteUserGroup = (response, error) => {
        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else
                setGeneralError("Impossible de supprimer le groupe");
        }
        else {
            setBoxDeleteUserGroup(false);
            setIdUserGroupToUpdate(0);
            setDeleting(false);

            getPartnershipUserGroups();
        }
    }

    useEffect(() => {
        if(first)
            getPartnershipUserGroups();
    });

    return (
        <div className="cardUsers">
            <SearchContext.Provider value={valueSearchContext}>
                <PartnershipUserGroupsSearchBar handleChangeInputSearch={ handleChangeInputSearch } />
                <PartnershipUserGroupsTable handleShowFormUpdateUserGroup={ showFormUpdateUserGroup } />
                {
                    formUpdateUserGroup && <FormUserGroup handleCloseFormUserGroup={ closeFormUpdateUserGroup } handleBoxDeleteUserGroup={ handleBoxDeleteUserGroup } handleUpdate={ getPartnershipUserGroups } newItem={ false } idUserGroup={ idUserGroupToUpdate } />
                }
                {
                    boxDeleteUserGroup && <DeleteUserGroup userGroup={ userGroupToDelete } deleting={ deleting } handleDeleteUserGroup={ handleDeleteUserGroup } handleCloseBoxDeleteUserGroup={ handleCloseBoxDeleteUserGroup } />
                }
            </SearchContext.Provider>
        </div>
    );
}

export default PartnershipCardGroups;
