import Rest from "../../../class/tool/Rest";
import Address from "./Address";

class AddressController {
    _rest = new Rest();
    _callback = null;
    _baseURL = "addresses";

    index(type, input= "") {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + "?" + type + (input !== "" ? "&input=" + input : ""),
            {},
            this.handleIndex);
    }
    handleIndex = (response, error) => {
        let listObjects = [];

        if(response.data !== undefined) {
            const data = response.data.data;

            for(var item in data)
                listObjects.push(new Address(data[item]));
        }

        this._callback(listObjects, error);
    }

    show(id) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + '/' + id,
            {},
            this.handleShow);
    }
    handleShow = (response, error) => {
        const object = !error ? new Address(response.data) : null;
        this._callback(object, error);
    }

    post(object) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURL,
            object,
            this.handlePost
        );
    }
    handlePost = (response, error) => {
        this._callback(response, error);
    }

    put(object) {
        const objectToSend = JSON.parse(JSON.stringify(object));
        delete objectToSend.type_name;

        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'PUT',
            '/' + this._baseURL + '/' + objectToSend.id,
            objectToSend,
            this.handlePut
        );
    }
    handlePut = (response, error) => {
        this._callback(response, error);
    }

    delete(object, secure = false) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'DELETE',
            '/' + this._baseURL + '/' + object.id + (secure && '?secure'),
            {},
            this.handleDelete);
    }
    handleDelete = (response, error) => {
        this._callback(response, error);
    }

    returnUpdatesFromCompare(object, datas) {
        var dataKeys = Object.keys(datas);
        var key = "";
        var updates = {};

        for(var i in dataKeys) {
            key = dataKeys[i];

            if (datas[key] !== undefined)
                if (Reflect.get(object, key) !== datas[key])
                    Reflect.set(updates, key, datas[key]);
        }

        return updates;
    }
}

export default AddressController;
