import React from "react";

function CatalogIcon (props) {
    const { fill } = props;

    return (
        <svg width="1em" height="1em" viewBox="0 0 323.863 323.863" fill={ fill }>
            <path d="M227.433 219.776l96.43-91.616-60.143-63.307zM194.469 122.104l1.83 5.711 24.02 74.986 29.457-125.793 2.246-9.579 2.126-9.063-.348-.081-85.45-20.013-.317 1.334 24.606 76.79zM160.043 50.05l-3.2-9.995-.907-2.825-83.912 26.882 3.078 9.6 59.842 59.843 4.494 4.499 4.493 4.492 10.32 10.32 3.823 3.819 3.818 3.823 49.109 49.1.079-.257-23.482-73.272-1.826-5.714-1.824-5.709-20.696-64.599zM62.303 68.558l-.15.15L0 130.86l109.851 109.852 4.493 4.492 4.493 4.493 1.912 1.914 1.376 1.377 5.745 5.748 1.221 1.218 4.52 4.52 3.829 3.828 4.288 4.288 4.002 4.002 4.003 3.997 6.035 6.044 19.296-19.301.433-.428.849-.849 4.261-4.261 2.162-2.162 2.948-2.953 2.125-2.125 1.35-1.351 11.443-11.438.586-.591 8.189-8.19 5.021-5.015 3.175-3.175.469-.475-.248-.242-2.014-2.015-2.305-2.31-4.319-4.313-51.21-51.216-3.818-3.815-3.818-3.818-7.852-7.852-4.493-4.493-4.493-4.493L78.698 84.95l-8.343-8.343-8.052-8.049zM162.728 216.18c8.87 0 16.063 7.192 16.063 16.062 0 8.875-7.193 16.063-16.063 16.063-8.872 0-16.065-7.193-16.065-16.063s7.192-16.062 16.065-16.062z" />
        </svg>
    );
}

export default CatalogIcon;
