import React, { useEffect, useState } from "react"
import AddIcon from "../../../icons/AddIcon"
import InputSwitch from "./InputSwitch"
import DeleteIcon from "../../../icons/DeleteIcon"

const InputTag = props => {
    const { attribute, params, returnType, inputText, classname, values, list, dictionary, titleButton, handleChange } = props
    const [ listShowed, setListShowed ] = useState(false)
    const [ listDatas, setListDatas ] = useState([])

    const buildListDatas = () => {
        let datas = [];

        for (let index in list) {
            datas.push({
                id: list[index].id !== undefined ? list[index].id : 0,
                label: buildDenomination(list[index]),
                value: buildIsIncluded(list[index]),
                master: list[index].master !== undefined ? list[index].master : false
            })
        }

        setListDatas(datas);
    }
    const atLeastOneMaster = () => {
        for (let i in listDatas)
            if (listDatas[i].master)
                return true

        return false
    }
    const masterProcess = () => {
        if (!atLeastOneMaster())
            return

        let datas = listDatas.slice()
        if (datas.length === 0) return

        let updated = false
        let countUnmasters = 0

        for (let i in datas)
            if (!datas[i].master && datas[i].value === 1)
                countUnmasters++

        if (countUnmasters === (datas.length - 1)) {
            for (let i in datas) {
                if (datas[i].master) {
                    updated = true
                    datas[i].value = 1
                }
                else {
                    updated = true
                    datas[i].value = 0
                }
            }
        }

        if (updated)
            setListDatas(datas)
    }
    const changeChoice = (attribute, returnType, val) => {
        let index
        let datas = listDatas.slice()
        if (datas.length === 0) return

        if (datas[0].id !== 0)
            index = datas.findIndex(_ => _.id === attribute)
        else
            index = datas.findIndex(_ => _.label === attribute)

        datas[index].value = val

        if (atLeastOneMaster()) {
            if (datas[index].master && datas[index].value === 1) {
                for (let i in datas) {
                    if (parseInt(i) !== index)
                        datas[i].value = 0
                }
            }
            else {
                for (let i in datas) {
                    if (datas[i].master)
                        datas[i].value = 0
                }
            }
        }

        setListDatas(datas)
    }
    const showList = () => {
        setListShowed(!listShowed);
    }
    const buildDenomination = (data) => {
        switch (data.type) {
            case "license": return data.object.name + " - " + data.object.number;
            case "text": return dictionary !== undefined ? dictionary[data.value] : data.value;
            default: return "";
        }
    }
    const buildIsIncluded = (data) => {
        switch (data.type) {
            case "license": return values.includes(data.id) ? 1 : 0;
            case "text": return values.includes(data.value) ? 1 : 0;
            default: return "";
        }
    }
    const buildChoice = (choice, index) => {
        return <li key={ index }>
            <InputSwitch
                attribute={ choice.id !== 0 ? choice.id : choice.label }
                returnType="int"
                label={ choice.label }
                classname=""
                value={ choice.value }
                handleChange={ changeChoice } />
        </li>
    }
    const getObjectLabel = val => {
        if (list.length === 0) return

        let index

        if (list[0].id !== undefined)
            index = list.findIndex(_ => _.id === val)
        else
            index = list.findIndex(_ => _.value === val)

        if (index >= 0)
            return buildDenomination(list[index]);
        else
            return "";
    }
    const valuesToArray = () => {
        let returnValues = []
        let value

        for (let index in listDatas) {
            if (listDatas[index].value === 1) {
                if (listDatas[index].id !== 0)
                    value = listDatas[index].id
                else {
                    if (dictionary === undefined)
                        value = listDatas[index].label
                    else {
                        for (let key in dictionary) {
                            if (listDatas[index].label === dictionary[key]) {
                                value = key
                                break
                            }
                        }
                    }
                }

                returnValues.push(value);
            }
        }

        return returnValues
    }
    const submitEvent = () => {
        showList();

        if (handleChange === null) return;
        handleChange(attribute, returnType, valuesToArray());
    }
    const deleteEvent = val => {
        let returnValues = valuesToArray()
        if (returnValues.includes(val))
            returnValues.splice(returnValues.indexOf(val), 1)

        if (handleChange === null) return
        handleChange(attribute, returnType, returnValues)
    }

    useEffect(() => {
        buildListDatas()
    }, [list, values])
    useEffect(() => {
        setTimeout(() => { masterProcess() }, 700)
    }, [listDatas])

    return (
        <div className={ "inputTag " + (classname !== undefined ? classname : "") }>
            <div className={ "containerTag " + (titleButton !== undefined ? "buttonWithTitle" : "buttonSvg") }>
                {
                    values !== undefined
                    && values.map((val, index) => (
                        <div key={ index } className="tag">
                            <p>{ getObjectLabel(val) }</p>
                            <DeleteIcon handleClick={ () => { deleteEvent(val) } } />
                        </div>
                    ))
                }
                {
                    inputText
                    && <input type="text" placeholder="Ajouter" />
                }
            </div>
            {
                titleButton !== undefined
                    ? <p className="button" onClick={ showList }>{ titleButton }</p>
                    : <AddIcon handleClick={ showList } />
            }
            {
                listShowed
                && <div className="overlayer">
                    <div className="wrapOverbox">
                        <div className="overbox subList">
                            <div className="form noPaddingT">
                                <ul className="listTag">
                                    {
                                        listDatas.length === 0
                                            ? <li className="empty">Aucun élément</li>
                                            : listDatas.map((choice, index) => buildChoice(choice, index))
                                    }
                                </ul>
                                <div className="marge" />
                                <button className="submit" onClick={ submitEvent }>Valider</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default InputTag
