import React from "react";
import AcquisitionChannelForm from "../../../form/prospecting/acquisitionChannel/AcquisitionChannelForm";

function FormAcquisitionChannel (props) {
    const { newItem, acquisitionChannel, handleClose, handleDelete } = props;

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox newItemSmall">
                    <AcquisitionChannelForm handleClose={ handleClose } handleDelete={ handleDelete } newItem={ newItem } acquisitionChannel={ acquisitionChannel } />
                </div>
            </div>
        </div>
    );
}

export default FormAcquisitionChannel;
