import React from "react"
import UpdateAccountForm from "../../form/account/UpdateAccountForm";
import UpdateAccountStoreRulesForm from "../../form/account/UpdateAccountStoreRulesForm";
import UpdateAccountCompanyRulesForm from "../../form/account/UpdateAccountCompanyRulesForm";

const UpdateAccount = props => {
    const { setup, object, typeForm, handleClose, handleSave } = props

    const callForm = () => {
        switch (typeForm) {
            case "account":
                return <UpdateAccountForm object={ object } setup={ setup } handleClose={ handleClose } handleSave={ handleSave } />
            case "storeRules":
                return <UpdateAccountStoreRulesForm object={ object } setup={ setup } handleClose={ handleClose } handleSave={ handleSave } />
            case "companyRules":
                return <UpdateAccountCompanyRulesForm object={ object } setup={ setup } handleClose={ handleClose } handleSave={ handleSave } />
            default: break
        }
    }

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox newItemFix">
                    {
                        callForm()
                    }
                </div>
            </div>
        </div>
    )
}

export default UpdateAccount
