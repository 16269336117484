import React from "react";
import {Link} from "react-router-dom";
import AccessIcon from "../../../../icons/AccessIcon";

function ProductLine (props) {
    const isDark = window.matchMedia('(prefers-color-scheme: dark)');
    const color = isDark.matches ? "#FFFFFF" : "#1D1D1D";
    const { product } = props;

    return (
        <tr className="line">
            <td className="name left">
                <Link to={ "/product/" + product.id + "/information" }>
                    { product.name }
                </Link>
            </td>
            <td className="reference left">
                <Link to={ "/product/" + product.id + "/information" }>
                    { product.reference }
                </Link>
            </td>
            <td className="category left">{ product.category_name }</td>
            <td className="brand left">{ product.brand_name }</td>
            <td className="partnership left">{ product.partnership_name }</td>
            <td className="action">
                <Link to={ "/product/" + product.id + "/information" }>
                    <div className="access">
                        <AccessIcon fill={ color } />
                    </div>
                </Link>
            </td>
        </tr>
    );
}

export default ProductLine;
