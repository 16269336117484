import React from "react";
import { Link } from "react-router-dom";
import LoaderCircle from "../../../loader/LoaderCircle";

import "../../../../css/page/cards/Tabs.css";

function ProspectPhaseTabs (props) {
    const { tab, sending } = props;

    return (
        <div className="tabs">
            <nav>
                <Link to={ "/prospecting/phase" }><p className={ "tab " + (tab === "phase" ? "selected" : "") }>Étapes de prospection</p></Link>
                <Link to={ "/prospecting/acquisitionchannel" }><p className={ "tab " + (tab === "acquisitionchannel" ? "selected" : "") }>Canaux d'acquisition</p></Link>
                <div className="clearing" />
            </nav>
            {
                sending && <LoaderCircle display="loader restPUT" hide="" strokeWidth="10" stroke="#008C4F" />
            }
            <div className="clearing" />
        </div>
    );
}

export default ProspectPhaseTabs;
