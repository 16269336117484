import React from "react";
import CheckIcon from "../../icons/CheckIcon";

function InputSelect (props) {
    const { color, classname, classError, value, list, dictionary, newItem, focus, handleChange, handleBlur, returnTextValue } = props;

    return (
        <div className={ "inputText " + (classname !== undefined ? classname : "") }>
            <select value={ value } className={ (newItem ? "extend " : "") + classError } onChange={ handleChange } onBlur={ handleBlur } autoFocus={ !newItem || focus } >
            {
                list.length > 0
                && list.map(object => (
                    <option key={ object.key } value={ returnTextValue !== undefined ? object.value : object.key }>
                        {
                            dictionary !== undefined && dictionary[object.value] !== undefined
                            ? dictionary[object.value]
                            : object.value
                        }
                    </option>
                ))
            }
            </select>
            {
                !newItem && <CheckIcon fill={color} handleClick={handleBlur} />
            }
        </div>
    );
}

export default InputSelect;
