import React, {useContext} from "react";
import SessionContext from "../../context/SessionContext";

import "../../css/error/Error.css";

function Error (props) {
    const { text } = props;
    const { setGlobalError } = useContext(SessionContext);


    // Perform

    const remove = () => {
        setGlobalError("");
    }


    // Render

    return (
        <p className="globalError" onClick={ remove }>{ text }</p>
    );

}

export default Error;
