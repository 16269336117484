import React from "react";
import moment from "moment";
import {Markup} from "interweave";

function HistoryConversation (props) {
    moment.updateLocale('fr');

    const { item } = props;
    const color = window.matchMedia('(prefers-color-scheme: dark)').matches ? "#FFFFFF" : "#1D1D1D";


    // Perform

    const displayDate = strDate => {
        return <span className="date">Le <b>{ moment(strDate).format("DD/MM/YYYY")} </b> à <b> { moment(strDate).format("hh:mm") }</b></span>;
    }
    const fromNow = strDate => {
        return <span className="fromNow">il y a <b>{ moment(strDate).fromNow() }</b></span>;
    }


    // Render

    return (
        <div className="itemHistory">
            <p className="creatorInfo">
                Créé par <b>{ item.created_by_name }</b><br />
                { displayDate(item.created_at) }, { fromNow(item.created_at) }
            </p>
            <div className="message">
                <Markup content={item.buildedMessage !== undefined ? item.buildedMessage : item.message} />
            </div>
        </div>
    );
}

export default HistoryConversation;
