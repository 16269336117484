import React, {useContext, useEffect, useState} from "react"
import SetupInstallationPhases from "./SetupInstallationPhases"
import SetupLicensesTable from "./SetupLicensesTable"
import SetupAccountsTable from "./SetupAccountsTable"
import LicenseController from "../../../../../stories/_setup/Licenses/LicenseController"
import SetupController from "../../../../../stories/_setup/Setup/SetupController"
import UpdateLicense from "../../../../overbox/license/UpdateLicense"
import AddAccount from "../../../../overbox/account/AddAccount"
import SearchContact from "../../../../overbox/contact/SearchContact"
import AddLicense from "../../../../overbox/license/AddLicense"
import ConfirmInstallLicense from "../../../../overbox/license/ConfirmInstallLicense"
import SessionContext from "../../../../../context/SessionContext"
import "../../../../../css/page/setup/Setup.css"
import NewAccount from "../../../../overbox/account/NewAccount";
import AccountController from "../../../../../stories/_setup/Accounts/AccountController";

function SetupView (props) {
    const { store, setup } = props
    const { handleLogout, setGlobalError } = useContext(SessionContext)
    const [ error, setError ] = useState("")
    const [ loadingLicenses, setLoadingLicenses ] = useState(false)
    const [ loadingAccounts, setLoadingAccounts ] = useState(false)
    const [ loadingDevices, setLoadingDevices ] = useState(false)
    const [ sendingLicense, setSendingLicense ] = useState(false)
    const [ sendingAccounts, setSendingAccounts ] = useState(false)
    const [ listLicenses, setListLicenses ] = useState([])
    const [ listAccounts, setListAccounts ] = useState([])
    const [ listDevices, setListDevices ] = useState([])
    const [ formUpdateLicense, setFormUpdateLicense ] = useState(false)
    const [ formAddLicense, setFormAddLicense ] = useState(false)
    const [ formAddAccount, setFormAddAccount ] = useState(false)
    const [ formNewAccount, setFormNewAccount ] = useState(false)
    const [ formUpdateAccount, setFormUpdateAccount ] = useState(false)
    const [ formSearchContact, setFormSearchContact ] = useState(false)
    const [ licenseSelected, setLicenseSelected ] = useState(null)
    const [ accountSelected, setAccountSelected ] = useState(null)
    const [ loaderEnableInstallation, setLoaderEnableInstallation ] = useState(false)
    const [ closeOptions, setCloseOptions ] = useState(false)
    const [ licenseIdToInstall, setLicenseIdToInstall ] = useState(null)
    const [ licenseIdToInstallReRe, setLicenseIdToInstallReRe ] = useState(null)

    const returnGetListLicenses = (list, error) => {
        setLoadingLicenses(false);

        if(!error)
            setListLicenses(list);
    }
    const getListLicenses = () => {
        setLoadingLicenses(true);

        const controller = new LicenseController();
        controller._callback = returnGetListLicenses;
        controller.index(setup.id);
    }
    const returnGetListAccounts = (list, error) => {
        setLoadingAccounts(false);

        if(!error)
            setListAccounts(list);
    }
    const getListAccounts = () => {
        setLoadingAccounts(true);

        const controller = new AccountController();
        controller._callback = returnGetListAccounts;
        controller.indexAccounts(setup);
    }
    const returnGetListDevices = (list, error) => {
        setLoadingDevices(false);

        if(!error)
            setListDevices(list);
    }
    const getListDevices = () => {
        return;
        setLoadingDevices(true);

        const controller = new SetupController();
        controller._callback = returnGetListDevices;
        controller.indexDevices(setup);
    }
    const getLicenseObject = id => {
        for (var item in listLicenses)
            if (listLicenses[item].id === id)
                return listLicenses[item];

        return null;
    }
    const handleSetCloseOptions = val => {
        setCloseOptions(val);
    }
    const closeFormUpdateLicense = () => {
        setFormUpdateLicense(false);
        setLicenseSelected(null);
    }
    const handleUpdateLicense = id => {
        handleSetCloseOptions(true);
        setFormUpdateLicense(true);
        setLicenseSelected(getLicenseObject(id));
    }
    const handleConfirmInstallLicense = id => {
        setLicenseIdToInstall(id)
    }
    const closeConfirmInstallLicense = () => {
        setLicenseIdToInstall(null)
    }
    const handleConfirmInstallLicenseReRe = id => {
        setLicenseIdToInstall(null)
        setLicenseIdToInstallReRe(id)
    }
    const closeConfirmInstallLicenseReRe = () => {
        setLicenseIdToInstallReRe(null)
    }
    const handleInstallLicense = id => {
        if (loaderEnableInstallation) return

        setLoaderEnableInstallation(true)
        handleSetCloseOptions(false)

        const licenseSelected = getLicenseObject(id)

        if (licenseSelected === null) {
            setLoaderEnableInstallation(false)
            handleSetCloseOptions(true)
            setError("La licence sélectionnée est introuvable")
            return
        }

        const controller = new LicenseController()
        controller._callback = handleSendInstallLicense
        controller.enablingInstallation(setup.id, licenseSelected)
    }
    const handleSendInstallLicense = (response, error) => {
        if (error)
            setError("Une erreur s'est produite dans la demande d'installation");

        setLoaderEnableInstallation(false)
        closeConfirmInstallLicenseReRe()
    }
    const handleAddLicense = () => {
        setFormAddLicense(!formAddLicense);
    }
    const handleCreateLicense = (response, error) => {
        setSendingLicense(false);

        if(error) {
            if(error.response !== undefined && error.response.status !== undefined) {
                if(error.response.status === 401)
                    handleLogout();
                else if(error.response.status === 422)
                    setGlobalError("Certaines données sont invalides");
                else {
                    if (typeof error.response.data === "object")
                        setGlobalError(error.response.data.message);
                    else
                        setGlobalError(error.response.data);
                }
            }
            else
                setGlobalError("Une erreur s'est produite lors de la création d'une licence");
        }

        handleAddLicense()
        getListLicenses()
    }
    const createLicense = () => {
        if (sendingLicense) return;

        setSendingLicense(true);

        let controller = new LicenseController();
        controller._callback = handleCreateLicense;
        controller.post(setup.id, {
            "type": "POS_WINDOWS",
            "main": false,
            "forwardSkyTill": true
        });
    }
    const getAccountObject = id => {
        for (var item in listAccounts)
            if (listAccounts[item].id === id)
                return listAccounts[item]

        return null
    }
    const closeFormAddAccount = () => {
        setFormAddAccount(false)
    }
    const handleAddAccount = () => {
        setFormAddAccount(true)
    }
    const closeFormNewAccount = () => {
        setFormNewAccount(false)
    }
    const handleNewAccount = () => {
        setFormAddAccount(false)
        setFormNewAccount(true)
    }
    const closeFormUpdateAccount = () => {
        setFormUpdateAccount(false);
        setAccountSelected(null);
    }
    const handleUpdateAccount = id => {
        handleSetCloseOptions(true)
        setFormUpdateAccount(true)
        setAccountSelected(getAccountObject(id))
    }
    const closeFormSearchContact = () => {
        setFormSearchContact(false)
    }
    const handleSearchContact = () => {
        closeFormAddAccount()
        setFormSearchContact(true)
    }
    const handleCreateAccounts = (response, error) => {
        setSendingAccounts(false)

        if(error) {
            if(error.response !== undefined && error.response.status !== undefined) {
                if(error.response.status === 401)
                    handleLogout()
                else if(error.response.status === 422)
                    setGlobalError("Certaines données sont invalides")
                else {
                    if (typeof error.response.data === "object")
                        setGlobalError(error.response.data.message)
                    else
                        setGlobalError(error.response.data)
                }
            }
            else
                setGlobalError("Une erreur s'est produite lors de la création des comptes utilisateurs SkyTill")
        }

        closeFormSearchContact()
        getListAccounts()
    }
    const createAccounts = (contacts, rules) => {
        if (sendingAccounts) return

        setSendingAccounts(true)

        let ids = []

        for (let item in contacts)
            ids.push(contacts[item].id)

        let controller = new AccountController()
        controller._callback = handleCreateAccounts
        controller.registerAccounts(setup, ids, rules)
    }
    const handleCreateTechnicianAccount = (response, error) => {
        setSendingAccounts(false)

        if(error) {
            if(error.response !== undefined && error.response.status !== undefined) {
                if(error.response.status === 401)
                    handleLogout()
                else if(error.response.status === 422)
                    setGlobalError("Certaines données sont invalides")
                else {
                    if (typeof error.response.data === "object")
                        setGlobalError(error.response.data.message)
                    else
                        setGlobalError(error.response.data)
                }
            }
            else
                setGlobalError("Une erreur s'est produite lors de la création du compte technicien")
        }

        closeFormAddAccount()
        getListAccounts()
    }
    const createTechnicianAccount = () => {
        if (sendingAccounts) return

        setSendingAccounts(true)

        let controller = new AccountController()
        controller._callback = handleCreateTechnicianAccount
        controller.registerTechnicianAccount(setup)
    }

    useEffect(() => {
        document.title = "HIVY - " + store.name + " - Installation " + setup.type_name

        getListLicenses()
        getListAccounts()
        getListDevices()
    }, [])

    return (
        <div className="setup">
            {
                (formUpdateLicense && licenseSelected !== null)
                && <UpdateLicense setup={setup} handleClose={closeFormUpdateLicense} handleRefresh={getListLicenses} object={licenseSelected} />
            }
            {
                formAddLicense
                && <AddLicense store={store} handleClose={handleAddLicense} handleNew={createLicense} saving={ sendingLicense } />
            }
            {
                licenseIdToInstall !== null
                && <ConfirmInstallLicense license={ licenseIdToInstall } handleClose={ closeConfirmInstallLicense } handleInstall={ handleConfirmInstallLicenseReRe } />
            }
            {
                licenseIdToInstallReRe !== null
                && <ConfirmInstallLicense license={ licenseIdToInstallReRe } handleClose={ closeConfirmInstallLicenseReRe } handleInstall={ handleInstallLicense } saving={ loaderEnableInstallation } />
            }
            {
                formAddAccount
                && <AddAccount
                    store={ store }
                    handleClose={ closeFormAddAccount }
                    handleNew={ handleNewAccount }
                    handleSearch={ handleSearchContact }
                    handleNewTechnician={ createTechnicianAccount } />
            }
            {
                formNewAccount
                && <NewAccount setup={ setup } handleClose={closeFormNewAccount} handleRefresh={ getListAccounts } />
            }
            {
                /*
                (formUpdateAccount && accountSelected !== null)
                && <UpdateAccount store={store} handleClose={closeFormUpdateAccount} handleRefresh={getListAccounts} object={accountSelected} />
                 */
            }
            {
                formSearchContact
                && <SearchContact handleClose={ closeFormSearchContact } handleAttach={ createAccounts } saving={ sendingAccounts } multi={ true } inStore={ true } title="Pour qui créer un compte SkyTill Manager ?" noRule={ false } />
            }
            <div className="wrapperTables">
                <SetupLicensesTable
                    setup={ setup }
                    loading={ loadingLicenses }
                    error={ error }
                    list={ listLicenses }
                    closeOptions={ closeOptions }
                    handleSetCloseOptions={ handleSetCloseOptions }
                    handleAddLicense={ handleAddLicense }
                    handleUpdateLicense={ handleUpdateLicense }
                    handleInstallLicense={ handleConfirmInstallLicense } />
                <SetupAccountsTable
                    setup={ setup }
                    loading={ loadingAccounts }
                    error={ error }
                    list={ listAccounts }
                    closeOptions={ closeOptions }
                    handleRefresh={ getListAccounts }
                    handleSetCloseOptions={ handleSetCloseOptions }
                    handleAddAccount={ handleAddAccount } />
                {
                    /*
                    <SetupDevicesTable
                        setup={ setup }
                        loading={ loadingDevices }
                        error={ error }
                        list={ listDevices }
                        closeOptions={ closeOptions }
                        handleSetCloseOptions={ handleSetCloseOptions } />
                     */
                }
            </div>
            <SetupInstallationPhases setup={ setup } />
        </div>
    )
}

export default SetupView