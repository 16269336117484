import React from "react";
import ProviderForm from "../../form/provider/ProviderForm";

function FormProvider (props) {
    const { newItem, handleClose, handleDelete, handleRefresh } = props;

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox newItem">
                    <ProviderForm handleClose={ handleClose } handleDelete={ handleDelete } handleRefresh={ handleRefresh } newItem={ newItem } />
                </div>
            </div>
        </div>
    );
}

export default FormProvider;
