import React, { useEffect, useState } from "react";
import $ from "jquery";

const InputBidirectionalList = props => {
    const {
        attribute,
        labelLeft,
        labelRight,
        labelButtonLeft,
        labelButtonRight,
        emptyText,
        classname,
        classnameLabel,
        classnameInputLiLeft,
        classnameInputLiRight,
        maxHeight,
        updated,
        value,
        list,
        handleChange
    } = props;
    const [ maxHeightCalculated, setMaxHeightCalculated ] = useState(0);

    const toRightOne = id => {
        let valuesToSend = value.slice();
        let index = list.findIndex(_ => _.id === id);

        if (index >= 0) {
            valuesToSend.push(Object.assign({}, list[index]));
            sendChanges(valuesToSend);
        }
    }
    const toLeftOne = id => {
        let valuesToSend = value.slice();
        let index = valuesToSend.findIndex(_ => _.id === id);

        if (index >= 0) {
            valuesToSend.splice(index, 1);
            sendChanges(valuesToSend);
        }
    }
    const toRightAll = () => {
        let valuesToSend = list.slice();
        sendChanges(valuesToSend);
    }
    const toLeftAll = () => {
        let valuesToSend = [];
        sendChanges(valuesToSend);
    }
    const sendChanges = valuesToSend => {
        handleChange(attribute, "array", valuesToSend);
    }
    const getListWithoutValues = () => {
        let listToDisplay = [];

        for (let index in list) {
            if (value.findIndex(_ => _.id === list[index].id) < 0) {
                listToDisplay.push(Object.assign({}, list[index]));
            }
        }

        return listToDisplay;
    }
    const buildListWithoutValues = () => {
        let listToDisplay = getListWithoutValues();

        if (listToDisplay.length === 0)
            return <li className="empty">{ emptyText }</li>

        return listToDisplay.map((object, index) => (
            <li key={ index } onClick={ () => { toRightOne(object.id) } } className={ classnameInputLiLeft }>{ object.value }</li>
        ))
    }
    const buildValuesWithList = () => {
        if (value.length === 0)
            return <li className="empty">{ emptyText }</li>

        let listToDisplay = [];

        for (let index in list) {
            if (value.findIndex(_ => _.id === list[index].id) >= 0) {
                listToDisplay.push(Object.assign({}, list[index]));
            }
        }

        return listToDisplay.map((object, index) => (
            <li key={ index } onClick={ () => { toLeftOne(object.id) } } className={ classnameInputLiRight }>{ object.value }</li>
        ))
    }

    useEffect(() => {
        if (maxHeight === "*")
            setMaxHeightCalculated($("#formRules").height());
        else
            setMaxHeightCalculated(parseInt(maxHeight.replace("px", "")));
    }, [updated])

    return (
        <div className={ "inputBidirectionalList " + (classname !== undefined ? classname : "") } style={{maxHeight: maxHeightCalculated}}>
            <div className="contList">
                {
                    labelLeft !== ""
                    && <label className={ classnameLabel }>{ labelLeft + " (" + getListWithoutValues().length + ")" }</label>
                }
                <ul>{ buildListWithoutValues() }</ul>
                {
                    labelButtonLeft !== ""
                    && <p className="button" onClick={ toRightAll }>{ labelButtonLeft }</p>
                }
            </div>
            <div className="contList">
                {
                    labelRight !== ""
                    && <label className={ classnameLabel }>{ labelRight + " (" + value.length + ")" }</label>
                }
                <ul>{ buildValuesWithList() }</ul>
                {
                    labelButtonRight !== ""
                    && <p className="button" onClick={ toLeftAll }>{ labelButtonRight }</p>
                }
            </div>
        </div>
    );
}

export default InputBidirectionalList;
