import React, {useContext} from "react";
import PartnershipLine from "./PartnershipLine";
import LoaderCircle from "../../../../loader/LoaderCircle";
import Error from "../../../../error/Error";

import SearchContext from "../../../../../context/SearchContext";

import "../../../../../css/page/listingTables/Table.css";
import "../../../../../css/page/listingTables/Line.css";
import "../../../../../css/page/listingTables/PartnershipLine.css";

function PartnershipTable () {
    const { generalError, initialLoading, list } = useContext(SearchContext);

    return (
        <div className="wrapTable">
            <table className="table partnership borderBottom">
                <tbody>
                    <tr className="line">
                        <th className="name left">NOM</th>
                        <th className="postalCode left">CP</th>
                        <th className="city left">VILLE</th>
                        <th className="type left">TYPE</th>
                        <th className="nbUser right">UTILISATEURS</th>
                        <th className="action">
                            <div className="action" />
                        </th>
                    </tr>
                </tbody>
            </table>
            <div className="list">
                {
                    !initialLoading
                    ? (
                        generalError === ""
                        ? <table className="table partnership">
                                <tbody>
                                {
                                    list.map(partnership => (
                                        <PartnershipLine key={ partnership.id } partnership={ partnership } />
                                    ))
                                }
                                </tbody>
                            </table>
                        : <Error text={ generalError } />
                    )
                    : <LoaderCircle display="loader logWait" hide="" strokeWidth="5" stroke="#008C4F" />
                }

            </div>
        </div>
    );
}

export default PartnershipTable;
