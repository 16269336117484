import React from "react";
import PartnershipForm from "../../form/partnership/PartnershipForm";

function NewPartnership (props) {
    const { handleCloseNewPartnership } = props;

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox newItem">
                    <PartnershipForm handleCloseNewPartnership={ handleCloseNewPartnership } newItem={ true } />
                </div>
            </div>
        </div>
    );
}

export default NewPartnership;
