import React, {useState, useEffect, useContext} from "react"
import AccessIcon from "../../../../icons/AccessIcon"
import OptionAccount from "./OptionAccount"
import moment from "moment"
import AccountController from "../../../../../stories/_setup/Accounts/AccountController"
import SessionContext from "../../../../../context/SessionContext"
import UpdateAccount from "../../../../overbox/account/UpdateAccount";

const SetupAccountsLine = props => {
    const { setup, object, closeOptions, handleSetCloseOptions, handleRefresh } = props
    const { setGlobalError, handleLogout } = useContext(SessionContext)
    const [ showOptions, setShowOptions ] = useState(false)
    const [ showUpdateAccount, setShowUpdateAccount ] = useState(false)
    const [ showUpdateStoreRules, setShowUpdateStoreRules ] = useState(false)
    const [ showUpdateCompanyRules, setShowUpdateCompanyRules ] = useState(false)
    const [ loadingState, setLoadingState ] = useState(false)
    const [ loadingRemove, setLoadingRemove ] = useState(false)
    const [ idObjectSelect, setIdObjectSelect ] = useState(0)

    const performCloseOptions = () => {
        setShowOptions(false)
        handleSetCloseOptions(false)
    }
    const handleShowOptions = id => {
        if (!showOptions) {
            setIdObjectSelect(id)
            setShowOptions(true)
        }
        else {
            setIdObjectSelect(0)
            setShowOptions(false)
        }
    }
    const displayDate = strDate => {
        return moment(strDate).format("DD/MM/YYYY LT")
    }
    const handleStateAccount = (response, error, status) => {
        setLoadingState(false)

        switch (status) {
            case 401:
                handleLogout()
                break
            case 204:
                handleRefresh()
                break
            default:
                setGlobalError("Une erreur s'est produite lors de la modification de l'état du compte")
                break
        }
    }
    const stateAccount = status => {
        if (loadingState) return
        setLoadingState(true)

        const controller = new AccountController()
        controller._callback = handleStateAccount

        if (status === 200)
            controller.enableAccount(setup, object.id)
        else
            controller.disableAccount(setup, object.id)
    }
    const updateAccount = () => {
        setShowOptions(false)
        setShowUpdateAccount(!showUpdateAccount)
    }
    const refresh = () => {
        handleRefresh()
    }
    const updateStoreRules = () => {
        setShowOptions(false)
        setShowUpdateStoreRules(!showUpdateStoreRules)
    }
    const updateCompanyRules = () => {
        setShowOptions(false)
        setShowUpdateCompanyRules(!showUpdateCompanyRules)
    }
    const handleRemoveAccount = (response, error, status) => {
        setLoadingRemove(false)

        switch (status) {
            case 401:
                handleLogout()
                break
            case 204:
                handleRefresh()
                closeOptions()
                break
            default:
                setGlobalError("Une erreur s'est produite lors de la suppression du compte backoffice")
                break
        }
    }
    const removeAccount = () => {
        if (loadingRemove) return
        setLoadingRemove(true)

        const controller = new AccountController()
        controller._callback = handleRemoveAccount
        controller.removeAccount(setup, object.id)
    }

    useEffect(() => {
        if (closeOptions && showOptions)
            performCloseOptions()
    }, [closeOptions, showOptions])

    return (
        <tr className="line">
            <td className="name left">{ object.buildStrName() }</td>
            <td className="email left">{ object.email.toLocaleLowerCase() }</td>
            <td className="rules left">{ object.buildStrRules("company") }</td>
            <td className="rules left">{ object.buildStrRules("store") }</td>
            <td className="state left">{ object.buildState() }</td>
            <td className="date left">{ displayDate(object.created_at)}</td>
            <td className="action left">
                <div className="access" onClick={ () => { handleShowOptions(object.id) } }>
                    <AccessIcon />
                </div>
                <OptionAccount show={ showOptions } idSelect={ idObjectSelect } object={ object } loadingState={ loadingState } loadingRemove={ loadingRemove } handleUpdate={ updateAccount } handleUpdateStoreRules={ updateStoreRules } handleUpdateCompanyRules={ updateCompanyRules } handleState={ stateAccount } handleRemove={ removeAccount } />
            </td>
            {
                showUpdateAccount
                && <UpdateAccount typeForm="account" setup={ setup } object={ object } handleClose={ updateAccount } handleSave={ refresh } />
            }
            {
                showUpdateStoreRules
                && <UpdateAccount typeForm="storeRules" setup={ setup } object={ object } handleClose={ updateStoreRules } handleSave={ refresh } />
            }
            {
                showUpdateCompanyRules
                && <UpdateAccount typeForm="companyRules" setup={ setup } object={ object } handleClose={ updateCompanyRules } handleSave={ refresh } />
            }
        </tr>
    )
}

export default SetupAccountsLine
