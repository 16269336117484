import React from "react";
import NewProspectStep1 from "../../icons/NewProspectStep1";
import NewProspectStep2 from "../../icons/NewProspectStep2";
import NewProspectStep3 from "../../icons/NewProspectStep3";

function ProspectFormSteps (props) {
    const { step } = props;

    return (
        <div className="steps">
            <NewProspectStep1 classname={ step > 1 ? "done" : "" } />
            <div className="line">
                <span className={ "bar" + ( step > 1 ? " done" : "" ) } />
            </div>
            <NewProspectStep2 classname={ step > 2 ? "done" : "" } />
            <div className="line">
                <span className={ "bar" + ( step > 2 ? " done" : "" ) } />
            </div>
            <NewProspectStep3 />
        </div>
    );
}

export default ProspectFormSteps;
