import React from "react";

function CopyIcon (props) {
    const { fill, bounce, handleClick } = props;

    return (
        <svg viewBox="0 0 460 460" width="1em" height="1em" fill={ fill } className={ bounce } onClick={ handleClick }>
            <path d="M425.934 0H171.662c-18.122 0-32.864 14.743-32.864 32.864v77.134h149.543c34.664 0 62.865 28.201 62.865 62.865v147.139h74.728c18.121 0 32.864-14.743 32.864-32.865V32.864C458.797 14.743 444.055 0 425.934 0z" />
            <path d="M288.339 139.998H34.068c-18.121 0-32.865 14.743-32.865 32.865v254.272C1.204 445.257 15.946 460 34.068 460H288.34c18.122 0 32.865-14.743 32.865-32.864V172.863c.001-18.122-14.744-32.865-32.866-32.865z" />
        </svg>
    );
}

export default CopyIcon;
