import React from "react"

const MainIcon = props => {
    const { fill } = props

    return (
        <svg
            height="1em"
            viewBox="0 0 32 32"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
            fill={ fill }
        >
            <path d="M27.488 23l-.513 2.225A1 1 0 0126 26H6a1 1 0 01-.975-.775L4.512 23zM29.975 12.225L27.95 21H4.05l-2.025-8.775a1.001 1.001 0 011.471-1.093l6.189 3.537 5.482-8.223c.179-.268.475-.434.796-.446a.99.99 0 01.826.386l6.429 8.266 5.227-3.484a1.002 1.002 0 011.53 1.057z" />
        </svg>
    )
}

export default MainIcon