import Rest from "../../../class/tool/Rest";
import Stock from "./Stock";

class StockController {
    _rest = new Rest();
    _callback = null;
    _baseURL = "products/{idProduct}/stocks";

    index(product) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idProduct}", product.id),
            {},
            this.handleIndex);
    }
    handleIndex = (response, error) => {
        let listObjects = [];

        if(response.data !== undefined) {
            const data = response.data;

            for(var item in data)
                listObjects.push(new Stock(data[item]));
        }

        this._callback(listObjects, error);
    }

    add(product, object) {
        const objectToSend = JSON.parse(JSON.stringify(object));

        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURL.replace("{idProduct}", product.id) + "/add",
            objectToSend,
            this.handlePost
        );
    }
    remove(product, object) {
        const objectToSend = JSON.parse(JSON.stringify(object));

        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURL.replace("{idProduct}", product.id) + "/remove",
            objectToSend,
            this.handlePost
        );
    }
    handlePost = (response, error) => {
        this._callback(response, error);
    }
}

export default StockController;
