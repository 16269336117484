import React, {useContext, useState} from "react";
import PartnershipTabs from "./PartnershipTabs";
import LoaderCircle from "../../../../loader/LoaderCircle";
import PartnershipCardInformations from "./PartnershipCardInformations";
import PartnershipCardUsers from "./PartnershipCardUsers";
import PartnershipCardStorages from "./PartnershipCardStorages";

import PartnershipContext from "../../../../../context/PartnershipContext";

import "../../../../../css/page/cards/Card.css";
import Error from "../../../../error/Error";
import PartnershipCardGroups from "./PartnershipCardGroups";

function PartnershipCard (props) {
    const { tab, handleAskDeletePartnership, addUser, addUserGroup, addStorage } = props;
    const { partnership, generalError } = useContext(PartnershipContext);
    const [ sending, setSending ] = useState(false);

    const handleUpdateSending = bool => {
        setSending(bool);
    }

    const goContentCard = () => {
        switch (tab) {
            case "information": return <PartnershipCardInformations handleUpdateSending={ handleUpdateSending } handleAskDeletePartnership={ handleAskDeletePartnership } />
            case "storage": return <PartnershipCardStorages />
            case "user": return <PartnershipCardUsers />
            case "group": return <PartnershipCardGroups />
            case "setting": return <div />
            default: return <div />
        }
    }

    return (
        <div className="card">
            {
                partnership !== null
                ? <PartnershipTabs tab={ tab } sending={ sending } addUser={ addUser } addUserGroup={ addUserGroup } addStorage={ addStorage } />
                : generalError === "" && <LoaderCircle display="loader logWait" hide="" strokeWidth="5" stroke="#008C4F" />
            }
            {
                partnership !== null
                ? goContentCard()
                : generalError !== "" && <Error text={ generalError } />
            }
        </div>
    );
}

export default PartnershipCard;
