import React, {useContext, useEffect, useState} from "react";
import LoaderCircle from "../../../../loader/LoaderCircle";
import InputSelect from "../../../../form/input/InputSelect";
import InputText from "../../../../form/input/InputText";
import PartnershipController from "../../../../../stories/_auth/Partnerships/PartnershipController";
import SessionContext from "../../../../../context/SessionContext";
import SearchContext from "../../../../../context/SearchContext";
import "../../../../../css/page/SearchBar.css";

function ProspectSearchBar (props) {

    const { handleChangeInputSearch, handleChangeAssignedTo, handleChangeProspectPhase, handleChangeState } = props;
    const { inputSearch, assignedTo, prospectPhase, state, initialLoading, loading } = useContext(SearchContext);
    const { handleLogout } = useContext(SessionContext);
    const [ first, setFirst ] = useState(true);
    const [ listAssignables, setListAssignables ] = useState([]);
    const [ listProspectPhases, setListProspectPhases ] = useState([]);
    const [ listStates, setListStates ] = useState([]);

    const initialize = () => {
        setFirst(false);

        getAssignables();
        getProspectPhases();
        getStates();
    }
    const getAssignables = idPartnership => {
        const controller = new PartnershipController();
        controller._callback = handleGetAssignables;
        //controller.indexAssignables(getObjectPartnership(idPartnership), ['COMMERCIAL']);
    }
    const handleGetAssignables = (list, error) => {
        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();

            return;
        }

        const listBuilded = buildListAssignedTo(list);
        setListAssignables(listBuilded);
    }
    const buildListAssignedTo = (listSrc) => {
        let list = [{
            key: -1,
            value: "--- Groupes ---",
            id: -1,
            type: ""
        }];
        var loop = 0;
        var i;

        for (i in listSrc.groups)
            list.push({
                key: loop++,
                value: listSrc.groups[i].name,
                id: listSrc.groups[i].id,
                type: "GROUP"
            });

        list.push({
            key: -2,
            value: "--- Utilisateurs ---",
            id: -2,
            type: ""
        });

        for (i in listSrc.users)
            list.push({
                key: loop++,
                value: (listSrc.users[i].lastname + " " + listSrc.users[i].firstname).trim(),
                id: listSrc.users[i].id,
                type: "USER"
            });

        return list;
    }
    const getProspectPhases = () => {

    }
    const getStates = () => {

    }

    useEffect(() => {
        if (first)
            initialize();
    });

    return (
        <div className="searchBarComponent">
            <div className="form noPadding">
                <InputText
                    classname="marginTop"
                    value={ inputSearch }
                    placeholder="Rechercher un prospect"
                    newItem={ true }
                    handleChange={ handleChangeInputSearch }
                />
                {
                    (loading && !initialLoading)
                    && <LoaderCircle
                        display="loader searchBarLoading"
                        strokeWidth="7"
                        stroke="#FFFFFF"
                    />
                }
                <InputSelect
                    classname="alone leftAlign"
                    value={ assignedTo }
                    list={ listAssignables }
                    newItem={ true }
                    handleChange={ handleChangeAssignedTo }
                />
                <InputSelect
                    classname="alone leftAlign"
                    value={ prospectPhase }
                    list={ listProspectPhases }
                    newItem={ true }
                    handleChange={ handleChangeProspectPhase }
                />
                <InputSelect
                    classname="alone leftAlign"
                    value={ state }
                    list={ listStates }
                    newItem={ true }
                    handleChange={ handleChangeState }
                />
                <div className="clearing" />
            </div>
        </div>
    );
}

export default ProspectSearchBar;
