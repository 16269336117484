import React, { useContext } from "react";
import SessionContext from "../../../context/SessionContext";
import NoInputComplex from "../input/NoInputComplex";

function ListContentContractForm (props) {
    const color = window.matchMedia('(prefers-color-scheme: dark)').matches ? "#FFFFFF" : "#1D1D1D";
    const { object, setObject } = props;
    const { handleLogout } = useContext(SessionContext);


    // RENDER

    return (
        <div className="clearing">
            <label className="label">Contenu</label>

        </div>
    );
}

export default ListContentContractForm;
