import Rest from "../../../class/tool/Rest";
import License from "./License";

class LicenseController {
    _rest = new Rest();
    _callback = null;
    _baseURL = "setups/{idSetup}/licenses";


    // Checking

    static checkName(val) {
        //const regxp = /^[\w&-_. ]{2,50}$/g;

        if(val === "") throw new Error("EMPTY");
        //if (!regxp.test(val.removeDiacritics())) throw new Error("WRONG");

        return true;
    }
    static checkMain(val) {
        const regxp = /^0|1$/g;

        if (val !== "" && !regxp.test(val)) throw new Error("WRONG");

        return true;
    }


    // perform

    returnUpdatesFromCompare(object, datas) {
        var dataKeys = Object.keys(datas);
        var key = "";
        var updates = {};

        for(var i in dataKeys) {
            key = dataKeys[i];

            if (datas[key] !== undefined)
                if (Reflect.get(object, key) !== datas[key])
                    Reflect.set(updates, key, datas[key]);
        }

        return updates;
    }

    checkValues(object) {
        const params = [
            { name: "name", method: "checkName", val: object.name },
            { name: "main", method: "checkMain", val: object.main }
        ];
        let errors = {};
        let method;

        for (var i in params) {
            method = LicenseController[params[i].method];

            try { method.apply(null, params[i].val); }
            catch (e) { errors.params[i].name = e.message; }
        }

        if (Object.keys(errors).length > 0) throw new Error(JSON.stringify(errors));
        else return true;
    }


    // rest API

    index(idSetup) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idSetup}", idSetup),
            {},
            this.handleIndex);
    }
    handleIndex = (response, error) => {
        let listObjects = [];

        if(response.data !== undefined) {
            const data = response.data;

            for(var item in data)
                listObjects.push(new License(data[item]));
        }

        this._callback(listObjects, error);
    }

    show(idSetup, id) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idSetup}", idSetup) + '/' + id,
            {},
            this.handleShow);
    }
    handleShow = (response, error) => {
        const object = !error ? new License(response.data) : null;
        this._callback(object, error);
    }

    post(idSetup, object) {
        const objectToSend = JSON.parse(JSON.stringify(object));
        delete objectToSend.partnership_type_id;

        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURL.replace("{idSetup}", idSetup),
            objectToSend,
            this.handlePost
        );
    }
    handlePost = (response, error) => {
        this._callback(response, error);
    }

    put(idSetup, object, datas) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'PUT',
            '/' + this._baseURL.replace("{idSetup}", idSetup) + '/' + object.id,
            datas,
            this.handlePut
        );
    }
    handlePut = (response, error) => {
        this._callback(response, error);
    }

    delete(idSetup, object) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'DELETE',
            '/' + this._baseURL.replace("{idSetup}", idSetup) + '/' + object.id,
            {},
            this.handleDelete);
    }
    handleDelete = (response, error) => {
        this._callback(response, error);
    }

    enablingInstallation = (idSetup, object) => {
        this._rest._contentType = 'aprplication/json';
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURL.replace("{idSetup}", idSetup) + '/' + object.id + '/enableinstallation',
            {},
            this.handleEnablingInstallation);
    }
    handleEnablingInstallation = (response, error) => {
        this._callback(response, error);
    }
}

export default LicenseController;
