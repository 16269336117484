import React from "react"
import AccountForm from "../../form/account/AccountForm"

const NewAccount = props => {
    const { setup, handleClose, handleRefresh } = props

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox newItemFix">
                    <AccountForm setup={ setup } handleClose={ handleClose } handleRefresh={ handleRefresh } />
                </div>
            </div>
        </div>
    )
}

export default NewAccount
