import React, {useContext, useEffect, useState} from "react";
import PartnershipContext from "../../../context/PartnershipContext";

function AddUser (props) {
    const { handleCloseAddUser, handleSearchUser, handleNewUser } = props;
    const { partnership } = useContext(PartnershipContext);

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox choices">
                    <div className="form margin">
                        <p className="titleForm center">Vous souhaitez ajouter un utilisateur à <strong>{ partnership.company }</strong></p>
                        <button className="buttonChoice spaceTop" onClick={ handleSearchUser }>Ajouter un utilisateur existant</button>
                        <button className="buttonChoice" onClick={ handleNewUser }>Créer un nouvel utilisateur</button>
                        <button className="cancel block" onClick={ handleCloseAddUser }>Annuler</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddUser;
