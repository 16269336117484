import Entity from "../../../class/abstract/Entity";

class InstallationEnabling extends Entity {
    license_id = null;
    token = "";
    readyDate = null;
    askingDate = null;
    validityPeriod = 0;

    constructor(datas = {}) {
        super(datas);
        this.setAttributes(datas);
    }

    setAttributes(datas) {
        let keys = Object.keys(this)
        let key = ""

        for(let i in keys) {
            key = keys[i]

            if (datas[key] !== undefined)
                Reflect.set(this, key, datas[key])
        }
    }
}

export default InstallationEnabling;
