import React from "react";
import TagSelected from "./TagSelected";
import CheckIcon from "../../icons/CheckIcon";

function InputTags (props) {
    const { color, idName, newItem, classname, values, input, handleUnselect, handleBlur, handleSearch, handleKeyEnter } = props;

    return (
        <div className="contInputTags">
            <div id={ idName } className={ "inputTags " + (newItem ? "extend " : "") + (classname !== undefined ? classname : "") }>
                <input type="text" value={ input } placeholder="Inscrire un tag ('Entrée' pour ajouter)" autoFocus={ true } onChange={ event => { handleSearch(event) } } onKeyDown={ handleKeyEnter } />
                {
                    values.length > 0 && (
                        values.map(tag => (
                            <TagSelected key={ tag.id } object={ tag } locked={ false } handleUnselect={ handleUnselect } />
                        ))
                    )
                }
                <div className="clearing" />
            </div>
            {
                !newItem && <CheckIcon fill={ color } handleClick={ handleBlur } classname="checkIcon" />
            }
            <div className="clearing" />
        </div>
    );
}

export default InputTags;
