import React, {useEffect, useState} from "react";
import Error from "../../../error/Error";
import Valid from "../../../valid/Valid";
import CategoryTable from "./CategoryTable";
import "../../../../css/page/Content.css";

function CategoryTableContent () {
    const [ generalError, setGeneralError ] = useState("");
    const [ generalValid, setGeneralValid ] = useState("");


    useEffect(() => {
        document.title = "HIVY - Liste des catégories";
    });

    return (
        <div className="content">
            {
                generalError !== "" && <Error text={ generalError } />
            }
            {
                generalValid !== "" && <Valid text={ generalValid } />
            }
            <CategoryTable setError={ setGeneralError } setValid={ setGeneralValid } />
        </div>
    );
}

export default CategoryTableContent;
