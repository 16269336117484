import Entity from "../../../class/abstract/Entity";

class BankData extends Entity {
    bank = "";
    iban = "";
    bic = "";
    mandat = "";
    date = 0;

    constructor(datas = {}) {
        super(datas);

        this.setAttributes(datas);
    }

    setAttributes(datas) {
        this.id = datas.id !== undefined ? datas.id : 0;
        this.bank = datas.bank !== undefined ? datas.bank : "";
        this.iban = datas.iban !== undefined ? datas.iban : "";
        this.bic = datas.bic !== undefined ? datas.bic : "";
        this.mandat = datas.mandat !== undefined ? datas.mandat : "";
        this.date = datas.date !== undefined ? datas.date : 0;
        this.updated_at = datas.updated_at !== undefined ? datas.updated_at : "";
        this.created_at = datas.created_at !== undefined ? datas.created_at : "";
    }
}

export default BankData;
