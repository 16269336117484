import React from "react";
import CheckIcon from "../../icons/CheckIcon";

function InputTextarea (props) {
    const { color, classname, classError,  value, placeholder, newItem, handleChange, handleBlur, handleKeyEnter } = props;

    return (
        <div className={ "inputTextarea " + (classname !== undefined ? classname : "") }>
            <textarea className={ (newItem ? "extend " : "") + classError } placeholder={ placeholder } onChange={ handleChange } onBlur={ newItem ? handleBlur : null } onKeyDown={ handleKeyEnter } autoFocus={ !newItem }>
                { value }
            </textarea>
            {
                !newItem && <CheckIcon fill={ color } handleClick={ handleBlur } />
            }
        </div>
    );
}

export default InputTextarea;
