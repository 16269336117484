import React from "react";
import StockForm from "../../form/stock/StockForm";

function FormStock (props) {
    const { type, storage, stocks, product, handleClose } = props;

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox newItemSmall">
                    <StockForm handleClose={ handleClose } stocks={ stocks } product={ product } type={ type } storage={ storage } />
                </div>
            </div>
        </div>
    );
}

export default FormStock;
