import React, {useContext, useEffect, useState} from "react";
import ProspectCard from "./card/ProspectCard";
import DeleteProspect from "../../../overbox/prospect/DeleteProspect";
import ProspectToClient from "../../../overbox/prospect/ProspectToclient";
import ChangeProspectState from "../../../overbox/prospect/ChangeProspectState";

import StoreController from "../../../../stories/_client/Stores/StoreController";
import SessionContext from "../../../../context/SessionContext";
import StoreContext from "../../../../context/StoreContext";

import "../../../../css/page/Content.css";

function ProspectCardContent (props) {
    const { id, tab } = props;
    const { handleLogout } = useContext(SessionContext);
    const [ first, setFirst ] = useState(true);
    const [ generalError, setGeneralError ] = useState("");
    const [ deleting, setDeleting ] = useState(false);
    const [ sending, setSending ] = useState(false);
    const [ store, setStore ] = useState(null);
    const [ boxDelete, setBoxDelete ] = useState(false);
    const [ boxToClient, setBoxToClient ] = useState(false);
    const [ showOptionProspect, setShowOptionProspect ] = useState(false);
    const [ changeProspectState, setChangeProspectState ] = useState(false);
    const [ sendingState, setSendingState ] = useState("");
    const valueStoreContext = {
        generalError,
        setGeneralError,
        store
    };

    const handleSetSending = val => {
        setSending(val);
    }

    const handleManage = () => {
        setShowOptionProspect(!showOptionProspect);
    }

    const getProspect = () => {
        setFirst(false);

        const controller = new StoreController();
        controller._callback = handleGetProspect;
        controller.show(id);
    }
    const handleGetProspect = (object, error) => {
        if(error) {
            if(error.response !== undefined) {
                if (error.response.status === 401)
                    handleLogout();
                else if (error.response.status === 404)
                    setGeneralError("Ce prospect est introuvable");
            }
            else
                setGeneralError("Impossible de récupérer les données");
        }

        setStore(object);
    }

    const handleAskDeleteProspect = () => {
        setShowOptionProspect(false);
        setBoxDelete(!boxDelete);
    }

    const deleteProspect = () => {
        setDeleting(true);

        const controller = new StoreController();
        controller._callback = handleDeleteProspect;
        controller.delete(store);
    }
    const handleDeleteProspect = (response, error) => {
        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else
                setGeneralError("Impossible de supprimer le prospect");
        }
        else
            window.location = "/prospect";
    }

    const handleAskToClient = () => {
        setShowOptionProspect(false);
        setBoxToClient(!boxToClient);
    }

    const toClient = () => {
        setSending(true);

        const controller = new StoreController();
        controller._callback = handleReturnToClient;
        controller.toClient(store);
    }
    const handleReturnToClient = (response, error) => {
        if(error) {
            if(error.response !== undefined) {
                if(error.response.status === 401)
                    handleLogout();
                else
                    setGeneralError("Une erreur s'est produite lors de la transformation du prospect en client");
            }
            else
                setGeneralError("Une erreur s'est produite lors de la transformation du prospect en client");
        }

        setSending(false);
        setBoxToClient(false);
    }

    const handleChangeProspectState = () => {
        if (showOptionProspect)
            handleManage();

        setChangeProspectState(!changeProspectState);
    }
    const handleUpdateProspectState = state => {
        setSendingState(state);

        const controller = new StoreController();
        controller._callback = handleReturnUpdateProspectState;

        const datas = controller.returnUpdatesFromCompare(store, {
            'prospect_state': state
        });

        if(Object.keys(datas).length === 0) return;

        controller.put(store, datas);
    }
    const handleReturnUpdateProspectState = (response, error) => {
        setSendingState("");

        if(error) {
            if(error.response !== undefined) {
                if(error.response.status === 401)
                    handleLogout();
                else if(error.response.status === 422)
                    setGeneralError("Le statut envoyé est incorrect");
            }
            else
                setGeneralError("Une erreur s'est produite lors de l'enregistrement");
        }
        else {
            handleChangeProspectState();
            getProspect();
        }
    }

    useEffect(() => {
        document.title = "HIVY - " + (store === null ? "Fiche prospect" : store.name);

        if(first)
            getProspect();
    });

    return (
        <div className="content">
            <StoreContext.Provider value={valueStoreContext}>
                {
                    changeProspectState && <ChangeProspectState handleUpdate={ handleUpdateProspectState } handleClose={ handleChangeProspectState } sending={ sendingState } />
                }
                {
                    boxToClient && <ProspectToClient store={ store } sending={ sending } handleClose={ handleAskToClient } toClient={ toClient } />
                }
                {
                    boxDelete && <DeleteProspect store={ store } deleting={ deleting } handleAskDeleteProspect={ handleAskDeleteProspect } deleteProspect={ deleteProspect } />
                }
                <ProspectCard tab={ tab } handleChangeProspectState={ handleChangeProspectState } handleAskDeleteProspect={ handleAskDeleteProspect } handleGetProspect={ getProspect } sending={ sending } setSending={ handleSetSending } handleManage={ handleManage } show={ showOptionProspect } handleToClient={ handleAskToClient } handleDelete={ handleAskDeleteProspect } />
            </StoreContext.Provider>
        </div>
    );
}

export default ProspectCardContent;
