import React, {useContext, useEffect, useState} from "react";
import PartnershipCard from "./card/PartnershipCard";
import PartnershipController from "../../../../stories/_auth/Partnerships/PartnershipController";
import SessionContext from "../../../../context/SessionContext";
import PartnershipContext from "../../../../context/PartnershipContext";
import DeletePartnership from "../../../overbox/partnership/DeletePartnership";
import AddUser from "../../../overbox/user/AddUser";
import SearchUser from "../../../overbox/user/SearchUser";
import FormUser from "../../../overbox/user/FormUser";
import "../../../../css/page/Content.css";
import FormStorage from "../../../overbox/storage/FormStorage";
import FormUserGroup from "../../../overbox/userGroup/FormUserGroup";

function PartnershipCardContent (props) {
    const { id, tab } = props;
    const { handleLogout } = useContext(SessionContext);
    const [ generalError, setGeneralError ] = useState("");
    const [ saving, setSaving ] = useState(false);
    const [ deleting, setDeleting ] = useState(false);
    const [ partnership, setPartnership ] = useState(null);
    const [ addUserBox, setAddUserBox ] = useState(false);
    const [ newUserGroupForm, setNewUserGroupForm ] = useState(false);
    const [ newUserForm, setNewUserForm ] = useState(false);
    const [ newStorageForm, setNewStorageForm ] = useState(false);
    const [ searchUserForm, setSearchUserForm ] = useState(false);
    const [ boxDeletePartnership, setBoxDeletePartnership ] = useState(false);
    const valuePartnershipContext = {
        generalError,
        partnership
    };

    const getPartnership = () => {
        const controller = new PartnershipController();
        controller._callback = handleGetPartnership;
        controller.show(id);
    }
    const handleGetPartnership = (object, error) => {
        if(error) {
            if(error.response !== undefined) {
                if (error.response.status === 401)
                    handleLogout();
                else if (error.response.status === 404)
                    setGeneralError("Ce partenaire est introuvable");
            }
            else
                setGeneralError("Impossible de récupérer les données");
        }

        setPartnership(object);
    }

    const handleAskDeletePartnership = () => {
        setBoxDeletePartnership(!boxDeletePartnership);
    }

    const deletePartnership = () => {
        setDeleting(true);

        const controller = new PartnershipController();
        controller._callback = handleDeletePartnership;
        controller.delete(partnership);
    }
    const handleDeletePartnership = (response, error) => {
        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else
                setGeneralError("Impossible de supprimer le partenaire");
        }
        else
            window.location = "/partnership";
    }

    const handleAttachUser = users => {
        setSaving(true);

        var ids = [];
        for (var i in users)
            ids.push(users[i].id);

        const controller = new PartnershipController();
        controller._callback = handleReturnAttachUser;
        controller.attachUser(partnership, ids);
    }
    const handleReturnAttachUser = (response, error) => {
        setSaving(false);

        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else
                setGeneralError("Impossible d'attacher l'utilisateur à ce partenaire'");
        }
        else
            window.location = "/partnership/" + partnership.id + "/user";
    }

    const addUser = () => {
        setAddUserBox(true);
    }
    const closeAddUser = () => {
        setAddUserBox(false);
    }
    const addUserGroup = () => {
        setNewUserGroupForm(true);
    }
    const closeNewUserGroup = () => {
        setNewUserGroupForm(false);
    }
    const newUser = () => {
        closeAddUser();
        setNewUserForm(true);
    }
    const closeNewUser = () => {
        setNewUserForm(false);
    }
    const searchUser = () => {
        closeAddUser();
        setSearchUserForm(true);
    }
    const closeSearchUser = () => {
        setSearchUserForm(false);
    }
    const addStorage = () => {
        setNewStorageForm(true);
    }
    const closeNewStorage = () => {
        setNewStorageForm(false);
    }

    useEffect(() => {
        document.title = "HIVY - " + (partnership === null ? "Fiche partenaire" : partnership.company);
        getPartnership();
    }, []);

    return (
        <div className="content">
            <PartnershipContext.Provider value={valuePartnershipContext}>
                {
                    boxDeletePartnership && <DeletePartnership partnership={ partnership } deleting={ deleting } handleAskDeletePartnership={ handleAskDeletePartnership } deletePartnership={ deletePartnership } />
                }
                {
                    addUserBox && <AddUser handleCloseAddUser={ closeAddUser } handleSearchUser={ searchUser } handleNewUser={ newUser } />
                }
                {
                    searchUserForm && <SearchUser handleCloseSearchUser={ closeSearchUser } handleAttachUser={ handleAttachUser } saving={ saving } />
                }
                {
                    newUserGroupForm && <FormUserGroup handleCloseFormUserGroup={ closeNewUserGroup } newItem={ true } />
                }
                {
                    newUserForm && <FormUser handleCloseFormUser={ closeNewUser } newItem={ true } />
                }
                {
                    newStorageForm && <FormStorage handleClose={ closeNewStorage } newItem={ true } />
                }
                <PartnershipCard tab={ tab } handleAskDeletePartnership={ handleAskDeletePartnership } addUser={ addUser } addUserGroup={ addUserGroup } addStorage={ addStorage } />
            </PartnershipContext.Provider>
        </div>
    );
}

export default PartnershipCardContent;
