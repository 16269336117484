import React, {useContext, useEffect, useState} from "react";
import ProductCard from "./card/ProductCard";
import ProductController from "../../../../stories/_catalog/Products/ProductController";
import SessionContext from "../../../../context/SessionContext";
import ProductContext from "../../../../context/ProductContext";
import DeleteProduct from "../../../overbox/product/DeleteProduct";

import "../../../../css/page/Content.css";

function ProductCardContent (props) {
    const { id, tab } = props;
    const { handleLogout } = useContext(SessionContext);
    const [ first, setFirst ] = useState(true);
    const [ refresh, setRefresh ] = useState(false);
    const [ generalError, setGeneralError ] = useState("");
    const [ deleting, setDeleting ] = useState(false);
    const [ sending, setSending ] = useState(false);
    const [ product, setProduct ] = useState(null);
    const [ boxDelete, setBoxDelete ] = useState(false);
    const [ showOptionProduct, setShowOptionProduct ] = useState(false);
    const valueProductContext = {
        generalError,
        setGeneralError,
        product
    };

    const handleSetSending = val => {
        setSending(val);
    }

    const handleManage = () => {
        setShowOptionProduct(!showOptionProduct);
    }

    const getProduct = () => {
        setFirst(false);

        const controller = new ProductController();
        controller._callback = handleGetProduct;
        controller.show(id);
    }
    const handleGetProduct = (object, error) => {
        if(error) {
            if(error.response !== undefined) {
                if (error.response.status === 401)
                    handleLogout();
                else if (error.response.status === 404)
                    setGeneralError("Ce product est introuvable");
            }
            else
                setGeneralError("Impossible de récupérer les données");
        }

        setProduct(object);
        setRefresh(true);
    }

    const handleAskDeleteProduct = () => {
        setShowOptionProduct(false);
        setBoxDelete(!boxDelete);
    }

    const deleteProduct = () => {
        setDeleting(true);

        const controller = new ProductController();
        controller._callback = handleDeleteProduct;
        controller.delete(product);
    }
    const handleDeleteProduct = (response, error) => {
        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else
                setGeneralError("Impossible de supprimer le produit");
        }
        else
            window.location = "/product";
    }

    useEffect(() => {
        document.title = "HIVY - " + (product === null ? "Fiche produit" : product.name);

        if(first)
            getProduct();
    });

    return (
        <div className="content">
            <ProductContext.Provider value={valueProductContext}>
                {
                    boxDelete && <DeleteProduct product={ product } deleting={ deleting } handleAskDeleteProduct={ handleAskDeleteProduct } deleteProduct={ deleteProduct } />
                }
                <ProductCard tab={ tab } handleAskDeleteProspect={ handleAskDeleteProduct } handleGet={ getProduct } sending={ sending } setSending={ handleSetSending } handleManage={ handleManage } show={ showOptionProduct } setShow={ setShowOptionProduct } handleDelete={ handleAskDeleteProduct } refresh={ refresh } setRefresh={ setRefresh } />
            </ProductContext.Provider>
        </div>
    );
}

export default ProductCardContent;
