import React, {useContext, useEffect, useState} from "react";
import NoInput from "../input/NoInput";
import InputText from "../input/InputText";
import StoreController from "../../../stories/_client/Stores/StoreController";
import StoreContext from "../../../context/StoreContext";
import GoogleLocation from "../../../class/tool/GoogleLocation";
import LoaderCircle from "../../loader/LoaderCircle";
import Store from "../../../stories/_client/Stores/Store";
import SessionContext from "../../../context/SessionContext";
import InputSelect from "../input/InputSelect";
import AuthController from "../../../stories/_auth/Auth/AuthController";
import SearchCompany from "../../overbox/company/SearchCompany";
import ListAddressForm from "../address/ListAddressForm";
import ListContactForm from "../contact/ListContactForm";

function ClientForm (props) {
    const color = window.matchMedia('(prefers-color-scheme: dark)').matches ? "#FFFFFF" : "#1D1D1D";
    const countries = [
        {
            key: 5,
            value: "FRANCE"
        },
        {
            key: 1,
            value: "ALLEMAGNE"
        },
        {
            key: 2,
            value: "ANGLETERRE"
        },
        {
            key: 3,
            value: "BELGIQUE"
        },
        {
            key: 4,
            value: "ESPAGNE"
        },
        {
            key: 6,
            value: "ITALIE"
        },
        {
            key: 7,
            value: "LUXEMBOURG"
        },
        {
            key: 8,
            value: "SUISSE"
        }
    ];

    const { handleUpdateSending, newItem, handleCloseNewClient, handleGetClient } = props;
    const { handleLogout } = useContext(SessionContext);
    const { store } = useContext(StoreContext);
    const [ clientObject, setclientObject ] = useState((store.id === undefined) ? new Store() : store);
    const [ first, setFirst ] = useState(true);
    const [ loading, setLoading ] = useState(true);
    const [ saving, setSaving ] = useState(false);
    const [ globalError, setGlobalError ] = useState("");
    const [ partnerships, setPartnerships ] = useState([]);
    const [ companyID, setCompanyID ] = useState(!newItem ? store.company_id : 0);
    const [ company, setCompany ] = useState(!newItem ? store.company_name : "");
    const [ editCompany, setEditCompany ] = useState(false);
    const [ errorCompany, setErrorCompany ] = useState("");
    const [ storeName, setStoreName ] = useState(!newItem ? store.name : "");
    const [ editStoreName, setEditStoreName ] = useState(false);
    const [ errorStoreName, setErrorStoreName ] = useState("");
    const [ partnershipID, setPartnershipID ] = useState(!newItem ? store.partnership_id : 0);
    const [ partnershipName, setPartnershipName ] = useState(!newItem ? store.partnership_name : "");
    const [ errorPartnership, setErrorPartnership ] = useState("");
    const [ address, setAddress ] = useState(!newItem ? (store.mainAddress !== null ? store.mainAddress.address : "" ) : "");
    const [ editAddress, setEditAddress ] = useState(false);
    const [ errorAddress, setErrorAddress ] = useState("");
    const [ additional, setAdditional ] = useState(!newItem ? (store.mainAddress !== null ? store.mainAddress.additional : "" ) : "");
    const [ editAdditional, setEditAdditional ] = useState(false);
    const [ errorAdditional, setErrorAdditional ] = useState("");
    const [ postalCode, setPostalCode ] = useState(!newItem ? (store.mainAddress !== null ? store.mainAddress.postalCode : "" ) : "");
    const [ editPostalCode, setEditPostalCode ] = useState(false);
    const [ errorPostalCode, setErrorPostalCode ] = useState("");
    const [ city, setCity ] = useState(!newItem ? (store.mainAddress !== null ? store.mainAddress.city : "" ) : "");
    const [ editCity, setEditCity ] = useState(false);
    const [ errorCity, setErrorCity ] = useState("");
    const [ country, setCountry ] = useState(!newItem ? (store.mainAddress !== null ? store.mainAddress.country : "" ) : countries[0].value);
    const [ editCountry, setEditCountry ] = useState(false);
    const [ errorCountry, setErrorCountry ] = useState("");
    const [ showBoxSearchCompanies, setShowBoxSearchCompanies ] = useState(false);

    // INIT

    const getOwn = () => {
        setFirst(false);

        const authController = new AuthController();
        authController._callback = handleGetOwn;
        authController.own();
    }
    const handleGetOwn = (list, error) => {
        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else
                setGlobalError(true);
        }

        let partnershipsList = [];

        list.map(object => {
            partnershipsList.push({
                key: object.id,
                value: object.company
            });
        });

        if(partnershipsList.length > 0)
            setPartnershipID(partnershipsList[0].key);

        setPartnerships(partnershipsList);
        setLoading(false);
    }

    // HANDLES EDITION

    const handleGlobalError = text => {
        setGlobalError(text);
    }

    const handleEditCompany = () => {
        if(processEdit(editCompany))
            setEditCompany(!editCompany);
    }
    const handleChangeCompany = event => {
        setCompany(event.currentTarget.value);
    }
    const checkCompany = () => {
        const regxp = /^[\w ]{2,50}/g;

        if (company !== "" && !regxp.test(company)) {
            setErrors("company_name", false);
            return false;
        }

        setErrorCompany("");

        return true;
    }

    const handleEditStoreName = () => {
        if(processEdit(editStoreName))
            setEditStoreName(!editStoreName);
    }
    const handleChangeStoreName = event => {
        setStoreName(event.currentTarget.value);
    }
    const checkStoreName = () => {
        const regxp = /^[\w ]{2,50}/g;

        if(storeName === "") {
            setErrors("store", true);
            return false;
        }

        if (!regxp.test(storeName)) {
            setErrors("store", false);
            return false;
        }

        setErrorStoreName("");

        return true;
    }

    const handleChangePartnership = event => {
        var val = event.currentTarget.value;

        for (var i in partnerships) {
            if (partnerships[i].value.localeCompare(val) === 0) {
                setPartnershipID(partnerships[i].key);
                setPartnershipName(val);
                break;
            }
        }
    }
    const checkPartnership = () => {
        if(partnershipID === 0) {
            setErrors("partnership", true);
            return false;
        }

        setErrorPartnership("");

        return true;
    }

    const handleEditAddress = () => {
        if(processEdit(editAddress))
            setEditAddress(!editAddress);
    }
    const handleChangeAddress = event => {
        const input = event.currentTarget.value;

        setAddress(input);

        if(input.length >= 3)
            searchAddress(input);
    }
    const checkAddress = () => {
        if(!newItem) return true;

        const regxp = /^\d+\s[A-z]+\s[A-z]+/g;

        if (address !== "" && !regxp.test(address)) {
            setErrors("address", false);
            return false;
        }

        setErrorAddress("");

        return true;
    }
    const searchAddress = input => {
        const location = new GoogleLocation();
        location._callback = handleSearchAddress;
        location.searchAddress(input);
    }
    const handleSearchAddress = (result, error) => {
        console.log("result", result);
        console.log("error", error);
    }

    const handleEditAdditional = () => {
        if(processEdit(editAdditional))
            setEditAdditional(!editAdditional);
    }
    const handleChangeAdditional = event => {
        setAdditional(event.currentTarget.value);
    }
    const checkAdditional = () => {
        if(!newItem) return true;

        const regxp = /^\d+\s[A-z]+\s[A-z]+/g;

        if (additional.length > 0 && !regxp.test(additional)) {
            setErrors("additional", false);
            return false;
        }

        setErrorAdditional("");

        return true;
    }

    const handleEditPostalCode = () => {
        if(processEdit(editPostalCode))
            setEditPostalCode(!editPostalCode);
    }
    const handleChangePostalCode = event => {
        setPostalCode(event.currentTarget.value);
    }
    const checkPostalCode = () => {
        if(!newItem) return true;

        const regxp = /^[A-Z0-9]+/g;

        if(postalCode === "") {
            setErrors("postalCode", true);
            return false;
        }

        if (!regxp.test(postalCode)) {
            setErrors("postalCode", false);
            return false;
        }

        setErrorPostalCode("");

        return true;
    }

    const handleEditCity = () => {
        if(processEdit(editCity))
            setEditCity(!editCity);
    }
    const handleChangeCity = event => {
        setCity(event.currentTarget.value);
    }
    const checkCity = () => {
        if(!newItem) return true;

        const regxp = /^[\w-]/g;

        if(city === "") {
            setErrors("city", true);
            return false;
        }

        if (!regxp.test(city)) {
            setErrors("city", false);
            return false;
        }

        setErrorCity("");

        return true;
    }

    const handleEditCountry = () => {
        if(processEdit(editCountry))
            setEditCountry(!editCountry);
    }
    const handleChangeCountry = event => {
        setCountry(event.currentTarget.value);
    }
    const checkCountry = () => {
        if(!newItem) return true;

        const regxp = /^[\w-]/g;

        if(city === "") {
            setErrors("country", true);
            return false;
        }

        if (!regxp.test(city)) {
            setErrors("country", false);
            return false;
        }

        setErrorCountry("");

        return true;
    }

    // COMPANIES

    const handleSearchCompanies = () => {
        setShowBoxSearchCompanies(!showBoxSearchCompanies);
    }
    const handleSelectCompany = company => {
        if (company.length > 0) {
            setCompany(company[0].main);
            setCompanyID(company[0].id);

            handleSearchCompanies();
        }
    }

    // CONTROL & SAVE

    const processEdit = edit => {
        if(edit) {
            if(checkCompany() && checkStoreName() && checkPartnership() && checkAddress() && checkAdditional() && checkPostalCode() && checkCity() && checkCountry() && !newItem)
                save();
            else
                return false;
        }

        return true;
    }
    const reinitAllEdits = () => {
        setEditCompany(false);
        setEditStoreName(false);
        setEditAddress(false);
        setEditAdditional(false);
        setEditPostalCode(false);
        setEditCity(false);
        setEditCountry(false);
    }
    const reinitAllErrors = () => {
        setGlobalError("");
        setErrorCompany("");
        setErrorPartnership("");
        setErrorStoreName("");
        setErrorAddress("");
        setErrorAdditional("");
        setErrorPostalCode("");
        setErrorCity("");
        setErrorCountry("");
    }

    const check422Errors = errorDatas => {
        setGlobalError("Certaines données sont invalides");

        if(errorDatas !== undefined) {
            var keys = Object.keys(errorDatas);
            var fields = ["company_name", "name", "partnership", "address", "additional", "postalCode", "city", "country"];

            for(var item in fields)
                if(keys.includes(fields[item]))
                    setErrors(fields[item], false);
        }
    }
    const setErrors = (type, empty) => {
        switch (type) {
            case "company_name":
                if(empty) setErrorCompany("Vous devez saisir une dénomination");
                else setErrorCompany("Cette dénomination n'est pas valide");
                break;
            case "store":
                if(empty) setErrorStoreName("Vous devez saisir un nom d'établissement");
                else setErrorStoreName("Ce nom d'établissement n'est pas valide");
                break;
            case "partnership":
                if(empty) setErrorStoreName("Vous devez sélectionner un partenaire");
                break;
            case "address":
                if(empty) setErrorAddress("Vous devez saisir une adresse");
                else setErrorAddress("Cette addresse n'est pas valide");
                break;
            case "additional":
                if(empty) setErrorAdditional("Vous devez saisir un complément d'adresse");
                else setErrorAdditional("Ce complément d'adresse n'est pas valide");
                break;
            case "postalCode":
                if(empty) setErrorPostalCode("Vous devez saisir un code postal");
                else setErrorPostalCode("Ce code postal est incorrect");
                break;
            case "city":
                if(empty) setErrorCity("Vous devez saisir une ville");
                else setErrorCity("Ce nom de ville est incorrect");
                break;
            case "country":
                if(empty) setErrorCountry("Vous devez faire un choix");
                else setErrorCountry("Ce choix est incorrect");
                break;
            default: break;
        }
    }

    const handleKeyEnter = event => {
        if (event.key === 'Enter')
            if(processEdit(true))
                reinitAllEdits();
    }

    const save = () => {
        setSaving(true);

        reinitAllErrors();

        if(handleUpdateSending !== undefined)
            handleUpdateSending(true);

        const controller = new StoreController();
        controller._callback = handleReturnSave;

        clientObject.company_id = companyID;
        clientObject.company_name = company;
        clientObject.name = storeName;
        clientObject.partnership_id = partnershipID;
        clientObject.address = address;
        clientObject.additional = additional;
        clientObject.postalCode = postalCode;
        clientObject.city = city;
        clientObject.country = country;

        setclientObject(clientObject);

        if(newItem)
            controller.post(clientObject);
        else
            controller.put(clientObject);

    }
    const handleReturnSave = (response, error) => {
        if(handleUpdateSending !== undefined)
            handleUpdateSending(false);

        setSaving(false);

        if(error) {
            if(error.response !== undefined) {
                if(error.response.status === 401)
                    handleLogout();
                else if(error.response.status === 422)
                    check422Errors(error.response.data);
            }
            else
                setGlobalError("Une erreur s'est produite lors de l'enregistrement");
        }
        else
            if(newItem)
                window.location = "/prospect/" + response.data.id + "/information";
    }

    // RENDER

    useEffect(() => {
        if (first) {
            if( newItem )
                getOwn();
            else
                setLoading(false);
        }
    });

    return (
        <div className="contForm">
            {
                loading
                    ? <LoaderCircle display="loader logWait" hide="" strokeWidth="5" stroke="#008C4F" />
                    : <div className={ "form " + (newItem ? "margin" : "") }>
                        {
                            showBoxSearchCompanies && <SearchCompany handleCloseSearch={ handleSearchCompanies } handleSelect={ handleSelectCompany } saving={ false } />
                        }
                        {
                            newItem && <p className="title">AJOUTER UN CLIENT</p>
                        }
                        {
                            globalError !== ""
                                ? <p className="globalError">{ globalError }</p>
                                : ""
                        }
                        <label className="label">Société</label>
                        {
                            !editCompany && !newItem
                                ? <NoInput value={ store.company_name !== "" ? store.company_name : "Aucun" } color={ color } edit={ true } copy={ store.company_name !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditCompany } />
                                : <InputText color={ color } classError={ errorCompany !== "" ? "wrong" : "" } value={ company } placeholder="Société" newItem={ newItem } handleChange={ handleChangeCompany } handleBlur={ handleEditCompany } handleKeyEnter={ handleKeyEnter } handleSearch={ handleSearchCompanies } />
                        }
                        {
                            errorCompany !== ""
                                ? <p className="error">{ errorCompany }</p>
                                : ""
                        }
                        <label className="label">Établissement</label>
                        {
                            !editStoreName && !newItem
                                ? <NoInput value={ store.name !== "" ? store.name : "Aucun" } color={ color } edit={ true } copy={ store.name !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditStoreName } />
                                : <InputText color={ color } classError={ errorStoreName !== "" ? "wrong" : "" } value={ storeName } placeholder="Établissement" newItem={ newItem } handleChange={ handleChangeStoreName } handleBlur={ handleEditStoreName } handleKeyEnter={ handleKeyEnter } />
                        }
                        {
                            errorStoreName !== ""
                                ? <p className="error">{ errorStoreName }</p>
                                : ""
                        }
                        {
                            (newItem && partnerships.length > 1) || !newItem
                                ? <div className="clearing">
                                    <label className="label">Partenaire</label>
                                    {
                                        !newItem
                                            ? <NoInput value={ store.partnership_name !== "" ? store.partnership_name : "Aucun" } color={ color } edit={ false } copy={ store.partnership_name !== "" } phone={ false } email={ false } link={ false } />
                                            : partnerships.length > 0 && <InputSelect color={ color } classError={ errorPartnership !== "" ? "wrong" : "" } list={ partnerships } value={ partnershipName } newItem={ newItem } handleChange={ handleChangePartnership } handleBlur={ () => {} } />
                                    }
                                </div>
                                : ""
                        }
                        <div className="separator" />
                        {
                            newItem
                            ? <div className="clearing">
                                <label className="label">Adresse</label>
                                {
                                    !editAddress && !newItem
                                        ? <NoInput value={ store.mainAddress !== null && store.mainAddress.address !== "" ? store.mainAddress.address : "Aucune" } color={ color } edit={ true } copy={ store.mainAddress !== null && store.mainAddress.address !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditAddress } />
                                        : <InputText color={ color } classError={ errorAddress !== "" ? "wrong" : "" } value={ address } placeholder="Adresse" newItem={ newItem } handleChange={ handleChangeAddress } handleBlur={ handleEditAddress } handleKeyEnter={ handleKeyEnter } />
                                }
                                {
                                    errorAddress !== ""
                                        ? <p className="error">{ errorAddress }</p>
                                        : ""
                                }
                                <label className="label">Complément d'adresse</label>
                                {
                                    !editAdditional && !newItem
                                        ? <NoInput value={ store.mainAddress !== null && store.mainAddress.additional !== "" ? store.mainAddress.additional : "Aucun" } color={ color } edit={ true } copy={ store.mainAddress !== null && store.mainAddress.additional !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditAdditional } />
                                        : <InputText color={ color } classError={ errorAdditional !== "" ? "wrong" : "" } value={ additional } placeholder="Complément d'adresse" newItem={ newItem } handleChange={ handleChangeAdditional } handleBlur={ handleEditAdditional } handleKeyEnter={ handleKeyEnter } />
                                }
                                {
                                    errorAdditional !== ""
                                        ? <p className="error">{ errorAdditional }</p>
                                        : ""
                                }
                                <label className="label left">Code postal</label>
                                <label className="label right">Ville</label>
                                {
                                    !editPostalCode && !newItem
                                        ? <NoInput classname="clear left" value={ store.mainAddress !== null && store.mainAddress.postalCode !== "" ? store.mainAddress.postalCode : "Aucun" } color={ color } edit={ true } copy={ store.mainAddress !== null && store.mainAddress.postalCode !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditPostalCode } />
                                        : <InputText color={ color } classname="left" classError={ errorPostalCode !== "" ? "wrong" : "" } value={ postalCode } placeholder="Code postal" newItem={ newItem } handleChange={ handleChangePostalCode } handleBlur={ handleEditPostalCode } handleKeyEnter={ handleKeyEnter } />
                                }
                                {
                                    !editCity && !newItem
                                        ? <NoInput classname="right" value={ store.mainAddress !== null && store.mainAddress.city !== "" ? store.mainAddress.city : "Aucune" } color={ color } edit={ true } copy={ store.mainAddress !== null && store.mainAddress.city !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditCity } />
                                        : <InputText color={ color } classname="right" classError={ errorCity !== "" ? "wrong" : "" } value={ city } placeholder="Ville" newItem={ newItem } handleChange={ handleChangeCity } handleBlur={ handleEditCity } handleKeyEnter={ handleKeyEnter } />
                                }
                                <div className="clearing" />
                                {
                                    errorPostalCode !== ""
                                        ? <p className="error left">{ errorPostalCode }</p>
                                        : ""
                                }
                                {
                                    errorCity !== ""
                                        ? <p className="error right">{ errorCity }</p>
                                        : ""
                                }
                                <label className="label clear">Pays</label>
                                {
                                    !editCountry && !newItem
                                        ? <NoInput value={ store.mainAddress !== null && store.mainAddress.country !== "" ? store.mainAddress.country : "Aucun" } color={ color } edit={ true } copy={ store.mainAddress !== null && store.mainAddress.country !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditCountry } />
                                        : <InputSelect color={ color } classError={ errorCountry !== "" ? "wrong" : "" } list={ countries } value={ country } newItem={ newItem } handleChange={ handleChangeCountry } handleBlur={ handleEditCountry } />
                                }
                                {
                                    errorCountry !== ""
                                        ? <p className="error">{ errorCountry }</p>
                                        : ""
                                }
                            </div>
                            : <ListAddressForm separator={ false } handleRefresh={ handleGetClient } handleGlobalError={ handleGlobalError } object={ store } setGeneralError={ setGlobalError } />
                        }
                        {
                            !newItem && <ListContactForm separator={ true } handleRefresh={ handleGetClient } handleGlobalError={ handleGlobalError } noSearch={ false } attachTo="store" object={ store } setGeneralError={ setGlobalError } />
                        }
                        {
                            newItem
                                && <div>
                                    <button className="submit" onClick={ save }>
                                        {saving ? "Enregistrement..." : "Enregistrer"}
                                        <LoaderCircle display="loader submitLogin" hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                                    </button>
                                    <button className="cancel" onClick={ handleCloseNewClient }>Annuler</button>
                                    <div className="clearing" />
                                </div>
                        }
                    </div>
            }
        </div>
    );
}

export default ClientForm;
