import React from "react";

function AccountantIcon (props) {
    const { fill } = props;

    return (
        <svg viewBox="0 0 512 512" width="1em" height="1em" fill={ fill }>
            <path d="M346 90c-20.801 0-41.025 3.808-59.961 11.17C332.225 137.06 362 193.115 362 256s-29.775 118.94-75.961 154.83C304.975 418.192 325.2 422 346 422c91.532 0 166-74.467 166-166S437.532 90 346 90z" />
            <path d="M166 90C74.467 90 0 164.467 0 256s74.467 166 166 166c91.532 0 166-74.467 166-166S257.532 90 166 90zm0 120.982v30h-37.402v30.048H166v30h-37.281c1.384 16.799 15.488 30.048 32.638 30.048h12.01c16.562 0 30.036-13.474 30.036-30.036h30c0 33.104-26.932 60.036-60.036 60.036h-12.01c-33.696 0-61.265-26.697-62.69-60.048H68.562v-30h30.036v-30.048H68.562v-30h30.105c1.426-33.352 28.994-60.048 62.69-60.048h12.01c33.104 0 60.036 26.932 60.036 60.036h-30c0-16.562-13.474-30.036-30.036-30.036h-12.01c-17.15 0-31.254 13.25-32.638 30.048H166z" />
        </svg>
    );
}

export default AccountantIcon;
