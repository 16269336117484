import React, { useContext } from "react";
import LoaderCircle from "../../../../loader/LoaderCircle";

import SearchContext from "../../../../../context/SearchContext";

import "../../../../../css/page/SearchBar.css";

function PartnershipUsersSearchBar (props) {
    const { handleChangeInputSearch } = props;
    const { inputSearch, initialLoading, loading } = useContext(SearchContext);

    return (
        <div className="searchBarComponent marginTop">
            <input type="text" className="search" placeholder="Recherche" value={ inputSearch } onChange={ handleChangeInputSearch } />
            {
                (loading && !initialLoading) && <LoaderCircle display="loader searchBarLoading" strokeWidth="10" stroke="#008C4F" />
            }
        </div>
    );
}

export default PartnershipUsersSearchBar;
