import React, {useContext, useState} from "react";
import ProspectForm from "../../../../form/prospect/ProspectForm";
import Error from "../../../../error/Error";
import ProspectPhaseInProgress from "../prospecting/ProspectPhaseInProgress";
import AddProspectingPhase from "../../../../overbox/prospect/AddProspectingPhase";

import StoreContext from "../../../../../context/StoreContext";

import "../../../../../css/page/cards/CardInformations.css";

function ProspectCardInformations (props) {
    const { handleUpdateSending, handleAskDeleteProspect, handleGetProspect } = props;
    const { generalError } = useContext(StoreContext);
    const [ showAddProspectingPhase, setShowAddProspectingPhase ] = useState(false);

    const handleAddProspectingPhase = () => {
        setShowAddProspectingPhase(!showAddProspectingPhase);
    }

    return (
        <div className="cardInformations">
            {
                showAddProspectingPhase && <AddProspectingPhase newItem={ true } handleClose={ handleAddProspectingPhase } />
            }
            <div className="contForm">
                {
                    generalError !== "" && <Error text={ generalError } />
                }
                <ProspectForm handleUpdateSending={ handleUpdateSending } handleAskDeleteProspect={ handleAskDeleteProspect } handleGetProspect={ handleGetProspect } />
            </div>
            <div className="contProspectPhases">
                <ProspectPhaseInProgress handleAdd={ handleAddProspectingPhase } />
            </div>
        </div>
    );
}

export default ProspectCardInformations;
