import React, {useContext, useEffect, useState} from "react";
import ProductSearchBar from "./table/ProductSearchBar";
import ProductTable from "./table/ProductTable";
import ProductToolsBar from "./table/ProductToolsBar";
import FormProduct from "../../../overbox/product/FormProduct";
import ProductController from "../../../../stories/_catalog/Products/ProductController";
import DeleteProduct from "../../../overbox/product/DeleteProduct";
import SessionContext from "../../../../context/SessionContext";
import SearchContext from "../../../../context/SearchContext";
import "../../../../css/page/Content.css";
import ChoosePartnership from "../../../overbox/partnership/choosePartnership";

function ProductTableContent () {
    const { handleLogout } = useContext(SessionContext);
    const [ first, setFirst ] = useState(true);
    const [ deleting, setDeleting ] = useState(false);
    const [ generalError, setGeneralError ] = useState("");
    const [ list, setList ] = useState([]);
    const [ selectSearch, setSelectSearch ] = useState([]);
    const [ valueSelectSearch, setValueSelectSearch ] = useState(0);
    const [ inputSearch, setInputSearch ] = useState("");
    const [ initialLoading, setInitialLoading ] = useState(true);
    const [ loading, setLoading ] = useState(true);
    const [ formUpdate, setFormUpdate ] = useState(false);
    const [ formDelete, setFormDelete ] = useState(false);
    const [ partnershipIDToAdd, setPartnershipIDToAdd ] = useState(0);
    const [ idObjectToUpdate, setIdObjectToUpdate ] = useState(null);
    const valueSearchContext = {
        generalError,
        list,
        selectSearch,
        valueSelectSearch,
        inputSearch,
        initialLoading,
        loading
    };

    const handleChangeSelectSearch = event => {
        var input = event.currentTarget.value;
        setValueSelectSearch(input);
        setLoading(true);
        getProviders(input, null);
    }

    const handleChangeInputSearch = event => {
        var input = event.currentTarget.value;
        setInputSearch(input);
        setLoading(true);
        getProviders(null, input);
    }

    const handleFormUpdate = (id = null) => {
        setIdObjectToUpdate(id);
        setFormDelete(false);
        setFormUpdate(true);
    }
    const handleFormDelete = (id = null) => {
        setIdObjectToUpdate(id);
        setFormUpdate(false);
        setFormDelete(true);
    }
    const closeForm = (refresh = false) => {
        setIdObjectToUpdate(null);
        setFormUpdate(false);
        setFormDelete(false);
        setPartnershipIDToAdd(0);

        if (refresh)
            getProviders();
    }

    const handleDelete = () => {
        setDeleting(true);

        var controller = new ProductController();
        controller._callback = returnDelete;
        controller.delete(getObject());
    }
    const returnDelete = (response, error) => {
        setDeleting(false);

        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else
                setGeneralError("Impossible de supprimer le fournisseur");
        }
        else {
            setFormDelete(false);
            setIdObjectToUpdate(null);
            getProviders();
        }
    }

    const getObject = () => {
        if (idObjectToUpdate === null) return null;

        for (var i in list)
            if (list[i].id === idObjectToUpdate)
                return list[i];

        return null;
    }

    const getPartnerships = () => {
        const user = JSON.parse(localStorage.getItem("user"))

        if(user.partnerships.length < 2) return;

        let listPartnerships = [{
            id: 0,
            name: "Tous les partenaires"
        }];

        for (let i in user.partnerships)
            listPartnerships.push({
                id: user.partnerships[i].id,
                name: user.partnerships[i].company,
            });

        if (user.partnerships.length === 1)
            setPartnershipIDToAdd(user.partnerships[0].id);

        setSelectSearch(listPartnerships);
    }

    const getProviders = (select = null, input = null) => {
        setFirst(false);

        select = (select === null ? valueSelectSearch : select);
        input = (input === null ? inputSearch : input);

        const controller = new ProductController();
        controller._callback = handleGetBrands;
        controller.index(select, input);
    }
    const handleGetBrands = (list, error) => {
        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else
                setGeneralError("Impossible de récupérer les données");
        }
        else
            setGeneralError("");

        setInitialLoading(false);
        setLoading(false);
        setList(list);
    }

    const choosePartnership = id => {
        setPartnershipIDToAdd(id);
    }

    useEffect(() => {
        document.title = "HIVY - Rechercher un fournisseur";

        if(first) {
            getPartnerships();
            getProviders();
        }
    });

    return (
        <div className="content">
            <SearchContext.Provider value={valueSearchContext}>
                {
                    formUpdate &&
                    (
                        partnershipIDToAdd === 0
                            ? <ChoosePartnership handleClose={ closeForm } handleSelect={ choosePartnership } />
                            : <FormProduct handleClose={ closeForm } handleDelete={ handleFormDelete } handleRefresh={ () => { getProviders() } } brand={ getObject() } newItem={ idObjectToUpdate === null } partnershipID={ partnershipIDToAdd } />
                    )
                }
                {
                    formDelete && <DeleteProduct handleClose={ closeForm } handleDelete={ handleDelete } brand={ getObject() } deleting={ deleting } />
                }
                <ProductToolsBar handleNew={ handleFormUpdate } />
                <ProductSearchBar handleChangeInput={ handleChangeInputSearch } handleChangeSelect={ handleChangeSelectSearch } />
                <ProductTable handleUpdate={ handleFormUpdate } />
            </SearchContext.Provider>
        </div>
    );
}

export default ProductTableContent;
