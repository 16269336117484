import React from "react";
import TicketTypeForm from "../../../form/settingTicket/ticketType/TicketTypeForm";

function FormTicketType (props) {
    const { newItem, type, handleClose, handleDelete } = props;

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox newItemSmall">
                    <TicketTypeForm handleClose={ handleClose } handleDelete={ handleDelete } newItem={ newItem } type={ type } />
                </div>
            </div>
        </div>
    );
}

export default FormTicketType;
