import React from "react";
import LoaderCircle from "../../loader/LoaderCircle";

function WhatToDoDeleteUser (props) {
    const { user, deleting, handleAskDeleteUser, handleDetachUser, handleCloseBoxAskDeleteUser } = props;

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox choices">
                    <div className="form margin">
                        <p className="titleForm center">Que souhaitez-vous faire à propos de <strong>{ user.lastname + " " + user.firstname }</strong></p>
                        <button className="buttonChoice spaceTop" onClick={ handleDetachUser }>
                            { deleting ? "Détachement en cours..." : "Détacher l'utilisateur de ce partenaire" }
                            <LoaderCircle display="loader submitLogin" hide={!deleting ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                        </button>
                        <button className="buttonChoice" onClick={ handleAskDeleteUser }>Supprimer complètement l'utilisateur</button>
                        <button className="cancel block" onClick={ handleCloseBoxAskDeleteUser }>Annuler</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhatToDoDeleteUser;
