import React, {useContext, useState} from "react"
import SetupController from "../../../stories/_setup/Setup/SetupController"
import Setup from "../../../stories/_setup/Setup/Setup"
import WhatTypeOfInstallation from "../setup/WhatTypeOfInstallation"
import WhatDateOfInstallation from "../setup/WhatDateOfInstallation"
import SearchContact from "../contact/SearchContact"
import ContactController from "../../../stories/_client/Contacts/ContactController"
import AccountController from "../../../stories/_setup/Accounts/AccountController"
import StoreContext from "../../../context/StoreContext"
import SessionContext from "../../../context/SessionContext"

const NewSetup = props => {
    const { handleClose, setSetupSelected } = props
    const { handleLogout } = useContext(SessionContext)
    const { store, setGeneralError } = useContext(StoreContext)
    const [ step, setStep ] = useState(1)
    const [ setupObject, setSetupObject ] = useState(new Setup())
    const [ sendingSetup, setSendingSetup ] = useState("")
    const [ typeName, setTypeName ] = useState("")
    const [ sendingDate, setSendingDate ] = useState(false)
    const [ sendingAccounts, setSendingAccounts ] = useState(false)

    const returnUpdates = (controller, datas) => {
        if (controller === undefined)
            controller = new ContactController()

        return controller.returnUpdatesFromCompare(setupObject, datas)
    }

    const createSetup = (idType, nameType) => {
        if (sendingSetup !== "") return

        setSendingSetup(idType)
        setTypeName(nameType)

        let controller = new SetupController()
        const datas = returnUpdates(controller, {
            "type_id": idType,
            "type_name": nameType
        })

        if(Object.keys(datas).length === 0) {
            setSendingSetup("")
            return
        }

        controller._callback = handleCreateSetup
        controller.post(store, datas)
    }
    const handleCreateSetup = (response, error) => {
        if(error) {
            if(error.response !== undefined && error.response.status !== undefined) {
                if(error.response.status === 401)
                    handleLogout()
                else if(error.response.status === 422)
                    setGeneralError("Certaines données sont invalides")
                else {
                    if (typeof error.response.data === "object")
                        setGeneralError(error.response.data.message)
                    else
                        setGeneralError(error.response.data)
                }
            }
            else
                setGeneralError("Une erreur s'est produite lors de l'enregistrement")

            handleClose()
        }
        else {
            let setup = Object.assign({}, setupObject)
            setup.id = response.data.id
            setSetupObject(setup)

            setStep(2)
        }
    }

    const pad = s => {
        return (s < 10) ? '0' + s : s
    }
    const setDateSetup = (choice, date) => {
        if (sendingDate) return

        setSendingDate(true)

        let controller = new SetupController()
        let datasToSend = {}

        if( choice === 1 ) {
            let d = new Date(date)
            datasToSend.expected_date = [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
        }

        if( choice === 3 ) datasToSend.already_installed = true

        const datas = returnUpdates(controller, datasToSend)

        if(Object.keys(datas).length === 0) {
            setSendingDate(false)
            return
        }

        controller._callback = handlePutSetup
        controller.put(store, setupObject, datas)
    }
    const handlePutSetup = (response, error) => {
        if(error) {
            if(error.response !== undefined && error.response.status !== undefined) {
                if(error.response.status === 401)
                    handleLogout()
                else if(error.response.status === 422)
                    setGeneralError("Certaines données sont invalides")
                else {
                    if (typeof error.response.data === "object")
                        setGeneralError(error.response.data.message)
                    else
                        setGeneralError(error.response.data)
                }
            }
            else
                setGeneralError("Une erreur s'est produite lors de l'enregistrement de la date")

            handleClose()
        }
        else {
            if (typeName.toLowerCase() === "skytill")
                setStep(3)
            else {
                setSetupSelected(setupObject.id)
                handleClose(true)
            }
        }
    }

    const createAccounts = (contacts, rules) => {
        if (sendingAccounts) return

        setSendingAccounts(true)

        let ids = []

        for (let item in contacts)
            ids.push(contacts[item].id)

        let controller = new AccountController()
        controller._callback = handleCreateAccounts
        controller.registerAccounts(setupObject, ids, rules)
    }
    const handleCreateAccounts = (response, error) => {
        setSendingAccounts(false);

        if(error) {
            if(error.response !== undefined && error.response.status !== undefined) {
                if(error.response.status === 401)
                    handleLogout();
                else if(error.response.status === 422)
                    setGeneralError("Certaines données sont invalides");
                else {
                    if (typeof error.response.data === "object")
                        setGeneralError(error.response.data.message);
                    else
                        setGeneralError(error.response.data);
                }
            }
            else
                setGeneralError("Une erreur s'est produite lors de la création des comptes utilisateurs SkyTill");
        }

        handleClose(true);
    }

    return (
        <div className="overlayer">
            {
                step === 1 && <WhatTypeOfInstallation sending={ sendingSetup } handleClose={ handleClose } handleCreateSetup={ createSetup } />
            }
            {
                step === 2 && <WhatDateOfInstallation sending={ sendingDate } handleSetDateSetup={ setDateSetup } />
            }
            {
                step === 3 &&
                <SearchContact
                    handleClose={ handleClose }
                    handleAttach={ createAccounts }
                    saving={ sendingAccounts }
                    multi={ true }
                    inStore={ true }
                    noRule={ false }
                    title="Pour qui créer un compte SkyTill Manager ?"
                />
            }
        </div>
    )
}

export default NewSetup
