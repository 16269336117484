import Entity from "../../../class/abstract/Entity";
import Company from "../Companies/Company";
import LegalData from "../LegalDatas/LegalData";
import Address from "../Addresses/Address";
import BankData from "../BankDatas/BankData";
import Contact from "../Contacts/Contact";
import Setup from "../../_setup/Setup/Setup";
import ProspectingPhase from "../ProspectingPhases/ProspectingPhase";

class Store extends Entity {
    skytill_id = null;
    name = "";
    code = "";
    company_id = 0;
    company_name = "";
    postal_code = "";
    city = "";
    country = "";
    partnership_id = 0;
    partnership_name = "";
    creator_id = 0;
    creator_name = "";
    assigned_to_id = 0;
    assigned_to_type = null;
    assigned_to_name = "";
    prospect_state = "";
    acquisition_channel_id = 0;
    acquisition_channel_name = "";
    prospect_phase_id = 0;
    current_prospecting_phase = null;
    company = null;
    legalData = null;
    mainAddress = null;
    has_setup = false;
    addressesBilling = [];
    addressesDelivery = [];
    addresses = [];
    bankDatas = [];
    contacts = [];
    setups = [];
    prospectingPhases = [];

    constructor(datas = {}) {
        super(datas);

        this.setAttributes(datas);
    }

    setAttributes(datas) {
        var keys = Object.keys(this);
        var key = "";
        let address;
        var i = 0;

        for(i in keys) {
            key = keys[i];

            if (datas[key] !== undefined) {
                if (key === "company") Reflect.set(this, key, datas[key] !== null ? new Company(datas[key]) : null);
                else if (key === "legalData") Reflect.set(this, key, datas[key] !== null ? new LegalData(datas[key]) : null);
                else if (key === "current_prospecting_phase") Reflect.set(this, key, datas[key] !== null ? new ProspectingPhase(datas[key]) : null);
                else if (key === "assigned_to_type") {
                    if (datas[key] === null)
                        Reflect.set(this, key, "");
                    else {
                        if (datas[key].includes('UserGroup'))
                            Reflect.set(this, key, "GROUP");
                        else
                            Reflect.set(this, key, "USER");
                    }
                }
                else {
                    // except keys
                    let exceptKeys = [
                        "prospecting_phases",
                        "addresses_billing",
                        "addresses_delivery",
                        "addresses",
                        "bank_datas",
                        "contacts",
                        "setups"
                    ];

                    if (!exceptKeys.includes(key))
                        Reflect.set(this, key, datas[key]);
                }
            }
        }

        if (datas.prospecting_phases_list !== undefined && datas.prospecting_phases_list.length > 0) {
            for (i in datas.prospecting_phases_list)
                this.prospectingPhases.push(new ProspectingPhase(datas.prospecting_phases_list[i]));
        }

        if (datas.addresses_billing !== undefined && datas.addresses_billing.length > 0) {
            for (i in datas.addresses_billing) {
                address = new Address(datas.addresses_billing[i]);

                this.addressesBilling.push(address);

                if(address.main)
                    this.mainAddress = address;
            }
        }

        if (datas.addresses_delivery !== undefined && datas.addresses_delivery.length > 0) {
            for (i in datas.addresses_delivery) {
                address = new Address(datas.addresses_delivery[i]);

                this.addressesDelivery.push(address);

                if(address.main)
                    this.mainAddress = address;
            }
        }

        this.addresses = this.addressesBilling.concat(this.addressesDelivery);

        if (datas.bank_datas !== undefined && datas.bank_datas.length > 0)
            for (i in datas.bank_datas)
                this.bankDatas.push(new BankData(datas.bank_datas[i]));

        if (datas.contacts !== undefined && datas.contacts.length > 0)
            for (i in datas.contacts)
                this.contacts.push(new Contact(datas.contacts[i]));

        if (datas.setups !== undefined && datas.setups.length > 0)
            for (i in datas.setups)
                this.setups.push(new Setup(datas.setups[i]));
    }
}

export default Store;
