import React from "react";

function TrashIcon (props) {
    const { fill, classname, handleClick } = props;

    return (
        <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill={ fill } className={ classname !== undefined ? classname : "" } onClick={ (handleClick !== undefined ? handleClick : () => {}) }>
            <path d="M724.9 952.2h-423c-22.1 0-40.4-17.1-41.9-39.2l-36.3-539.6c-1.6-24.3 17.6-44.8 41.9-44.8h495.6c24.3 0 43.5 20.6 41.9 44.8L766.8 913c-1.5 22.1-19.8 39.2-41.9 39.2zm119.6-702.3h-657c-.6 0-1-.4-1-1V134c0-.6.4-1 1-1h657c.6 0 1 .4 1 1v114.8c0 .6-.4 1.1-1 1.1z" />
            <path d="M690.9 189.5H339.8c-.6 0-1-.4-1-1V57.9c0-.6.4-1 1-1h351.1c.6 0 1 .4 1 1v130.6c0 .5-.4 1-1 1z" />
        </svg>
    );
}

export default TrashIcon;
