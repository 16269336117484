import Entity from "../../../class/abstract/Entity";

class Company extends Entity {
    skytill_id = null;
    name = "";

    constructor(datas = {}) {
        super(datas);

        this.setAttributes(datas);
    }

    setAttributes(datas) {
        this.id = datas.id !== undefined ? datas.id : 0;
        this.skytill_id = datas.skytill_id !== undefined ? datas.skytill_id : null;
        this.name = datas.name !== undefined ? datas.name : "";
        this.updated_at = datas.updated_at !== undefined ? datas.updated_at : "";
        this.created_at = datas.created_at !== undefined ? datas.created_at : "";
    }
}

export default Company;
