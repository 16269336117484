import React from "react";

import '../../../../css/page/Options.css';

const OptionClient = props => {
    const { show, handleDelete } = props;

    return (
        <div className={"overOptions prospectOptions " + (show ? " show" : "")}>
            <nav className="options">
                <p className="item red" onClick={ handleDelete }>Supprimer</p>
            </nav>
        </div>
    );
}

export default OptionClient;
