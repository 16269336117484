import React, {useContext} from "react";
import ProspectTabs from "./ProspectTabs";
import LoaderCircle from "../../../../loader/LoaderCircle";
import ProspectCardInformations from "./ProspectCardInformations";
import Error from "../../../../error/Error";

import StoreContext from "../../../../../context/StoreContext";

import "../../../../../css/page/cards/Card.css";

function ProspectCard (props) {
    const { tab, handleChangeProspectState, handleAskDeleteProspect, handleGetProspect, sending, setSending, handleManage, show, handleToClient, handleDelete } = props;
    const { store, generalError } = useContext(StoreContext);

    const handleUpdateSending = bool => {
        setSending(bool);
    }

    const goContentCard = () => {
        switch (tab) {
            case "information": return <ProspectCardInformations handleUpdateSending={ handleUpdateSending } handleAskDeleteProspect={ handleAskDeleteProspect } handleGetProspect={ handleGetProspect } />
            default: return <div />
        }
    }

    return (
        <div className="card">
            {
                store !== null
                ? <ProspectTabs tab={ tab } sending={ sending } handleManage={ handleManage } show={ show } handleChangeProspectState={ handleChangeProspectState } handleToClient={ handleToClient } handleDelete={ handleDelete } />
                : generalError === "" && <LoaderCircle display="loader logWait" hide="" strokeWidth="5" stroke="#008C4F" />
            }
            {
                store !== null
                ? goContentCard()
                : generalError !== "" && <Error text={ generalError } />
            }
        </div>
    );
}

export default ProspectCard;
