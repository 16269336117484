import React from "react";
import InputSelect from "./InputSelect";
import InputText from "./InputText";
import CancelIcon from "../../../icons/CancelIcon";
import ReApplyIcon from "../../../icons/ReApplyIcon";
import CrossIcon from "../../../icons/CrossIcon";

const InputPricelist = props => {
    const { lines, pricelists, vatrates, category, classname, handleChange, handleAdd, handleRemove, handleRecovery } = props;

    const getVatRateById = id => {
        for (let i in vatrates)
            if (vatrates[i].id === id)
                return vatrates[i];

        return null;
    }
    const getPricelistColor = line => {
        return line.pricelist.color !== null ? line.pricelist.color : "#FFFFFF";
    }
    const getMainPricelistColor = () => {
        for (let index in pricelists)
            if (pricelists[index].main === 1)
                return pricelists[index].color !== null ? pricelists[index].color : "#FFFFFF";

        return "#FFFFFF";
    }
    const getMainPrice = () => {
        for (let index in lines) {
            if (lines[index].pricelist.main === 1) {
                if (lines[index].productPrice !== null)
                    return lines[index].productPrice.value
                else
                    return null
            }
        }

        return 0
    }
    const getMainVat = () => {
        for (let index in lines)
            if (lines[index].pricelist.main === 1)
                return lines[index].productVatRate.id;

        return -1;
    }
    const buildLine = (line, index) => {
        const color = getPricelistColor(line);

        return <tr key={ index } className="lineList">
            <td className="color noBorderR noPadding" style={{backgroundColor: color}} />
            <td className="name noBorderL" style={{backgroundColor: color + "22"}}>
                <p className="info">{ line.pricelist.name }</p>
            </td>
            <td className="vatRate">{ buildVatRate(line, color) }</td>
            <td className="rule">{ buildPriceVariation(line, color) }</td>
            <td className="price">{ buildUnitPrice(line, color) }</td>
        </tr>
    }
    const getIndexVatRateForValue = vatRateId => {
        let value = 0

        for (let i = 0; i < vatrates.length; i++) {
            if (vatrates[i].id === vatRateId) {
                value = i
                break
            }
        }

        return value
    }
    const buildVatRate = (line, color) => {
        color = line.productVat === null ? getMainPricelistColor() : color;
        let hasRule = false;
        let applyingAvaible = true;
        let textRule = "";

        if (line.productVat !== null && line.productVat.vat_rule_id !== null)
            applyingAvaible = false;

        if (line.categoryVatRule !== null) {
            hasRule = true;
            let maxLength = 20;

            if (applyingAvaible)
                maxLength = 17;

            textRule = category.name.substring(0, maxLength) + (category.name.length > maxLength ? "..." : "") + " : " + getVatRateById(line.categoryVatRule.vat_rate_id).value;
        }

        return <div className="containerVertical">
            <div className={ "top" + (!hasRule ? " rowspan" : "") }>
                <InputSelect
                    attribute={line.pricelist.id + "|vatRate"}
                    returnType="pricelist"
                    classname="pricelist"
                    backgroundColor={ color }
                    classError=""
                    value={ getIndexVatRateForValue(line.productVat !== null ? line.productVat.vat_rate_id : getMainVat())  }
                    list={ vatrates }
                    newItem={ true }
                    handleChange={ changeEvent } />
            </div>
            {
                hasRule
                && <div className="bottom">
                    <p className={ !applyingAvaible ? "extend" : "" }>{ textRule }</p>
                    {
                        applyingAvaible
                        && <ReApplyIcon fill={ color } handleClick={ () => { recoveryEvent(line.pricelist.id, "vatRate") } } />
                    }
                </div>
            }
        </div>
    }
    const buildPriceVariation = (line, color) => {
        color = line.productPriceVariation === null ? getMainPricelistColor() : color;
        let hasRule = false;
        let applyingAvaible = true;
        let textRule = "";

        if (line.pricelist.main === 1 || (line.productPriceVariation !== null && line.productPriceVariation.rule_id !== null))
            applyingAvaible = false;

        if (line.categoryPriceRule !== null) {
            hasRule = true;
            let maxLength = 30;

            if (applyingAvaible)
                maxLength = 27;

            textRule = category.name.substring(0, maxLength)
                + (category.name.length > maxLength ? "..." : "")
                + " : "
                + line.categoryPriceRule.variationOperator
                + " "
                + (
                    line.categoryPriceRule.type === "PERCENT"
                        ? line.categoryPriceRule.value
                        : new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(line.categoryPriceRule.value).replace(" €", "")
                )
                + " "
                + (
                    line.categoryPriceRule.type === "PERCENT"
                        ? "%"
                        : "EUR"
                );
        }

        return <div className="containerVertical">
            <div className={ "top" + (!hasRule ? " rowspan" : "") }>
                {
                    getMainPrice() !== null
                        ? <div className="variationContainer">
                            <div className={ "variationOperator" + (line.pricelist.main === 1 ? " alone" : "") }>
                                <div className={ "bubble" + getBubblePlace(line, "variationOperator") } style={{backgroundColor: color + "22", borderColor: color}} />
                                {
                                    line.pricelist.main === 1
                                        ? <div className={ "texts" + (line.pricelist.main === 1 ? " readonly" : "") }>
                                            <p onClick={line.pricelist.main === 0 ? () => { changeEvent(line.pricelist.id + "|variationOperator", "pricelist", "=") } : () => {}}>=</p>
                                        </div>
                                        : <div className={ "texts" + (line.pricelist.main === 1 ? " readonly" : "") }>
                                            <p onClick={line.pricelist.main === 0 ? () => { changeEvent(line.pricelist.id + "|variationOperator", "pricelist", "=") } : () => {}}>=</p>
                                            <p onClick={line.pricelist.main === 0 ? () => { changeEvent(line.pricelist.id + "|variationOperator", "pricelist", "-") } : () => {}}>-</p>
                                            <p onClick={line.pricelist.main === 0 ? () => { changeEvent(line.pricelist.id + "|variationOperator", "pricelist", "+") } : () => {}}>+</p>
                                        </div>
                                }
                            </div>
                            <div className="value">
                                <InputText
                                    attribute={line.pricelist.id + "|variationValue"}
                                    returnType="pricelist"
                                    classname="pricelist extend"
                                    backgroundColor={ color }
                                    classError=""
                                    value={ line.productPriceVariation !== null ? line.productPriceVariation.value : getMainPrice() }
                                    placeholder="0"
                                    newItem={ true }
                                    handleChange={ changeEvent } />
                            </div>
                            <div className={ "type" + (line.pricelist.main === 1 ? " alone" : "") }>
                                <div className={ "bubble" + getBubblePlace(line, "variationType") }  style={{backgroundColor: color + "22", borderColor: color}} />
                                {
                                    line.pricelist.main === 1
                                        ? <div className="texts">
                                            <p onClick={line.pricelist.main === 0 ? () => { changeEvent(line.pricelist.id + "|variationType", "pricelist", "DEVISE") } : () => {}}>€</p>
                                        </div>
                                        : <div className="texts">
                                            <p onClick={line.pricelist.main === 0 ? () => { changeEvent(line.pricelist.id + "|variationType", "pricelist", "DEVISE") } : () => {}}>€</p>
                                            <p onClick={line.pricelist.main === 0 ? () => { changeEvent(line.pricelist.id + "|variationType", "pricelist", "PERCENT") } : () => {}}>%</p>
                                        </div>
                                }
                            </div>
                            <div className="remove">
                                {
                                    (line.pricelist.main === 1 || line.productPriceVariation !== null)
                                        && <div className="button">
                                            <CancelIcon click={ () => { removePrice(line.pricelist.id) } } />
                                        </div>
                                }
                            </div>
                        </div>
                    : <div className="variationContainer">
                            <div className="add">
                                {
                                    line.pricelist.main === 1
                                    && <div className="button">
                                        <CrossIcon click={ () => { addPrice(line.pricelist.id) } } />
                                    </div>
                                }
                            </div>
                    </div>
                }
            </div>
            {
                hasRule
                && <div className="bottom">
                    <p className={ !applyingAvaible ? "extend" : "" }>{ textRule }</p>
                    {
                        applyingAvaible
                        && <ReApplyIcon fill={ color } handleClick={ () => { recoveryEvent(line.pricelist.id, "variation") } } />
                    }
                </div>
            }
        </div>
    }
    const getBubblePlace = (line, attribute) => {
        if (line.productPriceVariation === null) return "";

        switch (attribute) {
            case "variationOperator":
                if (line.productPriceVariation.variationOperator === "=") return "";
                else if (line.productPriceVariation.variationOperator === "-") return " two";
                else if (line.productPriceVariation.variationOperator === "+") return " three";
                break;
            case "variationType":
                if (line.productPriceVariation.type === "DEVISE") return "";
                else if (line.productPriceVariation.type === "PERCENT") return " two";
                break;
            default: break;
        }
    }
    const buildUnitPrice = (line, color) => {
        if (getMainPrice() === null) return

        const price = (line.pricelist.main === 1 || line.productPrice === null) ? getMainPrice() : line.productPrice.value;
        color = line.productPrice === null ? getMainPricelistColor() : color;

        return <p className="value" style={{backgroundColor: color + "22"}}>
            {
                new Intl.NumberFormat(
                    'de-DE',
                    { style: 'currency', currency: 'EUR' }
                ).format(price).replace(" €", "")
            }
        </p>
    }
    const addPrice = idPricelist => {
        if (handleAdd === undefined || handleAdd === null) return;
        handleAdd(idPricelist);
    }
    const removePrice = idPricelist => {
        if (handleRemove === undefined || handleRemove === null) return;
        handleRemove(idPricelist);
    }
    const changeEvent = (attribute, returnType, value) => {
        if (handleChange === undefined || handleChange === null) return;
        handleChange(attribute, returnType, value);
    }
    const recoveryEvent = (idPricelist, type) => {
        if (handleRecovery === undefined || handleRecovery === null) return;
        handleRecovery(idPricelist, type, getMainPrice());
    }

    return (
        <table className={ "inputPricelist " + (classname !== undefined ? classname : "") }>
            <tbody>
                <tr className="headerList">
                    <th rowSpan="2" colSpan="2" className="noBorder"></th>
                    <th rowSpan="2">TVA</th>
                    <th colSpan="2">Prix</th>
                </tr>
                <tr className="subHeaderList">
                    <th>Règle</th>
                    <th>P.U</th>
                </tr>
                {
                    lines.map((line, index) => buildLine(line, index))
                }
            </tbody>
        </table>
    );
}

export default InputPricelist;
