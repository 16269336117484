import React, {useContext, useEffect, useState} from "react";
import ClientCard from "./card/ClientCard";
import DeleteClient from "../../../overbox/prospect/DeleteProspect";
import StoreController from "../../../../stories/_client/Stores/StoreController";
import SessionContext from "../../../../context/SessionContext";
import StoreContext from "../../../../context/StoreContext";
import "../../../../css/page/Content.css";

function ClientCardContent (props) {
    const { id, tab, idSubItem } = props;
    const { handleLogout } = useContext(SessionContext);
    const [ refresh, setRefresh ] = useState(false);
    const [ generalError, setGeneralError ] = useState("");
    const [ deleting, setDeleting ] = useState(false);
    const [ sending, setSending ] = useState(false);
    const [ store, setStore ] = useState(null);
    const [ boxDelete, setBoxDelete ] = useState(false);
    const [ showOptionClient, setShowOptionClient ] = useState(false);
    const valueStoreContext = {
        generalError,
        setGeneralError,
        store
    };

    const handleSetSending = val => {
        setSending(val);
    }
    const handleManage = () => {
        setShowOptionClient(!showOptionClient);
    }
    const getClient = () => {
        const controller = new StoreController();
        controller._callback = handleGetClient;
        controller.show(id);
    }
    const handleGetClient = (object, error) => {
        if(error) {
            if(error.response !== undefined) {
                if (error.response.status === 401)
                    handleLogout();
                else if (error.response.status === 404)
                    setGeneralError("Ce client est introuvable");
            }
            else
                setGeneralError("Impossible de récupérer les données");
        }

        setStore(object);
        setRefresh(true);
    }
    const handleAskDeleteClient = () => {
        setShowOptionClient(false);
        setBoxDelete(!boxDelete);
    }
    const deleteClient = () => {
        setDeleting(true);

        const controller = new StoreController();
        controller._callback = handleDeleteClient;
        controller.delete(store);
    }
    const handleDeleteClient = (response, error) => {
        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else
                setGeneralError("Impossible de supprimer le client");
        }
        else
            window.location = "/client";
    }

    useEffect(() => {
        document.title = "HIVY - " + (store === null ? "Fiche client" : store.name);
        getClient();
    }, []);

    return (
        <div className="content">
            <StoreContext.Provider value={valueStoreContext}>
                {
                    boxDelete && <DeleteClient store={ store } deleting={ deleting } handleAskDeleteClient={ handleAskDeleteClient } deleteClient={ deleteClient } />
                }
                <ClientCard tab={ tab } idSubItem={ idSubItem } handleAskDeleteProspect={ handleAskDeleteClient } handleGetClient={ getClient } sending={ sending } setSending={ handleSetSending } handleManage={ handleManage } show={ showOptionClient } handleDelete={ handleAskDeleteClient } refresh={ refresh } setRefresh={ setRefresh } />
            </StoreContext.Provider>
        </div>
    );
}

export default ClientCardContent;
