import React from "react";
import LicenseForm from "../../form/license/LicenseForm";

function UpdateLicense (props) {
    const { setup, handleClose, handleRefresh, object } = props;

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox newItem">
                    <LicenseForm setup={setup} handleClose={ handleClose } handleRefresh={ handleRefresh } object={ object } />
                </div>
            </div>
        </div>
    );
}

export default UpdateLicense;
