import React, {useContext, useEffect, useState} from "react";
import ProspectSearchBar from "./table/ProspectSearchBar";
import ProspectTable from "./table/ProspectTable";
import ProspectToolsBar from "./table/ProspectToolsBar";
import NewProspect from "../../../overbox/prospect/NewProspect";
import StoreController from "../../../../stories/_client/Stores/StoreController";
import SessionContext from "../../../../context/SessionContext";
import SearchContext from "../../../../context/SearchContext";
import "../../../../css/page/Content.css";

function ProspectTableContent () {
    const { handleLogout } = useContext(SessionContext);
    const [ generalError, setGeneralError ] = useState("");
    const [ list, setList ] = useState([]);
    const [ inputSearch, setInputSearch ] = useState("");
    const [ initialLoading, setInitialLoading ] = useState(true);
    const [ loading, setLoading ] = useState(true);
    const [ newProspectForm, setNewProspectForm ] = useState(false);
    const valueSearchContext = {
        generalError,
        list,
        inputSearch,
        initialLoading,
        loading
    };

    const handleChangeInputSearch = event => {
        var input = event.currentTarget.value;
        setInputSearch(input);
        setLoading(true);
        getProspects(input);
    }
    const newProspect = () => {
        setNewProspectForm(true);
    }
    const closeNewProspect = () => {
        setNewProspectForm(false);
    }
    const getProspects = input => {
        const controller = new StoreController();
        controller._callback = handleGetProspects;
        controller.index("prospect", input);
    }
    const handleGetProspects = (list, error) => {
        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else
                setGeneralError("Impossible de récupérer les données");
        }
        else
            setGeneralError("");

        setInitialLoading(false);
        setLoading(false);
        setList(list);
    }

    useEffect(() => {
        document.title = "HIVY - Rechercher un prospect";
        getProspects("");
    }, []);

    return (
        <div className="content">
            <SearchContext.Provider value={valueSearchContext}>
                {
                    newProspectForm && <NewProspect handleCloseNewProspect={ closeNewProspect } />
                }
                <ProspectToolsBar handleNewProspect={ newProspect } />
                <ProspectSearchBar handleChangeInputSearch={ handleChangeInputSearch } />
                <ProspectTable />
            </SearchContext.Provider>
        </div>
    );
}

export default ProspectTableContent;
