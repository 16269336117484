import React, {useContext, useEffect, useState} from "react";
import ProviderCard from "./card/ProviderCard";
import ProviderController from "../../../../stories/_catalog/Providers/ProviderController";
import SessionContext from "../../../../context/SessionContext";
import ProviderContext from "../../../../context/ProviderContext";
import DeleteProvider from "../../../overbox/provider/DeleteProvider";

import "../../../../css/page/Content.css";

function ProviderCardContent (props) {
    const { id, tab } = props;
    const { handleLogout } = useContext(SessionContext);
    const [ first, setFirst ] = useState(true);
    const [ refresh, setRefresh ] = useState(false);
    const [ generalError, setGeneralError ] = useState("");
    const [ deleting, setDeleting ] = useState(false);
    const [ sending, setSending ] = useState(false);
    const [ provider, setProvider ] = useState(null);
    const [ boxDelete, setBoxDelete ] = useState(false);
    const [ showOptionProvider, setShowOptionProvider ] = useState(false);
    const valueStoreContext = {
        generalError,
        setGeneralError,
        provider
    };

    const handleSetSending = val => {
        setSending(val);
    }

    const handleManage = () => {
        setShowOptionProvider(!showOptionProvider);
    }

    const getProvider = () => {
        setFirst(false);

        const controller = new ProviderController();
        controller._callback = handleGetProvider;
        controller.show(id);
    }
    const handleGetProvider = (object, error) => {
        if(error) {
            if(error.response !== undefined) {
                if (error.response.status === 401)
                    handleLogout();
                else if (error.response.status === 404)
                    setGeneralError("Ce fournisseur est introuvable");
            }
            else
                setGeneralError("Impossible de récupérer les données");
        }

        setProvider(object);
        setRefresh(true);
    }

    const handleAskDeleteProvider = () => {
        setShowOptionProvider(false);
        setBoxDelete(!boxDelete);
    }

    const deleteProvider = () => {
        setDeleting(true);

        const controller = new ProviderController();
        controller._callback = handleDeleteProvider;
        controller.delete(provider);
    }
    const handleDeleteProvider = (response, error) => {
        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else
                setGeneralError("Impossible de supprimer le client");
        }
        else
            window.location = "/client";
    }

    useEffect(() => {
        document.title = "HIVY - " + (provider === null ? "Fiche fournisseur" : provider.name);

        if(first)
            getProvider();
    });

    return (
        <div className="content">
            <ProviderContext.Provider value={valueStoreContext}>
                {
                    boxDelete && <DeleteProvider provider={ provider } deleting={ deleting } handleAskDeleteProvider={ handleAskDeleteProvider } deleteProvider={ deleteProvider } />
                }
                <ProviderCard tab={ tab } handleAskDeleteProspect={ handleAskDeleteProvider } handleGet={ getProvider } sending={ sending } setSending={ handleSetSending } handleManage={ handleManage } show={ showOptionProvider } handleDelete={ handleAskDeleteProvider } refresh={ refresh } setRefresh={ setRefresh } />
            </ProviderContext.Provider>
        </div>
    );
}

export default ProviderCardContent;
