import React, { useContext } from "react";
import LoaderCircle from "../../../../loader/LoaderCircle";
import SearchContext from "../../../../../context/SearchContext";
import "../../../../../css/page/SearchBar.css";
import InputText from "../../../../form/input/InputText";

function ClientSearchBar (props) {
    const { handleChangeInputSearch } = props;
    const { inputSearch, initialLoading, loading } = useContext(SearchContext);

    return (
        <div className="searchBarComponent">
            <div className="form noPadding">
                <InputText
                    classname="marginTop"
                    value={ inputSearch }
                    placeholder="Rechercher un prospect"
                    newItem={ true }
                    handleChange={ handleChangeInputSearch }
                />
                {
                    (loading && !initialLoading)
                    && <LoaderCircle
                        display="loader searchBarLoading"
                        strokeWidth="7"
                        stroke="#FFFFFF"
                    />
                }
            </div>
        </div>
    );
}

export default ClientSearchBar;
