import React, {useContext, useState} from "react";
import InputText from "../input/InputText";
import UserController from "../../../stories/_auth/Users/UserController";
import SessionContext from "../../../context/SessionContext";
import LoaderCircle from "../../loader/LoaderCircle";
import BarStrength from "../input/BarStrength";

function PwdForm (props) {
    const color = window.matchMedia('(prefers-color-scheme: dark)').matches ? "#FFFFFF" : "#1D1D1D";

    const { newItem, user, handleClose, handleError, handleValid } = props;
    const { handleLogout } = useContext(SessionContext);
    const [ saving, setSaving ] = useState(false);
    const [ password, setPassword ] = useState("");
    const [ editPassword, setEditPassword ] = useState(false);
    const [ errorPassword, setErrorPassword ] = useState("");
    const [ confirm, setConfirm ] = useState("");
    const [ editConfirm, setEditConfirm ] = useState(false);
    const [ errorConfirm, setErrorConfirm ] = useState("");
    const [ strength, setStrength ] = useState(0);

    const handleEditPassword = () => {
        if(processEdit(editPassword))
            setEditPassword(!editPassword);
    }
    const handleChangePassword = event => {
        var input = event.currentTarget.value;

        setPassword(input);
        calcStrength(input);
    }
    const calcStrength = input => {
        var strong = 0;

        const regxpStrong = /^(?=.*[!@#$%^&*-._])(?=.*[0-9])(?=.*[A-Z]).{8,}$/;
        const regxpMid = /^(?=.*[0-9])(?=.*[A-Z]).{8,}$/;

        if(input.length > 0 && input.length < 8)
            strong = 1;
        else {
            if (regxpStrong.test(input))
                strong = 3;
            else if (regxpMid.test(input))
                strong = 2;
        }

        console.log("STRONG", strong);
        setStrength(strong);
    }
    const checkPassword = () => {
        const regxp = /^(?=.*[0-9])(?=.*[A-Z]).{8,}$/;

        if(password === "") {
            setErrors("password", true);
            return false;
        }

        if (!regxp.test(password)) {
            setErrors("password", false);
            return false;
        }

        setErrorPassword("");

        return true;
    }

    const handleEditConfirm = () => {
        if(processEdit(editConfirm))
            setEditConfirm(!editConfirm);
    }
    const handleChangeConfirm = event => {
        setConfirm(event.currentTarget.value);
    }
    const checkConfirm = () => {
        if (password.localeCompare(confirm) !== 0) {
            setErrors("password_confirmation", false);
            return false;
        }

        setErrorConfirm("");

        return true;
    }

    // CONTROL & SAVE

    const processEdit = edit => {
        if(edit)
            if(!checkPassword() || !checkConfirm())
                return false;

        return true;
    }
    const reinitAllErrors = () => {
        setErrorPassword("");
        setErrorConfirm("");
    }

    const check422Errors = errorDatas => {
        if(errorDatas !== undefined) {
            var keys = Object.keys(errorDatas);
            var fields = ["password", "password_confirmation"];

            for(var item in fields)
                if(keys.includes(fields[item]))
                    setErrors(fields[item], false);
        }
    }
    const setErrors = (type, empty) => {
        switch (type) {
            case "password":
                if(empty) setErrorPassword("Vous devez saisir un mot de passe");
                else setErrorPassword("Ce mot de passe n'est pas valide");
                break;
            case "password_confirmation":
                setErrorConfirm("La confirmation et le mot de passe doivent être identique");
                break;
            default: break;
        }
    }

    const save = () => {
        setSaving(true);

        reinitAllErrors();

        const controller = new UserController();
        controller._callback = handleReturnSave;
        controller.putPassword(user, {
            "password": password,
            "password_confirmation": confirm
        });
    }
    const handleReturnSave = (response, error) => {
        if(error) {
            setSaving(false);

            if(error.response !== undefined) {
                if(error.response.status === 401)
                    handleLogout();
                else if(error.response.status === 422)
                    check422Errors(error.response.data);
                else {
                    handleError("Une erreur interne s'est produite lors de la modification du mot de passe");
                    handleClose();
                }
            }
            else {
                handleError("Une erreur interne s'est produite lors de la modification du mot de passe");
                handleClose();
            }
        }
        else {
            handleValid("Le nouveau mot de passe à bien été enregistré");
            handleClose();
        }
    }

    // RENDER

    return (
        <div className="contForm">
            <div className={ "form " + (newItem ? "margin" : "") }>
                <p className="titleForm center">MODIFIER MON MOT DE PASSE</p>
                <label className="label">Nouveau mot de passe</label>
                <InputText type="password" color={ color } classError={ errorPassword !== "" ? "wrong" : "" } value={ password } placeholder="Mot de passe" newItem={ true } handleChange={ handleChangePassword } handleBlur={ handleEditPassword } />
                <BarStrength strength={ strength } />
                {
                    errorPassword !== ""
                        ? <p className="error">{ errorPassword }</p>
                        : ""
                }
                <label className="label">Confirmation</label>
                <InputText type="password" color={ color } classError={ errorConfirm !== "" ? "wrong" : "" } value={ confirm } placeholder="Confirmation" newItem={ true } handleChange={ handleChangeConfirm } handleBlur={ handleEditConfirm } />
                {
                    errorConfirm !== ""
                        ? <p className="error">{ errorConfirm }</p>
                        : ""
                }
                <div>
                    <button className="submit" onClick={ save }>
                        {saving ? "Enregistrement..." : "Enregistrer"}
                        <LoaderCircle display="loader submitLogin" hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                    </button>
                    <button className="cancel" onClick={ handleClose }>Annuler</button>
                    <div className="clearing" />
                </div>
            </div>
        </div>
    );
}

export default PwdForm;
