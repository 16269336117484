import React from "react";
import DeleteIcon from "../../icons/DeleteIcon";

function TagSelected (props) {
    const { object, locked, color, handleUnselect } = props;

    return (
        <div className="choice" id={ "tagSelected-" + object.id } style={{ backgroundColor: object.color }}>
            <p>{ object.name }</p>
            {
                !locked && <div className="contDelete" onClick={ () => { handleUnselect(object.id) } }>
                    <DeleteIcon fill={ color } />
                </div>
            }
            <div className="clearing" />
        </div>
    );
}

export default TagSelected;
