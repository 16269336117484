import React, {useContext, useEffect, useState} from "react";
import TaxSearchBar from "./table/TaxSearchBar";
import TaxTable from "./table/TaxTable";
import TaxToolsBar from "./table/TaxToolsBar";
import FormTax from "../../../overbox/tax/FormTax";
import TaxController from "../../../../stories/_catalog/Taxes/TaxController";
import SessionContext from "../../../../context/SessionContext";
import SearchContext from "../../../../context/SearchContext";
import "../../../../css/page/Content.css";
import DeleteTax from "../../../overbox/tax/DeleteTax";

function TaxTableContent () {
    const { handleLogout } = useContext(SessionContext);
    const [ first, setFirst ] = useState(true);
    const [ deleting, setDeleting ] = useState(false);
    const [ generalError, setGeneralError ] = useState("");
    const [ list, setList ] = useState([]);
    const [ selectSearch, setSelectSearch ] = useState([]);
    const [ valueSelectSearch, setValueSelectSearch ] = useState(0);
    const [ inputSearch, setInputSearch ] = useState("");
    const [ initialLoading, setInitialLoading ] = useState(true);
    const [ loading, setLoading ] = useState(true);
    const [ formUpdateTax, setFormUpdateTax ] = useState(false);
    const [ formDeleteTax, setFormDeleteTax ] = useState(false);
    const [ idTaxToUpdate, setIdTaxToUpdate ] = useState(null);
    const valueSearchContext = {
        generalError,
        list,
        selectSearch,
        valueSelectSearch,
        inputSearch,
        initialLoading,
        loading
    };

    const handleChangeSelectSearch = event => {
        var input = event.currentTarget.value;
        setValueSelectSearch(input);
        setLoading(true);
        getTaxes(input, null);
    }

    const handleChangeInputSearch = event => {
        var input = event.currentTarget.value;
        setInputSearch(input);
        setLoading(true);
        getTaxes(null, input);
    }

    const updateTax = (id = null) => {
        setIdTaxToUpdate(id);
        setFormDeleteTax(false);
        setFormUpdateTax(true);
    }
    const deleteTax = (id = null) => {
        setIdTaxToUpdate(id);
        setFormUpdateTax(false);
        setFormDeleteTax(true);
    }
    const closeFormTax = (refresh = false) => {
        setIdTaxToUpdate(null);
        setFormUpdateTax(false);
        setFormDeleteTax(false);

        if (refresh)
            getTaxes();
    }

    const handleDeleteTax = () => {
        setDeleting(true);

        var controller = new TaxController();
        controller._callback = returnDeleteTax;
        controller.delete(getObjectTax());
    }
    const returnDeleteTax = (response, error) => {
        setDeleting(false);

        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else
                setGeneralError("Impossible de supprimer la marque");
        }
        else {
            setFormDeleteTax(false);
            setIdTaxToUpdate(null);
            getTaxes();
        }
    }

    const getObjectTax = () => {
        if (idTaxToUpdate === null) return null;

        for (var i in list)
            if (list[i].id === idTaxToUpdate)
                return list[i];

        return null;
    }

    const getPartnerships = () => {
        const user = JSON.parse(localStorage.getItem("user"))

        if(user.partnerships.length < 2) return;

        let listPartnerships = [{
            id: 0,
            name: "Tous les partenaires"
        }];

        for (let i in user.partnerships)
            listPartnerships.push({
                id: user.partnerships[i].id,
                name: user.partnerships[i].company,
            });

        setSelectSearch(listPartnerships);
    }

    const getTaxes = (select = null, input = null) => {
        setFirst(false);

        select = (select === null ? valueSelectSearch : select);
        input = (input === null ? inputSearch : input);

        const controller = new TaxController();
        controller._callback = handleGetTaxes;
        controller.index(select, input);
    }
    const handleGetTaxes = (list, error) => {
        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else
                setGeneralError("Impossible de récupérer les données");
        }
        else
            setGeneralError("");

        setInitialLoading(false);
        setLoading(false);
        setList(list);
    }

    useEffect(() => {
        document.title = "HIVY - Rechercher une TVA";

        if(first) {
            getPartnerships();
            getTaxes();
        }
    });

    return (
        <div className="content">
            <SearchContext.Provider value={valueSearchContext}>
                {
                    formUpdateTax && <FormTax handleClose={ closeFormTax } handleDelete={ deleteTax } handleRefresh={ () => { getTaxes() } } tax={ getObjectTax() } newItem={ idTaxToUpdate === null } />
                }
                {
                    formDeleteTax && <DeleteTax handleClose={ closeFormTax } handleDelete={ handleDeleteTax } brand={ getObjectTax() } deleting={ deleting } />
                }
                <TaxToolsBar handleNew={ updateTax } />
                <TaxSearchBar handleChangeInput={ handleChangeInputSearch } handleChangeSelect={ handleChangeSelectSearch } />
                <TaxTable handleUpdate={ updateTax } />
            </SearchContext.Provider>
        </div>
    );
}

export default TaxTableContent;
