import React, {useContext} from "react";
import ProviderForm from "../../../../form/provider/ProviderForm";
import Error from "../../../../error/Error";
import ProviderContext from "../../../../../context/ProviderContext";
import "../../../../../css/page/cards/CardInformations.css";

function ProviderCardInformations (props) {
    const { handleUpdateSending, handleAskDelete, handleGet } = props;
    const { generalError } = useContext(ProviderContext);

    return (
        <div className="cardInformations">
            {
                generalError !== "" && <Error text={ generalError } />
            }
            <ProviderForm handleUpdateSending={ handleUpdateSending } handleAskDelete={ handleAskDelete } handleRefresh={ handleGet } />
        </div>
    );
}

export default ProviderCardInformations;
