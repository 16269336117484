import React  from "react";
import AuthController from "../../../../../stories/_auth/Auth/AuthController";

import "../../../../../css/page/ToolsBar.css";

function PartnershipToolsBar (props) {
    const { handleNewPartnership } = props;
    const auth = new AuthController();

    return (
        <div className="toolsBarComponent">
            {
                auth.hasRules(['root'])
                && <p className="button" onClick={ handleNewPartnership }>Ajouter un partenaire</p>
            }
            <div className="clearing"/>
        </div>
    );
}

export default PartnershipToolsBar;
