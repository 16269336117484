import Entity from "../../../class/abstract/Entity";
import ProspectPhase from "../../_setup/ProspectPhase/ProspectPhase";

class ProspectingPhase extends Entity {
    stores_id = null;
    prospect_phases_id = null;
    type = "";
    goal = "";
    state = "";
    alert_date = "";
    shift_date = "";
    done_date = "";
    report = "";
    prospect_phase = null;

    constructor(datas = {}) {
        super(datas);

        this.setAttributes(datas);
    }

    setAttributes(datas) {
        var keys = Object.keys(this);
        var key = "";
        var i = 0;

        for(i in keys) {
            key = keys[i];

            if (datas[key] !== undefined)
                Reflect.set(this, key, datas[key]);
            else if (datas['prospect_phase_object'] !== undefined)
                Reflect.set(this, 'prospect_phase', new ProspectPhase(datas['prospect_phase_object']));
        }
    }
}

export default ProspectingPhase;
