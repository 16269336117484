import React, {useState} from "react";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {Markup} from "interweave";
import EditIcon from "../../icons/EditIcon";
import LinkIcon from "../../icons/LinkIcon";
import CopyIcon from "../../icons/CopyIcon";
import PhoneIcon from "../../icons/PhoneIcon";
import EmailIcon from "../../icons/EmailIcon";

function NoInput (props) {
    const { value, type, classname, color, edit, copy, phone, email, link, handleEdit } = props;
    const [ copied, setCopied ] = useState(false);

    const handleUpdateCopied = bool => {
        setCopied(bool);

        if(bool)
            setTimeout(() => { setCopied(false) }, 1000);
    }

    return (
        <div className={ "noInput " + (classname !== undefined ? classname : "") }>
            {
                type !== undefined && type === "rich"
                    ? <div className={"textRichInput" +  (classname !== undefined ? " " + classname : "")}>
                        <Markup content={value} />
                    </div>
                    : <p className="textInput">{ value }</p>
            }
            <div className="icons">
                {
                    edit && <EditIcon fill={ color } handleClick={ handleEdit } />
                }
                {
                    copy &&
                        <CopyToClipboard text={ value } onCopy={ handleUpdateCopied }>
                            <span><CopyIcon fill={ color } bounce={ copied ? "bounce" : "" } /></span>
                        </CopyToClipboard>
                }
                {
                    phone && <a href={ "tel:" + value }><PhoneIcon fill={ color } /></a>
                }
                {
                    email && <a href={ "mailto:" + value }><EmailIcon fill={ color } /></a>
                }
                {
                    link && <a href={ value } target="_blank"><LinkIcon fill={ color } /></a>
                }
            </div>
        </div>
    );
}

export default NoInput;
