import React, { useState } from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Cookies from "universal-cookie"
import Init from "./component/initialisation/Init"
import Site from "./component/page/Site"
import FormPwd from "./component/form/account/FormPwd"
import AuthController from "./stories/_auth/Auth/AuthController"
import User from "./stories/_auth/Users/User"
import SessionContext from "./context/SessionContext"
import "./css/Theme.css"

const Main = () => {
    const [ showBigBisoux, setShowBigBisoux ] = useState(false)
    const [ globalError, setGlobalError ] = useState("")
    const [ globalWarning, setGlobalWarning ] = useState("")
    const [ globalValid, setGlobalValid ] = useState("")
    const loginURL = process.env.REACT_APP_LOGIN_URL + "/fromclient"

    const removeSession = () => {
        localStorage.clear()

        window.location.href = loginURL
    }
    const logout = () => {
        const auth = new AuthController()
        auth._callback = handleLogout
        auth.logout()
    }
    const handleLogout = (response, error, status) => {
        removeSession()
    }
    const me = () => {
        const auth = new AuthController()
        auth._callback = handleMe
        auth.me()
    }
    const handleMe = (response, error, status) => {
        switch (status) {
            case 200:
                localStorage.setItem("user", JSON.stringify(response.data))
                window.location.href = "/"
                break
            default:
                removeSession()
                break
        }
    }
    const checkBigBisoux = () => {
        if (localStorage.getItem("user") === null) return
        let user = JSON.parse(localStorage.getItem("user"))

        if (user.bigBisoux === 1)
            setShowBigBisoux(true)
    }
    const closeBigBisoux = () => {
        setShowBigBisoux(false)
    }
    const redirectAfterLogin = (type, id) => {
        return "/client"
    }
    const redirectProcess = () => {
        const cookies = new Cookies(null, { domain: process.env.REACT_APP_HOST_ENVIRONMENT, path: "/" });
        let cookieToken = cookies.get("token");
        let cookieExpiresIn = cookies.get("expires_in");
        let cookieRememberMe = cookies.get("remember_me");

        if (localStorage.getItem("token") === undefined || localStorage.getItem("token") === null) {
            if (cookieToken === undefined) {
                removeSession()
            }
            else {
                localStorage.setItem("token", cookieToken)
                localStorage.setItem("expires_in", cookieExpiresIn)
                localStorage.setItem("remember_me", cookieRememberMe)

                cookies.remove("token")
                cookies.remove("expires_in")
                cookies.remove("remember_me")
            }
        }

        let pathname = window.location.pathname.replace("/", "")
        let emptyPath = [""]
        let loginPath = ["login"]

        // LOGIN PROCESS
        if (!loginPath.includes(pathname) && localStorage.getItem("user") === null) {
            me()
            return
        }

        // REFRESH OR UNLOG PROCESS
        const expiresIn = parseInt(localStorage.getItem("expires_in"))
        const rememberMe = localStorage.getItem("remember_me") === "true"

        if (expiresIn < (Date.now() / 1000) && rememberMe) {
            if (rememberMe)
                me()
            else
                removeSession()
        }

        // TARGET PROCESS IF NEEDED
        if (emptyPath.includes(pathname)) {
            window.location.href = redirectAfterLogin()
        }
    }

    const valueSessionContext = {
        handleLogout: logout,
        handleCheckBigBisoux: checkBigBisoux,
        globalError,
        setGlobalError,
        globalWarning,
        setGlobalWarning,
        globalValid,
        setGlobalValid
    }

    return (
        <SessionContext.Provider value={valueSessionContext}>
            <Router>
                {
                    redirectProcess()
                }
                <Switch>

                    { /* AUTH */ }
                    <Route exact path="/login" component={() => {
                        window.location.href = loginURL;
                        return null;
                    }}/>
                    <Route exact path="/logout">
                        <Init logout={true} />
                    </Route>
                    <Route exact path="/init">
                        <Init />
                    </Route>

                    { /* AUTH */ }
                    <Route exact path="/dashboard">
                        <Site page="dashboard" />
                    </Route>

                    { /* CLIENT */ }
                    <Route exact path="/prospect">
                        <Site page="prospect" />
                    </Route>
                    <Route path="/prospect/:id/:tab">
                        <Site page="prospectCard" />
                    </Route>
                    <Route exact path="/client">
                        <Site page="client" />
                    </Route>
                    <Route exact path="/client/:id/:tab">
                        <Site page="clientCard" />
                    </Route>
                    <Route exact path="/client/:id/:tab/:idSubItem">
                        <Site page="clientCard" />
                    </Route>
                    <Route exact path="/quote">
                        <Site page="quote" />
                    </Route>
                    <Route exact path="/voucher">
                        <Site page="voucher" />
                    </Route>
                    <Route exact path="/bill">
                        <Site page="bill" />
                    </Route>
                    <Route exact path="/sample">
                        <Site page="sample" />
                    </Route>

                    { /* SETTING */ }
                    <Route exact path="/partnership">
                        <Site page="partnership" />
                    </Route>
                    <Route path="/partnership/:id/:tab">
                        <Site page="partnershipCard" />
                    </Route>
                    <Route exact path="/setting/:tab">
                        <Site page="setting" />
                    </Route>
                    <Route exact path="/setup/:tab">
                        <Site page="setup" />
                    </Route>
                    <Route exact path="/prospecting/:tab">
                        <Site page="prospecting" />
                    </Route>
                    <Route exact path="/setting/tickets/:tab">
                        <Site page="settingTicket" />
                    </Route>
                    <Route exact path="/tax">
                        <Site page="tax" />
                    </Route>

                    { /* CATALOG */ }
                    <Route exact path="/category">
                        <Site page="category" />
                    </Route>
                    <Route exact path="/brand">
                        <Site page="brand" />
                    </Route>
                    <Route exact path="/provider">
                        <Site page="provider" />
                    </Route>
                    <Route path="/provider/:id/:tab">
                        <Site page="providerCard" />
                    </Route>
                    <Route exact path="/product">
                        <Site page="product" />
                    </Route>
                    <Route path="/product/:id/:tab">
                        <Site page="productCard" />
                    </Route>

                    { /* 404 */ }
                    <Route>
                        <div />
                    </Route>

                </Switch>
                {
                    showBigBisoux
                    && <FormPwd object={ new User(JSON.parse(localStorage.getItem("user"))) } closeView={ closeBigBisoux } />
                }
            </Router>
        </SessionContext.Provider>
    )
}

export default Main
