import React from "react";

function PricingIcon (props) {
    const {fill, classname} = props;

    return (
        <svg
            height="1em"
            viewBox="0 0 32 32"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
            fill={fill}
            className={classname}
        >
            <path d="M29 17h-8c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V19c0-1.1-.9-2-2-2zm-6 11a1 1 0 1 1-.001-1.999A1 1 0 0 1 23 28zm0-3a1 1 0 1 1-.001-1.999A1 1 0 0 1 23 25zm4 3a1 1 0 1 1-.001-1.999A1 1 0 0 1 27 28zm0-3a1 1 0 1 1-.001-1.999A1 1 0 0 1 27 25zm0-3h-4a1 1 0 1 1 0-2h4a1 1 0 1 1 0 2zM22.5 1h-19C2.12 1 1 2.12 1 3.5v25C1 29.88 2.12 31 3.5 31h14.04a3.99 3.99 0 0 1-.54-2V19c0-2.21 1.79-4 4-4h4V3.5C25 2.12 23.88 1 22.5 1zM8.707 24.707l-2 2a.997.997 0 0 1-1.414 0l-1-1a1 1 0 1 1 1.414-1.414l.293.293 1.293-1.293a1 1 0 1 1 1.414 1.414zm0-5-2 2a.997.997 0 0 1-1.414 0l-1-1a1 1 0 1 1 1.414-1.414l.293.293 1.293-1.293a1 1 0 1 1 1.414 1.414zM13 26h-2a1 1 0 1 1 0-2h2a1 1 0 1 1 0 2zm0-5h-2a1 1 0 1 1 0-2h2a1 1 0 1 1 0 2zm0-12c1.654 0 3 1.346 3 3a2.995 2.995 0 0 1-2 2.815V15a1 1 0 1 1-2 0v-.185A2.995 2.995 0 0 1 10 12a1 1 0 1 1 2 0 1.001 1.001 0 1 0 1-1c-1.654 0-3-1.346-3-3 0-1.302.838-2.401 2-2.815V5a1 1 0 1 1 2 0v.185c1.162.414 2 1.513 2 2.815a1 1 0 1 1-2 0 1.001 1.001 0 0 0-2 0c0 .552.449 1 1 1z" />
        </svg>
    );
}

export default PricingIcon;
