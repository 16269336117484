import Entity from "../../../class/abstract/Entity";
import PricesList from "../PricesLists/PricesList";
import Tax from "../Taxes/Tax";

class Price extends Entity {
    price = 0;
    maintenancePrice = 0;
    price_list_id = 0;
    tax_id = 0;
    product_id = 0;
    created_at = "";
    updated_at = "";
    deleted_at = "";
    prices_list = new PricesList();
    tax = new Tax();

    constructor(datas = {}) {
        super(datas);

        this.setAttributes(datas);
    }

    setAttributes(datas) {
        this.id = datas.id !== undefined ? datas.id : 0;
        this.price = datas.price !== undefined ? datas.price : 0;
        this.maintenancePrice = datas.maintenancePrice !== undefined ? datas.maintenancePrice : 0;
        this.product_id = datas.product_id !== undefined ? datas.product_id : 0;
        this.created_at = datas.created_at !== undefined ? datas.created_at : "";
        this.updated_at = datas.updated_at !== undefined ? datas.updated_at : "";
        this.deleted_at = datas.deleted_at !== undefined ? datas.deleted_at : "";

        if (datas.prices_list_data)
            this.prices_list = new PricesList(datas.prices_list_data);

        if (datas.tax_data)
            this.tax = new Tax(datas.tax_data);

        this.price_list_id = this.prices_list.id;
        this.tax_id = this.tax.id;
    }
}

export default Price;
