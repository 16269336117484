import React, {useContext, useEffect, useState} from "react";
import SetupPhaseLine from "./SetupPhaseLine";
import LoaderCircle from "../../../../loader/LoaderCircle";
import SessionContext from "../../../../../context/SessionContext";
import InstallationPhaseController from "../../../../../stories/_setup/InstallationPhase/InstallationPhaseController";
import FormSetupPhase from "../../../../overbox/setup/phase/FormSetupPhase";
import DeleteSetupPhase from "../../../../overbox/setup/phase/DeleteSetupPhase";

import "../../../../../css/page/listingTables/Table.css";
import "../../../../../css/page/listingTables/Line.css";
import "../../../../../css/page/listingTables/SetupPhaseLine.css";

function SetupPhaseTable (props) {
    const { setError, setValid } = props;
    const { handleLogout } = useContext(SessionContext);
    const [ first, setFirst ] = useState(true);
    const [ deleting, setDeleting ] = useState(false);
    const [ list, setList ] = useState([]);
    const [ initialLoading, setInitialLoading ] = useState(true);
    const [ boxUpdate, setBoxUpdate ] = useState(false);
    const [ boxDelete, setBoxDelete ] = useState(false);
    const [ lineMove, setLineMove ] = useState(false);
    const [ idObject, setIdObject ] = useState(0);

    const getInstallationPhases = () => {
        setFirst(false);
        setError("");
        setValid("");

        const controller = new InstallationPhaseController();
        controller._callback = handleGetInstallationPhases;
        controller.index();
    }
    const handleGetInstallationPhases = (list, error) => {
        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else
                setError("Impossible de récupérer les données");
        }

        // dispatch patnerships lists
        var lists = explodeListToPartnershipsLists(list);

        setInitialLoading(false);
        setList(lists);
    }

    const explodeListToPartnershipsLists = list => {
        var lists = [];
        var idEquivalent = 0;
        var newPartnership = true;

        for (var i in list) {
            // recherche equivalence
            newPartnership = true;

            for (var j in lists) {
                if(lists[j].partnership_name.localeCompare(list[i].partnership_name) === 0) {
                    idEquivalent = j;
                    newPartnership = false
                    break;
                }
            }

            if(newPartnership) {
                lists.push({
                    "partnership_id" : list[i].partnership_id,
                    "partnership_name" : list[i].partnership_name,
                    "list" : []
                });

                idEquivalent = lists.length - 1;
            }

            // add object
            lists[idEquivalent].list.push(list[i]);
        }

        return lists;
    }

    const getPhaseObject = () => {
        for (var i in list)
            for (var j in list[i].list)
                if (list[i].list[j].id === idObject)
                    return list[i].list[j];

        return null;
    }

    const handleAdd = refresh => {
        setIdObject(0);
        setBoxUpdate(!boxUpdate);

        if(refresh)
            getInstallationPhases();
    }

    const handleUpdate = id => {
        setIdObject(id);
        setBoxUpdate(!boxUpdate);
    }

    const handleDelete = () => {
        setBoxUpdate(false);
        setBoxDelete(!boxDelete);
    }

    const handleBeginMove = id => {
        setIdObject(id);
        setLineMove(true);
    }
    const handleEndMove = () => {
        setIdObject(0);
        setLineMove(false);
    }

    const callDelete = () => {
        setDeleting(true);

        const controller = new InstallationPhaseController();
        controller._callback = returnHandleDelete;
        controller.delete(getPhaseObject());
    }
    const returnHandleDelete = (response, error) => {
        if(error) {
            if(error.response !== undefined) {
                if(error.response.status === 401)
                    handleLogout();
                else
                    setError("Une erreur s'est produite lors de la suppression de la phase d'installation");
            }
            else
                setError("Une erreur s'est produite lors de la suppression de la phase d'installation");
        }
        else
            window.location.reload();
    }

    // TODO: Faire le déplacement temps réel avec survol table
    const callMove = () => {
        const controller = new InstallationPhaseController();
        controller._callback = returnCallMove;
        controller.putOrder();
    }
    const returnCallMove = (response, error) => {
        if(error) {
            if(error.response !== undefined) {
                if(error.response.status === 401)
                    handleLogout();
                else
                    setError("Une erreur s'est produite lors du déplacement de la phase d'installation");
            }
            else
                setError("Une erreur s'est produite lors du déplacement de la phase d'installation\"");
        }
    }

    useEffect(() => {
        if(first)
            getInstallationPhases();
    });

    return (
        <div className="wrapTable group">
            {
                boxUpdate && <FormSetupPhase newItem={ idObject === 0 } handleClose={ handleAdd } handleDelete={ handleDelete } phase={ getPhaseObject() } />
            }
            {
                boxDelete && <DeleteSetupPhase phase={ getPhaseObject() } deleting={ deleting } handleClose={ handleDelete } handleDelete={ callDelete } />
            }
            {
                lineMove && <div  />
            }
            <div className="list">
                {
                    !initialLoading
                    ? list.length > 0 &&
                    list.map(object => (
                        <div key={ object.partnership_id } className="clearing">
                            {
                                JSON.parse(localStorage.getItem("user")).partnerships.length > 1 && <p className="titleTable">{ object.partnership_name }</p>
                            }
                            <div className="group">
                                <table className="table setupPhase">
                                    <tbody>
                                    {
                                        object.list.map(phase => (
                                            <SetupPhaseLine key={ phase.id } phase={ phase } handleClick={ handleUpdate } />
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ))
                    : <LoaderCircle display="loader logWait" hide="" strokeWidth="5" stroke="#008C4F" />
                }
                <div className="group margin">
                    <table className="table setupPhase">
                        <tbody>
                            <tr className="line button">
                                <td colSpan="2" onClick={ handleAdd }>Ajouter une phase d'installation</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default SetupPhaseTable;
