import React, {useContext} from "react";
import StoreContext from "../../../context/StoreContext";

function AddAccount (props) {
    const { handleClose, handleSearch, handleNewTechnician, handleNew } = props;
    const { store } = useContext(StoreContext);

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox choices">
                    <div className="form margin">
                        <p className="titleForm center">Ajouter un compte backoffice</p>
                        <button className="buttonChoice" onClick={ handleNew }>Créer un nouveau compte</button>
                        <button className="buttonChoice" onClick={ handleSearch }>Ajouter via un contact existant</button>
                        <button className="buttonChoice" onClick={ handleNewTechnician }>Obtenir un accès technicien</button>
                        <button className="cancel block margin" onClick={ handleClose }>Annuler</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddAccount;
