import React, {useContext} from "react";
import PartnershipUserGroupLine from "./PartnershipUserGroupLine";
import LoaderCircle from "../../../../loader/LoaderCircle";
import Error from "../../../../error/Error";

import SearchContext from "../../../../../context/SearchContext";

import "../../../../../css/page/listingTables/Table.css";
import "../../../../../css/page/listingTables/Line.css";
import "../../../../../css/page/listingTables/PartnershipLine.css";
import "../../../../../css/page/listingTables/PartnershipUserGroupLine.css";

function PartnershipUserGroupsTable (props) {
    const { handleShowFormUpdateUserGroup } = props;
    const { generalError, initialLoading, list } = useContext(SearchContext);

    return (
        <div className="wrapTable">
            <table className="table partnershipUser borderBottom">
                <tbody>
                    <tr className="line">
                        <th className="lastname left">NOM</th>
                        <th className="email left">EMAIL</th>
                        <th className="member center">MEMBRES</th>
                        <th className="action">
                            <div className="action" />
                        </th>
                    </tr>
                </tbody>
            </table>
            <div className="list">
                {
                    !initialLoading
                    ? (
                        generalError === ""
                        ? <table className="table partnershipUser">
                                <tbody>
                                {
                                    list.length > 0
                                        ? list.map(group => (
                                            <PartnershipUserGroupLine key={ group.id } object={ group } handleShowFormUpdateUserGroup={ handleShowFormUpdateUserGroup } />
                                        ))
                                        : <tr className="line">
                                            <td className="left" colSpan="4">Aucun groupe</td>
                                        </tr>
                                }
                                </tbody>
                            </table>
                        : <Error text={ generalError } />
                    )
                    : <LoaderCircle display="loader logWait" hide="" strokeWidth="5" stroke="#008C4F" />
                }

            </div>
        </div>
    );
}

export default PartnershipUserGroupsTable;
