import React from "react";
import ContractIcon from "../../icons/ContractIcon";
import PricingIcon from "../../icons/PricingIcon";

function ContractFormSteps (props) {
    const color = window.matchMedia('(prefers-color-scheme: dark)').matches ? "#FFFFFF" : "#1D1D1D";
    const { step } = props;

    return (
        <div className="steps">
            <ContractIcon fill={ color } classname={ step > 1 ? "done" : "" } />
            <div className="line" style={{backgroundColor: color}}>
                <span className={ "bar" + ( step > 1 ? " done" : "" ) } />
            </div>
            <PricingIcon fill={ color } classname={ step > 2 ? "done" : "" } />
        </div>
    );
}

export default ContractFormSteps;
