import React, {useContext} from "react";
import SessionContext from "../../context/SessionContext";

import "../../css/warning/Warning.css";

function Warning (props) {
    const { text } = props;
    const { setGlobalWarning } = useContext(SessionContext);


    // Perform

    const remove = () => {
        setGlobalWarning("");
    }


    // Render

    return (
        <p className="globalWarning" onClick={ remove }>{ text }</p>
    );

}

export default Warning;
