import React, {useEffect, useState} from "react";
import ProspectPhaseTabs from "./ProspectPhaseTabs";
import Error from "../../../error/Error";
import Valid from "../../../valid/Valid";
import Warning from "../../../warning/Warning";
import ProspectPhaseTable from "./phase/ProspectPhaseTable";

import "../../../../css/page/Content.css";
import AcquisitionChannelTable from "./acquisitionChannel/AcquisitionChannelTable";

function ProspectPhaseContent (props) {
    const { tab } = props;
    const [ sending, setSending ] = useState(false);
    const [ generalError, setGeneralError ] = useState("");
    const [ generalWarning, setGeneralWarning ] = useState("");
    const [ generalValid, setGeneralValid ] = useState("");

    const handleError = text => {
        setGeneralError(text);
    }

    const handleWarning = text => {
        setGeneralWarning(text);
    }

    const handleValid = text => {
        setGeneralValid(text);
    }

    const goContent = () => {
        switch (tab) {
            case "phase": return <ProspectPhaseTable setError={ handleError } setValid={ handleValid } />;
            case "acquisitionchannel": return <AcquisitionChannelTable setError={ handleError } setValid={ handleValid } />;
            default: return <p>{ tab }</p>;
        }
    }

    useEffect(() => {
        document.title = "HIVY - Réglages sur la prospection";
    });

    return (
        <div className="content">
            <ProspectPhaseTabs tab={ tab } sending={ sending } />
            {
                generalError !== "" && <Error text={ generalError } />
            }
            {
                generalWarning !== "" && <Warning text={ generalWarning } />
            }
            {
                generalValid !== "" && <Valid text={ generalValid } />
            }
            {
                goContent()
            }
        </div>
    );
}

export default ProspectPhaseContent;
