import Rest from "../../../class/tool/Rest";
import ClientTicket from "./ClientTicket";
import ClientTicketComment from "./Comments/ClientTicketComment";

class ClientTicketController {
    _rest = new Rest();
    _callback = null;
    _baseEND = "clienttickets";
    _baseURL = "stores/{idStore}/" + this._baseEND;
    _baseURLComment = this._baseEND + "/{idClientTicket}/comments";

    handleIndex = (response, error) => {
        let listObjects = [];

        if(response.data !== undefined) {
            const data = response.data.data;

            for(var item in data)
                listObjects.push(new ClientTicket(data[item]));
        }

        this._callback(listObjects, error);
    }
    index(input= "") {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseEND + (input !== "" ? "?input=" + input : ""),
            {},
            this.handleIndex);
    }
    indexForStore(storeID, input = "") {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idStore}", storeID) + (input !== "" ? "?input=" + input : ""),
            {},
            this.handleIndex);
    }

    handleShow = (response, error) => {
        const object = !error ? new ClientTicket(response.data) : null;
        this._callback(object, error);
    }
    show(id) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseEND + '/' + id,
            {},
            this.handleShow);
    }
    showForStore(storeID, id) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idStore}", storeID) + '/' + id,
            {},
            this.handleShow);
    }

    handlePost = (response, error) => {
        this._callback(response, error);
    }
    post(datas) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'POST',
            '/' + this._baseEND,
            datas,
            this.handlePost
        );
    }
    postForStore(storeID, datas) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURL.replace("{idStore}", storeID),
            datas,
            this.handlePost
        );
    }

    put(object, datas) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'PUT',
            '/' + this._baseEND + '/' + object.id,
            datas,
            this.handlePut
        );
    }
    handlePut = (response, error) => {
        this._callback(response, error);
    }

    delete(object) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'DELETE',
            '/' + this._baseEND + '/' + object.id,
            {},
            this.handleDelete);
    }
    handleDelete = (response, error) => {
        this._callback(response, error);
    }

    handleHistory = (response, error) => {
        let listObjects = [];

        if(response.data !== undefined) {
            const data = response.data;

            for(var item in data)
                listObjects.push(data[item]);
        }

        this._callback(listObjects, error);
    }
    history(id) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseEND + '/' + id +'/history?sort=DESC',
            {},
            this.handleHistory);
    }

    handlePostComment = (response, error) => {
        this._callback(response, error);
    }
    postComment(idClientTicket, datas) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURLComment.replace("{idClientTicket}", idClientTicket),
            datas,
            this.handlePostComment);
    }

    handlePutComment = (response, error) => {
        this._callback(response, error);
    }
    putComment(object, datas) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'PUT',
            '/' + this._baseURLComment.replace("{idClientTicket}", object.client_ticket_id) + '/' + object.id,
            datas,
            this.handlePutComment);
    }

    handleDeleteComment = (response, error) => {
        this._callback(response, error);
    }
    deleteComment(object) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'DELETE',
            '/' + this._baseURLComment.replace("{idClientTicket}", object.client_ticket_id) + '/' + object.id,
            {},
            this.handleDeleteComment);
    }

    returnUpdatesFromCompare(object, datas) {
        var dataKeys = Object.keys(datas);
        var key = "";
        var updates = {};

        for(var i in dataKeys) {
            key = dataKeys[i];

            if (datas[key] !== undefined) {
                if (Reflect.get(object, key) !== datas[key])
                    Reflect.set(updates, key, datas[key]);
            }
        }

        return updates;
    }
}

export default ClientTicketController;
