import React, {useContext, useEffect, useState} from "react";
import ClientTabs from "./ClientTabs";
import LoaderCircle from "../../../../loader/LoaderCircle";
import ClientCardInformations from "./ClientCardInformations";
import ClientTicket from "./ClientTicket";
import ClientSetup from "./ClientSetup";
import Error from "../../../../error/Error";
import NewSetup from "../../../../overbox/setup/NewSetup";
import NewTicket from "../../../../overbox/ticket/NewTicket";
import SetupController from "../../../../../stories/_setup/Setup/SetupController";
import Setup from "../../../../../stories/_setup/Setup/Setup";
import NewContract from "../../../../overbox/contract/NewContract";
import StoreContext from "../../../../../context/StoreContext";
import SessionContext from "../../../../../context/SessionContext";
import "../../../../../css/page/cards/Card.css";

function ClientCard (props) {
    const { tab, idSubItem, handleAskDeleteProspect, handleGetClient, sending, setSending, handleManage, show, handleToClient, handleDelete, refresh, setRefresh } = props;
    const { handleLogout } = useContext(SessionContext);
    const { store, generalError, setGeneralError } = useContext(StoreContext);
    const [ loading, setLoading ] = useState(false);
    const [ boxAddSetup, setBoxAddSetup ] = useState(false);
    const [ boxAddContract, setBoxAddContract ] = useState(false);
    const [ boxAddTicket, setBoxAddTicket ] = useState(false);
    const [ listSetups, setListSetups ] = useState([]);
    const [ setup, setSetup ] = useState(new Setup());
    const [ setupSelected, setSetupSelected ] = useState(-1);

    const goContentCard = () => {
        switch (tab) {
            case "information": return <ClientCardInformations handleUpdateSending={ handleUpdateSending } handleAskDeleteProspect={ handleAskDeleteProspect } handleGetClient={ handleGetClient } />
            case "ticket": return <ClientTicket loading={ loading } setLoading={ setLoading } idTicket={ idSubItem } />
            case "setup": return <ClientSetup listSetups={ listSetups } setup={ setup } loading={ loading } />
            case "quote": return ""
            case "voucher": return ""
            case "bill": return ""
            default: return <div />
        }
    }
    const handleUpdateSending = bool => {
        setSending(bool);
    }
    const handleAction = type => {
        switch (type) {
            case "addSetup": showBoxAddSetup(); break;
            case "addContract": showBoxAddContract(); break;
            case "addTicket": showBoxAddTicket(); break;
            case "addQuote": break;
            default: break;
        }
    }
    const showBoxAddTicket = () => {
        setBoxAddTicket(!boxAddTicket);
    }
    const showBoxAddSetup = (refresh = false) => {
        setBoxAddSetup(!boxAddSetup);

        if(refresh)
            handleGetClient();
    }
    const handleSetListSetups = () => {
        setRefresh(false);

        if (store === null) return;

        var setups = [];

        for (var i in store.setups)
            setups.push({
                key: store.setups[i].id,
                value: store.setups[i].type_name
            });

        setups.push({
            key: 0,
            value: "Ajouter un système"
        });

        setListSetups(setups);

        if(setups.length > 1) {
            if(setupSelected === -1) {
                setSetupSelected(setups[0].key);
                getSetup(setups[0].key);
            }
            else if(setupSelected > 0)
                getSetup(setupSelected);
        }
    }
    const handleChangeSetupSelected = event => {
        var input = parseInt(event.currentTarget.value);

        setSetupSelected(input);

        if(input === 0)
            setBoxAddSetup(true);
        else
            getSetup(input);
    }
    const getSetup = idSetup => {
        setLoading(true);

        var controller = new SetupController();
        controller._callback = returnGetSetup;
        controller.show(store, idSetup);
    }
    const returnGetSetup = (object, error) => {
        setLoading(false);

        if(error) {
            if(error.response !== undefined) {
                if (error.response.status === 401)
                    handleLogout();
                else if (error.response.status === 404)
                    setGeneralError("Ce système est introuvable");
            }
            else
                setGeneralError("Impossible de récupérer les données");
        }
        else
            setSetup(object);
    }
    const showBoxAddContract = (refresh = false) => {
        setBoxAddContract(!boxAddContract);

        if(refresh)
            handleGetClient();
    }

    useEffect(() => {
        if((store !== null && listSetups.length === 0) || refresh)
            handleSetListSetups();
    })

    return (
        <div className="card">
            {
                boxAddTicket && <NewTicket handleClose={ showBoxAddTicket } />
            }
            {
                boxAddSetup && <NewSetup handleClose={ showBoxAddSetup } setSetupSelected={ setSetupSelected } />
            }
            {
                boxAddContract && <NewContract handleClose={ showBoxAddContract } />
            }
            {
                store !== null
                ? <ClientTabs tab={ tab } sending={ sending } action={ handleAction } handleManage={ handleManage } show={ show } handleToClient={ handleToClient } handleDelete={ handleDelete } listSetups={ listSetups } setupSelected={ setupSelected } handleChangeSetupSelected={ handleChangeSetupSelected } />
                : generalError === "" && <LoaderCircle display="loader logWait" hide="" strokeWidth="5" stroke="#008C4F" />
            }
            {
                generalError !== "" && <Error text={ generalError } />
            }
            {
                store !== null
                && goContentCard()
            }
        </div>
    );
}

export default ClientCard;
