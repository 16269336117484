import Storage from "../../_auth/Storages/Storage";

class Stock {
    quantity = 0;
    state = "ok";
    serials = [];
    storage = new Storage();

    constructor(datas = {}) {
        this.setAttributes(datas);
    }

    setAttributes(datas) {
        this.quantity = datas.quantity !== undefined ? datas.quantity : 0;
        this.state = datas.state !== undefined ? datas.state : "ok";
        this.serials = datas.serials !== undefined ? datas.serials : [];

        if (datas.storage)
            this.storage = new Storage(datas.storage);
    }
}

export default Stock;
