import React  from "react";
import "../../../../../css/page/ToolsBar.css";

function ProspectToolsBar (props) {
    const { handleNewProspect } = props;

    return (
        <div className="toolsBarComponent">
            <p className="button" onClick={ handleNewProspect }>Ajouter un prospect</p>
            <div className="clearing"/>
        </div>
    );
}

export default ProspectToolsBar;
