import Entity from "../../../class/abstract/Entity";

class Storage extends Entity {
    address = "";
    additional = "";
    postalCode = "";
    city = "";
    country = "";

    constructor(datas = {}) {
        super(datas);

        this.setAttributes(datas);
    }

    setAttributes(datas) {
        this.id = datas.id !== undefined ? datas.id : 0;
        this.address = datas.address !== undefined ? datas.address : "";
        this.additional = datas.additional !== undefined ? datas.additional : "";
        this.postalCode = datas.postalCode !== undefined ? datas.postalCode : "";
        this.city = datas.city !== undefined ? datas.city : "";
        this.country = datas.country !== undefined ? datas.country : "";
        this.updated_at = datas.updated_at !== undefined ? datas.updated_at : "";
        this.created_at = datas.created_at !== undefined ? datas.created_at : "";
    }
}

export default Storage;
