import React, {useEffect, useState} from "react";
import InputSelect from "../input/InputSelect";

function PartnershipChoose (props) {
    const color = window.matchMedia('(prefers-color-scheme: dark)').matches ? "#FFFFFF" : "#1D1D1D";

    const { handleClose, handleSelect } = props;
    const [ first, setFirst ] = useState(true);
    const [ partnerships, setPartnerships ] = useState([]);
    const [ partnership, setPartnership ] = useState(0);
    const [ errorPartnership, setErrorPartnership ] = useState("");

    // INIT

    const listPartnerships = () => {
        setFirst(false);

        const user = JSON.parse(localStorage.getItem("user"))
        let partnershipsList = [];

        user.partnerships.map(object => {
            partnershipsList.push({
                key: object.id,
                value: object.company
            });
        });

        setPartnership(partnershipsList[0].key);
        setPartnerships(partnershipsList);
    }

    // CONTROLS

    const handleChangePartnership = event => {
        setPartnership(event.currentTarget.value);
    }
    const checkPartnership = () => {
        const regxp = /^[0-9]+/g;

        if(partnership <= 0) {
            setErrors("partnership", true);
            return false;
        }

        if (!regxp.test(partnership)) {
            setErrors("partnership", false);
            return false;
        }

        setErrorPartnership("");

        return true;
    }

    const reinitAllErrors = () => {
        setErrorPartnership("");
    }

    const setErrors = (type, empty) => {
        switch (type) {
            case "partnership":
                if(empty) setErrorPartnership("Vous devez choisir un partenaire");
                else setErrorPartnership("Le partenaire choisi n'est pas valide");
                break;
            default: break;
        }
    }

    const save = () => {
        reinitAllErrors();

        if(!checkPartnership()) return;

        handleSelect(partnership);
    }

    useEffect(() => {
        if(first)
            listPartnerships();
    });

    return (
        <div className="form margin">
            <p className="titleForm center">CHOISIR UN PARTENAIRE</p>
            <InputSelect color={ color } classError={ errorPartnership !== "" ? "wrong" : "" } list={ partnerships } value={ partnership } newItem={ true } handleChange={ handleChangePartnership } />
            {
                errorPartnership !== "" && <p className="error">{ errorPartnership }</p>
            }
            <button className="submit" onClick={ save }>Continuer</button>
            <button className="cancel" onClick={ handleClose }>Annuler</button>
            <div className="clearing" />
        </div>
    );
}

export default PartnershipChoose;
