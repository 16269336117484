import React, {useContext, useEffect, useState} from "react";
import BrandSearchBar from "./table/BrandSearchBar";
import BrandTable from "./table/BrandTable";
import BrandToolsBar from "./table/BrandToolsBar";
import FormBrand from "../../../overbox/brand/FormBrand";
import BrandController from "../../../../stories/_catalog/Brands/BrandController";
import SessionContext from "../../../../context/SessionContext";
import SearchContext from "../../../../context/SearchContext";
import "../../../../css/page/Content.css";
import DeleteBrand from "../../../overbox/brand/DeleteBrand";

function BrandTableContent () {
    const { handleLogout } = useContext(SessionContext);
    const [ first, setFirst ] = useState(true);
    const [ deleting, setDeleting ] = useState(false);
    const [ generalError, setGeneralError ] = useState("");
    const [ list, setList ] = useState([]);
    const [ selectSearch, setSelectSearch ] = useState([]);
    const [ valueSelectSearch, setValueSelectSearch ] = useState(0);
    const [ inputSearch, setInputSearch ] = useState("");
    const [ initialLoading, setInitialLoading ] = useState(true);
    const [ loading, setLoading ] = useState(true);
    const [ formUpdateBrand, setFormUpdateBrand ] = useState(false);
    const [ formDeleteBrand, setFormDeleteBrand ] = useState(false);
    const [ idBrandToUpdate, setIdBrandToUpdate ] = useState(null);
    const valueSearchContext = {
        generalError,
        list,
        selectSearch,
        valueSelectSearch,
        inputSearch,
        initialLoading,
        loading
    };

    const handleChangeSelectSearch = event => {
        var input = event.currentTarget.value;
        setValueSelectSearch(input);
        setLoading(true);
        getBrands(input, null);
    }

    const handleChangeInputSearch = event => {
        var input = event.currentTarget.value;
        setInputSearch(input);
        setLoading(true);
        getBrands(null, input);
    }

    const updateBrand = (id = null) => {
        setIdBrandToUpdate(id);
        setFormDeleteBrand(false);
        setFormUpdateBrand(true);
    }
    const deleteBrand = (id = null) => {
        setIdBrandToUpdate(id);
        setFormUpdateBrand(false);
        setFormDeleteBrand(true);
    }
    const closeFormBrand = (refresh = false) => {
        setIdBrandToUpdate(null);
        setFormUpdateBrand(false);
        setFormDeleteBrand(false);

        if (refresh)
            getBrands();
    }

    const handleDeleteBrand = () => {
        setDeleting(true);

        var controller = new BrandController();
        controller._callback = returnDeleteBrand;
        controller.delete(getObjectBrand());
    }
    const returnDeleteBrand = (response, error) => {
        setDeleting(false);

        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else
                setGeneralError("Impossible de supprimer la marque");
        }
        else {
            setFormDeleteBrand(false);
            setIdBrandToUpdate(null);
            getBrands();
        }
    }

    const getObjectBrand = () => {
        if (idBrandToUpdate === null) return null;

        for (var i in list)
            if (list[i].id === idBrandToUpdate)
                return list[i];

        return null;
    }

    const getPartnerships = () => {
        const user = JSON.parse(localStorage.getItem("user"))

        if(user.partnerships.length < 2) return;

        let listPartnerships = [{
            id: 0,
            name: "Tous les partenaires"
        }];

        for (let i in user.partnerships)
            listPartnerships.push({
                id: user.partnerships[i].id,
                name: user.partnerships[i].company,
            });

        setSelectSearch(listPartnerships);
    }

    const getBrands = (select = null, input = null) => {
        setFirst(false);

        select = (select === null ? valueSelectSearch : select);
        input = (input === null ? inputSearch : input);

        const controller = new BrandController();
        controller._callback = handleGetBrands;
        controller.index(select, input);
    }
    const handleGetBrands = (list, error) => {
        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else
                setGeneralError("Impossible de récupérer les données");
        }
        else
            setGeneralError("");

        setInitialLoading(false);
        setLoading(false);
        setList(list);
    }

    useEffect(() => {
        document.title = "HIVY - Rechercher une marque";

        if(first) {
            getPartnerships();
            getBrands();
        }
    });

    return (
        <div className="content">
            <SearchContext.Provider value={valueSearchContext}>
                {
                    formUpdateBrand && <FormBrand handleClose={ closeFormBrand } handleDelete={ deleteBrand } handleRefresh={ () => { getBrands() } } brand={ getObjectBrand() } newItem={ idBrandToUpdate === null } />
                }
                {
                    formDeleteBrand && <DeleteBrand handleClose={ closeFormBrand } handleDelete={ handleDeleteBrand } brand={ getObjectBrand() } deleting={ deleting } />
                }
                <BrandToolsBar handleNew={ updateBrand } />
                <BrandSearchBar handleChangeInput={ handleChangeInputSearch } handleChangeSelect={ handleChangeSelectSearch } />
                <BrandTable handleUpdate={ updateBrand } />
            </SearchContext.Provider>
        </div>
    );
}

export default BrandTableContent;
