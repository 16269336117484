import React, {useContext, useEffect, useState} from "react";
import ClientSearchBar from "./table/ClientSearchBar";
import ClientTable from "./table/ClientTable";
import ClientToolsBar from "./table/ClientToolsBar";
import NewClient from "../../../overbox/client/NewClient";
import StoreController from "../../../../stories/_client/Stores/StoreController";
import SessionContext from "../../../../context/SessionContext";
import SearchContext from "../../../../context/SearchContext";
import "../../../../css/page/Content.css";

function ClientTableContent () {
    const { handleLogout } = useContext(SessionContext);
    const [ generalError, setGeneralError ] = useState("");
    const [ list, setList ] = useState([]);
    const [ inputSearch, setInputSearch ] = useState("");
    const [ initialLoading, setInitialLoading ] = useState(true);
    const [ loading, setLoading ] = useState(true);
    const [ newClientForm, setNewClientForm ] = useState(false);
    const valueSearchContext = {
        generalError,
        list,
        inputSearch,
        initialLoading,
        loading
    };

    const handleChangeInputSearch = event => {
        var input = event.currentTarget.value;
        setInputSearch(input);
        setLoading(true);
        getClients(input);
    }

    const newClient = () => {
        setNewClientForm(true);
    }
    const closeNewClient = () => {
        setNewClientForm(false);
    }

    const getClients = input => {
        const controller = new StoreController();
        controller._callback = handleGetClients;
        controller.index("client", input);
    }
    const handleGetClients = (list, error) => {
        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else
                setGeneralError("Impossible de récupérer les données");
        }
        else
            setGeneralError("");

        setInitialLoading(false);
        setLoading(false);
        setList(list);
    }

    useEffect(() => {
        document.title = "HIVY - Rechercher un client";

        getClients("");
    }, []);

    return (
        <div className="content">
            <SearchContext.Provider value={valueSearchContext}>
                {
                    newClientForm && <NewClient handleCloseNewClient={ closeNewClient } />
                }
                <ClientToolsBar handleNewClient={ newClient } />
                <ClientSearchBar handleChangeInputSearch={ handleChangeInputSearch } />
                <ClientTable />
            </SearchContext.Provider>
        </div>
    );
}

export default ClientTableContent;
