import React, {useEffect, useState} from "react";
import $ from "jquery";
import CheckIcon from "../../../icons/CheckIcon";

const InputCode = props => {
    const {
        attribute,
        maxLength,
        alphanumeric,
        classname,
        value,
        color,
        readonly,
        handleChange,
        handleSubmit,
        handleBlur
    } = props;
    const [ values, setValues ] = useState([]);

    const buildInputs = () => {
        let valuesTmp = [];
        let valueToUse = value !== undefined && value !== null ? value : "";

        for (let i = 0; i < maxLength; i++)
            valuesTmp.push(valueToUse[i] !== undefined ? valueToUse[i] : "");

        setValues(valuesTmp);
    }
    const changeEvent = (key, event) => {
        let valuesTmp = values.slice();
        let value = event.currentTarget.value.slice(-1);
        let regex = /^([0-9]{1})$/;

        if (alphanumeric)
            regex = /^([a-zA-Z0-9]{1})$/;

        if(value.length > 0 && !regex.test(value.replace(",", "."))) return;

        valuesTmp[key] = value;
        setValues(valuesTmp);

        if (value.length === 0) {
            $("#code-" + (key - 1)).focus();
        }
        else {
            if ($("#code-" + (key + 1)).length > 0)
                $("#code-" + (key + 1)).focus();
            else
                $("#code-" + key).blur();
        }

        if (handleChange === undefined || handleChange === undefined) return;
        handleChange(attribute, "string", valuesTmp.join(''));
    }
    const blurEvent = () => {
        if (handleBlur === undefined || handleBlur === null) return;
        handleBlur();
    }
    const submitEvent = () => {
        if (handleSubmit === undefined || handleSubmit === null) return;
        handleSubmit(attribute);
    }

    useEffect(() => {
        buildInputs()
    }, [])

    return (
        <div className={ "inputText " + (classname !== undefined ? classname : "") }>
            <div className="containerCode extend">
                {
                    values.map(function(val, index) {
                        return(
                            <input
                                key={ index }
                                id={ "code-" + index }
                                className="code"
                                type="text"
                                value={ val }
                                autoFocus={ value.length === 0 ? index === 0 : index === 3 }
                                onChange={ (event) => { changeEvent(index, event) } }
                            />
                        )
                    })
                }
            </div>
            <div className="clearing" />
        </div>
    );
}

export default InputCode;
