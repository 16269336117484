import React from "react";
import TaxForm from "../../form/tax/TaxForm";

function FormTax (props) {
    const { newItem, tax, handleClose, handleDelete, handleRefresh } = props;

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox newItemSmall">
                    <TaxForm handleClose={ handleClose } handleDelete={ handleDelete } newItem={ newItem } handleRefresh={ handleRefresh } tax={ tax } />
                </div>
            </div>
        </div>
    );
}

export default FormTax;
