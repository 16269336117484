import React from "react";
import {Link} from "react-router-dom";
import AccessIcon from "../../../../icons/AccessIcon";

function ProspectLine (props) {
    const isDark = window.matchMedia('(prefers-color-scheme: dark)');
    const color = isDark.matches ? "#FFFFFF" : "#1D1D1D";
    const { store } = props;
    const states = {
        "ACTIVE": "ACTIF",
        "STANDBY": "EN VEILLE",
        "EJECT": "EJECTÉ"
    }

    return (
        <tr className="line">
            <td className="company left">{ store.company_name !== "" ? store.company_name : "-" }</td>
            <td className="store left">{ store.name }</td>
            <td className="postalCode left">{ store.postal_code }</td>
            <td className="city left">{ store.city }</td>
            <td className="country left">{ store.country }</td>
            <td className="prospectPhase center" style={store.current_prospecting_phase !== null && store.current_prospecting_phase.prospect_phase !== null ? {backgroundColor: store.current_prospecting_phase.prospect_phase.color} : {}}>{ store.current_prospecting_phase !== null && store.current_prospecting_phase.prospect_phase !== null ? store.current_prospecting_phase.prospect_phase.name : "" }</td>
            <td className={ "prospectState center " + store.prospect_state }>{ states[store.prospect_state] }</td>
            <td className="action">
                <Link to={ "/prospect/" + store.id + "/information" }>
                    <div className="access">
                        <AccessIcon fill={ color } />
                    </div>
                </Link>
            </td>
        </tr>
    );
}

export default ProspectLine;
