import Entity from "../../../class/abstract/Entity";
import TicketType from "../../_setting/TicketType/TicketType";
import TicketPriority from "../../_setting/TicketPriority/TicketPriority";
import TicketState from "../../_setting/TicketState/TicketState";

class ClientTicket extends Entity {
    title = "";
    description = "";
    order = 0;
    store_id = 0;
    store_name = "";
    type_id = 0;
    priority_id = 0;
    state_id = 0;
    created_by = 0;
    created_by_name = "";
    assigned_to_id = 0;
    assigned_to_type = "";
    assigned_to_name = "";
    paused_at = "";
    closed_at = "";
    closed_at = "";

    type = null;
    priority = null;
    state = null;

    constructor(datas = {}) {
        super(datas);
        this.setAttributes(datas);
    }

    setAttributes(datas) {
        var keys = Object.keys(this);
        var key = "";
        var i = 0;

        for(i in keys) {
            key = keys[i];

            if (datas[key] !== undefined) {
                if (key == "type") Reflect.set(this, key, new TicketType(datas[key]));
                else if (key == "priority") Reflect.set(this, key, new TicketPriority(datas[key]));
                else if (key == "state") Reflect.set(this, key, new TicketState(datas[key]));
                else Reflect.set(this, key, datas[key]);
            }
        }
    }
}

export default ClientTicket;
