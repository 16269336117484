import React, {useContext, useState} from "react";
import NoInput from "../input/NoInput";
import InputText from "../input/InputText";
import InputSelect from "../input/InputSelect";
import LoaderCircle from "../../loader/LoaderCircle";
import SessionContext from "../../../context/SessionContext";
import PartnershipContext from "../../../context/PartnershipContext";
import StorageController from "../../../stories/_auth/Storages/StorageController";
import Storage from "../../../stories/_auth/Storages/Storage";
import Error from "../../error/Error";
import Warning from "../../warning/Warning";
import Valid from "../../valid/Valid";
import GoogleLocation from "../../../class/tool/GoogleLocation";

function StorageForm (props) {
    const color = window.matchMedia('(prefers-color-scheme: dark)').matches ? "#FFFFFF" : "#1D1D1D";
    const countries = [
        {
            key: 5,
            value: "FRANCE"
        },
        {
            key: 1,
            value: "ALLEMAGNE"
        },
        {
            key: 2,
            value: "ANGLETERRE"
        },
        {
            key: 3,
            value: "BELGIQUE"
        },
        {
            key: 4,
            value: "ESPAGNE"
        },
        {
            key: 6,
            value: "ITALIE"
        },
        {
            key: 7,
            value: "LUXEMBOURG"
        },
        {
            key: 8,
            value: "SUISSE"
        }
    ];

    const { newItem, storage, handleClose, handleDelete, handleRefresh } = props;
    const { handleLogout } = useContext(SessionContext);
    const { partnership } = useContext(PartnershipContext);
    const [ storageObject, setStorageObject ] = useState(storage !== null ? storage : new Storage());
    const [ saving, setSaving ] = useState(false);
    const [ error, setError ] = useState("");
    const [ warning, setWarning ] = useState("");
    const [ valid, setValid ] = useState("");
    const [ address, setAddress ] = useState(!newItem ? storage.address : "");
    const [ editAddress, setEditAddress ] = useState(false);
    const [ errorAddress, setErrorAddress ] = useState("");
    const [ additional, setAdditional ] = useState(!newItem ? storage.additional : "");
    const [ editAdditional, setEditAdditional ] = useState(false);
    const [ errorAdditional, setErrorAdditional ] = useState("");
    const [ postalCode, setPostalCode ] = useState(!newItem ? storage.postalCode : "");
    const [ editPostalCode, setEditPostalCode ] = useState(false);
    const [ errorPostalCode, setErrorPostalCode ] = useState("");
    const [ city, setCity ] = useState(!newItem ? storage.city : "");
    const [ editCity, setEditCity ] = useState(false);
    const [ errorCity, setErrorCity ] = useState("");
    const [ country, setCountry ] = useState(!newItem ? storage.country : countries[0].value);
    const [ editCountry, setEditCountry ] = useState(false);
    const [ errorCountry, setErrorCountry ] = useState("");

    const handleEditAddress = () => {
        if(processEdit(editAddress))
            setEditAddress(!editAddress);
    }
    const handleChangeAddress = event => {
        const input = event.currentTarget.value;

        setAddress(input);

        if(input.length >= 3)
            searchAddress(input);
    }
    const checkAddress = () => {
        const regxp = /^[A-z0-9]+/g;

        if(address === "") {
            setErrors("address", true);
            return false;
        }

        if (!regxp.test(address)) {
            setErrors("address", false);
            return false;
        }

        setErrorAddress("");

        return true;
    }
    const searchAddress = input => {
        const location = new GoogleLocation();
        location._callback = handleSearchAddress;
        location.searchAddress(input);
    }
    const handleSearchAddress = (result, error) => {
        console.log("result", result);
        console.log("error", error);
    }

    const handleEditAdditional = () => {
        if(processEdit(editAdditional))
            setEditAdditional(!editAdditional);
    }
    const handleChangeAdditional = event => {
        setAdditional(event.currentTarget.value);
    }
    const checkAdditional = () => {
        const regxp = /^\d+\s[A-z]+\s[A-z]+/g;

        if (additional.length > 0 && !regxp.test(additional)) {
            setErrors("additional", false);
            return false;
        }

        setErrorAdditional("");

        return true;
    }

    const handleEditPostalCode = () => {
        if(processEdit(editPostalCode))
            setEditPostalCode(!editPostalCode);
    }
    const handleChangePostalCode = event => {
        setPostalCode(event.currentTarget.value);
    }
    const checkPostalCode = () => {
        const regxp = /^[A-Z0-9]+/g;

        if(postalCode === "") {
            setErrors("postalCode", true);
            return false;
        }

        if (!regxp.test(postalCode)) {
            setErrors("postalCode", false);
            return false;
        }

        setErrorPostalCode("");

        return true;
    }

    const handleEditCity = () => {
        if(processEdit(editCity))
            setEditCity(!editCity);
    }
    const handleChangeCity = event => {
        setCity(event.currentTarget.value);
    }
    const checkCity = () => {
        const regxp = /^[\w-]/g;

        if(city === "") {
            setErrors("city", true);
            return false;
        }

        if (!regxp.test(city)) {
            setErrors("city", false);
            return false;
        }

        setErrorCity("");

        return true;
    }

    const handleEditCountry = () => {
        if(processEdit(editCountry))
            setEditCountry(!editCountry);
    }
    const handleChangeCountry = event => {
        setCountry(event.currentTarget.value);
    }
    const checkCountry = () => {
        const regxp = /^[\w-]/g;

        if(city === "") {
            setErrors("country", true);
            return false;
        }

        if (!regxp.test(city)) {
            setErrors("country", false);
            return false;
        }

        setErrorCountry("");

        return true;
    }

    // CONTROL & SAVE

    const processEdit = edit => {
        if(edit) {
            if(checkAddress() && checkAdditional() && checkPostalCode() && checkCity() && checkCountry() && !newItem)
                save();
            else
                return false;
        }

        return true;
    }
    const reinitAllEdits = () => {
        setEditAddress(false);
        setEditAdditional(false);
        setEditPostalCode(false);
        setEditCity(false);
        setEditCountry(false);
    }
    const reinitAllErrors = () => {
        setError("");
        setErrorAddress("");
        setErrorAdditional("");
        setErrorPostalCode("");
        setErrorCity("");
        setErrorCountry("");
    }
    const reinitInputs = () => {
        setAddress(storageObject.address);
        setAdditional(storageObject.additional);
        setPostalCode(storageObject.postalCode);
        setCity(storageObject.city);
        setCountry(storageObject.country);
    }

    const check422Errors = errorDatas => {
        setError("Certaines données sont invalides");

        if(errorDatas !== undefined) {
            var keys = Object.keys(errorDatas);
            var fields = ["address", "additional", "postalCode", "city", "country"];

            for(var item in fields)
                if(keys.includes(fields[item]))
                    setErrors(fields[item], false);
        }
    }
    const setErrors = (type, empty, same = false) => {
        switch (type) {
            case "address":
                if(empty) setErrorAddress("Vous devez saisir une adresse");
                else setErrorAddress("Cette addresse n'est pas valide");
                break;
            case "additional":
                if(empty) setErrorAdditional("Vous devez saisir un complément d'adresse");
                else setErrorAdditional("Ce complément d'adresse n'est pas valide");
                break;
            case "postalCode":
                if(empty) setErrorPostalCode("Vous devez saisir un code postal");
                else setErrorPostalCode("Ce code postal est incorrect");
                break;
            case "city":
                if(empty) setErrorCity("Vous devez saisir une ville");
                else setErrorCity("Ce nom de ville est incorrect");
                break;
            case "country":
                if(empty) setErrorCountry("Vous devez faire un choix");
                else setErrorCountry("Ce choix est incorrect");
                break;
            default: break;
        }
    }

    const handleKeyEnter = event => {
        if (event.key === 'Enter')
            if(processEdit(true))
                reinitAllEdits();
    }

    const save = () => {
        setSaving(true);

        reinitAllErrors();

        const controller = new StorageController();
        controller._callback = handleReturnSave;

        const storage = Object.assign({}, storageObject);
        storage.address = address;
        storage.additional = additional;
        storage.postalCode = postalCode;
        storage.city = city;
        storage.country = country;

        if(newItem)
            controller.post(partnership, storage);
        else
            controller.put(partnership, storage);
    }
    const handleReturnSave = (response, error) => {
        if(error) {
            setSaving(false);
            reinitInputs();

            if(error.response !== undefined) {
                if(error.response.status === 401)
                    handleLogout();
                else if(error.response.status === 403)
                    setWarning("Vous n'avez pas les droits nécessaire pour effectuer cette action");
                else if(error.response.status === 422)
                    check422Errors(error.response.data);
            }
            else
                setError("Une erreur s'est produite lors de l'enregistrement");
        }
        else {
            if (storageObject.id === 0)
                handleClose(true);
            else {
                // Save informations
                const storage = Object.assign({}, storageObject);
                storage.address = address;
                storage.additional = additional;
                storage.postalCode = postalCode;
                storage.city = city;
                storage.country = country;
                setStorageObject(storage);

                setValid("Les informations ont été enregistrées");
                handleRefresh();
            }
        }
    }

    // RENDER

    return (
        <div className="contForm">
            <div className={ "form " + (newItem ? "margin" : "") }>
                {
                    newItem && <p className="titleForm center">AJOUTER UN ÉTABLISSEMENT</p>
                }
                {
                    error !== "" && <Error text={ error } />
                }
                {
                    warning !== "" && <Warning text={ warning } />
                }
                {
                    valid !== "" && <Valid text={ valid } />
                }
                <label className="label">Adresse</label>
                {
                    !editAddress && !newItem
                        ? <NoInput value={ storageObject.address !== "" ? storageObject.address : "Aucune" } color={ color } edit={ true } copy={ storageObject.address !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditAddress } />
                        : <InputText color={ color } classError={ errorAddress !== "" ? "wrong" : "" } value={ address } placeholder="Adresse" newItem={ newItem } handleChange={ handleChangeAddress } handleBlur={ handleEditAddress } handleKeyEnter={ handleKeyEnter } />
                }
                {
                    errorAddress !== ""
                        ? <p className="error">{ errorAddress }</p>
                        : ""
                }
                <label className="label">Complément d'adresse</label>
                {
                    !editAdditional && !newItem
                        ? <NoInput value={ storageObject.additional !== "" ? storageObject.additional : "Aucun" } color={ color } edit={ true } copy={ storageObject.additional !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditAdditional } />
                        : <InputText color={ color } classError={ errorAdditional !== "" ? "wrong" : "" } value={ additional } placeholder="Complément d'adresse" newItem={ newItem } handleChange={ handleChangeAdditional } handleBlur={ handleEditAdditional } handleKeyEnter={ handleKeyEnter } />
                }
                {
                    errorAdditional !== ""
                        ? <p className="error">{ errorAdditional }</p>
                        : ""
                }
                <label className="label left">Code postal</label>
                <label className="label right">Ville</label>
                {
                    !editPostalCode && !newItem
                        ? <NoInput classname="clear left" value={ storageObject.postalCode !== "" ? storageObject.postalCode : "Aucun" } color={ color } edit={ true } copy={ storageObject.postalCode !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditPostalCode } />
                        : <InputText color={ color } classname="left" classError={ errorPostalCode !== "" ? "wrong" : "" } value={ postalCode } placeholder="Code postal" newItem={ newItem } handleChange={ handleChangePostalCode } handleBlur={ handleEditPostalCode } handleKeyEnter={ handleKeyEnter } />
                }
                {
                    !editCity && !newItem
                        ? <NoInput classname="right" value={ storageObject.city !== "" ? storageObject.city : "Aucune" } color={ color } edit={ true } copy={ storageObject.city !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditCity } />
                        : <InputText color={ color } classname="right" classError={ errorCity !== "" ? "wrong" : "" } value={ city } placeholder="Ville" newItem={ newItem } handleChange={ handleChangeCity } handleBlur={ handleEditCity } handleKeyEnter={ handleKeyEnter } />
                }
                <div className="clearing" />
                {
                    errorPostalCode !== ""
                        ? <p className="error left">{ errorPostalCode }</p>
                        : ""
                }
                {
                    errorCity !== ""
                        ? <p className="error right">{ errorCity }</p>
                        : ""
                }
                <label className="label clear">Pays</label>
                {
                    !editCountry && !newItem
                        ? <NoInput value={ storageObject.country !== "" ? storageObject.country : "Aucun" } color={ color } edit={ true } copy={ storageObject.country !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditCountry } />
                        : <InputSelect color={ color } classError={ errorCountry !== "" ? "wrong" : "" } list={ countries } value={ country } newItem={ newItem } handleChange={ handleChangeCountry } handleBlur={ handleEditCountry } returnTextValue={ true } />
                }
                {
                    errorCountry !== ""
                        ? <p className="error">{ errorCountry }</p>
                        : ""
                }
                {
                    newItem
                        ? <div>
                            <button className="submit" onClick={ save }>
                                {saving ? "Enregistrement..." : "Enregistrer"}
                                <LoaderCircle display="loader submitLogin" hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                            </button>
                            <button className="cancel" onClick={ handleClose }>Annuler</button>
                            <div className="clearing" />
                        </div>
                        : handleClose !== null
                        &&
                        <div>
                            <button className="delete" onClick={ () => { handleDelete(storage.id) } }>Supprimer l'établissement</button>
                            <button className="cancel block" onClick={ handleClose }>Fermer</button>
                        </div>
                }
            </div>
        </div>
    );
}

export default StorageForm;
