import React, {useContext, useEffect, useState} from "react";
import NoInput from "../input/NoInput";
import InputText from "../input/InputText";
import LoaderCircle from "../../loader/LoaderCircle";
import InputSelect from "../input/InputSelect";
import SearchCompany from "../../overbox/company/SearchCompany";
import ListAddressForm from "../address/ListAddressForm";
import ListContactForm from "../contact/ListContactForm";
import AuthController from "../../../stories/_auth/Auth/AuthController";
import StoreController from "../../../stories/_client/Stores/StoreController";
import ProspectPhaseController from "../../../stories/_setup/ProspectPhase/ProspectPhaseController";
import PartnershipController from "../../../stories/_auth/Partnerships/PartnershipController";
import SessionContext from "../../../context/SessionContext";
import StoreContext from "../../../context/StoreContext";

function ProspectForm (props) {
    const color = window.matchMedia('(prefers-color-scheme: dark)').matches ? "#FFFFFF" : "#1D1D1D";

    const { handleUpdateSending, newItem, handleGetProspect, object, setObject, errors } = props;
    const { handleLogout } = useContext(SessionContext);
    const { store } = useContext(StoreContext);
    const [ prospectObject, setProspectObject ] = useState((object !== undefined) ? object : store);
    const [ firstInitializationObject, setFirstInitializationObject ] = useState(true);
    const [ loadingOwn, setLoadingOwn ] = useState(true);
    const [ loadingProspectPhase, setLoadingProspectPhase ] = useState(true);
    const [ loadingAcquisitionChannel, setLoadingAcquisitionChannel ] = useState(true);
    const [ globalError, setGlobalError ] = useState("");
    const [ partnerships, setPartnerships ] = useState([]);
    const [ partnershipObjects, setPartnershipObjects ] = useState([]);
    const [ companyID, setCompanyID ] = useState(0);
    const [ company, setCompany ] = useState("");
    const [ editCompany, setEditCompany ] = useState(false);
    const [ errorCompany, setErrorCompany ] = useState("");
    const [ storeName, setStoreName ] = useState("");
    const [ editStoreName, setEditStoreName ] = useState(false);
    const [ errorStoreName, setErrorStoreName ] = useState("");
    const [ partnershipID, setPartnershipID ] = useState(0);
    const [ errorPartnership, setErrorPartnership ] = useState("");
    const [ firstAssignedTo, setFirstAssignedTo ] = useState(true);
    const [ listAssignedTo, setListAssignedTo ] = useState([]);
    const [ assignedTo, setAssignedTo ] = useState("");
    const [ editAssignedTo, setEditAssignedTo ] = useState(false);
    const [ errorAssignedTo, setErrorAssignedTo ] = useState("");
    const [ listAcquisitionChannels, setListAcquisitionChannels ] = useState([]);
    const [ acquisitionChannel, setAcquisitionChannel ] = useState(0);
    const [ editAcquisitionChannel, setEditAcquisitionChannel ] = useState(false);
    const [ errorAcquisitionChannel, setErrorAcquisitionChannel ] = useState("");
    const [ listProspectPhases, setListProspectPhases ] = useState([]);
    const [ prospectPhase, setProspectPhase ] = useState(0);
    const [ editProspectPhase, setEditProspectPhase ] = useState(false);
    const [ errorProspectPhase, setErrorProspectPhase ] = useState("");
    const [ showBoxSearchCompanies, setShowBoxSearchCompanies ] = useState(false);

    const initialize = () => {
        let objectToFillDatas = getObjectToFillDatas();

        setCompanyID(objectToFillDatas.company_id);
        setCompany(objectToFillDatas.company_name);
        setStoreName(objectToFillDatas.name);
        setPartnershipID(objectToFillDatas.partnership_id);
        setAcquisitionChannel(objectToFillDatas.acquisition_channel_id);
        setProspectPhase(objectToFillDatas.prospect_phase_id);

        getOwn();

        if (!newItem) {
            setLoadingAcquisitionChannel(false);
            setLoadingProspectPhase(false);
        }
        else {
            getProspectPhases();
        }
    }
    const initializeAssignedTo = () => {
        setFirstAssignedTo(false);

        getAssignables(partnershipID);
        getAcquisitionChannels(partnershipID);
    }
    const initializeFirstValuesInObject = () => {
        setFirstInitializationObject(false);

        let objectToFillDatas = getObjectToFillDatas();

        if(objectToFillDatas.partnership_id === 0 && partnerships.length > 0) {
            saveObject([ {
                type: 'partnership',
                value: partnerships[0].key
            }]);
        }
    }
    const getObjectToFillDatas = () => {
        if (object !== undefined) return object;
        else return store;
    }
    const handleGetOwn = (list, error) => {
        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else
                setGlobalError("Erreur lors de la récupération de l'environnement");
        }

        let partnershipsList = [];

        list.map(objPart => {
            partnershipsList.push({
                key: objPart.id,
                value: objPart.company
            });
        });

        let objectToFillDatas = getObjectToFillDatas();

        if(partnershipsList.length > 0 && objectToFillDatas.partnership_id === 0)
            setPartnershipID(partnershipsList[0].key);

        setPartnershipObjects(list);
        setPartnerships(partnershipsList);
        setLoadingOwn(false);
    }
    const getOwn = () => {
        const authController = new AuthController();
        authController._callback = handleGetOwn;
        authController.own();
    }
    const getObjectPartnership = idPartnership => {
        for (var i in partnershipObjects)
            if (partnershipObjects[i].id === idPartnership)
                return partnershipObjects[i];
    }
    const handleGetAcquisitionChannels = (list, error) => {
        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else
                setGlobalError(true);
        }

        let acquisitionChannelsList = [{
            key: -1,
            value: "--- Canal d'acquisition ---",
            id: -1,
            type: ""
        }];

        list.map(objAcq => {
            acquisitionChannelsList.push({
                key: objAcq.id,
                value: objAcq.name
            });
        });

        let objectToFillDatas = getObjectToFillDatas();

        if (objectToFillDatas.acquisition_channel_id === 0 && acquisitionChannelsList.length > 0)
            setAcquisitionChannel(acquisitionChannelsList[0].key);

        setListAcquisitionChannels(acquisitionChannelsList);
        setLoadingAcquisitionChannel(false);
    }
    const getAcquisitionChannels = idPartnership => {
        const controller = new PartnershipController();
        controller._callback = handleGetAcquisitionChannels;
        controller.indexAcquisitionChannels(getObjectPartnership(idPartnership));
    }
    const handleGetProspectPhases = (list, error) => {
        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else
                setGlobalError("Erreur lors de la récupération des phases de prospection");
        }

        let prospectPhasesList = [];

        list.map(objPros => {
            prospectPhasesList.push({
                key: objPros.id,
                value: objPros.name
            });
        });

        let objectToFillDatas = getObjectToFillDatas();

        if (objectToFillDatas.prospect_phase_id === 0 && prospectPhasesList.length > 0) {
            setProspectPhase(prospectPhasesList[0].key);
        }

        setListProspectPhases(prospectPhasesList);
        setLoadingProspectPhase(false);
    }
    const getProspectPhases = () => {
        const controller = new ProspectPhaseController();
        controller._callback = handleGetProspectPhases;
        controller.index();
    }
    const buildListAssignedTo = (listSrc) => {
        let list = [{
            key: -1,
            value: "--- Groupes ---",
            id: -1,
            type: ""
        }];
        var loop = 0;
        var i;

        for (i in listSrc.groups)
            list.push({
                key: loop++,
                value: listSrc.groups[i].name,
                id: listSrc.groups[i].id,
                type: "GROUP"
            });

        list.push({
            key: -2,
            value: "--- Utilisateurs ---",
            id: -2,
            type: ""
        });

        for (i in listSrc.users)
            list.push({
                key: loop++,
                value: (listSrc.users[i].lastname + " " + listSrc.users[i].firstname).trim(),
                id: listSrc.users[i].id,
                type: "USER"
            });

        return list;
    }
    const handleGetAssignables = (list, error) => {
        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else
                setGlobalError("Erreur lors de la récupération des assignables");

            return;
        }

        const listBuilded = buildListAssignedTo(list);
        setListAssignedTo(listBuilded);

        setAssignedTo(getAssignedToKey(listBuilded));
    }
    const getAssignables = idPartnership => {
        const controller = new PartnershipController();
        controller._callback = handleGetAssignables;
        controller.indexAssignables(getObjectPartnership(idPartnership), ['BOSS', 'COMMERCIAL']);
    }
    const handleGlobalError = text => {
        setGlobalError(text);
    }
    const handleEditCompany = () => {
        if(processEdit(editCompany))
            setEditCompany(!editCompany);
    }
    const handleChangeCompany = event => {
        const val = event.currentTarget.value;
        setCompany(val);
        saveObject([{ type: 'company', value: val }]);
    }
    const checkCompany = () => {
        try {
            StoreController.checkCompanyName(company);
        }
        catch (e) {
            if (e.message === "EMPTY") setErrors("company_name", true);
            else setErrors("company_name", false);
        }

        setErrorCompany("");

        return true;
    }
    const handleEditStoreName = () => {
        if(processEdit(editStoreName))
            setEditStoreName(!editStoreName);
    }
    const handleChangeStoreName = event => {
        const val = event.currentTarget.value;
        setStoreName(val);
        saveObject([{ type: 'name', value: val }]);
    }
    const checkStoreName = () => {
        try {
            StoreController.checkName(storeName);
        }
        catch (e) {
            if (e.message === "EMPTY") setErrors("store", true);
            else setErrors("store", false);
        }

        setErrorStoreName("");

        return true;
    }
    const getPartnershipName = () => {
        for (let i in partnershipObjects)
            if (partnershipObjects[i].id === partnershipID)
                return partnershipObjects[i].company;

        return "";
    }
    const handleChangePartnership = event => {
        const val = parseInt(event.currentTarget.value);
        setPartnershipID(val);
        setListAssignedTo([]);
        setFirstAssignedTo(true);
        setAssignedTo("");
        saveObject([{ type: 'partnership', value: val }]);
    }
    const checkPartnership = () => {
        try {
            StoreController.checkPartnership(partnershipID);
        }
        catch (e) {
            if (e.message === "EMPTY") setErrors("partnership", true);
            else setErrors("partnership", false);
        }

        setErrorPartnership("");

        return true;
    }
    const getAcquisitionChannelName = () => {
        for (var i in listAcquisitionChannels)
            if (listAcquisitionChannels[i].key === acquisitionChannel)
                return listAcquisitionChannels[i].value;

        return "";
    }
    const handleEditAcquisitionChannel = () => {
        if(processEdit(editAcquisitionChannel))
            setEditAcquisitionChannel(!editAcquisitionChannel);
    }
    const handleChangeAcquisitionChannel = event => {
        const val = parseInt(event.currentTarget.value);
        setAcquisitionChannel(val);
        saveObject([{ type: 'acquisition_channel', value: val }]);
    }
    const checkAcquisitionChannel = () => {
        try {
            StoreController.checkAcquisitionChannel(acquisitionChannel);
        }
        catch (e) {
            if (e.message === "EMPTY") setErrors("acquisitionChannel", true);
            else setErrors("acquisitionChannel", false);
        }

        setErrorAcquisitionChannel("");

        return true;
    }
    const getAssignedToName = () => {
        for (var i in listAssignedTo)
            if (listAssignedTo[i].key === assignedTo)
                return listAssignedTo[i].value;

        return "";
    }
    const getAssignedToKey = list => {
        let objectToFillDatas = getObjectToFillDatas();

        for (var i in list)
            if (list[i].id === objectToFillDatas.assigned_to_id && list[i].type === objectToFillDatas.assigned_to_type)
                return list[i].key;

        return "";
    }
    const handleEditAssignedTo = () => {
        if(processEdit(editAssignedTo))
            setEditAssignedTo(!editAssignedTo);
    }
    const handleChangeAssignedTo = event => {
        const val = parseInt(event.currentTarget.value);

        setAssignedTo(val);

        let assignedToObject = null;
        for (var i in listAssignedTo)
            if (listAssignedTo[i].key === val)
                assignedToObject = listAssignedTo[i];

        if (assignedToObject === null) {
            setGlobalError("Veuillez remplir le champ 'Assigné à'");
            return;
        }

        saveObject([{ type: 'assigned_to', value: assignedToObject }]);
    }
    const checkAssignedTo = () => {
        let assignedToObject = null;
        for (var i in listAssignedTo)
            if (listAssignedTo[i].key === assignedTo)
                assignedToObject = listAssignedTo[i];

        if (assignedToObject === null) {
            setErrors("assignedTo", true);
            return;
        }

        try {
            StoreController.checkAssignedTo(assignedToObject.id, assignedToObject.type);
        }
        catch (e) {
            if (e.message === "EMPTY") setErrors("assignedTo", true);
            else setErrors("assignedTo", false);
        }

        setErrorAssignedTo("");

        return true;
    }
    const getProspectPhaseName = () => {
        for (var i in listProspectPhases)
            if (listProspectPhases[i].key === prospectPhase)
                return listProspectPhases[i].value;

        return "";
    }
    const handleEditProspectPhase = () => {
        if(processEdit(editProspectPhase))
            setEditProspectPhase(!editProspectPhase);
    }
    const handleChangeProspectPhase = event => {
        const val = parseInt(event.currentTarget.value);
        setProspectPhase(val);
        saveObject([{ type: 'prospect_phase', value: val }]);
    }
    const checkProspectPhase = () => {
        try {
            StoreController.checkProspectPhase(prospectPhase);
        }
        catch (e) {
            if (e.message === "EMPTY") setErrors("prospectPhase", true);
            else setErrors("prospectPhase", false);
        }

        setErrorProspectPhase("");

        return true;
    }
    const handleSearchCompanies = () => {
        setShowBoxSearchCompanies(!showBoxSearchCompanies);
    }
    const handleSelectCompany = company => {
        if (company.length > 0) {
            setCompany(company[0].main);
            setCompanyID(company[0].id);
            saveObject([{ type: 'company', value: company[0].main }, { type: 'company_id', value: company[0].id }]);

            handleSearchCompanies();
        }
    }
    const processEdit = edit => {
        if(edit) {
            if(checkCompany() && checkStoreName() && checkPartnership() && checkAssignedTo() && checkAcquisitionChannel() && checkProspectPhase() && !newItem)
                save();
            else
                return false;
        }

        return true;
    }
    const reinitAllEdits = () => {
        setEditCompany(false);
        setEditStoreName(false);
        setEditAssignedTo(false);
        setEditAcquisitionChannel(false);
        setEditProspectPhase(false);
    }
    const reinitAllErrors = () => {
        setGlobalError("");
        setErrorCompany("");
        setErrorPartnership("");
        setErrorStoreName("");
        setErrorAssignedTo("");
        setErrorAcquisitionChannel("");
        setErrorProspectPhase("");
    }
    const check422Errors = errorDatas => {
        setGlobalError("Certaines données sont invalides");

        if(errorDatas !== undefined) {
            var keys = Object.keys(errorDatas);
            var fields = ["company_name", "name", "partnership", "address", "additional", "postalCode", "city", "country", "assignedTo", "acquisitionChannel", "prospectPhase"];

            for(var item in fields)
                if(keys.includes(fields[item]))
                    setErrors(fields[item], false);
        }
    }
    const setErrors = (type, empty) => {
        switch (type) {
            case "company_name":
                if(empty) setErrorCompany("Vous devez saisir une dénomination");
                else setErrorCompany("Cette dénomination n'est pas valide");
                break;
            case "store":
                if(empty) setErrorStoreName("Vous devez saisir un nom d'établissement");
                else setErrorStoreName("Ce nom d'établissement n'est pas valide");
                break;
            case "partnership":
                if(empty) setErrorStoreName("Vous devez sélectionner un partenaire");
                break;
            case "assignedTo":
                if(empty) setErrorAssignedTo("Vous devez faire un choix");
                else setErrorAssignedTo("Ce choix est incorrect");
                break;
            case "acquisitionChannel":
                if(empty) setErrorAcquisitionChannel("Vous devez faire un choix");
                else setErrorAcquisitionChannel("Ce choix est incorrect");
                break;
            case "prospectPhase":
                if(empty) setErrorProspectPhase("Vous devez faire un choix");
                else setErrorProspectPhase("Ce choix est incorrect");
                break;
            default: break;
        }
    }
    const handleKeyEnter = event => {
        if (event.key === 'Enter')
            if(processEdit(true))
                reinitAllEdits();
    }
    const returnUpdates = (controller, datas) => {
        if (controller === undefined)
            controller = new StoreController();

        return controller.returnUpdatesFromCompare(prospectObject, datas);
    }
    const saveObject = datas => {
        if (object !== undefined) {
            const obj = Object.assign({}, object);

            for (var i in datas) {
                switch (datas[i].type) {
                    case "partnership": obj.partnership_id = datas[i].value; break;
                    case "company": obj.company_name = datas[i].value; break;
                    case "company_id": obj.company_id = datas[i].value; break;
                    case "name": obj.name = datas[i].value; break;
                    case "acquisition_channel": obj.acquisition_channel_id = datas[i].value; break;
                    case "prospect_phase": obj.prospect_phase_id = datas[i].value; break;
                    case "assigned_to":
                        obj.assigned_to_id = datas[i].value.id;
                        obj.assigned_to_type = datas[i].value.type;
                        break;
                    default: break;
                }
            }

            setObject(obj);
        }
    }
    const save = () => {
        reinitAllErrors();

        if(handleUpdateSending !== undefined)
            handleUpdateSending(true);

        let assignedToObject = null;
        for (var i in listAssignedTo)
            if (listAssignedTo[i].key === assignedTo)
                assignedToObject = listAssignedTo[i];

        if (assignedToObject === null) {
            setGlobalError("Veuillez remplir le champ 'Assigné à'");
            return;
        }

        const controller = new StoreController();
        const datas = returnUpdates(controller, {
            company_id: companyID,
            company_name: company,
            name: storeName,
            partnership_id: partnershipID,
            assigned_to_id: assignedToObject.id,
            assigned_to_type: assignedToObject.type
        });

        if(Object.keys(datas).length === 0) return;

        if (datas.assigned_to_id !== undefined && datas.assigned_to_type === undefined) // secure change id assignated but same type
            datas.assigned_to_type = assignedToObject.type;
        else if (datas.assigned_to_type !== undefined && datas.assigned_to_id === undefined) // secure change type assignated but same id
            datas.assigned_to_id = assignedToObject.id;

        controller._callback = handleReturnSave;

        if(newItem) {
            const user = JSON.parse(localStorage.getItem("user"))

            datas.prospect_phase_id = prospectPhase;
            datas.acquisition_channel_id = acquisitionChannel;
            datas.creator_id = user.id;
            controller.post(datas);
        }
        else
            controller.put(prospectObject, datas);
    }
    const handleReturnSave = (response, error) => {
        if(handleUpdateSending !== undefined)
            handleUpdateSending(false);

        if(error) {
            if(error.response !== undefined) {
                if(error.response.status === 401)
                    handleLogout();
                else if(error.response.status === 422)
                    check422Errors(error.response.data);
            }
            else
                setGlobalError("Une erreur s'est produite lors de l'enregistrement");
        }
        else
            if(newItem)
                window.location = "/prospect/" + response.data.id + "/information";
    }

    useEffect(() => {
        initialize();
    }, []);
    useEffect(() => {
        if (partnershipID > 0 && partnershipObjects.length > 0 && listAssignedTo.length === 0 && firstAssignedTo)
            initializeAssignedTo();

        if (errors !== undefined && Object.keys(errors).length > 0) {
            reinitAllErrors();

            for (const [key, value] of Object.entries(errors))
                setErrors(key, value === "EMPTY");
        }

        if (!loadingOwn && !loadingAcquisitionChannel && !loadingProspectPhase && firstInitializationObject)
            initializeFirstValuesInObject();
    }, [partnershipID, partnershipObjects, listAssignedTo, firstAssignedTo, errors, loadingOwn, loadingAcquisitionChannel, loadingProspectPhase, firstInitializationObject]);

    return (
        <div className="contForm">
            {
                loadingOwn || loadingProspectPhase || loadingAcquisitionChannel
                    ? <LoaderCircle display="loader logWait" hide="" strokeWidth="5" stroke="#008C4F" />
                    : <div className="form">
                        {
                            showBoxSearchCompanies && <SearchCompany handleCloseSearch={ handleSearchCompanies } handleSelect={ handleSelectCompany } saving={ false } />
                        }
                        {
                            globalError !== ""
                                ? <p className="globalError">{ globalError }</p>
                                : ""
                        }
                        <label className="label">Groupe</label>
                        {
                            !editCompany && !newItem
                                ? <NoInput value={ company !== "" ? company : "Aucune" } color={ color } edit={ true } copy={ company !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditCompany } />
                                : <InputText color={ color } classError={ errorCompany !== "" ? "wrong" : "" } value={ company } placeholder="Société" newItem={ newItem } handleChange={ handleChangeCompany } handleBlur={ handleEditCompany } handleKeyEnter={ handleKeyEnter } handleSearch={ handleSearchCompanies } />
                        }
                        {
                            errorCompany !== ""
                                ? <p className="error">{ errorCompany }</p>
                                : ""
                        }
                        <label className="label">Établissement</label>
                        {
                            !editStoreName && !newItem
                                ? <NoInput value={ storeName ? storeName : "Aucun" } color={ color } edit={ true } copy={ storeName !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditStoreName } />
                                : <InputText color={ color } classError={ errorStoreName !== "" ? "wrong" : "" } value={ storeName } placeholder="Établissement" newItem={ newItem } focus={ true } handleChange={ handleChangeStoreName } handleBlur={ handleEditStoreName } handleKeyEnter={ handleKeyEnter } />
                        }
                        {
                            errorStoreName !== ""
                                ? <p className="error">{ errorStoreName }</p>
                                : ""
                        }
                        {
                            partnerships.length > 1 && <div className="clearing">
                                <label className="label">Partenaire</label>
                                {
                                    !newItem
                                        ? <NoInput value={ getPartnershipName() !== "" ? getPartnershipName() : "Aucun" } color={ color } edit={ false } copy={ getPartnershipName() !== "" } phone={ false } email={ false } link={ false } />
                                        : partnerships.length > 0 && <InputSelect color={ color } classError={ errorPartnership !== "" ? "wrong" : "" } list={ partnerships } value={ partnershipID } newItem={ newItem } handleChange={ handleChangePartnership } handleBlur={ () => {} } />
                                }
                            </div>
                        }
                        <label className="label">Assigné à</label>
                        {
                            !editAssignedTo && !newItem
                                ? <NoInput value={ getAssignedToName() } color={ color } edit={ true } copy={ getAssignedToName() !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditAssignedTo } />
                                : <InputSelect color={ color } classError={ errorAssignedTo !== "" ? "wrong" : "" } list={ listAssignedTo } value={ assignedTo } newItem={ newItem } handleChange={ handleChangeAssignedTo } handleBlur={ handleEditAssignedTo } />
                        }
                        {
                            errorAssignedTo !== ""
                                ? <p className="error">{ errorAssignedTo }</p>
                                : ""
                        }
                        {
                            newItem
                            && <div className="clearing">
                                <div className="separator" />
                                <label className="label">Canal d'acquisition</label>
                                {
                                    !editAcquisitionChannel && !newItem
                                        ? <NoInput value={ getAcquisitionChannelName() } color={ color } edit={ true } copy={ getAcquisitionChannelName() !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditAcquisitionChannel } />
                                        : <InputSelect color={ color } classError={ errorAcquisitionChannel !== "" ? "wrong" : "" } list={ listAcquisitionChannels } value={ acquisitionChannel } newItem={ newItem } handleChange={ handleChangeAcquisitionChannel } handleBlur={ handleEditAcquisitionChannel } />
                                }
                                {
                                    errorAcquisitionChannel !== ""
                                        ? <p className="error">{ errorAcquisitionChannel }</p>
                                        : ""
                                }
                                <label className="label">Étape de prospection</label>
                                {
                                    !editProspectPhase && !newItem
                                        ? <NoInput value={ getProspectPhaseName() } color={ color } edit={ true } copy={ getProspectPhaseName() !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditProspectPhase } />
                                        : <InputSelect color={ color } classError={ errorProspectPhase !== "" ? "wrong" : "" } list={ listProspectPhases } value={ prospectPhase } newItem={ newItem } handleChange={ handleChangeProspectPhase } handleBlur={ handleEditProspectPhase } />
                                }
                                {
                                    errorProspectPhase !== ""
                                        ? <p className="error">{ errorProspectPhase }</p>
                                        : ""
                                }
                            </div>
                        }
                        {
                            !newItem
                            && <ListContactForm separator={ true } handleRefresh={ handleGetProspect } handleGlobalError={ handleGlobalError } noSearch={ false } attachTo="store" setGeneralError={ setGlobalError } object={ store } />
                        }
                        {
                            !newItem
                            && <ListAddressForm separator={ true } handleRefresh={ handleGetProspect } setGeneralError={ setGlobalError } object={ store } />
                        }
                    </div>
            }
        </div>
    );
}

export default ProspectForm;
