import React, {useEffect, useState} from "react";
import UserController from "../../../stories/_auth/Users/UserController";
import LoaderCircle from "../../loader/LoaderCircle";
import InputText from "../../form/input/InputText";
import InputMultiLinesChoices from "../../form/input/InputMultiLinesChoices";

function SearchUser (props) {
    const color = window.matchMedia('(prefers-color-scheme: dark)').matches ? "#FFFFFF" : "#1D1D1D";
    const { handleCloseSearchUser, handleAttachUser, saving } = props;
    const [ first, setFirst ] = useState(true);
    const [ listUsers, setListUsers ] = useState([]);
    const [ listFiltredUsers, setListFiltredUsers ] = useState([]);
    const [ listSelected, setlistSelected ] = useState([]);
    const [ input, setInput ] = useState("");

    const handleChangeInput = event => {
        let inputVal = event.currentTarget.value;

        let listFiltred = [];

        for (var item in listUsers)
            if (listUsers[item].main.includes(inputVal) || listUsers[item].secondary.includes(inputVal))
                listFiltred.push(listUsers[item]);

        setInput(inputVal);
        setListFiltredUsers(listFiltred);
        setlistSelected([]);
    }

    const handleSelectChoiceList = id => {
        var listDatas = listSelected.slice();
        var idToRemove = -1;

        for (var item in listUsers) {
            if(listUsers[item].id === id) {
                for (var i = 0; i < listSelected.length; i++) {
                    if(listSelected[i].id === id) {
                        idToRemove = i;
                        break;
                    }
                }

                if(idToRemove >= 0)
                    listDatas.splice(idToRemove, 1);
                else
                    listDatas.push({
                        id: listUsers[item].id,
                        main: listUsers[item].lastname + " " + listUsers[item].firstname,
                        secondary: listUsers[item].email
                    });

                break;
            }
        }

        setlistSelected(listDatas);
    }

    const getUsers = () => {
        setFirst(false);

        const controller = new UserController();
        controller._callback = handleGetUsers;
        controller.index();
    }
    const handleGetUsers = (list, error) => {
        var listDatas = [];

        for (var item in list)
            listDatas.push({
                id: list[item].id,
                main: list[item].lastname + " " + list[item].firstname,
                secondary: list[item].email
            });

        setListUsers(listDatas);
        setListFiltredUsers(listDatas);
    }

    const saveAttachUser = () => {
        handleAttachUser(listSelected);
    }

    useEffect(() => {
        if(first)
            getUsers();
    });

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox search">
                    <div className="contForm">
                        <div className="form">
                        {
                            listUsers.length > 0
                            ? <div>
                                <InputText color={ color } classError="" value={ input } placeholder="Recherche..." newItem={ true } handleChange={ handleChangeInput } />
                                <InputMultiLinesChoices color={ color } list={ listFiltredUsers } listSelected={ listSelected } handleSelectChoiceList={ handleSelectChoiceList } />
                                <button className="submit" onClick={ saveAttachUser }>
                                    {saving ? "Ajout en cours..." : "Ajouter"}
                                    <LoaderCircle display="loader submitLogin" hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                                </button>
                                <button className="cancel" onClick={ handleCloseSearchUser }>Annuler</button>
                                <div className="clearing" />
                            </div>
                            : <LoaderCircle display="loader logWait" hide="" strokeWidth="5" stroke="#008C4F" />
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SearchUser;
