import React, {useContext} from "react";
import StoreContext from "../../../context/StoreContext";
import LoaderCircle from "../../loader/LoaderCircle";

const AddLicense = props => {
    const { handleClose, handleNew, saving } = props;
    const { store } = useContext(StoreContext);

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox choices">
                    <div className="form margin">
                        <p className="titleForm center">Vous souhaitez ajouter une licence à <strong>{ store.name }</strong></p>
                        <button className="buttonChoice" onClick={ handleNew }>
                            Ajouter une licence secondaire
                            <LoaderCircle display="loader submitLogin" hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                        </button>
                        <button className="cancel block" onClick={ handleClose }>Annuler</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddLicense;
