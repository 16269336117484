import React, {useState} from "react";
import User from "./menu/User";
import Item from "./menu/Item";
import OptionUser from "./menu/OptionUser";
import AuthController from "../../stories/_auth/Auth/AuthController";
import "../../css/page/Menu.css";

function Menu (props) {
    const { page, handleHideSubMenu, handleSetTypeSubMenu } = props;
    const [showOptionUser, setShowOptionUser] = useState(false);
    const auth = new AuthController();

    const handleShowOptionUser = () => {
        setShowOptionUser(!showOptionUser);
    }

    const itemTypeIsSelected = () => {
        if(page === 'dashboard')
            return "dashboard";
        else if(page === 'client' || page === 'prospect')
            return "client";
        else if(page === 'product' || page === 'category' || page === 'brand')
            return "catalog";
        else if(page === 'quote' || page === 'voucher' || page === 'bill' || page === 'sample')
            return "accountant";
        else if(page === 'partnership' || page === 'setting' || page === 'setup' || page === 'provider' || page === 'prospecting' || page === 'tax')
            return "setting";
        else
            return "";
    }

    return (
        <div className="menu">
            <User onShowOptionUser={handleShowOptionUser} />
            <OptionUser show={ showOptionUser } handleClose={ handleShowOptionUser } />
            <Item type="dashboard" selected={ itemTypeIsSelected() === "dashboard" ? "true" : "false" } />
            <Item type="client" selected={ itemTypeIsSelected() === "client" ? "true" : "false" } handleSetTypeSubMenu={ handleSetTypeSubMenu } handleHideSubMenu={ handleHideSubMenu } />
            <Item type="catalog" selected={ itemTypeIsSelected() === "catalog" ? "true" : "false" } handleSetTypeSubMenu={ handleSetTypeSubMenu } handleHideSubMenu={ handleHideSubMenu } />
            {
                auth.hasRules(['boss', 'accountant', 'root'])
                && <Item type="accountant" selected={ itemTypeIsSelected() === "accountant" ? "true" : "false" } handleSetTypeSubMenu={ handleSetTypeSubMenu } handleHideSubMenu={ handleHideSubMenu } />
            }
            <Item type="setting" selected={ itemTypeIsSelected() === "setting" ? "true" : "false" } handleSetTypeSubMenu={ handleSetTypeSubMenu } handleHideSubMenu={ handleHideSubMenu } />
            {
                auth.hasRules(['root', 'developer'])
                && <Item type="project" selected={ itemTypeIsSelected() === "project" ? "true" : "false" } />
            }
        </div>
    );
}

export default Menu;
