import React, { useContext } from "react";
import { Link } from "react-router-dom";
import LoaderCircle from "../../../../loader/LoaderCircle";
import ProviderContext from "../../../../../context/ProviderContext";
import "../../../../../css/page/cards/Tabs.css";
import AccessIcon from "../../../../icons/AccessIcon";
import OptionProvider from "../OptionProvider";

function ProviderTabs (props) {
    const color = window.matchMedia('(prefers-color-scheme: dark)').matches ? "#FFFFFF" : "#1D1D1D";

    const { tab, sending, handleManage, show, handleDelete } = props;
    const { provider } = useContext(ProviderContext);

    return (
        <div className="tabs">
            <p className="title">{ provider.name }</p>
            <nav>
                <Link to={ "/provider/" + provider.id + "/information" }><p className={ "tab " + (tab === "information" ? "selected" : "") }>Informations</p></Link>
                <div className="clearing" />
            </nav>
            {
                sending
                ? <LoaderCircle display="loader restPUT" hide="" strokeWidth="10" stroke="#008C4F" />
                : <div className="access" onClick={ handleManage }>
                    <AccessIcon fill={ color } />
                </div>
            }
            <div className="clearing" />
            <OptionProvider show={ show } handleDelete={ handleDelete } />
        </div>
    );
}

export default ProviderTabs;
