import Rest from "../../../class/tool/Rest";
import Product from "./Product";

class ProductController {
    _rest = new Rest();
    _callback = null;
    _baseURL = "products";
    _baseURLPrices = "products/{idProduct}/prices";


    // Product

    handleIndex = (response, error) => {
        let listObjects = [];

        if(response.data !== undefined) {
            const data = response.data.data;

            for(var item in data)
                listObjects.push(new Product(data[item]));
        }

        this._callback(listObjects, error);
    }
    index(partnership = 0, input= "") {
        var params = "";

        if(partnership > 0)
            params += "?partnership=" + partnership;

        if(input !== "")
            params += (params.length === 0 ? "?" : "&") + "input=" + input;

        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + params,
            {},
            this.handleIndex);
    }

    handleShow = (response, error) => {
        const object = !error ? new Product(response.data) : null;
        this._callback(object, error);
    }
    show(id) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + '/' + id,
            {},
            this.handleShow);
    }

    handlePost = (response, error) => {
        this._callback(response, error);
    }
    post(object) {
        const objectToSend = JSON.parse(JSON.stringify(object));

        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURL,
            objectToSend,
            this.handlePost
        );
    }

    handlePut = (response, error) => {
        this._callback(response, error);
    }
    put(object) {
        const objectToSend = JSON.parse(JSON.stringify(object));

        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'PUT',
            '/' + this._baseURL + '/' + objectToSend.id,
            objectToSend,
            this.handlePut
        );
    }

    handleDelete = (response, error) => {
        this._callback(response, error);
    }
    delete(object) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'DELETE',
            '/' + this._baseURL + '/' + object.id,
            {},
            this.handleDelete);
    }


    // Price

    handleIndexPrices = (response, error) => {
        let listObjects = [];

        if(response.data !== undefined) {
            const data = response.data.data;

            for(var item in data)
                listObjects.push(new Product(data[item]));
        }

        this._callback(listObjects, error);
    }
    indexPrices(product) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURLPrices.replace("{idProduct}", product.id),
            {},
            this.handleIndexPrices);
    }
}

export default ProductController;
