import React from "react"
import Error from "../../../../error/Error"
import SetupAccountsLine from "./SetupAccountsLine"
import LoaderCircle from "../../../../loader/LoaderCircle"
import AddIcon from "../../../../icons/AddIcon"
import "../../../../../css/page/setup/SetupAccounts.css"
import "../../../../../css/form/Form.css"
import "../../../../../css/page/listingTables/Table.css"
import "../../../../../css/page/listingTables/Line.css"
import "../../../../../css/page/listingTables/SetupAccountsLine.css"

const SetupAccountsTable = props => {
    const { setup, list, error, loading, closeOptions, handleRefresh, handleSetCloseOptions, handleAddAccount } = props

    return (
        <div className="wrapTable tableSetupAccounts">
            {
                error !== "" && <Error text={ error } classname="noMargin" />
            }
            <table className="table setupAccounts borderBottom">
                <tbody>
                    <tr className="line">
                        <th className="name left">NOM</th>
                        <th className="email left">EMAIL</th>
                        <th className="rules left">DROITS GROUPE</th>
                        <th className="rules left">DROITS BOUTIQUE</th>
                        <th className="state left">ETAT</th>
                        <th className="date left">DATE</th>
                        <th className="action">
                            <div className="access visible" onClick={ () => { handleAddAccount() } }>
                                <AddIcon fill={ "#1D1D1D" } />
                            </div>
                        </th>
                    </tr>
                </tbody>
            </table>
            {
                !loading
                ? <table className="table setupAccounts">
                    <tbody>
                    {
                        list.length > 0
                            ? list.map((object, index) => (
                                <SetupAccountsLine key={ index } setup={ setup } object={ object } closeOptions={ closeOptions } handleRefresh={ handleRefresh } handleSetCloseOptions={ handleSetCloseOptions } />
                            ))
                            : <tr className="line">
                                <td className="center" colSpan="6">Aucun compte backoffice</td>
                            </tr>
                    }
                    </tbody>
                </table>
                : <LoaderCircle display="loader logWaitLine" hide="" strokeWidth="5" stroke="#008C4F" />
            }
        </div>
    )
}

export default SetupAccountsTable
