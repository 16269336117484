import React, {useContext} from "react";
import LoaderCircle from "../../../../loader/LoaderCircle";

import SearchContext from "../../../../../context/SearchContext";

import "../../../../../css/page/SearchBar.css";

function TaxSearchBar (props) {
    const { handleChangeInput, handleChangeSelect } = props;
    const { selectSearch, valueSelectSearch, inputSearch, initialLoading, loading } = useContext(SearchContext);

    return (
        <div className="searchBarComponent">
            {
                selectSearch.length > 0 &&
                <select value={ valueSelectSearch } className="select" onChange={ handleChangeSelect } >
                    {
                        selectSearch.map(option => (
                            <option key={ option.id } value={ option.id }>{ option.name }</option>
                        ))
                    }
                </select>
            }

            <input type="text" className={ "search" + (selectSearch.length > 0 ? " withSelect" : "") } placeholder="Recherche" value={ inputSearch } onChange={ handleChangeInput } />
            {
                (loading && !initialLoading) && <LoaderCircle display="loader searchBarLoading" strokeWidth="10" stroke="#008C4F" />
            }
        </div>
    );
}

export default TaxSearchBar;
