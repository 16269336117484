import React, {useContext, useEffect, useState} from "react";
import NoInput from "../input/NoInput";
import InputText from "../input/InputText";
import ProviderController from "../../../stories/_catalog/Providers/ProviderController";
import Provider from "../../../stories/_catalog/Providers/Provider";
import ProviderContext from "../../../context/ProviderContext";
import GoogleLocation from "../../../class/tool/GoogleLocation";
import LoaderCircle from "../../loader/LoaderCircle";
import SessionContext from "../../../context/SessionContext";
import InputSelect from "../input/InputSelect";
import ListContactForm from "../contact/ListContactForm";
import InputMultiLinesChoices from "../input/InputMultiLinesChoices";

function ProviderForm (props) {
    const color = window.matchMedia('(prefers-color-scheme: dark)').matches ? "#FFFFFF" : "#1D1D1D";
    const countries = [
        {
            key: 5,
            value: "FRANCE"
        },
        {
            key: 1,
            value: "ALLEMAGNE"
        },
        {
            key: 2,
            value: "ANGLETERRE"
        },
        {
            key: 3,
            value: "BELGIQUE"
        },
        {
            key: 4,
            value: "ESPAGNE"
        },
        {
            key: 6,
            value: "ITALIE"
        },
        {
            key: 7,
            value: "LUXEMBOURG"
        },
        {
            key: 8,
            value: "SUISSE"
        }
    ];

    const { handleUpdateSending, newItem, handleClose, handleRefresh } = props;
    const { handleLogout } = useContext(SessionContext);
    const { provider } = useContext(ProviderContext);
    const [ providerObject, setProviderObject ] = useState((provider.id === undefined) ? new Provider() : provider);
    const [ first, setFirst ] = useState(true);
    const [ saving, setSaving ] = useState(false);
    const [ globalError, setGlobalError ] = useState("");
    const [ partnerships, setPartnerships ] = useState([]);
    const [ name, setName ] = useState(!newItem ? provider.name : "");
    const [ editName, setEditName ] = useState(false);
    const [ errorName, setErrorName ] = useState("");
    const [ reference, setReference ] = useState(!newItem ? provider.reference : "");
    const [ editReference, setEditReference ] = useState(false);
    const [ errorReference, setErrorReference ] = useState("");
    const [ site, setSite ] = useState(!newItem ? provider.site : "");
    const [ editSite, setEditSite ] = useState(false);
    const [ errorSite, setErrorSite ] = useState("");
    const [ partnershipsSelected, setPartnershipsSelected ] = useState([]);
    const [ errorPartnership, setErrorPartnership ] = useState("");
    const [ address, setAddress ] = useState(!newItem ? (provider !== null ? provider.address : "" ) : "");
    const [ editAddress, setEditAddress ] = useState(false);
    const [ errorAddress, setErrorAddress ] = useState("");
    const [ additional, setAdditional ] = useState(!newItem ? (provider !== null ? provider.additional : "" ) : "");
    const [ editAdditional, setEditAdditional ] = useState(false);
    const [ errorAdditional, setErrorAdditional ] = useState("");
    const [ postalCode, setPostalCode ] = useState(!newItem ? (provider !== null ? provider.postalCode : "" ) : "");
    const [ editPostalCode, setEditPostalCode ] = useState(false);
    const [ errorPostalCode, setErrorPostalCode ] = useState("");
    const [ city, setCity ] = useState(!newItem ? (provider !== null ? provider.city : "" ) : "");
    const [ editCity, setEditCity ] = useState(false);
    const [ errorCity, setErrorCity ] = useState("");
    const [ country, setCountry ] = useState(!newItem ? (provider !== null ? provider.country : "" ) : countries[0].value);
    const [ editCountry, setEditCountry ] = useState(false);
    const [ errorCountry, setErrorCountry ] = useState("");

    // INIT

    const listPartnerships = () => {
        setFirst(false);

        const user = JSON.parse(localStorage.getItem("user"))
        let partnershipsList = [];

        user.partnerships.map(object => {
            partnershipsList.push({
                id: object.id,
                main: object.company
            });
        });

        setPartnerships(partnershipsList);
    }

    const buildListIDsPartnership = () => {
        var listIDs = [];

        for (var i in partnershipsSelected)
            listIDs.push(partnershipsSelected[i].id);

        return listIDs;
    }

    // HANDLES EDITION

    const handleGlobalError = text => {
        setGlobalError(text);
    }

    const handleEditName = () => {
        if(processEdit(editName))
            setEditName(!editName);
    }
    const handleChangeName = event => {
        setName(event.currentTarget.value);
    }
    const checkName = () => {
        const regxp = /^[\w ]{2,50}/g;

        if(name === "") {
            setErrors("name", true);
            return false;
        }

        if (!regxp.test(name.removeDiacritics())) {
            setErrors("name", false);
            return false;
        }

        setErrorName("");

        return true;
    }

    const handleEditReference = () => {
        if(processEdit(editReference))
            setEditReference(!editReference);
    }
    const handleChangeReference = event => {
        setReference(event.currentTarget.value);
    }
    const checkReference = () => {
        const regxp = /^[\w]{2,50}/g;

        if (!newItem && reference === "") {
            setErrors("reference", true);
            return false;
        }

        if (reference !== "" && !regxp.test(reference.removeDiacritics())) {
            setErrors("reference", false);
            return false;
        }

        setErrorReference("");

        return true;
    }

    const handleEditSite = () => {
        if(processEdit(editSite))
            setEditSite(!editSite);
    }
    const handleChangeSite = event => {
        setSite(event.currentTarget.value);
    }
    const checkSite = () => {
        const regxp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;

        if (site !== "" && !regxp.test(site.removeDiacritics())) {
            setErrors("site", false);
            return false;
        }

        setErrorSite("");

        return true;
    }

    const handleSelectPartnership = id => {
        var listDatas = partnershipsSelected.slice();
        var idToRemove = -1;

        for (var item in partnerships) {
            if(partnerships[item].id === id) {
                for (var i = 0; i < partnershipsSelected.length; i++) {
                    if(partnershipsSelected[i].id === id) {
                        idToRemove = i;
                        break;
                    }
                }

                if(idToRemove >= 0)
                    listDatas.splice(idToRemove, 1);
                else
                    listDatas.push({
                        id: partnerships[item].id,
                        main: partnerships[item].company
                    });

                break;
            }
        }

        setPartnershipsSelected(listDatas);
    }
    const checkPartnership = () => {
        if(!newItem) return true;

        if(partnershipsSelected.length === 0) {
            setErrors("partnership", true);
            return false;
        }

        setErrorPartnership("");

        return true;
    }

    const handleEditAddress = () => {
        if(processEdit(editAddress))
            setEditAddress(!editAddress);
    }
    const handleChangeAddress = event => {
        const input = event.currentTarget.value;

        setAddress(input);

        if(input.length >= 3)
            searchAddress(input);
    }
    const checkAddress = () => {
        const regxp = /^\d+\s[A-z]+\s[A-z]+/g;

        if (address !== "" && !regxp.test(address)) {
            setErrors("address", false);
            return false;
        }

        setErrorAddress("");

        return true;
    }
    const searchAddress = input => {
        const location = new GoogleLocation();
        location._callback = handleSearchAddress;
        location.searchAddress(input);
    }
    const handleSearchAddress = (result, error) => {
        console.log("result", result);
        console.log("error", error);
    }

    const handleEditAdditional = () => {
        if(processEdit(editAdditional))
            setEditAdditional(!editAdditional);
    }
    const handleChangeAdditional = event => {
        setAdditional(event.currentTarget.value);
    }
    const checkAdditional = () => {
        const regxp = /^\d+\s[A-z]+\s[A-z]+/g;

        if (additional.length > 0 && !regxp.test(additional)) {
            setErrors("additional", false);
            return false;
        }

        setErrorAdditional("");

        return true;
    }

    const handleEditPostalCode = () => {
        if(processEdit(editPostalCode))
            setEditPostalCode(!editPostalCode);
    }
    const handleChangePostalCode = event => {
        setPostalCode(event.currentTarget.value);
    }
    const checkPostalCode = () => {
        const regxp = /^[A-Z0-9]+/g;

        if(postalCode === "") {
            setErrors("postalCode", true);
            return false;
        }

        if (!regxp.test(postalCode)) {
            setErrors("postalCode", false);
            return false;
        }

        setErrorPostalCode("");

        return true;
    }

    const handleEditCity = () => {
        if(processEdit(editCity))
            setEditCity(!editCity);
    }
    const handleChangeCity = event => {
        setCity(event.currentTarget.value);
    }
    const checkCity = () => {
        const regxp = /^[\w-]/g;

        if(city === "") {
            setErrors("city", true);
            return false;
        }

        if (!regxp.test(city)) {
            setErrors("city", false);
            return false;
        }

        setErrorCity("");

        return true;
    }

    const handleEditCountry = () => {
        if(processEdit(editCountry))
            setEditCountry(!editCountry);
    }
    const handleChangeCountry = event => {
        setCountry(event.currentTarget.value);
    }
    const checkCountry = () => {
        const regxp = /^[\w-]/g;

        if(city === "") {
            setErrors("country", true);
            return false;
        }

        if (!regxp.test(city)) {
            setErrors("country", false);
            return false;
        }

        setErrorCountry("");

        return true;
    }

    // CONTROL & SAVE

    const processEdit = edit => {
        if(edit) {
            if(checkName() && checkReference() && checkSite() && checkPartnership() && checkAddress() && checkAdditional() && checkPostalCode() && checkCity() && checkCountry() && !newItem)
                save();
            else
                return false;
        }

        return true;
    }
    const reinitAllEdits = () => {
        setEditName(false);
        setEditReference(false);
        setEditSite(false);
        setEditAddress(false);
        setEditAdditional(false);
        setEditPostalCode(false);
        setEditCity(false);
        setEditCountry(false);
    }
    const reinitAllErrors = () => {
        setGlobalError("");
        setErrorPartnership("");
        setErrorName("");
        setErrorReference("");
        setErrorSite("");
        setErrorAddress("");
        setErrorAdditional("");
        setErrorPostalCode("");
        setErrorCity("");
        setErrorCountry("");
    }

    const check422Errors = errorDatas => {
        setGlobalError("Certaines données sont invalides");

        if(errorDatas !== undefined) {
            var keys = Object.keys(errorDatas);
            var fields = ["name", "reference", "site", "partnership", "address", "additional", "postalCode", "city", "country"];

            for(var item in fields)
                if(keys.includes(fields[item]))
                    setErrors(fields[item], false);
        }
    }
    const setErrors = (type, empty) => {
        switch (type) {
            case "name":
                if(empty) setErrorName("Vous devez saisir un nom");
                else setErrorName("Ce nom n'est pas valide");
                break;
            case "reference":
                if(empty) setErrorReference("Vous devez saisir une référence");
                else setErrorReference("Cette référence n'est pas valide");
                break;
            case "site":
                setErrorSite("Ce site n'est pas valide");
                break;
            case "partnership":
                if(empty) setErrorPartnership("Vous devez sélectionner un partenaire");
                break;
            case "address":
                if(empty) setErrorAddress("Vous devez saisir une adresse");
                else setErrorAddress("Cette addresse n'est pas valide");
                break;
            case "additional":
                if(empty) setErrorAdditional("Vous devez saisir un complément d'adresse");
                else setErrorAdditional("Ce complément d'adresse n'est pas valide");
                break;
            case "postalCode":
                if(empty) setErrorPostalCode("Vous devez saisir un code postal");
                else setErrorPostalCode("Ce code postal est incorrect");
                break;
            case "city":
                if(empty) setErrorCity("Vous devez saisir une ville");
                else setErrorCity("Ce nom de ville est incorrect");
                break;
            case "country":
                if(empty) setErrorCountry("Vous devez faire un choix");
                else setErrorCountry("Ce choix est incorrect");
                break;
            default: break;
        }
    }

    const handleKeyEnter = event => {
        if (event.key === 'Enter')
            if(processEdit(true))
                reinitAllEdits();
    }

    const save = () => {
        setSaving(true);

        reinitAllErrors();

        if(handleUpdateSending !== undefined)
            handleUpdateSending(true);

        const controller = new ProviderController();
        controller._callback = handleReturnSave;

        const provider = Object.assign({}, providerObject);
        provider.name = name;
        provider.reference = reference;
        provider.site = site;
        provider.address = address;
        provider.additional = additional;
        provider.postalCode = postalCode;
        provider.city = city;
        provider.country = country;

        if(provider.id === 0)
            provider.partnership_ids = buildListIDsPartnership();

        if(newItem)
            controller.post(provider);
        else
            controller.put(provider);

    }
    const handleReturnSave = (response, error) => {
        if(handleUpdateSending !== undefined)
            handleUpdateSending(false);

        setSaving(false);

        if(error) {
            if(error.response !== undefined) {
                if(error.response.status === 401)
                    handleLogout();
                else if(error.response.status === 422)
                    check422Errors(error.response.data);
            }
            else
                setGlobalError("Une erreur s'est produite lors de l'enregistrement");
        }
        else {
            const provider = Object.assign({}, providerObject);

            if(provider.id === 0)
                handleClose(true);
            else {
                provider.name = name;
                provider.reference = reference;
                provider.site = site;
                provider.address = address;
                provider.additional = additional;
                provider.postalCode = postalCode;
                provider.city = city;
                provider.country = country;

                setProviderObject(provider);
            }
        }
    }

    // RENDER

    useEffect(() => {
        if (first)
            listPartnerships();
    });

    return (
        <div className="contForm">
            <div className={ "form " + (newItem ? "margin" : "") }>
                {
                    newItem && <p className="title">AJOUTER UN FOURNISSEUR</p>
                }
                {
                    globalError !== ""
                        ? <p className="globalError">{ globalError }</p>
                        : ""
                }
                <label className="label">Nom</label>
                {
                    !editName && !newItem
                        ? <NoInput value={ providerObject.name !== "" ? providerObject.name : "Aucun" } color={ color } edit={ true } copy={ providerObject.name !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditName } />
                        : <InputText color={ color } classError={ errorName !== "" ? "wrong" : "" } value={ name } placeholder="Nom" newItem={ newItem } handleChange={ handleChangeName } handleBlur={ handleEditName } handleKeyEnter={ handleKeyEnter } />
                }
                {
                    errorName !== ""
                        ? <p className="error">{ errorName }</p>
                        : ""
                }
                <label className="label">Référence</label>
                {
                    !editReference && !newItem
                        ? <NoInput value={ providerObject.reference !== "" ? providerObject.reference : "Aucun" } color={ color } edit={ true } copy={ providerObject.reference !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditReference } />
                        : <InputText color={ color } classError={ errorReference !== "" ? "wrong" : "" } value={ reference } placeholder="Référence" newItem={ newItem } handleChange={ handleChangeReference } handleBlur={ handleEditReference } handleKeyEnter={ handleKeyEnter } />
                }
                {
                    errorReference !== ""
                        && <p className="error">{ errorReference }</p>
                }
                <label className="label">Site internet</label>
                {
                    !editSite && !newItem
                        ? <NoInput value={ providerObject.site !== "" ? providerObject.site : "Aucun" } color={ color } edit={ true } copy={ providerObject.site !== "" } phone={ false } email={ false } link={ true } handleEdit={ handleEditSite } />
                        : <InputText color={ color } classError={ errorSite !== "" ? "wrong" : "" } value={ site } placeholder="Site" newItem={ newItem } handleChange={ handleChangeSite } handleBlur={ handleEditSite } handleKeyEnter={ handleKeyEnter } />
                }
                {
                    errorSite !== ""
                        && <p className="error">{ errorSite }</p>
                }
                {
                    (newItem && partnerships.length > 1) &&
                    <div className="clearing">
                        <label className="label">Partenaire</label>
                        <InputMultiLinesChoices mini={ true } color={ color } list={ partnerships } listSelected={ partnershipsSelected } handleSelectChoiceList={ handleSelectPartnership } />
                    </div>
                }
                {
                    errorPartnership !== "" && <p className="error">{ errorPartnership }</p>
                }
                <div className="separator" />
                <label className="label">Adresse</label>
                {
                    !editAddress && !newItem
                        ? <NoInput value={ provider.address !== "" ? provider.address : "Aucune" } color={ color } edit={ true } copy={ provider.address !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditAddress } />
                        : <InputText color={ color } classError={ errorAddress !== "" ? "wrong" : "" } value={ address } placeholder="Adresse" newItem={ newItem } handleChange={ handleChangeAddress } handleBlur={ handleEditAddress } handleKeyEnter={ handleKeyEnter } />
                }
                {
                    errorAddress !== ""
                        ? <p className="error">{ errorAddress }</p>
                        : ""
                }
                <label className="label">Complément d'adresse</label>
                {
                    !editAdditional && !newItem
                        ? <NoInput value={ provider.additional !== "" ? provider.additional : "Aucun" } color={ color } edit={ true } copy={ provider.additional !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditAdditional } />
                        : <InputText color={ color } classError={ errorAdditional !== "" ? "wrong" : "" } value={ additional } placeholder="Complément d'adresse" newItem={ newItem } handleChange={ handleChangeAdditional } handleBlur={ handleEditAdditional } handleKeyEnter={ handleKeyEnter } />
                }
                {
                    errorAdditional !== ""
                        ? <p className="error">{ errorAdditional }</p>
                        : ""
                }
                <label className="label left">Code postal</label>
                <label className="label right">Ville</label>
                {
                    !editPostalCode && !newItem
                        ? <NoInput classname="clear left" value={ provider.postalCode !== "" ? provider.postalCode : "Aucun" } color={ color } edit={ true } copy={ provider.postalCode !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditPostalCode } />
                        : <InputText color={ color } classname="left" classError={ errorPostalCode !== "" ? "wrong" : "" } value={ postalCode } placeholder="Code postal" newItem={ newItem } handleChange={ handleChangePostalCode } handleBlur={ handleEditPostalCode } handleKeyEnter={ handleKeyEnter } />
                }
                {
                    !editCity && !newItem
                        ? <NoInput classname="right" value={ provider.city !== "" ? provider.city : "Aucune" } color={ color } edit={ true } copy={ provider.city !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditCity } />
                        : <InputText color={ color } classname="right" classError={ errorCity !== "" ? "wrong" : "" } value={ city } placeholder="Ville" newItem={ newItem } handleChange={ handleChangeCity } handleBlur={ handleEditCity } handleKeyEnter={ handleKeyEnter } />
                }
                <div className="clearing" />
                {
                    errorPostalCode !== ""
                        ? <p className="error left">{ errorPostalCode }</p>
                        : ""
                }
                {
                    errorCity !== ""
                        ? <p className="error right">{ errorCity }</p>
                        : ""
                }
                <label className="label clear">Pays</label>
                {
                    !editCountry && !newItem
                        ? <NoInput value={ provider.country !== "" ? provider.country : "Aucun" } color={ color } edit={ true } copy={ provider.country !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditCountry } />
                        : <InputSelect color={ color } classError={ errorCountry !== "" ? "wrong" : "" } list={ countries } value={ country } newItem={ newItem } handleChange={ handleChangeCountry } handleBlur={ handleEditCountry } />
                }
                {
                    errorCountry !== ""
                        ? <p className="error">{ errorCountry }</p>
                        : ""
                }
                {
                    !newItem && <ListContactForm separator={ true } handleRefresh={ handleRefresh } handleGlobalError={ handleGlobalError } noSearch={ true } attachTo="provider" object={ provider } setGeneralError={ setGlobalError } />
                }
                {
                    newItem
                    && <div>
                        <button className="submit" onClick={ save }>
                            {saving ? "Enregistrement..." : "Enregistrer"}
                            <LoaderCircle display="loader submitLogin" hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                        </button>
                        <button className="cancel" onClick={ handleClose }>Annuler</button>
                        <div className="clearing" />
                    </div>
                }
            </div>
        </div>
    );
}

export default ProviderForm;
