import React, {useContext, useEffect, useState} from "react"
import Account from "../../../stories/_setup/Accounts/Account";
import LoaderCircle from "../../loader/LoaderCircle"
import FormBuilder from "../../../class/tool/FormBuilder"
import SessionContext from "../../../context/SessionContext"
import AccountController from "../../../stories/_setup/Accounts/AccountController";

const AccountForm = props => {
    const rules = [
        { type: "text", value: "ADMIN", master: true },
        { type: "text", value: "MANAGER" },
        { type: "text", value: "ACCOUNTANT" },
        { type: "text", value: "ANALYST" },
        { type: "text", value: "PRODUCTS" },
        { type: "text", value: "TECH" }
    ]
    const rulesDictionary = {
        "ADMIN": "Administrateur",
        "MANAGER": "Manager",
        "ACCOUNTANT": "Comptable",
        "ANALYST": "Analyste",
        "PRODUCTS": "Catalogue",
        "TECH": "Technicien"
    }
    const controller = new AccountController()

    const { setup, handleClose, handleRefresh, object } = props
    const { handleLogout, setGlobalError } = useContext(SessionContext)
    const [ objectToEdit, setObjectToEdit ] = useState(object !== undefined ? object : new Account())
    const [ values, setValues ] = useState(null)
    const [ errors, setErrors ] = useState([])
    const [ saving, setSaving ] = useState(false)
    const [ rows, setRows ] = useState([
        {
            label: "Nom",
            attribute: "lastname",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Dupont",
            emptyText: "Aucun"
        },
        {
            label: "Prénom",
            attribute: "firstname",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Jean",
            emptyText: "Aucun"
        },
        {
            label: "Email",
            attribute: "email",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "jean@dupont.fr",
            emptyText: "Aucune"
        },
        {
            label: "Droits sur le groupe",
            attribute: "companyRule",
            inputType: "tag",
            returnType: "array",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucun droit",
            list: rules,
            dictionary: rulesDictionary,
            titleButton: "Modifier"
        },
        {
            label: "Droits sur la boutique",
            attribute: "rule",
            inputType: "tag",
            returnType: "array",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucun droit",
            list: rules,
            dictionary: rulesDictionary,
            titleButton: "Modifier"
        }
    ])

    const initValues = () => {
        controller.setFormValues(objectToEdit, setValues)
    }
    const handleChange = (attribute, returnType, val, strict = false) => {
        let value = FormBuilder.buildVal(returnType, val)
        let filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "int")
        let index = value

        if (!strict && filtered.length > 0 && filtered[0].list.length > 0) {
            value = parseInt(filtered[0].list[index].id)

            if (filtered[0].list[index].type !== undefined && values[attribute.replace("_id", "_type")] !== undefined) {
                setValues(prev => ({
                    ...prev,
                    [attribute]: value,
                    [attribute.replace("_id", "_type")]: filtered[0].list[index].type
                }))
            }
            else {
                setValues(prev => ({
                    ...prev,
                    [attribute]: value
                }))
            }
        }
        else {
            setValues(prev => ({
                ...prev,
                [attribute]: value
            }))
        }
    }
    const callToSave = () => {
        setGlobalError("");
        reinitAllEdits();
        save();
    }
    const reinitAllEdits = () => {
        setErrors([]);
    }
    const returnUpdates = () => {
        return controller.returnUpdatesFromCompare(objectToEdit, values);
    }
    const check422Errors = errorDatas => {
        setGlobalError("Certaines données sont invalides");

        if(errorDatas !== undefined) {
            var keys = Object.keys(errorDatas);
            var fields = ["reference"];

            for(var item in fields)
                if(keys.includes(fields[item]))
                    defineErrors(fields[item], false);
        }
    }
    const defineErrors = (type, empty) => {
        let errorsTmp = errors.slice();

        switch (type) {
            case "name":
                if(empty) errorsTmp["name"] = "Vous devez saisir un nom";
                else errorsTmp["name"] = "Ce nom n'est pas valide";
                break;
            default: break;
        }

        setErrors(errorsTmp);
    }
    const handleReturnSave = (response, error) => {
        if(error) {
            if(error.response !== undefined) {
                if(error.response.status === 401)
                    handleLogout();
                else if(error.response.status === 422)
                    check422Errors(error.response.data);
            }
            else
                setGlobalError("Une erreur s'est produite lors de l'enregistrement");
        }
        else {
            handleRefresh()
            handleClose()
        }

        setSaving(false);
    }
    const save = () => {
        if (saving) return

        const datas = returnUpdates();

        if(Object.keys(datas).length === 0) return;

        setSaving(true);

        controller._callback = handleReturnSave;
        controller.addAccount(setup, datas);
    }
    const handleSubmit = event => {
        event.preventDefault();
        callToSave();
    }

    useEffect(() => {
        initValues()
    }, [])

    return (
        <div className="contForm">
            <div className="form margin">
                <p className="titleForm noMarginTop center">NOUVEAU COMPTE BACKOFFICE</p>
                {
                    values !== null
                        ?  rows.map((row, index) => (
                            <div key={ index } className="clearing">
                                {
                                    row.noLabel === undefined
                                    && <label className={ row.classnameLabel }>{ row.label }</label>
                                }
                                {
                                    FormBuilder.buildInputByType(row, values, errors, handleChange, null, handleSubmit)
                                }
                            </div>
                        ))
                        : <LoaderCircle display="loader formWait" hide="" strokeWidth="8" stroke="#008C4F" />
                }
                <div className="marge" />
                <button className="submit" onClick={ save }>
                    {saving ? "Enregistrement..." : "Enregistrer"}
                    <LoaderCircle display="loader submitLogin" hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                </button>
                <button className="cancel" onClick={ handleClose }>Annuler</button>
                <div className="clearing" />
            </div>
        </div>
    )
}

export default AccountForm
