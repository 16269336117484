import React, {useContext, useState} from "react";
import { useParams } from "react-router";
import Menu from "./Menu";
import SubMenu from "./SubMenu";
import Header from "./Header";
import ProspectTableContent from "./contents/prospect/ProspectTableContent";
import ProspectCardContent from "./contents/prospect/ProspectCardContent";
import ClientTableContent from "./contents/client/ClientTableContent";
import ClientCardContent from "./contents/client/ClientCardContent";
import PartnershipTableContent from "./contents/partnership/PartnershipTableContent";
import PartnershipCardContent from "./contents/partnership/PartnershipCardContent";
import SettingContent from "./contents/setting/SettingContent";
import SetupContent from "./contents/setup/SetupContent";
import CategoryTableContent from "./contents/category/CategoryTableContent";
import BrandTableContent from "./contents/brand/BrandTableContent";
import ProviderTableContent from "./contents/provider/ProviderTableContent";
import ProviderCardContent from "./contents/provider/ProviderCardContent";
import TaxTableContent from "./contents/tax/TaxTableContent";
import ProductTableContent from "./contents/product/ProductTableContent";
import ProductCardContent from "./contents/product/ProductCardContent";
import ProspectPhaseContent from "./contents/prospecting/ProspectPhaseContent";
import SettingTicketContent from "./contents/settingTicket/SettingTicketContent";
import Error from "../error/Error";
import Warning from "../warning/Warning";
import Valid from "../valid/Valid";
import SessionContext from "../../context/SessionContext";
import "../../css/page/Site.css";

function Site (props) {
    const { page } = props;
    const params = useParams();
    const { globalError, globalWarning, globalValid } = useContext(SessionContext);
    const [showSubMenu, setShowSubMenu] = useState(false);
    const [typeSubMenu, setTypeSubMenu] = useState("");

    const handleSetTypeSubMenu = type => {
        setTypeSubMenu(type);
        setShowSubMenu(!showSubMenu);
    }
    const handleShowSubMenu = () => {
        setShowSubMenu(true);
    }
    const handleHideSubMenu = () => {
        setShowSubMenu(false);
    }
    const goContent = () => {
        switch (page) {
            case "prospect": return <ProspectTableContent />;
            case "prospectCard": return <ProspectCardContent id={ params.id } tab={ params.tab } />;
            case "client": return <ClientTableContent />;
            case "clientCard": return <ClientCardContent id={ params.id } tab={ params.tab } idSubItem={ params.idSubItem } />;
            case "partnership": return <PartnershipTableContent />;
            case "partnershipCard": return <PartnershipCardContent id={ params.id } tab={ params.tab } />;
            case "setting": return <SettingContent tab={ params.tab } />;
            case "setup": return <SetupContent tab={ params.tab } />;
            case "prospecting": return <ProspectPhaseContent tab={ params.tab } />;
            case "settingTicket": return <SettingTicketContent tab={ params.tab } />;
            case "category": return <CategoryTableContent />;
            case "brand": return <BrandTableContent />;
            case "provider": return <ProviderTableContent />;
            case "providerCard": return <ProviderCardContent id={ params.id } tab={ params.tab } />;
            case "tax": return <TaxTableContent />;
            case "product": return <ProductTableContent />;
            case "productCard": return <ProductCardContent id={ params.id } tab={ params.tab } />;
            default: return <p>{ page + " non disponible" }</p>;
        }
    }

    return (
        <div className="site">
            <Menu page={ page } handleHideSubMenu={ handleHideSubMenu } handleSetTypeSubMenu={ handleSetTypeSubMenu } />
            <SubMenu page={ page } showSubMenu={ showSubMenu } typeSubMenu={ typeSubMenu } handleShowSubMenu={ handleShowSubMenu } handleHideSubMenu={ handleHideSubMenu } />
            <Header page={ page } />
            {
                goContent()
            }
            {
                globalValid != ""
                && <Valid text={ globalValid } />
            }
            {
                globalWarning != ""
                && <Warning text={ globalWarning } />
            }
            {
                globalError != ""
                && <Error text={ globalError } />
            }
        </div>
    );
}

export default Site;
