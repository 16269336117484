import React, {useContext, useState} from "react";
import ProductTabs from "./ProductTabs";
import LoaderCircle from "../../../../loader/LoaderCircle";
import ProductCardInformations from "./ProductCardInformations";
import ProductContext from "../../../../../context/ProductContext";
import Error from "../../../../error/Error";
import "../../../../../css/page/cards/Card.css";

function ProductCard (props) {
    const { tab, handleAskDelete, handleGet, sending, setSending, handleManage, show, setShow, handleDelete } = props;
    const { product, generalError } = useContext(ProductContext);
    const [ addPricesList, setAddPricesList ] = useState(false);

    const handleUpdateSending = bool => {
        setSending(bool);
    }

    const goContentCard = () => {
        switch (tab) {
            case "information": return <ProductCardInformations handleUpdateSending={ handleUpdateSending } handleAskDeleteProspect={ handleAskDelete } handleGet={ handleGet } />
            case "stock": return <div />
            default: return <div />
        }
    }

    return (
        <div className="card">
            {
                product !== null
                ? <ProductTabs tab={ tab } sending={ sending } handleManage={ handleManage } show={ show } handleDelete={ handleDelete } />
                : generalError === "" && <LoaderCircle display="loader logWait" hide="" strokeWidth="5" stroke="#008C4F" />
            }
            {
                product !== null
                ? goContentCard()
                : generalError !== "" && <Error text={ generalError } />
            }
        </div>
    );
}

export default ProductCard;
