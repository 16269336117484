import Entity from "../../../class/abstract/Entity";
import Price from "../Prices/Price";

class Product extends Entity {
    name = "";
    reference = "";
    description = "";
    stockQuantityAlert = 0;
    hasSerial = false;
    storagePlace = "";
    partnership_id = 0;
    partnership_name = "";
    provider_id = 0;
    provider_name = "";
    category_id = 0;
    category_name = "";
    brand_id = 0;
    brand_name = "";
    tax_id = 0;
    price = 0;
    maintenancePrice = 0;
    prices = [];
    serials = [];
    created_at = "";
    updated_at = "";
    deleted_at = "";

    constructor(datas = {}) {
        super(datas);

        this.setAttributes(datas);
    }

    setAttributes(datas) {
        this.id = datas.id !== undefined ? datas.id : 0;
        this.name = datas.name !== undefined ? datas.name : "";
        this.reference = datas.reference !== undefined ? datas.reference : "";
        this.description = (datas.description !== undefined && datas.description !== null) ? datas.description : "";
        this.stockQuantityAlert = (datas.stockQuantityAlert !== undefined && datas.stockQuantityAlert !== null) ? datas.stockQuantityAlert : 0;
        this.hasSerial = datas.hasSerial !== undefined ? datas.hasSerial === 1 : false;
        this.storagePlace = (datas.storagePlace !== undefined && datas.storagePlace !== null) ? datas.storagePlace : "";
        this.partnership_id = datas.partnership_id !== undefined ? datas.partnership_id : 0;
        this.partnership_name = datas.partnership_name !== undefined ? datas.partnership_name : "";
        this.provider_id = datas.provider_id !== undefined ? datas.provider_id : 0;
        this.provider_name = datas.provider_name !== undefined ? datas.provider_name : "";
        this.category_id = datas.category_id !== undefined ? datas.category_id : 0;
        this.category_name = datas.category_name !== undefined ? datas.category_name : "";
        this.brand_id = datas.brand_id !== undefined ? datas.brand_id : 0;
        this.brand_name = datas.brand_name !== undefined ? datas.brand_name : "";
        this.tax_id = datas.tax_id !== undefined ? datas.tax_id : 0;
        this.price = datas.price !== undefined ? datas.price : 0;
        this.maintenancePrice = datas.maintenancePrice !== undefined ? datas.maintenancePrice : 0;
        this.serials = datas.serials !== undefined ? datas.serials : [];
        this.created_at = datas.created_at !== undefined ? datas.created_at : "";
        this.updated_at = datas.updated_at !== undefined ? datas.updated_at : "";
        this.deleted_at = datas.deleted_at !== undefined ? datas.deleted_at : "";

        if (datas.prices)
            for (var i in datas.prices)
                this.prices.push(new Price(datas.prices[i]));
    }
}

export default Product;
