import Entity from "../../../class/abstract/Entity";

class Brand extends Entity {
    name = "";
    reference = "";
    partnership_id = 0;
    partnership_name = "";

    constructor(datas = {}) {
        super(datas);

        this.setAttributes(datas);
    }

    setAttributes(datas) {
        this.id = datas.id !== undefined ? datas.id : 0;
        this.name = datas.name !== undefined ? datas.name : "";
        this.reference = datas.reference !== undefined ? datas.reference : "";
        this.partnership_id = datas.partnership_id !== undefined ? datas.partnership_id : 0;
        this.partnership_name = datas.partnership_name !== undefined ? datas.partnership_name : "";
    }
}

export default Brand;
