import React from "react"

class BarcodeBuilder {
    static buildRandomBarcode = (type, storeSettings) => {
        let barcode = ""
        let prefixes = JSON.parse(storeSettings.barcodePrefixes)
        let checksum = 0
        let weightCode = 3

        barcode = prefixes[type] + BarcodeBuilder.randomNumberWithLength(10)

        let barcodeInArray = barcode.split('')

        for (let char in barcodeInArray) {
            if(weightCode === 1)
                weightCode = 3
            else
                weightCode = 1

            checksum += (parseInt(barcodeInArray[char]) * weightCode)
        }

        let controlKey = 10 - (checksum % 10)

        if(controlKey === 10)
            controlKey = 0

        barcode += controlKey

        return barcode
    }
    static randomNumberWithLength = length => {
        let text = "";
        let possible = "0123456789";

        for (let i = 0; i < length; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }
}

export default BarcodeBuilder;
