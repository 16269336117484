import React from "react";
import PriceForm from "../../form/price/PriceForm";

function FormPrice (props) {
    const { newItem, object, product, handleClose, handleDelete, handleRefresh } = props;

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox newItemSmall">
                    <PriceForm handleClose={ handleClose } handleDelete={ handleDelete } newItem={ newItem } handleRefresh={ handleRefresh } object={ object } product={ product } />
                </div>
            </div>
        </div>
    );
}

export default FormPrice;
