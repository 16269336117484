import React from "react";

function LessIcon (props) {
    const { fill, click } = props;

    return (
        <svg width="1em" height="1em" viewBox="0 0 88 88" fill={ fill } onClick={ click }>
            <path d="M86 32.5H2a2 2 0 00-2 2v19a2 2 0 002 2h84a2 2 0 002-2v-19a2 2 0 00-2-2z" />
        </svg>
    );
}

export default LessIcon;
