import React from "react";
import AccessIcon from "../../../../icons/AccessIcon";
import VerticalMove from "../../../../icons/VerticalMove";

function SetupPhaseLine (props) {
    const isDark = window.matchMedia('(prefers-color-scheme: dark)');
    const color = isDark.matches ? "#FFFFFF" : "#1D1D1D";
    const { phase, handleClick } = props;

    return (
        <tr className="line">
            <td className="name left">{ phase.name }</td>
            <td className="action">
                <div className="access" onMouseDown={ () => { console.log("DOWN..") } } onMouseUp={ () => { console.log("UP !") } }>
                    <VerticalMove fill={ color } />
                </div>
            </td>
            <td className="action">
                <div className="access" onClick={ () => { handleClick(phase.id) } }>
                    <AccessIcon fill={ color } />
                </div>
            </td>
        </tr>
    );
}

export default SetupPhaseLine;
