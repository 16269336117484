import React from "react";
import ClientForm from "../../form/client/ClientForm";

function NewClient (props) {
    const { handleCloseNewClient } = props;

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox newItem">
                    <ClientForm handleCloseNewClient={ handleCloseNewClient } newItem={ true } />
                </div>
            </div>
        </div>
    );
}

export default NewClient;
