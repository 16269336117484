import React, {useEffect, useState} from "react";

import "../../../../../css/page/setup/SetupInstallationPhases.css";

function SetupInstallationPhases (props) {
    const { setup } = props;
    const [ first, setFirst ] = useState(true);
    const [ phases, setPhases ] = useState([]);

    const buildPhases = () => {
        setFirst(false);

        var fullDone = true;
        var counter = 1;
        var lastState = false;
        var stateLine = "";
        var statePhase = "";
        var phasesList = [
            {
                key: counter++,
                class: "unselected",
                item: "phase",
                name: "Début",
                state: "done",
                id: "0"
            }
        ];

        // loop
        for (var i in setup.installation_phases) {
            stateLine = (setup.installation_phases[i].execution_date !== null ? "done" : (lastState ? "done" : ""));
            statePhase = (setup.installation_phases[i].execution_date !== null ? "done" : (lastState ? "inProgress" : ""));

            if (stateLine === "" && i === 0) stateLine = "done";

            phasesList.push(
                {
                    key: counter++,
                    item: "line",
                    name: "",
                    description: "",
                    state: stateLine,
                    id: "0"
                },
                {
                    key: counter++,
                    class: "",
                    item: "phase",
                    name: setup.installation_phases[i].name,
                    description: setup.installation_phases[i].description,
                    state: statePhase,
                    id: setup.installation_phases[i].id
                }
            );

            if (setup.installation_phases[i].execution_date !== null)
                lastState = true;
            else
                fullDone = false;
        }

        phasesList.push(
            {
                key: counter++,
                item: "line",
                name: "",
                description: "",
                state: (fullDone ? "done" : ""),
                id: "0"
            },
            {
                key: counter++,
                class: "unselected",
                item: "phase",
                name: "Terminé",
                description: "",
                state: (fullDone ? "done" : ""),
                id: "0"
            }
        );

        setPhases(phasesList);
    }

    useEffect(() => {
        if (first)
            buildPhases();
    })

    return (
        <div className="installationPhases">
            {
                phases.map(phase => (
                    phase.item === "line"
                    ? <div key={ phase.key } className={ "line " + phase.state } />
                    : <div key={ phase.key } className={ "phase " + phase.state }>
                        <span className={ "puce " + phase.class } />
                        <div className="wrapDescription">
                            <p className="name">{ phase.name }</p>
                            <p className="description">{ phase.description }</p>
                        </div>
                    </div>
                ))
            }
        </div>
    );
}

export default SetupInstallationPhases;
