import React, {useContext, useEffect, useState} from "react";
import SettingTabs from "./SettingTabs";
import UserForm from "../../../form/user/UserForm";
import User from "../../../../stories/_auth/Users/User";
import UpdatePwdUser from "../../../overbox/user/UpdatePwdUser";
import Error from "../../../error/Error";
import Valid from "../../../valid/Valid";

import SessionContext from "../../../../context/SessionContext";

import "../../../../css/page/Content.css";

function SettingContent (props) {
    const { handleLogout } = useContext(SessionContext);
    const { tab } = props;
    const [ sending, setSending ] = useState(false);
    const [ user, setUser ] = useState(new User(JSON.parse(localStorage.getItem("user"))));
    const [ boxUpdatePwd, setBoxUpdatePwd ] = useState(false);
    const [ generalError, setGeneralError ] = useState("");
    const [ generalValid, setGeneralValid ] = useState("");

    const handleBoxUpdatePwd = () => {
        setBoxUpdatePwd(!boxUpdatePwd);
    }

    const handleError = text => {
        setGeneralError(text);
    }

    const handleValid = text => {
        setGeneralValid(text);
    }

    const goContent = () => {
        switch (tab) {
            case "information":
                return <UserForm handleCloseFormUser={ null } handleBoxUpdatePwd={ handleBoxUpdatePwd } user={ user } />;
            default:
                return <p>{ tab }</p>;
        }
    }

    useEffect(() => {
        document.title = "HIVY - Réglages";
    });

    return (
        <div className="content">
            <SettingTabs tab={ tab } sending={ sending } />
            {
                generalError !== "" && <Error text={ generalError } />
            }
            {
                generalValid !== "" && <Valid text={ generalValid } />
            }
            {
                goContent()
            }
            {
                boxUpdatePwd && <UpdatePwdUser handleClose={ handleBoxUpdatePwd } handleError={ handleError } handleValid={ handleValid } user={ user } />
            }
        </div>
    );
}

export default SettingContent;
