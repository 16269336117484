import React from "react";
import EditIcon from "../../../../icons/EditIcon";

Object.assign(Number.prototype, {
    numberFormat(decimals, dec_point, thousands_sep) {
        // Strip all characters but numerical ones.
        var number = (this + '').replace(/[^0-9+\-Ee.]/g, '');
        var n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
            dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
            s = '',
            toFixedFix = function (n, prec) {
                var k = Math.pow(10, prec);
                return '' + Math.round(n * k) / k;
            };
        // Fix for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
    }
});

function PriceProduct (props) {
    const { object, edit, handleEdit } = props;

    return (
        <div className="pricesList">
            <p className="name">{ object.prices_list.name }</p>
            <div className="icons">
                {
                    edit && <EditIcon handleClick={ handleEdit } />
                }
            </div>
            <div className="keys">
                <p className="key">{ object.tax.name }</p>
                <p className="key">{ object.price.numberFormat(2, ",", " ") }</p>
                <p className="key">{ object.maintenancePrice.numberFormat(2, ",", " ") }</p>
            </div>
            <div className="headers">
                <p className="head">TVA</p>
                <p className="head">EURO</p>
                <p className="head">€ / MOIS</p>
            </div>
        </div>
    );
}

export default PriceProduct;
