import Entity from "../../../class/abstract/Entity";
import Contact from "../../_client/Contacts/Contact";

class Provider extends Entity {
    name = "";
    reference = "";
    site = "";
    address = "";
    additional = "";
    postalCode = "";
    city = "";
    country = "";
    partnership_id = 0;
    partnership_name = "";
    contacts = [];

    constructor(datas = {}) {
        super(datas);

        this.setAttributes(datas);
    }

    setAttributes(datas) {
        this.id = datas.id !== undefined ? datas.id : 0;
        this.name = datas.name !== undefined ? datas.name : "";
        this.reference = datas.reference !== undefined ? datas.reference : "";
        this.site = datas.site !== undefined ? datas.site : "";
        this.address = datas.address !== undefined ? datas.address : "";
        this.additional = datas.additional !== undefined ? datas.additional : "";
        this.postalCode = datas.postalCode !== undefined ? datas.postalCode : "";
        this.city = datas.city !== undefined ? datas.city : "";
        this.country = datas.country !== undefined ? datas.country : "";
        this.partnership_id = datas.partnership_id !== undefined ? datas.partnership_id : 0;
        this.partnership_name = datas.partnership_name !== undefined ? datas.partnership_name : "";

        if (datas.contacts !== undefined && datas.contacts.length > 0)
            for (var i in datas.contacts)
                this.contacts.push(new Contact(datas.contacts[i]));
    }
}

export default Provider;
