import React from "react";

function CancelIcon (props) {
    const { fill, classname, click } = props;

    return (
        <svg height="1em" viewBox="0 0 320.591 320.591" width="1em" xmlns="http://www.w3.org/2000/svg" fill={ fill } className={ classname } onClick={ click !== undefined ? click : () => {} }>
            <path d="M30.391 318.583a30.37 30.37 0 01-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 01-21.256 7.288z" />
            <path d="M287.9 318.583a30.37 30.37 0 01-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 01-23.078 7.288z" />
        </svg>
    );
}

export default CancelIcon;
