import React from "react";
import PwdForm from "../../form/user/PwdForm";

function UpdatePwdUser (props) {
    const { handleClose, handleError, handleValid, user } = props;

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox password">
                    <PwdForm handleClose={ handleClose } handleError={ handleError } handleValid={ handleValid } user={ user } newItem={ true } />
                </div>
            </div>
        </div>
    );
}

export default UpdatePwdUser;
