import React, {useContext, useState} from "react";
import ContractForm from "../../form/contract/ContractForm";
import LoaderCircle from "../../loader/LoaderCircle";
import ContractFormSteps from "../../form/contract/ContractFormSteps";
import Store from "../../../stories/_client/Stores/Store";
import ContactController from "../../../stories/_client/Contacts/ContactController";
import AddressController from "../../../stories/_client/Addresses/AddressController";
import StoreController from "../../../stories/_client/Stores/StoreController";
import SessionContext from "../../../context/SessionContext";

import "../../../css/form/NewContract.css";
import ListContentContractForm from "../../form/contract/ListContentContractForm";

function NewContract (props) {
    const { handleClose } = props;
    const { handleLogout, setGlobalError } = useContext(SessionContext);
    const [ step, setStep ] = useState(1);
    const [ saving, setSaving ] = useState(false);
    const [ showConfirmClose, setShowConfirmClose ] = useState(false);
    const [ object, setObject ] = useState(new Store());


    // Performs

    const previous = () => {
        if (step > 1)
            setStep((step - 1));
        else
            handleShowConfirmClose();
    }

    const next = () => {
        if (step === 1)
            validationStep1();
        else if (step === 2)
            validationStep2();
    }

    const refreshObject = object => {
        setObject(object);
        setGlobalError("");
    }

    const handleShowConfirmClose = () => {
        setShowConfirmClose(!showConfirmClose);
    }

    const closeNewContract = () => {
        // remove new contacts and addresses before close
        let i;
        let controller;

        if (object.contacts.length > 0) {
            controller = new ContactController();

            for (i in object.contacts)
                controller.delete(object.contacts[i], true);
        }

        if (object.addresses.length > 0) {
            controller = new AddressController();

            for (i in object.addresses)
                controller.delete(object.addresses[i], true);
        }

        handleClose();
    }

    const validationStep1 = () => {
        try {
            new StoreController().checkValues(object);
        }
        catch (e) {
            return;
        }

        setStep(2);
    }

    const validationStep2 = () => {
        if (object.contacts.length === 0) {
            setGlobalError("Vous devez saisir au moins un contact");
            return;
        }

        setGlobalError("");
        setStep(3);
    }

    const save = () => {
        if (saving) return;

        setSaving(true);

        const user = JSON.parse(localStorage.getItem("user"))
        const controller = new StoreController();
        controller._callback = handleReturnSave;

        let datas = {
            partnership_id: object.partnership_id,
            name: object.name,
            prospect_phase_id: object.prospect_phase_id,
            acquisition_channel_id: object.acquisition_channel_id,
            creator_id: user.id,
            assigned_to_id: object.assigned_to_id,
            assigned_to_type: object.assigned_to_type,
            contacts: object.contacts,
            addresses: object.addresses
        }

        if (object.company_id > 0) datas.company_id = object.company_id;
        else datas.company_name = object.company_name;

        controller.post(datas);
    }
    const handleReturnSave = (response, error) => {
        setSaving(false);

        if(error) {
            if(error.response !== undefined) {
                console.log("error.response", error.response);

                if(error.response.status === 401)
                    handleLogout();

                setGlobalError("Une erreur s'est produite lors de l'enregistrement");
            }
            else
                setGlobalError("Une erreur s'est produite lors de l'enregistrement");
        }
        else
            window.location = "/prospect/" + response.data.id + "/information";
    }


    // Render

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox newItem NewContract">
                    <p className="title">AJOUTER UN CONTRAT</p>
                    <ContractFormSteps step={ step } />
                    <div className="elevator">
                        {
                            step === 1
                            && <ContractForm handleClose={handleClose} />
                        }
                        {
                            step === 2
                            && <div className="form margin">
                                <ListContentContractForm object={ object } setObject={ refreshObject } />
                            </div>
                        }
                    </div>
                    <div className="form margin">
                        <button className="submit" onClick={ next }>
                            {saving ? "Enregistrement..." : (step < 3 ? "Suivant" : "Enregistrer")}
                            <LoaderCircle display="loader submitLogin" hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                        </button>
                        <button className="cancel" onClick={ previous }>
                            {
                                step === 1
                                    ? "Annuler"
                                    : "Retour"
                            }
                        </button>
                        <div className="clearing" />
                    </div>
                </div>
                {
                    showConfirmClose
                    && <div className="overlayer hover">
                        <div className="wrapOverbox hover">
                            <div className="overbox hover yesOrNo">
                                <div className="form margin">
                                    <p className="text">Êtes-vous sûr de vouloir abandonner la saisie du contrat ?</p>
                                    <button className="delete block" onClick={ closeNewContract }>Confirmer</button>
                                    <button className="cancel" onClick={ handleShowConfirmClose }>Annuler</button>
                                    <div className="clearing" />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default NewContract;
