import React, {useEffect, useState} from "react";
import UserGroupForm from "../../form/userGroup/UserGroupForm";
import LoaderCircle from "../../loader/LoaderCircle";
import UserGroupController from "../../../stories/_auth/UserGroups/UserGroupController";

function FormUserGroup (props) {
    const { handleCloseFormUserGroup, handleBoxDeleteUserGroup, handleUpdate, newItem, idUserGroup } = props;
    const [ first, setFirst ] = useState(true);
    const [ userGroup, setUserGroup ] = useState(null);

    const getUser = () => {
        const controller = new UserGroupController();
        controller._callback = handleGetUser;
        controller.show(idUserGroup);
    }
    const handleGetUser = (object, error) => {
        setFirst(false);
        setUserGroup(object);
    }

    useEffect(() => {
        if(first && !newItem && idUserGroup !== undefined)
            getUser();
    });

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox newItem">
                    {
                        newItem || (!newItem && userGroup !== null)
                        ? <UserGroupForm handleCloseFormUserGroup={ handleCloseFormUserGroup } handleBoxDeleteUserGroup={ handleBoxDeleteUserGroup } handleUpdate={ handleUpdate } newItem={ newItem } userGroup={ userGroup } />
                        : <LoaderCircle display="loader logWait" hide="" strokeWidth="5" stroke="#008C4F" />
                    }
                </div>
            </div>
        </div>
    );
}

export default FormUserGroup;
