import React from "react";
import moment from "moment";
import { DatePicker } from "antd";
import CheckIcon from "../../icons/CheckIcon";
import Search2Icon from "../../icons/Search2Icon";

import "antd/dist/antd.css";

function InputText (props) {
    const { color, type, classname, classError, value, placeholder, newItem, focus, readonly, autocomplete, handleChange, handleBlur, handleKeyEnter, handleSearch } = props;

    return (
        <div className={ "inputText " + (classname !== undefined ? classname : "") }>
            {
                type === "dateTimePicker"
                    ? <DatePicker className="customDatePicker" format="DD/MM/YYYY HH:mm" showTime={{ defaultValue: moment('09:00', 'HH:mm') }} placeholder={placeholder} />
                    : <input type={ type !== undefined ? type : "text" } className={ (newItem ? "extend " : "") + classError } value={ value } placeholder={ placeholder } onChange={ handleChange } onBlur={ newItem ? handleBlur : null } onKeyDown={ handleKeyEnter } autoFocus={ !newItem || focus !== undefined  } readOnly={ (readonly !== undefined && readonly) } autoComplete={ (autocomplete !== undefined ? "true" : "false") } />
            }
            {
                !newItem && <CheckIcon fill={ color } handleClick={ handleBlur } />
            }
            {
                handleSearch !== undefined && <Search2Icon fill={ color } classname={ "searchInput " + (!newItem ? "decal" : "") } handleClick={ handleSearch } />
            }
        </div>
    );
}

export default InputText;
