import React, {useContext, useEffect, useState} from "react";
import PartnershipSearchBar from "./table/PartnershipSearchBar";
import PartnershipTable from "./table/PartnershipTable";
import PartnershipController from "../../../../stories/_auth/Partnerships/PartnershipController";
import PartnershipToolsBar from "./table/PartnershipToolsBar";
import NewPartnership from "../../../overbox/partnership/NewPartnership";

import SessionContext from "../../../../context/SessionContext";
import SearchContext from "../../../../context/SearchContext";

import "../../../../css/page/Content.css";

function PartnershipTableContent () {
    const { handleLogout } = useContext(SessionContext);
    const [ first, setFirst ] = useState(true);
    const [ generalError, setGeneralError ] = useState("");
    const [ list, setList ] = useState([]);
    const [ inputSearch, setInputSearch ] = useState("");
    const [ initialLoading, setInitialLoading ] = useState(true);
    const [ loading, setLoading ] = useState(true);
    const [ newPartnershipForm, setNewPartnershipForm ] = useState(false);
    const valueSearchContext = {
        generalError,
        list,
        inputSearch,
        initialLoading,
        loading
    };

    const handleChangeInputSearch = event => {
        setInputSearch(event.currentTarget.value);
        setLoading(true);
        getPartnerships();
    }

    const newPartnership = () => {
        setNewPartnershipForm(true);
    }
    const closeNewPartnership = () => {
        setNewPartnershipForm(false);
    }

    const getPartnerships = () => {
        const controller = new PartnershipController();
        controller._callback = handleGetPartnerships;
        controller.index(inputSearch);
    }
    const handleGetPartnerships = (list, error) => {
        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else
                setGeneralError("Impossible de récupérer les données");
        }

        setFirst(false);
        setInitialLoading(false);
        setLoading(false);
        setList(list);
    }

    useEffect(() => {
        document.title = "HIVY - Rechercher un partenaire";

        if(first)
            getPartnerships();
    });

    return (
        <div className="content">
            <SearchContext.Provider value={valueSearchContext}>
                {
                    newPartnershipForm && <NewPartnership handleCloseNewPartnership={ closeNewPartnership } />
                }
                <PartnershipToolsBar handleNewPartnership={ newPartnership } />
                <PartnershipSearchBar handleChangeInputSearch={ handleChangeInputSearch } />
                <PartnershipTable />
            </SearchContext.Provider>
        </div>
    );
}

export default PartnershipTableContent;
