import React from "react";
import ContactForm from "../../form/contact/ContactForm";

function FormContact (props) {
    const { contact, handleClose, handleDelete, handleRefresh, attachTo, setGeneralError, object, setObject } = props;

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox newItem">
                    <ContactForm handleClose={ handleClose } handleDelete={ handleDelete } handleRefresh={ handleRefresh } newItem={ contact === null } contact={ contact } attachTo={ attachTo } setGeneralError={ setGeneralError } object={ object } setObject={ setObject } />
                </div>
            </div>
        </div>
    );
}

export default FormContact;
