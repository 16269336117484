import React from "react";
import ProductForm from "../../form/product/ProductForm";

function FormProduct (props) {
    const { newItem, handleClose, handleDelete, handleRefresh, partnershipID } = props;

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox newItem">
                    <ProductForm handleClose={ handleClose } handleDelete={ handleDelete } handleRefresh={ handleRefresh } newItem={ newItem } partnershipID={ partnershipID } />
                </div>
            </div>
        </div>
    );
}

export default FormProduct;
