import React from "react";

function NewProspectStep3 (props) {
    const { fill, classname } = props;

    return (
        <svg height="1em" viewBox="0 0 512 512" width="1em" fill={ fill !== undefined ? fill : "" } className={ classname !== undefined ? classname : "" }>
            <path d="M256 75c-24.813 0-45 20.188-45 45s20.188 45 45 45 45-20.188 45-45-20.188-45-45-45zm0 0" />
            <path d="M256 0c-66.168 0-120 53.832-120 120 0 22.691 6.371 44.797 18.43 63.926L256 346l101.57-162.074C369.63 164.796 376 142.69 376 120 376 53.832 322.168 0 256 0zm0 195c-41.352 0-75-33.648-75-75s33.648-75 75-75 75 33.648 75 75-33.648 75-75 75zm0 0M182.996 512h145.957l-11.535-91H194.242zm0 0M197.992 391h50.914l-42.488-67.387zm0 0M343.828 391h118.176l-37.5-90h-92.219zm0 0M49.996 391h117.766l11.25-90H87.496zm0 0M263.094 391h50.476l-8.527-66.523zm0 0M164.012 421H37.496L0 512h152.766zm0 0M474.504 421H347.672l11.539 91H512zm0 0" />
        </svg>
    );
}

export default NewProspectStep3;
