import React, {useContext} from "react";
import {Link} from "react-router-dom";
import LoaderCircle from "../../../../loader/LoaderCircle";

import PartnershipContext from "../../../../../context/PartnershipContext";

import "../../../../../css/page/cards/Tabs.css";

function PartnershipTabs (props) {
    const { tab, sending, addUser, addUserGroup, addStorage } = props;
    const { partnership } = useContext(PartnershipContext);

    return (
        <div className="tabs">
            <p className="title">{ partnership.company }</p>
            {
                tab === "storage" && <p className="button" onClick={ addStorage }>Ajouter un établissement</p>
            }
            {
                tab === "user" && <p className="button" onClick={ addUser }>Ajouter un utilisateur</p>
            }
            {
                tab === "group" && <p className="button" onClick={ addUserGroup }>Ajouter un groupe</p>
            }
            <nav>
                <Link to={ "/partnership/" + partnership.id + "/information" }><p className={ "tab " + (tab === "information" ? "selected" : "") }>Informations</p></Link>
                <Link to={ "/partnership/" + partnership.id + "/storage" }><p className={ "tab " + (tab === "storage" ? "selected" : "") }>Établissements</p></Link>
                <Link to={ "/partnership/" + partnership.id + "/user" }><p className={ "tab " + (tab === "user" ? "selected" : "") }>Utilisateurs</p></Link>
                <Link to={ "/partnership/" + partnership.id + "/group" }><p className={ "tab " + (tab === "group" ? "selected" : "") }>Groupes</p></Link>
                <Link to={ "/partnership/" + partnership.id + "/setting" }><p className={ "tab " + (tab === "setting" ? "selected" : "") }>Réglages</p></Link>
                <div className="clearing" />
            </nav>
            {
                sending && <LoaderCircle display="loader restPUT" hide="" strokeWidth="10" stroke="#008C4F" />
            }
            <div className="clearing" />
        </div>
    );
}

export default PartnershipTabs;
