import React from "react";
import CategoryForm from "../../form/category/CategoryForm";

function FormCategory (props) {
    const { newItem, category, handleClose, handleDelete } = props;

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox newItemSmall">
                    <CategoryForm handleClose={ handleClose } handleDelete={ handleDelete } newItem={ newItem } category={ category } />
                </div>
            </div>
        </div>
    );
}

export default FormCategory;
