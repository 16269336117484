import React from "react";

function CheckIcon (props) {
    const { fill, handleClick, classname } = props;

    return (
        <svg height="1em" viewBox="0 0 24 24" width="1em" className={ classname } fill={ fill } onClick={ handleClick }>
            <path d="M9.707 19.121a.997.997 0 01-1.414 0l-5.646-5.647a1.5 1.5 0 010-2.121l.707-.707a1.5 1.5 0 012.121 0L9 14.171l9.525-9.525a1.5 1.5 0 012.121 0l.707.707a1.5 1.5 0 010 2.121z" />
        </svg>
    );
}

export default CheckIcon;
