import React from "react";
import AccessIcon from "../../../../icons/AccessIcon";

function TaxLine (props) {
    const isDark = window.matchMedia('(prefers-color-scheme: dark)');
    const color = isDark.matches ? "#FFFFFF" : "#1D1D1D";
    const { tax, handleUpdate } = props;

    return (
        <tr className="line">
            <td className="name left">{ tax.name }</td>
            <td className="reference left">{ tax.reference }</td>
            <td className="partnership left">{ tax.partnership_name }</td>
            <td className="action">
                <div className="access" onClick={ () => { handleUpdate(tax.id) } }>
                    <AccessIcon fill={ color } />
                </div>
            </td>
        </tr>
    );
}

export default TaxLine;
