import Rest from "./Rest";

class GoogleLocation {
    _APIKey = "AIzaSyBrf7eSk8vH6P3jB-XtjLsiQ25PfSM2HRA";
    #_rest = new Rest();
    _callback = null;

    searchAddress(input) {
        this.#_rest._external = true;
        this.#_rest.sendQuery(
            'GET',
            'https://maps.googleapis.com/maps/api/place/findplacefromtext/json?key=' + this._APIKey + '&inputtype=textquery&input=' + encodeURIComponent(input),
            {},
            this.handleSearchAddress);
    }
    handleSearchAddress = (response, error) => {
        this._callback(response, error);
    }
}

export default GoogleLocation;
