import Entity from "../../../class/abstract/Entity";

class PricesList extends Entity {
    name = "";
    reference = "";
    partnership_id = 0;
    color = "#008C4F";
    default = false;
    created_at = "";
    updated_at = "";
    deleted_at = "";

    constructor(datas = {}) {
        super(datas);

        this.setAttributes(datas);
    }

    setAttributes(datas) {
        this.id = datas.id !== undefined ? datas.id : 0;
        this.name = datas.name !== undefined ? datas.name : "";
        this.reference = datas.reference !== undefined ? datas.reference : "";
        this.color = datas.color !== undefined ? datas.color : "#008C4F";
        this.partnership_id = datas.partnership_id !== undefined ? datas.partnership_id : 0;
        this.default = datas.default !== undefined ? datas.default === 1 : false;
        this.created_at = datas.created_at !== undefined ? datas.created_at : "";
        this.updated_at = datas.updated_at !== undefined ? datas.updated_at : "";
        this.deleted_at = datas.deleted_at !== undefined ? datas.deleted_at : "";
    }
}

export default PricesList;
