import React from "react";
import AccessIcon from "../../../../icons/AccessIcon";

function BrandLine (props) {
    const isDark = window.matchMedia('(prefers-color-scheme: dark)');
    const color = isDark.matches ? "#FFFFFF" : "#1D1D1D";
    const { brand, handleUpdate } = props;

    return (
        <tr className="line">
            <td className="name left">{ brand.name }</td>
            <td className="reference left">{ brand.reference }</td>
            <td className="partnership left">{ brand.partnership_name }</td>
            <td className="action">
                <div className="access" onClick={ () => { handleUpdate(brand.id) } }>
                    <AccessIcon fill={ color } />
                </div>
            </td>
        </tr>
    );
}

export default BrandLine;
