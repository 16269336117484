import React from "react";
import LoaderCircle from "../../loader/LoaderCircle";

function ProspectToClient (props) {
    const { store, handleClose, toClient, sending } = props;

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox yesOrNo">
                    <div className="form margin">
                        <p className="text">Êtes-vous sûr de vouloir basculer le prospect { store.name } en client ?</p>
                        <button className="submit block" onClick={ toClient }>
                            {sending ? "Bascule en cours..." : "Basculer"}
                            <LoaderCircle display="loader submitLogin" hide={!sending ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                        </button>
                        <button className="cancel" onClick={ handleClose }>Annuler</button>
                        <div className="clearing" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProspectToClient;
