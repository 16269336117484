import React from "react";
import ClientForm from "../../../../form/client/ClientForm";
import "../../../../../css/page/cards/CardInformations.css";

function ClientCardInformations (props) {
    const { handleUpdateSending, handleAskDeleteClient, handleGetClient } = props;

    return (
        <div className="cardInformations">
            <div className="contForm">
                <ClientForm handleUpdateSending={ handleUpdateSending } handleAskDeleteClient={ handleAskDeleteClient } handleGetClient={ handleGetClient } />
            </div>
            <div className="contContracts">

            </div>
        </div>
    );
}

export default ClientCardInformations;
