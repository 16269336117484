import Rest from "../../../class/tool/Rest";
import PartnershipType from "./PartnershipType";

class PartnershipTypeController {
    _rest = new Rest();
    _callback = null;
    _baseURL = "partnershipTypes";

    index(input = "") {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + (input !== "" ? "?input=" + input : ""),
            {},
            this.handleIndex);
    }
    handleIndex = (response, error) => {
        let listObjects = [];

        if(response.data !== undefined) {
            const data = response.data.data;

            for(var item in data)
                listObjects.push( new PartnershipType(data[item]));
        }

        this._callback(listObjects, error);
    }

    show(id) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + '/' + id,
            {},
            this.handleShow);
    }
    handleShow = (response, error) => {
        const object = !error ? new PartnershipType(response.data) : null;
        this._callback(object, error);
    }

    post(object) {
        const objectToSend = JSON.parse(JSON.stringify(object));

        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURL,
            objectToSend,
            this.handlePost()
        );
    }
    handlePost = (response, error) => {
        this._callback(response, error);
    }

    put(object) {
        const objectToSend = JSON.parse(JSON.stringify(object));

        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'PUT',
            '/' + this._baseURL + '/' + objectToSend.id,
            objectToSend,
            this.handlePut());
    }
    handlePut = (response, error) => {
        this._callback(response, error);
    }

    delete(object) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'DELETE',
            '/' + this._baseURL + '/' + object.id,
            {},
            this._callback);
    }
}

export default PartnershipTypeController;
