import React from "react";
import {Link} from "react-router-dom";
import AccessIcon from "../../../../icons/AccessIcon";

function PartnershipLine (props) {
    const isDark = window.matchMedia('(prefers-color-scheme: dark)');
    const color = isDark.matches ? "#FFFFFF" : "#1D1D1D";
    const { partnership } = props;

    return (
        <tr className="line">
            <td className="name left">{ partnership.company }</td>
            <td className="postalCode left">{ partnership.postalCode }</td>
            <td className="city left">{ partnership.city }</td>
            <td className="type left">{ partnership.partnership_type_name }</td>
            <td className="nbUser right">{ partnership.nb_users }</td>
            <td className="action">
                <Link to={ "/partnership/" + partnership.id + "/information" }>
                    <div className="access">
                        <AccessIcon fill={ color } />
                    </div>
                </Link>
            </td>
        </tr>
    );
}

export default PartnershipLine;
