import React, {useContext} from "react";
import SessionContext from "../../context/SessionContext";

import "../../css/valid/Valid.css";

function Valid (props) {
    const { text } = props;
    const { setGlobalValid } = useContext(SessionContext);


    // Perform

    const remove = () => {
        setGlobalValid("");
    }


    // Render

    return (
        <p className="globalValid" onClick={ remove }>{ text }</p>
    );

}

export default Valid;
