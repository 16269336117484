import React from "react";
import AddressForm from "../../form/address/AddressForm";

function FormAddress (props) {
    const { address, handleClose, handleDelete, handleRefresh, object, setObject } = props;

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox newItem">
                    <AddressForm handleClose={ handleClose } handleDelete={ handleDelete } handleRefresh={ handleRefresh } newItem={ address === null } address={ address } object={ object } setObject={ setObject } />
                </div>
            </div>
        </div>
    );
}

export default FormAddress;
