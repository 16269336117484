import React, { useContext } from "react";
import { Link } from "react-router-dom";
import LoaderCircle from "../../../../loader/LoaderCircle";
import ProductContext from "../../../../../context/ProductContext";
import "../../../../../css/page/cards/Tabs.css";
import AccessIcon from "../../../../icons/AccessIcon";
import OptionProduct from "../OptionProduct";

function ProductTabs (props) {
    const color = window.matchMedia('(prefers-color-scheme: dark)').matches ? "#FFFFFF" : "#1D1D1D";

    const { tab, sending, handleManage, show, handleDelete } = props;
    const { product } = useContext(ProductContext);

    return (
        <div className="tabs">
            <p className="title">{ product.name }</p>
            <nav>
                <Link to={ "/product/" + product.id + "/information" }><p className={ "tab " + (tab === "information" ? "selected" : "") }>Informations</p></Link>
                <Link to={ "/product/" + product.id + "/stock" }><p className={ "tab " + (tab === "stock" ? "selected" : "") }>Mouvements de stock</p></Link>
                <div className="clearing" />
            </nav>
            {
                sending
                ? <LoaderCircle display="loader restPUT" hide="" strokeWidth="10" stroke="#008C4F" />
                : <div className="access" onClick={ handleManage }>
                    <AccessIcon fill={ color } />
                </div>
            }
            <div className="clearing" />
            <OptionProduct show={ show } handleDelete={ handleDelete } />
        </div>
    );
}

export default ProductTabs;
