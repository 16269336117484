import React, { useContext } from "react";
import { Link } from "react-router-dom";
import LoaderCircle from "../../../../loader/LoaderCircle";
import AccessIcon from "../../../../icons/AccessIcon";
import OptionClient from "../OptionClient";
import InputSelect from "../../../../form/input/InputSelect";
import StoreContext from "../../../../../context/StoreContext";
import "../../../../../css/page/cards/Tabs.css";

function ClientTabs (props) {
    const color = window.matchMedia('(prefers-color-scheme: dark)').matches ? "#FFFFFF" : "#1D1D1D";

    const { tab, sending, action, handleManage, show, handleToClient, handleDelete, listSetups, setupSelected, handleChangeSetupSelected } = props;
    const { store } = useContext(StoreContext);

    const actionsContent = () => {
        switch (tab) {
            case "setup":

                if (listSetups.length === 1) {
                    return <p className="button" onClick={ () => { action("addSetup") } }>AJOUTER UN SYSTÈME DE CAISSE</p>;
                }
                else {
                    return <div className="form tabBar">
                        <InputSelect color={ color } list={ listSetups } value={ setupSelected } newItem={ true } handleChange={ handleChangeSetupSelected } />
                    </div>;
                }

            case "contract":

                return <p className="button" onClick={ () => { action("addContract") } }>Nouveau contrat</p>;

            case "ticket":

                return <p className="button" onClick={ () => { action("addTicket") } }>Nouveau ticket</p>;

            case "quote":

                return <p className="button" onClick={ () => { action("addQuote") } }>Nouveau devis</p>;

            default: break;
        }
    }

    return (
        <div className="tabs">
            <p className="title">{ (store.company_name !== "" ? store.company_name + " | " : "") + store.name + " | " + store.code }</p>
            {
                actionsContent()
            }
            <nav>
                <Link to={ "/client/" + store.id + "/information" }><p className={ "tab " + (tab === "information" ? "selected" : "") }>Informations</p></Link>
                <Link to={ "/client/" + store.id + "/ticket" }><p className={ "tab " + (tab === "ticket" ? "selected" : "") }>Tickets</p></Link>
                <Link to={ "/client/" + store.id + "/setup" }><p className={ "tab " + (tab === "setup" ? "selected" : "") }>Systèmes de caisse</p></Link>
                <Link to={ "/client/" + store.id + "/contract" }><p className={ "tab " + (tab === "contract" ? "selected" : "") }>Contrats</p></Link>
                <Link to={ "/client/" + store.id + "/quote" }><p className={ "tab " + (tab === "quote" ? "selected" : "") }>Devis</p></Link>
                <Link to={ "/client/" + store.id + "/voucher" }><p className={ "tab " + (tab === "voucher" ? "selected" : "") }>Bons de livraison</p></Link>
                <Link to={ "/client/" + store.id + "/bill" }><p className={ "tab " + (tab === "bill" ? "selected" : "") }>Factures</p></Link>
                <div className="clearing" />
            </nav>
            {
                sending
                ? <LoaderCircle display="loader restPUT" hide="" strokeWidth="10" stroke="#008C4F" />
                : <div className="access" onClick={ handleManage }>
                    <AccessIcon fill={ color } />
                </div>
            }
            <div className="clearing" />
            <OptionClient show={ show } handleToClient={ handleToClient } handleDelete={ handleDelete } />
        </div>
    );
}

export default ClientTabs;
