import React from "react";
import Error from "../../../../error/Error";
import SetupLicensesLine from "./SetupLicensesLine";
import LoaderCircle from "../../../../loader/LoaderCircle";
import AddIcon from "../../../../icons/AddIcon";
import "../../../../../css/page/setup/SetupLicenses.css";
import "../../../../../css/page/listingTables/Table.css";
import "../../../../../css/page/listingTables/Line.css";
import "../../../../../css/page/listingTables/SetupLicensesLine.css";

function SetupLicensesTable (props) {
    const { setup, list, error, loading, closeOptions, handleSetCloseOptions, handleAddLicense, handleUpdateLicense, handleInstallLicense } = props;

    return (
        <div className="wrapTable tableSetupLicenses">
            {
                error !== "" && <Error text={ error } classname="noMargin" />
            }
            <table className="table setupLicenses borderBottom">
                <tbody>
                    <tr className="line">
                        <th className="number left">LICENCE</th>
                        <th className="name left">NOM</th>
                        <th className="type left">TYPE</th>
                        <th className="main center">PRINCIPALE</th>
                        <th className="code left">CODE D'INSTALLATION</th>
                        <th className="action">
                            <div className="access visible" onClick={ () => { handleAddLicense() } }>
                                <AddIcon fill={ "#1D1D1D" } />
                            </div>
                        </th>
                    </tr>
                </tbody>
            </table>
            {
                !loading
                ? <table className="table setupLicenses">
                    <tbody>
                    {
                        list.length > 0
                            ? list.map(object => (
                                <SetupLicensesLine key={ object.id } setup={ setup } object={ object } closeOptions={ closeOptions } handleSetCloseOptions={ handleSetCloseOptions } handleUpdate={ handleUpdateLicense } handleInstall={ handleInstallLicense } />
                            ))
                            : <tr className="line">
                                <td className="center" colSpan="6">Aucune licence</td>
                            </tr>
                    }
                    </tbody>
                </table>
                : <LoaderCircle display="loader logWaitLine" hide="" strokeWidth="5" stroke="#008C4F" />
            }
        </div>
    );
}

export default SetupLicensesTable;
