import React from "react";
import Moment from "react-moment";

function PhaseInProgress (props) {
    const { phase } = props;

    const buildDate = () => {
        switch (phase.state) {
            case "WAIT":
                if (phase.alert_date !== null)
                    return <p className="date">Planifié le <Moment format="DD/MM/YYYY à hh:mm">{ phase.alert_date }</Moment></p>;
                else
                    return <p className="date">Pas de rappel</p>;

            case "SHIFT":
                if (phase.alert_date !== null) {
                    if (phase.shift_date !== null)
                        return <p className="date">Planifié le <Moment format="DD/MM/YYYY à hh:mm">{ phase.alert_date }</Moment><br />Décalée le <Moment format="DD/MM/YYYY à hh:mm">{ new Date(phase.shift_date) }</Moment></p>;
                    else
                        return <p className="date">Planifié le <Moment format="DD/MM/YYYY à hh:mm">{ phase.alert_date }</Moment><br />Décalée à une date non définie</p>;
                }
                else
                    return <p className="date">Décalée à une date non définie</p>;

            case "DONE":
                return <p className="date">Terminée le <Moment format="DD/MM/YYYY à hh:mm">{ phase.done_date }</Moment></p>;

            default: break;
        }
    }

    return (
        <div className="phase" style={phase.prospect_phase.color !== null ? {backgroundColor: phase.prospect_phase.color} : {}}>
            <div className="description">
                <p className="name">{ phase.prospect_phase.name }</p>
            </div>
            <div className="dates">
                {
                    buildDate()
                }
            </div>
        </div>
    );
}

export default PhaseInProgress;
