import React from "react";
import AccessIcon from "../../../../icons/AccessIcon";

function PartnershipUserLine (props) {
    const isDark = window.matchMedia('(prefers-color-scheme: dark)');
    const color = isDark.matches ? "#FFFFFF" : "#1D1D1D";
    const { user, handleShowFormUpdateUser } = props;

    return (
        <tr className="line">
            <td className="lastname left">{ user.lastname }</td>
            <td className="firstname left">{ user.firstname }</td>
            <td className="email left">{ user.email }</td>
            <td className="action">
                <div className="access" onClick={ () => { handleShowFormUpdateUser(user.id) } }>
                    <AccessIcon fill={ color } />
                </div>
            </td>
        </tr>
    );
}

export default PartnershipUserLine;
