import Rest from "../../../class/tool/Rest";
import UserGroup from "./UserGroup";

class UserGroupController {
    _rest = new Rest();
    _callback = null;
    _baseURL = "usergroups";
    _baseURLMembers = "usergroups/{idUserGroup}/members";

    index(input= "") {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + "?" + (input !== "" ? "&input=" + input : ""),
            {},
            this.handleIndex);
    }
    handleIndex = (response, error) => {
        let listObjects = [];

        if(response.data !== undefined) {
            const data = response.data.data;

            for(var item in data)
                listObjects.push(new UserGroup(data[item]));
        }

        this._callback(listObjects, error);
    }

    show(id) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + '/' + id,
            {},
            this.handleShow);
    }
    handleShow = (response, error) => {
        const object = !error ? new UserGroup(response.data) : null;
        this._callback(object, error);
    }

    post(datas) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURL,
            datas,
            this.handlePost
        );
    }
    handlePost = (response, error) => {
        this._callback(response, error);
    }

    put(object, datas) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'PUT',
            '/' + this._baseURL + '/' + object.id,
            datas,
            this.handlePut
        );
    }
    handlePut = (response, error) => {
        this._callback(response, error);
    }

    delete(object) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'DELETE',
            '/' + this._baseURL + '/' + object.id,
            {},
            this.handleDelete);
    }
    handleDelete = (response, error) => {
        this._callback(response, error);
    }

    returnUpdatesFromCompare(object, datas) {
        var dataKeys = Object.keys(datas);
        var key = "";
        var updates = {};

        for(var i in dataKeys) {
            key = dataKeys[i];

            if (datas[key] !== undefined) {
                if (Reflect.get(object, key) !== datas[key])
                    Reflect.set(updates, key, datas[key]);
            }
        }

        return updates;
    }

    // Members

    postMember(object, id) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURLMembers.replace('{idUserGroup}', object.id),
            {
                user_id: id
            },
            this.handlePostMember);
    }
    handlePostMember = (response, error) => {
        this._callback(response, error);
    }

    deleteMember(object, id) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'DELETE',
            '/' + this._baseURLMembers.replace('{idUserGroup}', object.id) + '/' + id,
            {},
            this.handleDeleteMember);
    }
    handleDeleteMember = (response, error) => {
        this._callback(response, error);
    }
}

export default UserGroupController;
