import React from "react";
import LoaderCircle from "../../loader/LoaderCircle";

function ChangeProspectState (props) {
    const { handleUpdate, handleClose, sending } = props;
    const states = [ {
            "key": 1,
            "state": "ACTIVE",
            "title": "ACTIF",
            "color": "#008C4F"
        }, {
            "key": 2,
            "state": "STANDBY",
            "title": "EN VEILLE",
            "color": "#C38613"
        }, {
            "key": 3,
            "state": "EJECT",
            "title": "EJECTÉ",
            "color": "#C33030"
        }
    ];

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox choices">
                    <div className="form margin">
                        <p className="text">Changer le statut du prospect ?</p>
                        {
                            states.map(state => (
                                <button key={ state.key } className={ "buttonChoice" + (state.key === 1 ? " spaceTop" : "") } style={{backgroundColor: state.color}} onClick={ () => { handleUpdate(state.state) } }>
                                    { state.title }
                                    <LoaderCircle display="loader submitLogin" hide={sending === state.state ? "" : "hide"} strokeWidth="8" stroke="#FFFFFF" />
                                </button>
                            ))
                        }
                        <button className="cancel block" onClick={ handleClose }>Fermer</button>
                        <div className="clearing" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChangeProspectState;
