import React, {useContext, useEffect, useState} from "react";
import NoInput from "../../input/NoInput";
import InputText from "../../input/InputText";
import LoaderCircle from "../../../loader/LoaderCircle";
import SessionContext from "../../../../context/SessionContext";
import SetupTypeController from "../../../../stories/_setup/SetupTypes/SetupTypeController";
import SetupType from "../../../../stories/_setup/SetupTypes/SetupType";
import InputSelect from "../../input/InputSelect";

function TypeForm (props) {
    const color = window.matchMedia('(prefers-color-scheme: dark)').matches ? "#FFFFFF" : "#1D1D1D";

    const { newItem, handleClose, handleDelete, type } = props;
    const { handleLogout } = useContext(SessionContext);
    const [ globalError, setGlobalError ] = useState("");
    const [ partnerships, setPartnerships ] = useState([]);
    const [ first, setFirst ] = useState(true);
    const [ saving, setSaving ] = useState(false);
    const [ typeObject, setTypeObject ] = useState(type !== null ? type : new SetupType());
    const [ name, setName ] = useState(type !== null ? type.name : "");
    const [ editName, setEditName ] = useState(false);
    const [ errorName, setErrorName ] = useState("");
    const [ partnershipID, setPartnershipID ] = useState(type !== null ? type.partnership_id : 0);
    const [ partnershipName, setPartnershipName ] = useState("");
    const [ errorPartnership, setErrorPartnership ] = useState("");

    const listPartnerships = () => {
        setFirst(false);

        const user = JSON.parse(localStorage.getItem("user"))
        let partnershipsList = [];

        user.partnerships.map(object => {
            partnershipsList.push({
                key: object.id,
                value: object.company
            });
        });

        setPartnershipID(partnershipsList[0].key);
        setPartnershipName(partnershipsList[0].value);
        setPartnerships(partnershipsList);
    }

    // HANDLES EDITION

    const handleEditName = () => {
        if(processEdit(editName))
            setEditName(!editName);
    }
    const handleChangeName = event => {
        setName(event.currentTarget.value);
    }
    const checkName = () => {
        const regxp = /^[\w ]{2,50}/g;

        if(name === "") {
            setErrors("name", true);
            return false;
        }

        if (!regxp.test(name.removeDiacritics())) {
            setErrors("name", false);
            return false;
        }

        setErrorName("");

        return true;
    }

    const handleChangePartnership = event => {
        var val = parseInt(event.currentTarget.value);

        for (var i in partnerships) {
            if (partnerships[i].key === val) {
                setPartnershipID(partnerships[i].key);
                setPartnershipName(partnerships[i].value);
                break;
            }
        }
    }
    const checkPartnership = () => {
        if(partnershipID === 0) {
            setErrors("partnership", true);
            return false;
        }

        setErrorPartnership("");

        return true;
    }

    // CONTROL & SAVE

    const processEdit = edit => {
        if(edit) {
            if(checkName() && checkPartnership() && !newItem)
                save();
            else
                return false;
        }

        return true;
    }
    const reinitAllEdits = () => {
        setEditName(false);
    }
    const reinitAllErrors = () => {
        setGlobalError("");
        setErrorName("");
        setErrorPartnership("");
    }

    const check422Errors = errorDatas => {
        setGlobalError("Certaines données sont invalides");

        if(errorDatas !== undefined) {
            var keys = Object.keys(errorDatas);
            var fields = ["name", "partnership_id"];

            for(var item in fields)
                if(keys.includes(fields[item]))
                    setErrors(fields[item], false);
        }
    }
    const setErrors = (type, empty) => {
        switch (type) {
            case "name":
                if(empty) setErrorName("Vous devez saisir un nom");
                else setErrorName("Ce nom n'est pas valide");
                break;
            case "partnership_id":
                setErrorPartnership("Vous devez sélectionner un partenaire");
                break;
            default: break;
        }
    }

    const handleKeyEnter = event => {
        if (event.key === 'Enter')
            if(processEdit(true))
                reinitAllEdits();
    }

    const save = () => {
        setSaving(true);

        reinitAllErrors();

        const controller = new SetupTypeController();
        controller._callback = handleReturnSave;

        const type = Object.assign({}, typeObject);
        type.name = name;
        type.partnership_id = partnershipID;
        setTypeObject(type);

        if(newItem)
            controller.post(type);
        else
            controller.put(type);
    }
    const handleReturnSave = (response, error) => {
        setSaving(false);

        if(error) {
            if(error.response !== undefined) {
                if(error.response.status === 401)
                    handleLogout();
                else if(error.response.status === 422)
                    check422Errors(error.response.data);
            }
            else
                setGlobalError("Une erreur s'est produite lors de l'enregistrement");
        }
        else
            handleClose(true);
    }

    useEffect(() => {
        if(first && newItem)
            listPartnerships();
    })

    // RENDER

    return (
        <div className="contForm">
            <div className={ "form " + (newItem ? "margin" : "") }>
                {
                    newItem && <p className="titleForm center">AJOUTER UN TYPE D'INSTALLATION</p>
                }
                {
                    globalError !== ""
                        ? <p className="globalError">{ globalError }</p>
                        : ""
                }
                <label className="label">Nom</label>
                {
                    !editName && !newItem
                        ? <NoInput value={ typeObject.name !== "" ? typeObject.name : "Aucun" } color={ color } edit={ true } copy={ typeObject.name !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditName } />
                        : <InputText color={ color } classError={ errorName !== "" ? "wrong" : "" } value={ name } placeholder="Nom" newItem={ newItem } handleChange={ handleChangeName } handleBlur={ handleEditName } handleKeyEnter={ handleKeyEnter } />
                }
                {
                    errorName !== "" && <p className="error">{ errorName }</p>
                }
                {
                    partnerships.length > 1 && <div className="clearing">
                        <label className="label">Partenaire</label>
                        {
                            !newItem
                                ? <NoInput value={ partnershipName } color={ color } edit={ false } copy={ false } phone={ false } email={ false } link={ false } />
                                : partnerships.length > 0 && <InputSelect color={ color } classError={ errorPartnership !== "" ? "wrong" : "" } list={ partnerships } value={ partnershipID } newItem={ newItem } handleChange={ handleChangePartnership } handleBlur={ () => {} } />
                        }
                    </div>
                }
                {
                    errorPartnership !== "" && <p className="error">{ errorPartnership }</p>
                }
                {
                    newItem
                    ? <div>
                        <button className="submit" onClick={ save }>
                            {saving ? "Enregistrement..." : "Enregistrer"}
                            <LoaderCircle display="loader submitLogin" hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                        </button>
                        <button className="cancel" onClick={ handleClose }>Annuler</button>
                        <div className="clearing" />
                    </div>
                    : <div>
                        <button className="delete" onClick={ handleDelete }>Supprimer le type</button>
                        <button className="cancel block" onClick={ () => { handleClose(false) } }>Fermer</button>
                    </div>
                }
            </div>
        </div>
    );
}

export default TypeForm;
