import Rest from "../../../class/tool/Rest";
import ProspectingPhase from "./ProspectingPhase";

class ProspectingPhaseController {
    _rest = new Rest();
    _callback = null;
    _baseURL = "stores/{idStore}/prospectingphases";

    // Checking

    static checkType(val) {
        const regxp = /^EMAIL|PHONE|VISIT|OTHER$/g;

        if (val !== "" && !regxp.test(val)) throw new Error("WRONG");

        return true;
    }
    static checkAlertDate(val) {
        const regxp = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}[\s]{1}[0-9]{2}:[0-9]{2}$/g;

        if (val !== "" && !regxp.test(val.removeDiacritics())) throw new Error("WRONG");

        return true;
    }
    static checkProspectPhase(val) {
        const regxp = /^[0-9]+$/g;

        if(val === "") throw new Error("EMPTY");
        if (!regxp.test(val)) throw new Error("WRONG");

        return true;
    }

    // Stores

    index(type, input= "") {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + "?" + type + (input !== "" ? "&input=" + input : ""),
            {},
            this.handleIndex);
    }
    handleIndex = (response, error) => {
        let listObjects = [];

        if(response.data !== undefined) {
            const data = response.data.data;

            for(var item in data)
                listObjects.push(new ProspectingPhase(data[item]));
        }

        this._callback(listObjects, error);
    }

    show(id) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + '/' + id,
            {},
            this.handleShow);
    }
    handleShow = (response, error) => {
        const object = !error ? new ProspectingPhase(response.data) : null;
        this._callback(object, error);
    }

    post(store, datas) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURL.replace('{idStore}', store.id),
            datas,
            this.handlePost
        );
    }
    handlePost = (response, error) => {
        this._callback(response, error);
    }

    put(object) {
        const objectToSend = JSON.parse(JSON.stringify(object));
        delete objectToSend.skytill_id;

        if(objectToSend.company_id === 0 || objectToSend.company_id === null)
            delete objectToSend.company_id;

        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'PUT',
            '/' + this._baseURL + '/' + objectToSend.id,
            objectToSend,
            this.handlePut
        );
    }
    handlePut = (response, error) => {
        this._callback(response, error);
    }

    delete(object) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'DELETE',
            '/' + this._baseURL + '/' + object.id,
            {},
            this.handleDelete);
    }
    handleDelete = (response, error) => {
        this._callback(response, error);
    }

    returnUpdatesFromCompare(object, datas) {
        var dataKeys = Object.keys(datas);
        var key = "";
        var updates = {};

        for(var i in dataKeys) {
            key = dataKeys[i];

            if (datas[key] !== undefined) {
                if (Reflect.get(object, key) !== datas[key])
                    Reflect.set(updates, key, datas[key]);
            }
        }

        return updates;
    }
}

export default ProspectingPhaseController;
