import React, {useContext} from "react";
import ProductForm from "../../../../form/product/ProductForm";
import ProductPrices from "./ProductPrices";
import ProductStocks from "./ProductStocks";
import Error from "../../../../error/Error";
import ProductContext from "../../../../../context/ProductContext";

import "../../../../../css/page/cards/CardInformations.css";
import "../../../../../css/page/content/Product.css";

function ProductCardInformations (props) {
    const { handleUpdateSending, handleAskDelete, handleGet } = props;
    const { generalError } = useContext(ProductContext);

    return (
        <div className="cardInformations">
            {
                generalError !== "" && <Error text={ generalError } />
            }

            <div className="product">
                <div className="left">
                    <ProductForm handleUpdateSending={ handleUpdateSending } handleAskDelete={ handleAskDelete } handleRefresh={ handleGet } />
                </div>
                <div className="right">
                    <ProductPrices />
                    <ProductStocks />
                </div>
            </div>
        </div>
    );
}

export default ProductCardInformations;
