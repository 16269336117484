import React  from "react";

import "../../../../../css/page/ToolsBar.css";

function ClientToolsBar (props) {
    const { handleNewClient } = props;

    return (
        <div className="toolsBarComponent">
            <p className="button" onClick={ handleNewClient }>Ajouter un client</p>
            <div className="clearing"/>
        </div>
    );
}

export default ClientToolsBar;
