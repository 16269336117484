import React from "react";
import LoaderCircle from "../../../loader/LoaderCircle";

function DeleteSetupType (props) {
    const { type, handleClose, handleDelete, deleting } = props;

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox yesOrNo">
                    <div className="form margin">
                        <p className="text">Êtes-vous sûr de vouloir supprimer le système de caisse { type.name } ?</p>
                        <button className="delete block" onClick={ handleDelete }>
                            { deleting ? "Suppression..." : "Supprimer" }
                            <LoaderCircle display="loader submitLogin" hide={!deleting ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                        </button>
                        <button className="cancel" onClick={ handleClose }>Annuler</button>
                        <div className="clearing" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DeleteSetupType;
