import Entity from "../../../class/abstract/Entity";
import User from "../Users/User";

class UserGroup extends Entity {
    name = "";
    email = "";
    forward_users = null;
    prefix_forward = "";
    partnership_id = 0;
    partnership_name = "";
    creator_id = 0;
    creator_name = "";
    nb_members = 0;
    members = [];

    constructor(datas = {}) {
        super(datas);

        this.setAttributes(datas);
    }

    setAttributes(datas) {
        var keys = Object.keys(this);
        var key = "";
        var i = 0;

        for(i in keys) {
            key = keys[i];

            if (datas[key] !== undefined)
                if (key !== "members")
                    Reflect.set(this, key, datas[key]);
        }

        if (datas.members !== undefined && datas.members.length > 0) {
            for (i in datas.members)
                this.members.push(new User(datas.members[i]));
        }
    }
}

export default UserGroup;
