import Entity from "../../../class/abstract/Entity";
import InstallationEnabling from "../InstallationEnablings/InstallationEnabling";

class License extends Entity {
    skytill_id = null;
    name = "";
    number = "";
    type = "";
    main = 0;
    install = null;

    constructor(datas = {}) {
        super(datas);

        this.setAttributes(datas);
    }

    setAttributes(datas) {
        var keys = Object.keys(this);
        var key = "";

        for(var i in keys) {
            key = keys[i];

            if (datas[key] !== undefined) {
                // except keys
                let exceptKeys = ['install'];

                if (!exceptKeys.includes(key))
                    Reflect.set(this, key, datas[key]);
            }
        }

        if (datas.install !== undefined && datas.install !== null)
            this.install = new InstallationEnabling(datas.install);
    }
}

export default License;
