import Entity from "../../../class/abstract/Entity";

class Address extends Entity {
    name = "";
    address = "";
    additional = "";
    postalCode = "";
    city = "";
    country = "";
    type_name = "";
    main = false;

    constructor(datas = {}) {
        super(datas);

        this.setAttributes(datas);
    }

    setAttributes(datas) {
        this.id = datas.id !== undefined ? datas.id : 0;
        this.name = datas.name !== undefined ? datas.name : "";
        this.address = datas.address !== undefined ? datas.address : "";
        this.additional = datas.additional !== undefined ? datas.additional : "";
        this.postalCode = datas.postalCode !== undefined ? datas.postalCode : "";
        this.city = datas.city !== undefined ? datas.city : "";
        this.country = datas.country !== undefined ? datas.country : "";
        this.type_name = datas.type_name !== undefined ? datas.type_name : "";
        this.main = datas.main !== undefined ? datas.main : false;
        this.updated_at = datas.updated_at !== undefined ? datas.updated_at : "";
        this.created_at = datas.created_at !== undefined ? datas.created_at : "";
    }
}

export default Address;
