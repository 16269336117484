import React, { useContext, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import SessionContext from "../../../context/SessionContext";
import NoInputComplex from "../input/NoInputComplex";
import AddContact from "../../overbox/contact/AddContact";
import SearchContact from "../../overbox/contact/SearchContact";
import FormContact from "../../overbox/contact/FormContact";
import DeleteContact from "../../overbox/contact/DeleteContact";
import StoreController from "../../../stories/_client/Stores/StoreController";
import ContactController from "../../../stories/_client/Contacts/ContactController";
import Contact from "../../../stories/_client/Contacts/Contact";

function ListContactForm (props) {
    const color = window.matchMedia('(prefers-color-scheme: dark)').matches ? "#FFFFFF" : "#1D1D1D";

    const { separator, handleRefresh, handleGlobalError, noSearch, attachTo, setGeneralError, object, setObject } = props;
    const { handleLogout } = useContext(SessionContext);
    const [ deleting, setDeleting ] = useState(false);
    const [ attaching, setAttaching ] = useState(false);
    const [ idObjectSelected, setIdObjectSelected ] = useState(0);
    const [ boxToCopy, setBoxToCopy ] = useState(false);
    const [ boxToCall, setBoxToCall ] = useState(false);
    const [ boxToAdd, setBoxToAdd ] = useState(false);
    const [ boxToSearch, setBoxToSearch ] = useState(false);
    const [ boxToEdit, setBoxToEdit ] = useState(false);
    const [ boxToDelete, setBoxToDelete ] = useState(false);

    const handleBoxToCopy = id => {
        setIdObjectSelected(id);
        setBoxToCopy(!boxToCopy);
    }
    const handleBoxToCall = id => {
        setIdObjectSelected(id);
        setBoxToCall(!boxToCall);
    }
    const handleBoxToAdd = () => {
        setBoxToAdd(!boxToAdd);
    }
    const handleBoxToSearch = () => {
        setBoxToAdd(false);
        setBoxToSearch(!boxToSearch);
    }
    const handleBoxToEdit = id => {
        setBoxToAdd(false);
        setIdObjectSelected(id);
        setBoxToEdit(!boxToEdit);

        if (id === 0 && handleRefresh !== undefined)
            handleRefresh();
    }
    const handleBoxToDelete = id => {
        setBoxToEdit(false);
        setBoxToDelete(!boxToDelete);
        setIdObjectSelected(id);
        setIdObjectSelected(id);
        setBoxToDelete(!boxToDelete);

        if (id === 0 && handleRefresh !== undefined)
            handleRefresh();
    }

    const returnObjectSelected = () => {
        if (idObjectSelected === 0)
            return null;

        let contact;

        for (var i in object.contacts) {
            contact = object.contacts[i];

            if(idObjectSelected === contact.id)
                break;
        }

        return contact;
    }

    const handleSelectSearch = (contacts, rules) => {
        if (contacts.length > 0) {
            setAttaching(true);
            attachToStore(contacts[0].id);
        }
    }

    const attachToStore = idContact => {
        if (object.id > 0) {
            const controller = new StoreController();
            controller._callback = handleReturnAttachToStore;
            controller.postContact(object, idContact);
        }
        else
            getContact(idContact);
    }
    const handleReturnAttachToStore = (response, error) => {
        setAttaching(false);
        handleBoxToSearch();

        if(error) {
            if(error.response !== undefined) {
                if(error.response.status === 401)
                    handleLogout();
                else
                    setGeneralError("Une erreur s'est produite lors de l'attachement du contact");
            }
            else
                setGeneralError("Une erreur s'est produite lors de l'attachement du contact");
        }
        else {
            if (handleRefresh !== undefined)
                handleRefresh();
        }
    }

    const getContact = idContact => {
        var objTmp = Object.assign({}, object);

        for (var i in objTmp.contacts) {
            if (objTmp.contacts.id === idContact) {
                setGeneralError("Cet utilisateur a déjà été ajouté");
                setAttaching(false);
                handleBoxToSearch();
                return;
            }
        }


        const controller = new ContactController();
        controller._callback = handleGetContact;
        controller.show(idContact);
    }
    const handleGetContact = (response, error) => {
        setAttaching(false);
        handleBoxToSearch();

        if(error) {
            if(error.response !== undefined) {
                if(error.response.status === 401)
                    handleLogout();
                else
                    setGeneralError("Une erreur s'est produite lors de la récupération du contact");
            }
            else
                setGeneralError("Une erreur s'est produite lors de la récupération du contact");

            return;
        }

        var objTmp = Object.assign({}, object);
        objTmp.contacts.push(new Contact(response));
        setObject(objTmp);
    }

    const handleDetach = () => {
        setDeleting(true);

        const controller = new StoreController();
        controller._callback = handleReturnDetach;

        controller.deleteContact(object, idObjectSelected);
    }
    const handleReturnDetach = (response, error) => {
        if(error) {
            if(error.response !== undefined) {
                if(error.response.status === 401)
                    handleLogout();
                else
                    handleGlobalError("Une erreur s'est produite lors du détachement du contact");
            }
            else
                handleGlobalError("Une erreur s'est produite lors du détachement du contact");
        }

        setDeleting(false);
        setIdObjectSelected(0);

        if (handleRefresh !== undefined)
            handleRefresh();
    }

    const handleDelete = () => {
        setDeleting(true);

        const controller = new ContactController();
        controller._callback = returnHandleDelete;
        controller.delete(returnObjectSelected());
    }
    const returnHandleDelete = (response, error) => {
        if(error) {
            if(error.response !== undefined) {
                if(error.response.status === 401)
                    handleLogout();
                else
                    handleGlobalError("Une erreur s'est produite lors de la suppression du contact");
            }
            else
                handleGlobalError("Une erreur s'est produite lors de la suppression du contact");
        }

        setDeleting(false);
        handleBoxToDelete(0);
    }

    // BUILD

    const buildSeparator = () => {
        return (separator ? <div className="separator"/> : "");
    }
    const buildChoiceCopy = () => {
        let contact = returnObjectSelected();
        let name = (contact.lastname + " " + contact.firstname).trim();
        let phone = contact.phone !== "" ? contact.phone : "";
        let mobile = contact.mobile !== "" ? contact.mobile : "";
        let email = contact.email !== "" ? contact.email : "";

        return <div style={{margin: 0, marginTop: 30 + "px", padding: 0}}>
                {
                    name !== "" && <CopyToClipboard text={ name } onCopy={ () => { handleBoxToCopy('contact', 0) } }><button className="buttonChoice">{ name }</button></CopyToClipboard>
                }
                {
                    phone !== "" && <CopyToClipboard text={ phone } onCopy={ () => { handleBoxToCopy('contact', 0) } }><button className="buttonChoice">{ phone }</button></CopyToClipboard>
                }
                {
                    mobile !== "" && <CopyToClipboard text={ mobile } onCopy={ () => { handleBoxToCopy('contact', 0) } }><button className="buttonChoice">{ mobile }</button></CopyToClipboard>
                }
                {
                    email !== "" && <CopyToClipboard text={ email } onCopy={ () => { handleBoxToCopy('contact', 0) } }><button className="buttonChoice">{ email }</button></CopyToClipboard>
                }
            </div>;
    }
    const buildChoiceCall = () => {
        let contact = returnObjectSelected();
        let phone = contact.phone !== "" ? contact.phone : "";
        let mobile = contact.mobile !== "" ? contact.mobile : "";

        return <div style={{margin: 0, marginTop: 30 + "px", padding: 0}}>
            {
                phone !== "" && <a href={ "tel:" + phone }><button className="buttonChoice">{ phone }</button></a>
            }
            {
                mobile !== "" && <a href={ "tel:" + mobile }><button className="buttonChoice">{ mobile }</button></a>
            }
        </div>;
    }

    // RENDER

    return (
        <div className="clearing">
            {
                boxToCopy && <div className="overlayer">
                    <div className="wrapOverbox">
                        <div className="overbox choices">
                            <div className="form margin">
                                <p className="titleForm center">Que souhaitez-vous copier ?</p>
                                { buildChoiceCopy() }
                                <button className="cancel block" onClick={ () => { handleBoxToCopy(0) } }>Annuler</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                boxToCall && <div className="overlayer">
                    <div className="wrapOverbox">
                        <div className="overbox choices">
                            <div className="form margin">
                                <p className="titleForm center">Quel numéro souhaitez-vous appeller ?</p>
                                { buildChoiceCall() }
                                <button className="cancel block" onClick={ () => { handleBoxToCall(0) } }>Annuler</button>
                            </div>
                        </div>Êtes-vous sûr de vouloir supprimer le contact
                    </div>
                </div>
            }
            {
                boxToAdd && <AddContact handleClose={ handleBoxToAdd } handleSearch={ handleBoxToSearch } handleNew={ () => { handleBoxToEdit(0) } } />
            }
            {
                boxToSearch && <SearchContact handleClose={ handleBoxToSearch } handleAttach={ handleSelectSearch } saving={ attaching } multi={ false } inStore={ false } noRule={ true } />
            }
            {
                boxToEdit && <FormContact contact={ returnObjectSelected() } handleClose={ () => { handleBoxToEdit(0) } } handleDelete={ handleBoxToDelete } handleRefresh={ handleRefresh } attachTo={ attachTo } setGeneralError={ setGeneralError } object={ object } setObject={ setObject } />
            }
            {
                boxToDelete && <DeleteContact contact={ returnObjectSelected() } deleting={ deleting } handleClose={ handleBoxToDelete } handleDelete={ handleDelete } />
            }
            {
                buildSeparator()
            }
            <label className="label">Contacts</label>
            {
                (object.contacts !== undefined && object.contacts.length > 0)
                && object.contacts.map(
                    contact => (
                        <NoInputComplex key={ contact.id } color={ color } object={ contact } type="contact" handleToCopy={ handleBoxToCopy } handleToCall={ handleBoxToCall } handleToEdit={ handleBoxToEdit } />
                    )
                )
            }
            <button className="add" onClick={ noSearch ? () => { handleBoxToEdit(0) } : handleBoxToAdd }>Ajouter un contact</button>
        </div>
    );
}

export default ListContactForm;
