import React, {useContext} from "react";
import PartnershipStorageLine from "./PartnershipStorageLine";
import LoaderCircle from "../../../../loader/LoaderCircle";
import Error from "../../../../error/Error";

import SearchContext from "../../../../../context/SearchContext";

import "../../../../../css/page/listingTables/Table.css";
import "../../../../../css/page/listingTables/Line.css";
import "../../../../../css/page/listingTables/PartnershipLine.css";
import "../../../../../css/page/listingTables/PartnershipStorageLine.css";

function PartnershipStoragesTable (props) {
    const { handleShowFormUpdateStorage } = props;
    const { generalError, initialLoading, list } = useContext(SearchContext);

    return (
        <div className="wrapTable">
            <table className="table partnershipStorage borderBottom">
                <tbody>
                    <tr className="line">
                        <th className="city left">VILLE</th>
                        <th className="postalCode left">CODE POSTAL</th>
                        <th className="country left">PAYS</th>
                        <th className="action">
                            <div className="action" />
                        </th>
                    </tr>
                </tbody>
            </table>
            <div className="list">
                {
                    !initialLoading
                    ? (
                        generalError === ""
                        ? <table className="table partnershipStorage">
                                <tbody>
                                {
                                    list.length > 0
                                        ? list.map(storage => (
                                            <PartnershipStorageLine key={ storage.id } storage={ storage } handleShowFormUpdateStorage={ handleShowFormUpdateStorage } />
                                        ))
                                        : <tr className="line">
                                            <td className="left" colSpan="4">Aucun établissement</td>
                                        </tr>
                                }
                                </tbody>
                            </table>
                        : <Error text={ generalError } />
                    )
                    : <LoaderCircle display="loader logWait" hide="" strokeWidth="5" stroke="#008C4F" />
                }

            </div>
        </div>
    );
}

export default PartnershipStoragesTable;
