import React, {useContext, useEffect, useState} from "react";
import PriceProduct from "./PriceProduct";
import FormPrice from "../../../../overbox/price/FormPrice";
import DeletePrice from "../../../../overbox/price/DeletePrice";
import LoaderCircle from "../../../../loader/LoaderCircle";
import SessionContext from "../../../../../context/SessionContext";
import ProductContext from "../../../../../context/ProductContext";
import ProductController from "../../../../../stories/_catalog/Products/ProductController";

function ProductPrices () {
    const { handleLogout } = useContext(SessionContext);
    const [ first, setFirst ] = useState(true);
    const [ loadingPrices, setLoadingPrices ] = useState(true);
    const [ prices, setPrices ] = useState([]);
    const { product, setGeneralError } = useContext(ProductContext);
    const [ updatePrice, setUpdatePrice ] = useState(false);
    const [ deletePrice, setDeletePrice ] = useState(false);
    const [ price, setPrice ] = useState(null);
    const [ deleting, setDeleting ] = useState(false);


    // PERFORM

    const handleGetPrices = (list, error) => {
        setLoadingPrices(false);

        if(error) {
            if(error.response !== undefined) {
                if (error.response.status === 401)
                    handleLogout();
                else if (error.response.status === 404)
                    setGeneralError("Les prix sont introuvables");
            }
            else
                setGeneralError("Impossible de récupérer les données");
        }

        setPrices(list);
    }
    const getPrices = () => {
        const controller = new ProductController();
        controller._callback = handleGetPrices;
        controller.indexPrices(product);
    }

    const handleUpdatePrice = price => {
        setPrice(price);
        setUpdatePrice(true);
    }

    const handleCloseUpdatePrice = () => {
        setPrice(null);
        setUpdatePrice(false);
    }

    const askDeletePrice = () => {
        setUpdatePrice(false);
        setDeletePrice(!deletePrice);
    }

    const handleReturnDeletePrice = (response, error) => {
        setDeleting(false);

        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else
                setGeneralError("Impossible de supprimer le prix");
        }
        else
            getPrices();
    }
    const handleDeletePrice = () => {
        askDeletePrice();
        setDeleting(true);

        const controller = new ProductController();
        controller._callback = handleReturnDeletePrice;
        controller.delete(product, price);
    }


    // INIT

    const init = () => {
        setFirst(false);
        getPrices();
    }

    useEffect(() => {
        if (first)
            init();
    })

    // RENDER

    return (
        <div className="prices">
            {
                !loadingPrices
                    ? <div className="clearing">
                        {
                            prices.map(object => (
                                <PriceProduct
                                    key={ object.id }
                                    object={ object }
                                    edit={ true }
                                    handleEdit={ () => { handleUpdatePrice(object) } }
                                />
                            ))
                        }
                        {
                            updatePrice
                            && <FormPrice newItem={ false } object={ price } product={ product } handleClose={ handleCloseUpdatePrice } handleDelete={ askDeletePrice } handleRefresh={ getPrices } />
                        }
                        {
                            deletePrice
                            && <DeletePrice price={ price } handleClose={ askDeletePrice } handleDelete={ handleDeletePrice } deleting={ deleting } />
                        }
                    </div>
                    : <LoaderCircle display="loader logWait" hide="" strokeWidth="5" stroke="#008C4F" />
            }
        </div>
    );
}

export default ProductPrices;
