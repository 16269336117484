import React, {useContext, useEffect, useState} from "react";
import StoreContext from "../../../../../context/StoreContext";
import LoaderCircle from "../../../../loader/LoaderCircle";
import TicketsTable from "../../ticket/TicketsTable";

import "../../../../../css/form/Form.css";
import ClientTicketController from "../../../../../stories/_ticket/ClientTickets/ClientTicketController";
import SheetClientTicket from "../../../../overbox/clientTicket/SheetClientTicket";

function ClientTicket (props) {
    const { loading, setLoading, idTicket } = props;
    const { store } = useContext(StoreContext);
    const [ first, setFirst ] = useState(true);
    const [ error, setError ] = useState("");
    const [ tickets, setTickets ] = useState([]);


    // Perform

    const handleGetTickets = (list, error) => {
        if (error) {
            setError("Une erreur s'est produite lors de la récupération des tickets");
        }

        setTickets(list);
    }
    const getTickets = () => {
        setFirst(false);

        var clientTicketController = new ClientTicketController();
        clientTicketController._callback = handleGetTickets;
        clientTicketController.indexForStore(store.id);
    }

    const init = () => {
        setFirst(false);
        getTickets();
    }


    // Init

    useEffect(() => {
        if (first)
            init();
    });


    // Render

    return (
        <div className="clientSetup">
            {
                loading
                    ? <LoaderCircle display="loader logWait" hide="" strokeWidth="5" stroke="#008C4F" />
                    : <TicketsTable tickets={ tickets } error={ error } />
            }
            {
                idTicket !== undefined
                && <SheetClientTicket id={ idTicket } handleRefresh={ getTickets } />
            }
        </div>
    );
}

export default ClientTicket;
