import React, {useContext, useEffect, useState} from "react";
import NoInput from "../input/NoInput";
import InputText from "../input/InputText";
import InputSelect from "../input/InputSelect";
import InputFile from "../input/InputFile";
import PartnershipController from "../../../stories/_auth/Partnerships/PartnershipController";
import PartnershipTypeController from "../../../stories/_auth/PartnershipTypes/PartnershipTypeController";
import PartnershipContext from "../../../context/PartnershipContext";
import GoogleLocation from "../../../class/tool/GoogleLocation";
import LoaderCircle from "../../loader/LoaderCircle";
import Partnership from "../../../stories/_auth/Partnerships/Partnership";
import SessionContext from "../../../context/SessionContext";

function PartnershipForm (props) {
    const color = window.matchMedia('(prefers-color-scheme: dark)').matches ? "#FFFFFF" : "#1D1D1D";
    const countries = [
        {
            key: 5,
            value: "FRANCE"
        },
        {
            key: 1,
            value: "ALLEMAGNE"
        },
        {
            key: 2,
            value: "ANGLETERRE"
        },
        {
            key: 3,
            value: "BELGIQUE"
        },
        {
            key: 4,
            value: "ESPAGNE"
        },
        {
            key: 6,
            value: "ITALIE"
        },
        {
            key: 7,
            value: "LUXEMBOURG"
        },
        {
            key: 8,
            value: "SUISSE"
        }
    ];

    const { handleUpdateSending, newItem, handleCloseNewPartnership, handleAskDeletePartnership } = props;
    const { handleLogout } = useContext(SessionContext);
    const { partnership } = useContext(PartnershipContext);
    const [ partnershipObject, setPartnershipObject ] = useState((partnership.id === undefined) ? new Partnership() : partnership);
    const [ first, setFirst ] = useState(true);
    const [ saving, setSaving ] = useState(false);
    const [ partnershipTypes, setPartnershipTypes ] = useState([]);
    const [ globalError, setGlobalError ] = useState("");
    const [ file, setFile ] = useState("");
    const [ filePath, setFilePath ] = useState(!newItem ? partnership.logo : "");
    const [ progress, setProgress ] = useState(0);
    const [ errorFile, setErrorFile ] = useState("");
    const [ company, setCompany ] = useState(!newItem ? partnership.company : "");
    const [ editCompany, setEditCompany ] = useState(false);
    const [ errorCompany, setErrorCompany ] = useState("");
    const [ address, setAddress ] = useState(!newItem ? partnership.address : "");
    const [ editAddress, setEditAddress ] = useState(false);
    const [ errorAddress, setErrorAddress ] = useState("");
    const [ additional, setAdditional ] = useState(!newItem ? partnership.additional : "");
    const [ editAdditional, setEditAdditional ] = useState(false);
    const [ errorAdditional, setErrorAdditional ] = useState("");
    const [ postalCode, setPostalCode ] = useState(!newItem ? partnership.postalCode : "");
    const [ editPostalCode, setEditPostalCode ] = useState(false);
    const [ errorPostalCode, setErrorPostalCode ] = useState("");
    const [ city, setCity ] = useState(!newItem ? partnership.city : "");
    const [ editCity, setEditCity ] = useState(false);
    const [ errorCity, setErrorCity ] = useState("");
    const [ country, setCountry ] = useState(!newItem ? partnership.country : countries[0].value);
    const [ editCountry, setEditCountry ] = useState(false);
    const [ errorCountry, setErrorCountry ] = useState("");
    const [ partnershipType, setPartnershipType ] = useState(!newItem ? (partnership.partnership_type !== undefined ? partnership.partnership_type.name : "") : "");
    const [ editPartnershipType, setEditPartnershipType ] = useState(false);
    const [ errorPartnershipType, setErrorPartnershipType ] = useState("");

    const handleEditCompany = () => {
        if(processEdit(editCompany))
            setEditCompany(!editCompany);
    }
    const handleChangeCompany = event => {
        setCompany(event.currentTarget.value);
    }
    const checkCompany = () => {
        const regxp = /^[\w ]{2,50}/g;

        if(company === "") {
            setErrors("company", true);
            return false;
        }

        if (!regxp.test(company)) {
            setErrors("company", false);
            return false;
        }

        setErrorCompany("");

        return true;
    }

    const handleEditAddress = () => {
        if(processEdit(editAddress))
            setEditAddress(!editAddress);
    }
    const handleChangeAddress = event => {
        const input = event.currentTarget.value;

        setAddress(input);

        if(input.length >= 3)
            searchAddress(input);
    }
    const checkAddress = () => {
        const regxp = /^\d+\s[A-z]+\s[A-z]+/g;

        if(address === "") {
            setErrors("address", true);
            return false;
        }

        if (!regxp.test(address)) {
            setErrors("address", false);
            return false;
        }

        setErrorAddress("");

        return true;
    }
    const searchAddress = input => {
        const location = new GoogleLocation();
        location._callback = handleSearchAddress;
        location.searchAddress(input);
    }
    const handleSearchAddress = (result, error) => {
        console.log("result", result);
        console.log("error", error);
    }

    const handleEditAdditional = () => {
        if(processEdit(editAdditional))
            setEditAdditional(!editAdditional);
    }
    const handleChangeAdditional = event => {
        setAdditional(event.currentTarget.value);
    }
    const checkAdditional = () => {
        const regxp = /^\d+\s[A-z]+\s[A-z]+/g;

        if (additional.length > 0 && !regxp.test(additional)) {
            setErrors("additional", false);
            return false;
        }

        setErrorAdditional("");

        return true;
    }

    const handleEditPostalCode = () => {
        if(processEdit(editPostalCode))
            setEditPostalCode(!editPostalCode);
    }
    const handleChangePostalCode = event => {
        setPostalCode(event.currentTarget.value);
    }
    const checkPostalCode = () => {
        const regxp = /^[A-Z0-9]+/g;

        if(postalCode === "") {
            setErrors("postalCode", true);
            return false;
        }

        if (!regxp.test(postalCode)) {
            setErrors("postalCode", false);
            return false;
        }

        setErrorPostalCode("");

        return true;
    }

    const handleEditCity = () => {
        if(processEdit(editCity))
            setEditCity(!editCity);
    }
    const handleChangeCity = event => {
        setCity(event.currentTarget.value);
    }
    const checkCity = () => {
        const regxp = /^[\w-]/g;

        if(city === "") {
            setErrors("city", true);
            return false;
        }

        if (!regxp.test(city)) {
            setErrors("city", false);
            return false;
        }

        setErrorCity("");

        return true;
    }

    const handleEditCountry = () => {
        if(processEdit(editCountry))
            setEditCountry(!editCountry);
    }
    const handleChangeCountry = event => {
        setCountry(event.currentTarget.value);
    }
    const checkCountry = () => {
        const regxp = /^[\w-]/g;

        if(city === "") {
            setErrors("country", true);
            return false;
        }

        if (!regxp.test(city)) {
            setErrors("country", false);
            return false;
        }

        setErrorCountry("");

        return true;
    }

    const handleEditPartnershipType = () => {
        if(processEdit(editPartnershipType))
            setEditPartnershipType(!editPartnershipType);
    }
    const handleChangePartnershipType = event => {
        setPartnershipType(event.currentTarget.value);
    }
    const checkPartnershipType = () => {
        const regxp = /^[A-Z]/g;

        if(partnershipType === "") {
            setErrors("type", true);
            return false;
        }

        if (!regxp.test(partnershipType)) {
            setErrors("type", false);
            return false;
        }

        setErrorPartnershipType("");

        return true;
    }

    const processEdit = edit => {
        if(edit) {
            if(checkCompany() && checkAddress() && checkAdditional() && checkPostalCode() && checkCity() && checkCountry() && checkPartnershipType() && !newItem)
                save();
            else
                return false;
        }

        return true;
    }
    const reinitAllEdits = () => {
        setEditCompany(false);
        setEditAddress(false);
        setEditAdditional(false);
        setEditPostalCode(false);
        setEditCity(false);
        setEditCountry(false);
        setEditPartnershipType(false);
    }
    const reinitAllErrors = () => {
        setGlobalError("");
        setErrorCompany("");
        setErrorAddress("");
        setErrorAdditional("");
        setErrorPostalCode("");
        setErrorCity("");
        setErrorCountry("");
        setErrorPartnershipType("");
    }

    const getPartnershipTypes = () => {
        const controller = new PartnershipTypeController();
        controller._callback = handleGetPartnershipTypes;
        controller.index();
    }
    const handleGetPartnershipTypes = (list, error) => {
        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else
                setGlobalError(true);
        }

        let partnershipTypesList = [];

        list.map(object => {
            partnershipTypesList.push({
                key: object.id,
                value: object.name
            });
        });

        if(partnershipType.length === 0)
            setPartnershipType(partnershipTypesList[0].value);

        setFirst(false);
        setPartnershipTypes(partnershipTypesList);
    }

    const setErrors = (type, empty) => {
        switch (type) {
            case "company":
                if(empty) setErrorCompany("Vous devez saisir une dénomination");
                else setErrorCompany("Cette dénomination n'est pas valide");
                break;
            case "address":
                if(empty) setErrorAddress("Vous devez saisir une adresse");
                else setErrorAddress("Cette addresse n'est pas valide");
                break;
            case "additional":
                if(empty) setErrorAdditional("Vous devez saisir un complément d'adresse");
                else setErrorAdditional("Ce complément d'adresse n'est pas valide");
                break;
            case "postalCode":
                if(empty) setErrorPostalCode("Vous devez saisir un code postal");
                else setErrorPostalCode("Ce code postal est incorrect");
                break;
            case "city":
                if(empty) setErrorCity("Vous devez saisir une ville");
                else setErrorCity("Ce nom de ville est incorrect");
                break;
            case "country":
                if(empty) setErrorCountry("Vous devez faire un choix");
                else setErrorCountry("Ce choix est incorrect");
                break;
            case "type":
                if(empty) setErrorPartnershipType("Vous devez faire un choix");
                else setErrorPartnershipType("Ce choix est incorrect");
                break;
            default: break;
        }
    }

    const handleKeyEnter = event => {
        if (event.key === 'Enter')
            if(processEdit(true))
                reinitAllEdits();
    }

    const handleSubmit = () => {
        uploadLogo();
    }
    const uploadLogo = () => {
        const controller = new PartnershipController();
        controller._callback = returnUploadLogo;
        controller.uploadLogo(partnership, new FormData(document.getElementById('form')), handleProgress);
    }
    const handleProgress = val => {
        setProgress(val);
    }
    const returnUploadLogo = (response, error) =>  {
        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else
                setErrorFile("Une erreur s'est produite lors de l'upload du logo");
        }

        setFilePath(response.data);
    }

    const save = () => {
        setSaving(true);

        reinitAllErrors();

        if(handleUpdateSending !== undefined)
            handleUpdateSending(true);

        const controller = new PartnershipController();
        controller._callback = handleReturnSave;

        partnershipObject.company = company;
        partnershipObject.address = address;
        partnershipObject.additional = additional;
        partnershipObject.postalCode = postalCode;
        partnershipObject.city = city;
        partnershipObject.country = country;
        partnershipObject.type = partnershipType;
        partnershipObject.partnership_type.name = partnershipType;

        if(newItem)
            controller.post(partnershipObject);
        else
            controller.put(partnershipObject);

        setPartnershipObject(partnershipObject);
    }
    const handleReturnSave = (response, error) => {
        if(handleUpdateSending !== undefined)
            handleUpdateSending(false);

        setSaving(false);

        if(error) {
            if(error.response !== undefined) {
                if(error.response.status === 401)
                    handleLogout();
                else if(error.response.status === 422) {
                    setGlobalError("Certaines données sont invalides");

                    if(error.response.data !== undefined) {
                        var keys = Object.keys(error.response.data);
                        var fields = ["company", "address", "additional", "postalCode", "city", "country", "type"];

                        for(var item in fields)
                            if(keys.includes(fields[item]))
                                setErrors(fields[item], false);
                    }
                }
            }
            else
                setGlobalError("Une erreur s'est produite lors de l'enregistrement");
        }
        else
            if(newItem)
                window.location = "/partnership/" + response.data.id + "/information";

    }

    useEffect(() => {
        if(first)
            getPartnershipTypes();
    });

    return (
        <div className={ "form " + (newItem ? "margin" : "") }>
            {
                newItem && <p className="title">AJOUTER UN PARTENAIRE</p>
            }
            {
                globalError !== ""
                    ? <p className="globalError">{ globalError }</p>
                    : ""
            }
            <InputFile color={ color } idname="file" classname="logo" file={ file } setFile={ setFile } filePath={ filePath } progress={ progress } handleSubmit={ handleSubmit } />
            {
                errorFile !== ""
                    ? <p className="error">{ errorFile }</p>
                    : ""
            }
            <label className="label">Société</label>
            {
                !editCompany && !newItem
                    ? <NoInput value={ partnership.company !== "" ? partnership.company : "Aucun" } color={ color } edit={ true } copy={ partnership.company !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditCompany } />
                    : <InputText color={ color } classError={ errorCompany !== "" ? "wrong" : "" } value={ company } placeholder="Société" newItem={ newItem } handleChange={ handleChangeCompany } handleBlur={ handleEditCompany } handleKeyEnter={ handleKeyEnter } />
            }
            {
                errorCompany !== ""
                    ? <p className="error">{ errorCompany }</p>
                    : ""
            }
            <label className="label">Adresse</label>
            {
                !editAddress && !newItem
                    ? <NoInput value={ partnership.address !== "" ? partnership.address : "Aucune" } color={ color } edit={ true } copy={ partnership.address !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditAddress } />
                    : <InputText color={ color } classError={ errorAddress !== "" ? "wrong" : "" } value={ address } placeholder="Adresse" newItem={ newItem } handleChange={ handleChangeAddress } handleBlur={ handleEditAddress } handleKeyEnter={ handleKeyEnter } />
            }
            {
                errorAddress !== ""
                    ? <p className="error">{ errorAddress }</p>
                    : ""
            }
            <label className="label">Complément d'adresse</label>
            {
                !editAdditional && !newItem
                    ? <NoInput value={ partnership.additional !== "" ? partnership.additional : "Aucun" } color={ color } edit={ true } copy={ partnership.additional !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditAdditional } />
                    : <InputText color={ color } classError={ errorAdditional !== "" ? "wrong" : "" } value={ additional } placeholder="Complément d'adresse" newItem={ newItem } handleChange={ handleChangeAdditional } handleBlur={ handleEditAdditional } handleKeyEnter={ handleKeyEnter } />
            }
            {
                errorAdditional !== ""
                    ? <p className="error">{ errorAdditional }</p>
                    : ""
            }
            <label className="label left">Code postal</label>
            <label className="label right">Ville</label>
            {
                !editPostalCode && !newItem
                    ? <NoInput classname="clear left" value={ partnership.postalCode !== "" ? partnership.postalCode : "Aucun" } color={ color } edit={ true } copy={ partnership.postalCode !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditPostalCode } />
                    : <InputText color={ color } classname="left" classError={ errorPostalCode !== "" ? "wrong" : "" } value={ postalCode } placeholder="Code postal" newItem={ newItem } handleChange={ handleChangePostalCode } handleBlur={ handleEditPostalCode } handleKeyEnter={ handleKeyEnter } />
            }
            {
                !editCity && !newItem
                    ? <NoInput classname="right" value={ partnership.city !== "" ? partnership.city : "Aucune" } color={ color } edit={ true } copy={ partnership.city !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditCity } />
                    : <InputText color={ color } classname="right" classError={ errorCity !== "" ? "wrong" : "" } value={ city } placeholder="Ville" newItem={ newItem } handleChange={ handleChangeCity } handleBlur={ handleEditCity } handleKeyEnter={ handleKeyEnter } />
            }
            <div className="clearing" />
            {
                errorPostalCode !== ""
                    ? <p className="error left">{ errorPostalCode }</p>
                    : ""
            }
            {
                errorCity !== ""
                    ? <p className="error right">{ errorCity }</p>
                    : ""
            }
            <label className="label clear">Pays</label>
            {
                !editCountry && !newItem
                    ? <NoInput value={ partnership.country !== "" ? partnership.country : "Aucun" } color={ color } edit={ true } copy={ partnership.country !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditCountry } />
                    : <InputSelect color={ color } classError={ errorCountry !== "" ? "wrong" : "" } list={ countries } value={ country } newItem={ newItem } handleChange={ handleChangeCountry } handleBlur={ handleEditCountry } returnTextValue={ true } />
            }
            {
                errorCountry !== ""
                    ? <p className="error">{ errorCountry }</p>
                    : ""
            }
            <label className="label">Type de partenaire</label>
            {
                !editPartnershipType && !newItem
                    ? <NoInput value={ partnershipType !== "" ? partnershipType : "Aucun" } color={ color } edit={ true } copy={ partnershipType !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditPartnershipType } />
                    : <InputSelect color={ color } classError={ errorPartnershipType !== "" ? "wrong" : "" } list={ partnershipTypes } value={ partnershipType } newItem={ newItem } handleChange={ handleChangePartnershipType } handleBlur={ handleEditPartnershipType } returnTextValue={ true } />
            }
            {
                errorPartnershipType !== ""
                    ? <p className="error">{ errorPartnershipType }</p>
                    : ""
            }
            {
                newItem
                ? <div>
                    <button className="submit" onClick={ save }>
                        {saving ? "Enregistrement..." : "Enregistrer"}
                        <LoaderCircle display="loader submitLogin" hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                    </button>
                    <button className="cancel" onClick={ handleCloseNewPartnership }>Annuler</button>
                    <div className="clearing" />
                </div>
                : <button className="delete" onClick={ handleAskDeletePartnership }>Supprimer le partenaire</button>
            }
        </div>
    );
}

export default PartnershipForm;
