import React, {useContext, useState} from "react";
import LoaderCircle from "../../loader/LoaderCircle";
import Error from "../../error/Error";
import ProspectForm from "../../form/prospect/ProspectForm";
import ProspectFormSteps from "../../form/prospect/ProspectFormSteps";
import ListContactForm from "../../form/contact/ListContactForm";
import ListAddressForm from "../../form/address/ListAddressForm";
import Store from "../../../stories/_client/Stores/Store";
import StoreController from "../../../stories/_client/Stores/StoreController";
import ContactController from "../../../stories/_client/Contacts/ContactController";
import AddressController from "../../../stories/_client/Addresses/AddressController";
import SessionContext from "../../../context/SessionContext";
import "../../../css/form/NewProspect.css";

function NewProspect (props) {
    const { handleCloseNewProspect } = props;
    const { handleLogout } = useContext(SessionContext);
    const [ step, setStep ] = useState(1);
    const [ saving, setSaving ] = useState(false);
    const [ showConfirmClose, setShowConfirmClose ] = useState(false);
    const [ globalError, setGlobalError ] = useState("");
    const [ object, setObject ] = useState(new Store());
    const [ errors, setErrors ] = useState({});

    const previous = () => {
        if (step > 1)
            setStep((step - 1));
        else
            handleShowConfirmClose();
    }
    const next = () => {
        if (step === 1)
            validationStep1();
        else if (step === 2)
            validationStep2();
        else if (step === 3)
            validationStep3();
    }
    const refreshObject = object => {
        setObject(object);
        setGlobalError("");
    }
    const handleShowConfirmClose = () => {
        setShowConfirmClose(!showConfirmClose);
    }
    const closeNewProspect = () => {
        // remove new contacts and addresses before close
        let i;
        let controller;

        if (object.contacts.length > 0) {
            controller = new ContactController();

            for (i in object.contacts)
                controller.delete(object.contacts[i], true);
        }

        if (object.addresses.length > 0) {
            controller = new AddressController();

            for (i in object.addresses)
                controller.delete(object.addresses[i], true);
        }

        handleCloseNewProspect();
    }
    const validationStep1 = () => {
        try {
            new StoreController().checkValues(object);
        }
        catch (e) {
            setErrors(JSON.parse(e.message));
            return;
        }

        setStep(2);
    }
    const validationStep2 = () => {
        if (object.contacts.length === 0) {
            setGlobalError("Vous devez saisir au moins un contact");
            return;
        }

        setGlobalError("");
        setStep(3);
    }
    const validationStep3 = () => {
        if (object.addresses.length === 0) {
            setGlobalError("Vous devez saisir au moins une adresse");
            return;
        }

        setGlobalError("");

        save();
    }
    const save = () => {
        if (saving) return;

        setSaving(true);

        const user = JSON.parse(localStorage.getItem("user"))
        const controller = new StoreController();
        controller._callback = handleReturnSave;

        let datas = {
            partnership_id: object.partnership_id,
            name: object.name,
            prospect_phase_id: object.prospect_phase_id,
            acquisition_channel_id: object.acquisition_channel_id,
            creator_id: user.id,
            assigned_to_id: object.assigned_to_id,
            assigned_to_type: object.assigned_to_type,
            contacts: object.contacts,
            addresses: object.addresses
        }

        if (object.company_id > 0) datas.company_id = object.company_id;
        else datas.company_name = object.company_name;

        controller.post(datas);
    }
    const handleReturnSave = (response, error) => {
        setSaving(false);

        if(error) {
            if(error.response !== undefined) {
                console.log("error.response", error.response);

                if(error.response.status === 401)
                    handleLogout();

                setGlobalError("Une erreur s'est produite lors de l'enregistrement");
            }
            else
                setGlobalError("Une erreur s'est produite lors de l'enregistrement");
        }
        else
            window.location = "/prospect/" + response.data.id + "/information";
    }

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox newItem NewProspect">
                    <p className="title">AJOUTER UN PROSPECT</p>
                    <ProspectFormSteps step={ step } />
                    <div className="elevator">
                        {
                            globalError !== ""
                            && <div className="form margin">
                                <Error text={ globalError } classname="noMargin marginTop25" />
                            </div>
                        }
                        {
                            step === 1
                            && <ProspectForm handleCloseNewProspect={ handleCloseNewProspect } newItem={ true } object={ object } setObject={ refreshObject } errors={ errors } />
                        }
                        {
                            step === 2
                            && <div className="form margin">
                                <ListContactForm noSearch={ false } attachTo="store" setGeneralError={ setGlobalError } object={ object } setObject={ refreshObject } />
                            </div>
                        }
                        {
                            step === 3
                            && <div className="form margin">
                                <ListAddressForm setGeneralError={ setGlobalError } object={ object } setObject={ refreshObject } />
                            </div>
                        }
                    </div>
                    <div className="form">
                        <button className="submit" onClick={ next }>
                            {saving ? "Enregistrement..." : (step < 3 ? "Suivant" : "Enregistrer")}
                            <LoaderCircle display="loader submitLogin" hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                        </button>
                        <button className="cancel" onClick={ previous }>
                            {
                                step === 1
                                    ? "Annuler"
                                    : "Retour"
                            }
                        </button>
                        <div className="clearing" />
                    </div>
                </div>
                {
                    showConfirmClose
                    && <div className="overlayer hover">
                        <div className="wrapOverbox hover">
                            <div className="overbox hover yesOrNo">
                            <div className="form margin">
                                <p className="text">Êtes-vous sûr de vouloir abandonner la saisie du prospect ?</p>
                                <button className="delete block" onClick={ closeNewProspect }>Confirmer</button>
                                <button className="cancel" onClick={ handleShowConfirmClose }>Annuler</button>
                                <div className="clearing" />
                            </div>
                        </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default NewProspect;
