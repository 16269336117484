import React, { useContext, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import SessionContext from "../../../context/SessionContext";
import StoreContext from "../../../context/StoreContext";
import NoInputComplex from "../input/NoInputComplex";
import FormAddress from "../../overbox/address/FormAddress";
import DeleteAddress from "../../overbox/address/DeleteAddress";
import AddressController from "../../../stories/_client/Addresses/AddressController";

function ListAddressForm (props) {
    const color = window.matchMedia('(prefers-color-scheme: dark)').matches ? "#FFFFFF" : "#1D1D1D";

    const { separator, handleRefresh, setGeneralError, object, setObject } = props;
    const { handleLogout } = useContext(SessionContext);
    const { store } = useContext(StoreContext);
    const [ deleting, setDeleting ] = useState(false);
    const [ idObjectSelected, setIdObjectSelected ] = useState(0);
    const [ boxToCopy, setBoxToCopy ] = useState(false);
    const [ boxToEdit, setBoxToEdit ] = useState(false);
    const [ boxToDelete, setBoxToDelete ] = useState(false);

    const handleBoxToCopy = id => {
        setIdObjectSelected(id);
        setBoxToCopy(!boxToCopy);
    }
    const handleBoxToEdit = id => {
        setIdObjectSelected(id);
        setBoxToEdit(!boxToEdit);

        if (id === 0 && handleRefresh !== undefined)
            handleRefresh();
    }
    const handleBoxToDelete = id => {
        setBoxToEdit(false);
        setBoxToDelete(!boxToDelete);
        setIdObjectSelected(id);

        if (id === 0 && handleRefresh !== undefined)
            handleRefresh();
    }

    const returnObjectSelected = () => {
        if (idObjectSelected === 0)
            return null;

        let address;

        for (var i in store.addresses) {
            address = store.addresses[i];

            if(idObjectSelected === address.id)
                break;
        }

        return address;
    }

    const handleDelete = () => {
        setDeleting(true);

        const controller = new AddressController();
        controller._callback = returnHandleDelete;
        controller.delete(returnObjectSelected());
    }
    const returnHandleDelete = (response, error) => {
        if(error) {
            if(error.response !== undefined) {
                if(error.response.status === 401)
                    handleLogout();
                else
                    setGeneralError("Une erreur s'est produite lors de la suppression de l'adresse");
            }
            else
                setGeneralError("Une erreur s'est produite lors de la suppression de l'adresse");
        }

        setDeleting(false);
        handleBoxToDelete(0);
    }

    // BUILD

    const buildSeparator = () => {
        return (separator ? <div className="separator"/> : "");
    }
    const buildChoiceCopy = () => {
        let address = returnObjectSelected();
        let street = address.address !== "" ? address.address.trim() : "";
        let additional = address.additional !== "" ? address.additional.trim() : "";
        let postalCode = address.postalCode !== "" ? address.postalCode.trim() : "";
        let city = address.city !== "" ? address.city.trim() : "";
        let country = address.country !== "" ? address.country.trim() : "";

        return <div style={{margin: 0, marginTop: 30 + "px", padding: 0}}>
            {
                street !== "" && <CopyToClipboard text={ street } onCopy={ () => { handleBoxToCopy('address', 0) } }><button className="buttonChoice">{ street }</button></CopyToClipboard>
            }
            {
                additional !== "" && <CopyToClipboard text={ additional } onCopy={ () => { handleBoxToCopy('address', 0) } }><button className="buttonChoice">{ additional }</button></CopyToClipboard>
            }
            {
                postalCode !== "" && <CopyToClipboard text={ postalCode } onCopy={ () => { handleBoxToCopy('address', 0) } }><button className="buttonChoice">{ postalCode }</button></CopyToClipboard>
            }
            {
                city !== "" && <CopyToClipboard text={ city } onCopy={ () => { handleBoxToCopy('address', 0) } }><button className="buttonChoice">{ city }</button></CopyToClipboard>
            }
            {
                country !== "" && <CopyToClipboard text={ country } onCopy={ () => { handleBoxToCopy('address', 0) } }><button className="buttonChoice">{ country }</button></CopyToClipboard>
            }
        </div>;
    }

    // RENDER

    return (
        <div className="clearing">
            {
                boxToCopy && <div className="overlayer">
                    <div className="wrapOverbox">
                        <div className="overbox choices">
                            <div className="form margin">
                                <p className="titleForm center">Que souhaitez-vous copier ?</p>
                                { buildChoiceCopy() }
                                <button className="cancel block" onClick={ () => { handleBoxToCopy(0) } }>Annuler</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                boxToEdit && <FormAddress address={ returnObjectSelected() } handleClose={ () => { handleBoxToEdit(0) } } handleDelete={ handleBoxToDelete } handleRefresh={ handleRefresh } object={ object } setObject={ setObject } />
            }
            {
                boxToDelete && <DeleteAddress address={ returnObjectSelected() } deleting={ deleting } handleClose={ handleBoxToDelete } handleDelete={ handleDelete } />
            }
            { buildSeparator() }
            <label className="label">Adresses</label>
            {
                (object.addresses !== undefined && object.addresses.length > 0)
                && object.addresses.map(
                    address => (
                        <NoInputComplex key={ address.id } color={ color } object={ address } type="address" handleToCopy={ handleBoxToCopy } handleToEdit={ handleBoxToEdit } />
                    )
                )
            }
            <button className="add" onClick={ () => { handleBoxToEdit(0) } }>Ajouter une adresse</button>
        </div>
    );
}

export default ListAddressForm;
