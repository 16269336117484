import React, { useState } from "react"
import moment from "moment";
import Barcode from "react-barcode"
import InputText from "./InputText"
import CrossIcon from "../../../icons/CrossIcon"
import RandomIcon from "../../../icons/RandomIcon"
import BarcodeBuilder from "../../../../class/tool/BarcodeBuilder";
import TrashIcon from "../../../icons/TrashIcon";
moment.locale('fr')

const InputBarcode = props => {
    const { barcodes, storeSettings, classname, handleChange, handleRecovery } = props
    const [ barcode, setBarcode ] = useState("")

    const buildList = () => {
        if (barcodes.length === 0) {
            return <li className="empty">Aucun</li>
        }
        else {
            // sort by dates desc
            barcodes.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at)
            });

            return barcodes.map((row, index) => (
                <li key={ index } className={ row.deleted_at !== null ? "red" : "" }>
                    <div className="wrapBarcodePicture">
                        <Barcode value={ row.barcode } format="EAN13" height={ 40 } />
                    </div>
                    <div className="wrapBarcodeText">
                        <p className="barcode">{ row.barcode }</p>
                        <p className="date">
                            {
                                buildDate(row)
                            }
                        </p>
                    </div>
                    {
                        row.deleted_at === null
                        && <TrashIcon handleClick={ () => { removeBarcodeInValues(row.barcode) } } />
                    }
                    <div className="clearing" />
                </li>
            ))
        }
    }
    const buildDate = row => {
        if (row.toAdd)
            return "En cours d'ajout..."
        else if (row.toDelete)
            return "En cours de suppression..."
        else {
            if (row.deleted_at !== null)
                return "Supprimé le " + moment(row.deleted_at).format("dddd Do MMMM YYYY, LT")
            else
                return "Ajouté le " + moment(row.created_at).format("dddd Do MMMM YYYY, LT")
        }
    }
    const inputBarcode = (attribute, returnType, value) => {
        setBarcode(value)
    }
    const addBarcode = () => {
        if (barcode.length === 8 || barcode.length === 13) {
            insertBarcodeInValues(barcode)
            setBarcode("")
        }
        else
            alert("La longueur du code doit etre de 8 ou 13 caractères")
    }
    const randomBarcode = () => {
        const randomCode = BarcodeBuilder.buildRandomBarcode("products", storeSettings)
        insertBarcodeInValues(randomCode)
    }
    const insertBarcodeInValues = code => {
        handleChange("add", "barcode", code)
    }
    const removeBarcodeInValues = code => {
        handleChange("remove", "barcode", code)
    }

    return (
        <div className={ "inputBarcode " + (classname !== undefined ? classname : "") }>
            <InputText classname="extend" attribute="barcode" value={ barcode } placeholder="Saisir un code barre" handleChange={ inputBarcode } />
            <RandomIcon click={ randomBarcode } />
            <CrossIcon click={ addBarcode } />
            <div className="clearing" />
            <ul>
            {
                buildList()
            }
            </ul>
        </div>
    );
}

export default InputBarcode;
