import React, {useContext, useEffect, useState} from "react";
import LoaderCircle from "../../loader/LoaderCircle";
import NoInput from "../../form/input/NoInput";
import InputText from "../../form/input/InputText";
import InputSelect from "../../form/input/InputSelect";
import InputRichText from "../../form/input/InputRichText";

import ProspectPhaseController from "../../../stories/_setup/ProspectPhase/ProspectPhaseController";
import ProspectingPhaseController from "../../../stories/_client/ProspectingPhases/ProspectingPhaseController";
import ProspectingPhase from "../../../stories/_client/ProspectingPhases/ProspectingPhase";

import SessionContext from "../../../context/SessionContext";
import StoreContext from "../../../context/StoreContext";

function AddProspectingPhase (props) {
    const color = window.matchMedia('(prefers-color-scheme: dark)').matches ? "#FFFFFF" : "#1D1D1D";
    const types = ["EMAIL", "PHONE", "VISIT", "OTHER"];
    const listTypes = [
        {
            key: 0,
            value: "Email"
        },
        {
            key: 1,
            value: "Téléphone"
        },
        {
            key: 2,
            value: "Visite"
        },
        {
            key: 3,
            value: "Autre"
        }
    ];

    const { newItem, handleAdd, handleClose } = props;
    const { handleLogout } = useContext(SessionContext);
    const { store } = useContext(StoreContext);
    const [ first, setFirst ] = useState(true);
    const [ loading, setLoading ] = useState(true);
    const [ saving, setSaving ] = useState(false);
    const [ globalError, setGlobalError ] = useState("");
    const [ listProspectPhases, setListProspectPhases ] = useState([]);
    const [ prospectPhase, setProspectPhase ] = useState("");
    const [ editProspectPhase, setEditProspectPhase ] = useState(false);
    const [ errorProspectPhase, setErrorProspectPhase ] = useState("");
    const [ date, setDate ] = useState("");
    const [ editDate, setEditDate ] = useState(false);
    const [ errorDate, setErrorDate ] = useState("");
    const [ type, setType ] = useState("PHONE");
    const [ editType, setEditType ] = useState(false);
    const [ errorType, setErrorType ] = useState("");
    const [ goal, setGoal ] = useState("");
    const [ editGoal, setEditGoal ] = useState(false);
    const [ errorGoal, setErrorGoal ] = useState("");

    const handleGetProspectPhases = (list, error) => {
        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else
                alert("Une erreur s'est produite ");
        }

        let prospectPhasesList = [];

        list.map(object => {
            prospectPhasesList.push({
                key: object.id,
                value: object.name
            });
        });

        if (prospectPhase.length === 0 && prospectPhasesList.length > 0)
            setProspectPhase(prospectPhasesList[0].key);

        setListProspectPhases(prospectPhasesList);
        setLoading(false);
    }
    const getProspectPhases = () => {
        setFirst(false);

        const controller = new ProspectPhaseController();
        controller._callback = handleGetProspectPhases;
        controller.index();
    }

    useEffect(() => {
        if (first)
            getProspectPhases();
    })

    // PERFORMS

    const getProspectPhaseName = () => {
        for (var i in listProspectPhases)
            if (listProspectPhases[i].key === prospectPhase)
                return listProspectPhases[i].value;

        return "";
    }
    const handleEditProspectPhase = () => {
        if(processEdit(editProspectPhase))
            setEditProspectPhase(!editProspectPhase);
    }
    const handleChangeProspectPhase = event => {
        setProspectPhase(event.currentTarget.value);
    }
    const checkProspectPhase = () => {
        try {
            ProspectingPhaseController.checkProspectPhase(prospectPhase);
        }
        catch (e) {
            if (e.message === "EMPTY") setErrors("prospectPhase", true);
            else setErrors("prospectPhase", false);
        }

        setErrorDate("");

        return true;
    }

    const handleEditDate = () => {
        if(processEdit(editDate))
            setEditDate(!editDate);
    }
    const handleChangeDate = event => {
        setDate(event.currentTarget.value);
    }
    const checkDate = () => {
        try {
            ProspectingPhaseController.checkAlertDate(date);
        }
        catch (e) {
            if (e.message === "EMPTY") setErrors("date", true);
            else setErrors("date", false);
        }

        setErrorDate("");

        return true;
    }

    const getTypeName = () => {
        for (var i in listTypes)
            if (types[listTypes[i].key] === type)
                return listTypes[i].value;

        return "";
    }
    const handleEditType = () => {
        if(processEdit(editType))
            setEditType(!editType);
    }
    const handleChangeType = event => {
        setType(event.currentTarget.value);
    }
    const checkType = () => {
        try {
            ProspectingPhaseController.checkType(type);
        }
        catch (e) {
            if (e.message === "EMPTY") setErrors("type", true);
            else setErrors("type", false);
        }

        setErrorDate("");

        return true;
    }

    const handleEditGoal = () => {
        if(processEdit(editGoal))
            setEditGoal(!editGoal);
    }
    const handleChangeGoal = text => {
        setGoal(text);
    }
    const checkGoal = () => {
        setErrorType("");
        return true;
    }

    // CONTROL & SAVE

    const processEdit = edit => {
        if(edit) {
            if(checkProspectPhase() && checkDate() && checkType() && checkGoal() && !newItem)
                save();
            else
                return false;
        }

        return true;
    }
    const reinitAllErrors = () => {
        setGlobalError("");
        setErrorProspectPhase("");
        setErrorDate("");
        setErrorType("");
        setErrorGoal("");
    }

    const setErrors = (type, empty) => {
        switch (type) {
            case "prospectPhase":
                if(empty) setErrorProspectPhase("Vous devez faire un choix");
                else setErrorProspectPhase("Ce choix est incorrect");
                break;
            case "date":
                setErrorType("La date est incorrecte");
                break;
            case "type":
                if(empty) setErrorType("Vous devez faire un choix");
                else setErrorType("Ce choix est incorrect");
                break;
            case "goal":
                setErrorGoal("La donnée saisie est incorrecte");
                break;
            default: break;
        }
    }
    const check422Errors = errorDatas => {
        setGlobalError("Certaines données sont invalides");

        if(errorDatas !== undefined) {
            var keys = Object.keys(errorDatas);
            var fields = ["prospectPhase", "date", "type", "goal"];

            for(var item in fields)
                if(keys.includes(fields[item]))
                    setErrors(fields[item], false);
        }
    }

    const returnUpdates = (controller, datas) => {
        if (controller === undefined)
            controller = new ProspectingPhaseController();

        return controller.returnUpdatesFromCompare(new ProspectingPhase(), datas);
    }

    const handleReturnSave = (response, error) => {
        setSaving(false);

        if(error) {
            if(error.response !== undefined) {
                if(error.response.status === 401)
                    handleLogout();
                else if(error.response.status === 422)
                    check422Errors(error.response.data);
            }
            else
                setGlobalError("Une erreur s'est produite lors de l'enregistrement");
        }
    }
    const save = () => {
        setSaving(true);

        reinitAllErrors();

        const controller = new ProspectingPhaseController();
        const datas = returnUpdates(controller, {
            prospect_phase_id: prospectPhase,
            date: date,
            type: types[type],
            goal: /*draftToHtml(convertToRaw(goal.getCurrentContent()))*/""
        });

        if(Object.keys(datas).length === 0) return;

        controller._callback = handleReturnSave;
        controller.post(store, datas);
    }

    // RENDER

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox newItem">
                    {
                        loading
                        ? <LoaderCircle display="loader logWait" hide="" strokeWidth="5" stroke="#008C4F" />
                        : <div className="form margin">
                            <p className="titleForm center">AJOUTER UNE ETAPE DE PROSPECTION</p>
                            <label className="label">Étape de prospection</label>
                            {
                                !editProspectPhase && !newItem
                                    ? <NoInput value={ getProspectPhaseName() } color={ color } edit={ true } copy={ getProspectPhaseName() !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditProspectPhase } />
                                    : <InputSelect color={ color } classError={ errorProspectPhase !== "" ? "wrong" : "" } list={ listProspectPhases } value={ prospectPhase } newItem={ newItem } handleChange={ handleChangeProspectPhase } handleBlur={ handleEditProspectPhase } />
                            }
                            {
                                errorProspectPhase !== ""
                                    ? <p className="error">{ errorProspectPhase }</p>
                                    : ""
                            }
                            <label className="label marginBottom">Date</label>
                            {
                                !editDate && !newItem
                                    ? <NoInput value={ date !== "" ? date : "Aucune" } edit={ true } handleEdit={ handleEditDate } />
                                    : <InputText classError={ errorDate !== "" ? "wrong" : "" } value={ date } type="dateTimePicker" placeholder="__/__/____ __:__" newItem={ newItem } handleChange={ handleChangeDate } handleBlur={ handleEditDate } />
                            }
                            {
                                errorDate !== "" && <p className="error">{errorDate}</p>
                            }
                            <label className="label">Type de relance</label>
                            {
                                !editType && !newItem
                                    ? <NoInput value={ getTypeName() } color={ color } edit={ true } copy={ getTypeName() !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditType } />
                                    : <InputSelect color={ color } classError={ errorType !== "" ? "wrong" : "" } list={ listTypes } value={ type } newItem={ newItem } handleChange={ handleChangeType } handleBlur={ handleEditType } />
                            }
                            {
                                errorType !== ""
                                    ? <p className="error">{ errorType }</p>
                                    : ""
                            }
                            <label className="label marginBottom">Description</label>
                            {
                                !editGoal && !newItem
                                    ? <NoInput value={ goal !== "" ? goal : "Aucune" } edit={ true } handleEdit={ handleEditGoal } />
                                    : <InputRichText classError={ errorGoal !== "" ? "wrong" : "" } value={ goal } placeholder="Description..." newItem={ newItem } handleChange={ handleChangeGoal } handleBlur={ handleEditGoal } />
                            }
                            {
                                errorGoal !== "" && <p className="error">{errorGoal}</p>
                            }
                            <button className="submit" onClick={ save }>
                                {saving ? "Enregistrement..." : "Enregistrer"}
                                <LoaderCircle display="loader submitLogin" hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                            </button>
                            <button className="cancel" onClick={ handleClose }>Annuler</button>
                            <div className="clearing" />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default AddProspectingPhase;
