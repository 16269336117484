import React from "react";

function BarStrength (props) {
    const { strength } = props;

    const labelStrength = () => {
        if (strength === 1)
            return "low";
        else if (strength === 2)
            return "mid";
        else if (strength === 3)
            return "strong";

        return "";
    }

    return (
        <div className="barStrength">
            <div className={ "bar " + labelStrength() } />
        </div>
    );
}

export default BarStrength;
