import React from "react";

export default React.createContext({
    generalError: "",
    list: [],
    selectSearch: [],
    valueSelectSearch: 0,
    inputSearch: "",
    initialLoading: true,
    loading: false
})
