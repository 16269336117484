import React, {useContext, useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import DeleteIcon from "../../icons/DeleteIcon";
import LoaderCircle from "../../loader/LoaderCircle";
import Conversation from "../../page/contents/clientTicket/Conversation";
import ClientTicketController from "../../../stories/_ticket/ClientTickets/ClientTicketController";
import SessionContext from "../../../context/SessionContext";
import StoreContext from "../../../context/StoreContext";

function SheetClientTicket (props) {
    const { id, handleRefresh, handleClose } = props;
    const { handleLogout, globalError, setGlobalError } = useContext(SessionContext);
    const [ first, setFirst ] = useState(true);
    const [ loading, setLoading ] = useState(false);
    const [ clientTicket, setClientTicket ] = useState(null);
    const location = useLocation();


    // Perform

    const buildBackUrl = () => {
        var paths = location.pathname.split("/");
        paths.splice(paths.length - 1, 1);
        return paths.join("/");
    }

    const handleGetClientTicket = (object, error) => {
        setLoading(false);

        if(error) {
            if(error.response !== undefined) {
                if (error.response.status === 401)
                    handleLogout();
                else if (error.response.status === 404)
                    setGlobalError("Ce ticket client est introuvable");
                else {
                    var message = "-";

                    if (error.response.data != undefined && error.response.data.message != undefined)
                        message = error.response.data.message;

                    setGlobalError("Une erreur s'est produite : " + message);
                }
            }
            else
                setGlobalError("Impossible de récupérer les données");
        }
        else
            setClientTicket(object);
    }
    const getClientTicket = () => {
        setLoading(true);

        const controller = new ClientTicketController();
        controller._callback = handleGetClientTicket;
        controller.show(id);
    }

    const init = () => {
        setFirst(false);
        getClientTicket();
    }

    useEffect(() => {
        if (first)
            init();
    });


    // Render

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox sheet">
                    <Link to={ buildBackUrl() }>
                        <div className="cross">
                            <DeleteIcon />
                        </div>
                    </Link>
                    {
                        clientTicket != null
                            ? <Conversation ticket={ clientTicket } handleRefresh={ handleRefresh } />
                            : <LoaderCircle display="loader logWait" hide="" strokeWidth="5" stroke="#008C4F" />
                    }
                </div>
            </div>
        </div>
    );
}

export default SheetClientTicket;
