import Entity from "../../../class/abstract/Entity";

class Contract extends Entity {
    name = "";
    setup = null;
    type_duration = null;
    billing_period = null;
    creator = null;
    commitment = false;
    begin = "";
    end = "";
    paused = false;
    paused_at = "";
    delayed_at = "";
    discountValue = 0;
    discountType = "";
    amountInclTax = 0;
    amountInclTaxUndiscounted = 0;
    amountExclTax = 0;
    amountExclTaxUndiscounted = 0;
    amountTax = 0;
    amountTaxUndiscounted = 0;

    constructor(datas = {}) {
        super(datas);
        this.setAttributes(datas);
    }

    setAttributes(datas) {
        var keys = Object.keys(this);
        var key = "";
        var i = 0;

        for(i in keys) {
            key = keys[i];

            if (datas[key] !== undefined)
                Reflect.set(this, key, datas[key]);
        }
    }
}

export default Contract;
