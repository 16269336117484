import React, {useContext, useState} from "react";
import LoaderCircle from "../../loader/LoaderCircle";
import SessionContext from "../../../context/SessionContext";

import "../../../css/form/NewContract.css";

function NewTicket (props) {
    const { handleClose } = props;
    const { handleLogout, setGlobalError } = useContext(SessionContext);
    const [ saving, setSaving ] = useState(false);


    // Performs

    const save = () => {
        if (saving) return;

        /*
        setSaving(true);

        const user = JSON.parse(localStorage.getItem("user"))
        const controller = new StoreController();
        controller._callback = handleReturnSave;

        let datas = {
            partnership_id: object.partnership_id,
            name: object.name,
            prospect_phase_id: object.prospect_phase_id,
            acquisition_channel_id: object.acquisition_channel_id,
            creator_id: user.id,
            assigned_to_id: object.assigned_to_id,
            assigned_to_type: object.assigned_to_type,
            contacts: object.contacts,
            addresses: object.addresses
        }

        if (object.company_id > 0) datas.company_id = object.company_id;
        else datas.company_name = object.company_name;

        controller.post(datas);
        */
    }
    const handleReturnSave = (response, error) => {
        setSaving(false);

        if(error) {
            if(error.response !== undefined) {
                console.log("error.response", error.response);

                if(error.response.status === 401)
                    handleLogout();

                setGlobalError("Une erreur s'est produite lors de l'enregistrement");
            }
            else
                setGlobalError("Une erreur s'est produite lors de l'enregistrement");
        }
        else
            window.location = "/prospect/" + response.data.id + "/information";
    }


    // Render

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox newItem NewContract">
                    <p className="title">AJOUTER UN TICKET CLIENT</p>
                    <div className="form margin">
                        <button className="submit">
                            {saving ? "Enregistrement..." : "Enregistrer"}
                            <LoaderCircle display="loader submitLogin" hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                        </button>
                        <button className="cancel">Annuler</button>
                        <div className="clearing" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewTicket;
