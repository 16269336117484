import Entity from "../../../class/abstract/Entity";
import SetupType from "../SetupTypes/SetupType";
import InstallationPhase from "../InstallationPhase/InstallationPhase";

class Setup extends Entity {
    name = "";
    type_id = 0;
    type_name = "";
    expected_date = "";
    already_installed = false;
    setup_type = {};
    installation_phases = [];

    constructor(datas = {}) {
        super(datas);

        this.setAttributes(datas);
    }

    setAttributes(datas) {
        this.id = datas.id !== undefined ? datas.id : 0;
        this.name = datas.name !== undefined ? datas.name : "";
        this.type_id = datas.type_id !== undefined ? datas.type_id : 0;
        this.type_name = datas.type_name !== undefined ? datas.type_name : "";
        this.expected_date = datas.expected_date !== undefined ? datas.expected_date : null;
        this.already_installed = datas.already_installed !== undefined ? datas.already_installed : false;
        this.updated_at = datas.updated_at !== undefined ? datas.updated_at : "";
        this.created_at = datas.created_at !== undefined ? datas.created_at : "";
        this.setup_type = datas.setup_type !== undefined ? new SetupType(datas.setup_type) : {};

        if (datas.installation_phases_list)
            for (var i in datas.installation_phases_list)
                this.installation_phases.push(new InstallationPhase(datas.installation_phases_list[i]));
    }
}

export default Setup;
