import React from "react";
import AccessIcon from "../../../../icons/AccessIcon";

function PartnershipUserGroupLine (props) {
    const isDark = window.matchMedia('(prefers-color-scheme: dark)');
    const color = isDark.matches ? "#FFFFFF" : "#1D1D1D";
    const { object, handleShowFormUpdateUserGroup } = props;

    return (
        <tr className="line">
            <td className="lastname left">{ object.name }</td>
            <td className="email left">{ object.email }</td>
            <td className="member center">{ object.nb_members }</td>
            <td className="action">
                <div className="access" onClick={ () => { handleShowFormUpdateUserGroup(object.id) } }>
                    <AccessIcon fill={ color } />
                </div>
            </td>
        </tr>
    );
}

export default PartnershipUserGroupLine;
