import Rest from "../../../class/tool/Rest";
import Contact from "./Contact";
import SetupType from "../../_setup/SetupTypes/SetupType";

class ContactController {
    _rest = new Rest();
    _callback = null;
    _baseURL = "contacts";

    index(input= "") {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + (input !== "" ? "?input=" + input : ""),
            {},
            this.handleIndex);
    }
    handleIndex = (response, error) => {
        let listObjects = [];

        if(response.data !== undefined) {
            const data = response.data.data;

            for(var item in data)
                listObjects.push(new Contact(data[item]));
        }

        this._callback(listObjects, error);
    }

    show(id) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + '/' + id,
            {},
            this.handleShow);
    }
    handleShow = (response, error) => {
        const object = !error ? new Contact(response.data) : null;
        this._callback(object, error);
    }

    post(datas) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURL,
            datas,
            this.handlePost
        );
    }
    handlePost = (response, error) => {
        this._callback(response, error);
    }

    put(object, datas) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'PUT',
            '/' + this._baseURL + '/' + object.id,
            datas,
            this.handlePut
        );
    }
    handlePut = (response, error) => {
        this._callback(response, error);
    }

    delete(object, secure = false) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'DELETE',
            '/' + this._baseURL + '/' + object.id + (secure && '?secure'),
            {},
            this.handleDelete);
    }
    handleDelete = (response, error) => {
        this._callback(response, error);
    }

    returnUpdatesFromCompare(object, datas) {
        var dataKeys = Object.keys(datas);
        var key = "";
        var updates = {};

        for(var i in dataKeys) {
            key = dataKeys[i];

            if (datas[key] !== undefined) {
                if (key === "tags") {
                    var tags = object.tags;

                    if (tags.length !== datas[key].length)
                        Reflect.set(updates, key, datas[key]);
                    else {
                        var finded = false;

                        for (var item in tags) {
                            finded = false;

                            for (var item1 in datas[key]) {
                                if (tags[item].name === datas[key][item1].name && tags[item].color === datas[key][item1].color) {
                                    finded = true;
                                    break;
                                }
                            }

                            if (!finded) {
                                Reflect.set(updates, key, datas[key]);
                                break;
                            }
                        }
                    }
                }
                else {
                    if (Reflect.get(object, key) !== datas[key])
                        Reflect.set(updates, key, datas[key]);
                }
            }
        }

        return updates;
    }
}

export default ContactController;
