import React from "react";
import LessIcon from "../../../../icons/LessIcon";
import CrossIcon from "../../../../icons/CrossIcon";

function StockProduct (props) {
    const { object, handleStockForm } = props;

    return (
        <div className={ "storageStock " + object.state }>
            <div className="less">
                <LessIcon click={ () => { handleStockForm(object.storage, 'out') } } />
            </div>
            <p className="key">{ object.quantity }</p>
            <div className="plus">
                <CrossIcon click={ () => { handleStockForm(object.storage, 'in') } } />
            </div>
            <p className="title">{ object.storage.city }</p>
        </div>
    );
}

export default StockProduct;
