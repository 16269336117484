import React, {useContext} from "react";
import StoreContext from "../../../context/StoreContext";

function AddContact (props) {
    const { handleClose, handleSearch, handleNew } = props;
    const { store } = useContext(StoreContext);

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox choices">
                    <div className="form margin">
                        <p className="titleForm center">Vous souhaitez ajouter un contact à <strong>{ store.name }</strong></p>
                        <button className="buttonChoice spaceTop" onClick={ handleSearch }>Ajouter un contact existant</button>
                        <button className="buttonChoice" onClick={ handleNew }>Créer un nouveau contact</button>
                        <button className="cancel block" onClick={ handleClose }>Annuler</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddContact;
