import Rest from "../../../class/tool/Rest";
import TicketPriority from "./TicketPriority";

class TicketPriorityController {
    _rest = new Rest();
    _callback = null;
    _baseURL = "ticketpriorities";

    index() {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL,
            {},
            this.handleIndex);
    }
    handleIndex = (response, error) => {
        let listObjects = [];

        if(response.data !== undefined) {
            const data = response.data;

            for(var item in data)
                listObjects.push(new TicketPriority(data[item]));
        }

        this._callback(listObjects, error);
    }

    returnUpdatesFromCompare(object, datas) {
        var dataKeys = Object.keys(datas);
        var key = "";
        var updates = {};

        for(var i in dataKeys) {
            key = dataKeys[i];

            if (datas[key] !== undefined) {
                if (Reflect.get(object, key) !== datas[key])
                    Reflect.set(updates, key, datas[key]);
            }
        }

        return updates;
    }
}

export default TicketPriorityController;
