import React from "react";

function ListChoices (props) {
    const { values, width, left, top, handleSelect } = props;

    return (
        <div className="listChoices" style={{ width: width, left: left, top: top }}>
            {
                values.map(value => (
                    <div key={ value.id } className="choice" onClick={ () => { handleSelect(value) } }>
                        <p className="main">{ value.main }</p>
                        {
                            value.secondary !== undefined && <p className="secondary">{ value.secondary }</p>
                        }
                    </div>
                ))
            }
        </div>
    );
}

export default ListChoices;
