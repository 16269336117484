import React, {useContext, useEffect, useState} from "react";
import moment from "moment";
import NoInput from "../../../form/input/NoInput";
import InputText from "../../../form/input/InputText";
import ClientTicketController from "../../../../stories/_ticket/ClientTickets/ClientTicketController";
import InputRichText from "../../../form/input/InputRichText";

import SessionContext from "../../../../context/SessionContext";

import "../../../../css/page/clientTicket/Conversation.css";
import InputSelect from "../../../form/input/InputSelect";
import Contact from "../../../../stories/_client/Contacts/Contact";
import TicketTypeController from "../../../../stories/_setting/TicketType/TicketTypeController";
import TicketPriorityController from "../../../../stories/_setting/TicketPriority/TicketPriorityController";
import TicketStateController from "../../../../stories/_setting/TicketState/TicketStateController";
import LoaderCircle from "../../../loader/LoaderCircle";
import StoreContext from "../../../../context/StoreContext";
import ChoiceMultiLine from "../../../form/input/ChoiceMultiLine";
import HistoryConversation from "./HistoryConversation";

function Conversation (props) {
    moment.updateLocale('fr', {
        months : 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
        monthsShort : 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
        monthsParseExact : true,
        weekdays : 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
        weekdaysShort : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
        weekdaysMin : 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
        weekdaysParseExact : true,
        longDateFormat : {
            LT : 'HH:mm',
            LTS : 'HH:mm:ss',
            L : 'DD/MM/YYYY',
            LL : 'D MMMM YYYY',
            LLL : 'D MMMM YYYY HH:mm',
            LLLL : 'dddd D MMMM YYYY HH:mm'
        },
        calendar : {
            sameDay : '[Aujourd’hui à] LT',
            nextDay : '[Demain à] LT',
            nextWeek : 'dddd [à] LT',
            lastDay : '[Hier à] LT',
            lastWeek : 'dddd [dernier à] LT',
            sameElse : 'L'
        },
        relativeTime : {
            future : '%s',
            past : '%s',
            s : 'quelques secondes',
            m : 'une minute',
            mm : '%d minutes',
            h : 'une heure',
            hh : '%d heures',
            d : 'un jour',
            dd : '%d jours',
            M : 'un mois',
            MM : '%d mois',
            y : 'un an',
            yy : '%d ans'
        },
        dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
        ordinal : function (number) {
            return number + (number === 1 ? 'er' : 'e');
        },
        meridiemParse : /PD|MD/,
        isPM : function (input) {
            return input.charAt(0) === 'M';
        },
        // In case the meridiem units are not separated around 12, then implement
        // this function (look at locale/id.js for an example).
        // meridiemHour : function (hour, meridiem) {
        //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
        // },
        meridiem : function (hours, minutes, isLower) {
            return hours < 12 ? 'PD' : 'MD';
        },
        week : {
            dow : 1, // Monday is the first day of the week.
            doy : 4  // Used to determine first week of the year.
        }
    });

    const { ticket, handleRefresh } = props;
    const { handleLogout, setGlobalError } = useContext(SessionContext);
    const { store } = useContext(StoreContext);
    const [ first, setFirst ] = useState(true);
    const [ loadingTypes, setLoadingTypes ] = useState(true);
    const [ loadingPriorities, setLoadingPriorities ] = useState(true);
    const [ loadingStates, setLoadingStates ] = useState(true);
    const [ loadingHistory, setLoadingHistory ] = useState(true);
    const [ saving, setSaving ] = useState(false);
    const [ ticketTypes, setTicketTypes ] = useState([]);
    const [ ticketPriorities, setTicketPriorities ] = useState([]);
    const [ ticketStates, setTicketStates ] = useState([]);
    const [ histories, setHistories ] = useState([]);
    const [ title, setTitle ] = useState(ticket.title);
    const [ editTitle, setEditTitle ] = useState(false);
    const [ errorTitle, setErrorTitle ] = useState("");
    const [ description, setDescription ] = useState(ticket.description);
    const [ editDescription, setEditDescription ] = useState(false);
    const [ errorDescription, setErrorDescription ] = useState("");
    const [ type, setType ] = useState(ticket.type_id);
    const [ errorType, setErrorType ] = useState("");
    const [ priority, setPriority ] = useState(ticket.priority_id);
    const [ errorPriority, setErrorPriority ] = useState("");
    const [ state, setState ] = useState(ticket.state_id);
    const [ errorState, setErrorState ] = useState("");
    const [ comment, setComment ] = useState("");
    const color = window.matchMedia('(prefers-color-scheme: dark)').matches ? "#FFFFFF" : "#1D1D1D";
    const dictPriorities = {
        "LOW": "Faible",
        "MIDDLE": "Moyen",
        "HIGH": "Fort"
    };
    const dictStates = {
        "WAIT": "En attente",
        "RUN": "En cours",
        "CLOSE": "Ferme",
        "HOLD": "En pause"
    };


    // Init

    const handleGetTicketTypes = (list, error) => {
        if(error) {
            if(error.response !== undefined) {
                if(error.response.status === 401)
                    handleLogout();
                else {
                    var message = "";

                    if (error.response.data != undefined && error.response.data.message != undefined && error.response.data.message != "")
                        message = " : " + error.response.data.message;

                    setGlobalError("Impossible de recuperer les types de ticket" + message);
                }
            }
            else
                setGlobalError("Impossible de recuperer les types de ticket");
        }
        else {
            let typesList = [];

            list.map(object => {
                typesList.push({
                    key: object.id,
                    value: object.name
                });
            });

            setTicketTypes(typesList);
        }

        setLoadingTypes(false);
    }
    const getTicketTypes = () => {
        let controller = new TicketTypeController();
        controller._callback = handleGetTicketTypes;
        controller.index(store.partnership_id);
    }

    const handleGetTicketPriorities = (list, error) => {
        if(error) {
            if(error.response !== undefined) {
                if(error.response.status === 401)
                    handleLogout();
                else {
                    var message = "";

                    if (error.response.data != undefined && error.response.data.message != undefined && error.response.data.message != "")
                        message = " : " + error.response.data.message;

                    setGlobalError("Impossible de recuperer les priorites de ticket" + message);
                }
            }
            else
                setGlobalError("Impossible de recuperer les priorites de ticket");
        }
        else {
            let prioritiesList = [];

            list.map(object => {
                prioritiesList.push({
                    key: object.id,
                    value: object.name
                });
            });

            setTicketPriorities(prioritiesList);
        }

        setLoadingPriorities(false);
    }
    const getTicketPriorities = () => {
        let controller = new TicketPriorityController();
        controller._callback = handleGetTicketPriorities;
        controller.index();
    }

    const handleGetTicketStates = (list, error) => {
        if(error) {
            if(error.response !== undefined) {
                if(error.response.status === 401)
                    handleLogout();
                else {
                    var message = "";

                    if (error.response.data != undefined && error.response.data.message != undefined && error.response.data.message != "")
                        message = " : " + error.response.data.message;

                    setGlobalError("Impossible de recuperer les etats de ticket" + message);
                }
            }
            else
                setGlobalError("Impossible de recuperer les etats de ticket");
        }
        else {
            let statesList = [];

            list.map(object => {
                statesList.push({
                    key: object.id,
                    value: object.name
                });
            });

            setTicketStates(statesList);
        }

        setLoadingStates(false);
    }
    const getTicketStates = () => {
        let controller = new TicketStateController();
        controller._callback = handleGetTicketStates;
        controller.index();
    }

    const handleGetTicketHistory = (list, error) => {
        if(error) {
            if(error.response !== undefined) {
                if(error.response.status === 401)
                    handleLogout();
                else {
                    var message = "";

                    if (error.response.data != undefined && error.response.data.message != undefined && error.response.data.message != "")
                        message = " : " + error.response.data.message;

                    setGlobalError("Impossible de recuperer l'historique" + message);
                }
            }
            else
                setGlobalError("Impossible de recuperer l'historique");
        }
        else {
            console.log("HISTORY", list);
            setHistories(list);
        }

        setLoadingHistory(false);
    }
    const getTicketHistory = () => {
        let controller = new ClientTicketController();
        controller._callback = handleGetTicketHistory;
        controller.history(ticket.id);
    }

    const init = () => {
        setFirst(false);

        getTicketTypes();
        getTicketPriorities();
        getTicketStates();
        getTicketHistory();
    }

    useEffect(() => {
        if (first)
            init();
    });

    // check states to save updates
    useEffect(() => {
        processEdit(true);
    },[type, priority, state]);


    // Perform

    const displayDate = strDate => {
        return <span className="date">Le <b>{ moment(strDate).format("DD/MM/YYYY")} </b> à <b> { moment(strDate).format("HH:mm") }</b></span>;
    }
    const fromNow = strDate => {
        return <span className="fromNow">il y a <b>{ moment(strDate).fromNow() }</b></span>;
    }

    const handleEditTitle = () => {
        if (processEdit(editTitle))
            setEditTitle(!editTitle);
    }
    const handleChangeTitle = event => {
        setTitle(event.currentTarget.value);
    }
    const checkTitle = () => {
        if(title === "") {
            setErrors("title", true);
            return false;
        }

        setErrorTitle("");
        return true;
    }

    const handleEditDescription = () => {
        if (processEdit(editDescription))
            setEditDescription(!editDescription);
    }
    const handleChangeDescription = value => {
        setDescription(value);
    }
    const checkDescription = () => {
        if(title === "") {
            setErrors("description", true);
            return false;
        }

        setErrorDescription("");
        return true;
    }

    const handleChangeType = event => {
        setType(parseInt(event.currentTarget.value));
    }
    const checkType = () => {
        if(type === 0) {
            setErrors("type", true);
            return false;
        }

        setErrorType("");

        return true;
    }

    const handleChangePriority = event => {
        setPriority(parseInt(event.currentTarget.value));
    }
    const checkPriority = () => {
        if(type === 0) {
            setErrors("priority", true);
            return false;
        }

        setErrorPriority("");

        return true;
    }

    const handleChangeState = event => {
        setState(parseInt(event.currentTarget.value));
    }
    const checkState = () => {
        if(type === 0) {
            setErrors("state", true);
            return false;
        }

        setErrorState("");

        return true;
    }

    const handleChangeComment = value => {
        setComment(value);
    }
    const handleSendComment = () => {
        saveComment();
    }


    // Control & save

    const processEdit = edit => {
        if(edit) {
            if(checkTitle() && checkDescription() && checkType() && checkPriority() && checkState()) save();
            else return false;
        }

        return true;
    }
    const reinitAllEdits = () => {
        setEditTitle(false);
        setEditDescription(false);
    }
    const reinitAllErrors = () => {
        setGlobalError("");
        setErrorTitle("");
        setErrorDescription("");
        setErrorType("");
        setErrorPriority("");
        setErrorState("");
    }

    const check422Errors = errorDatas => {
        setGlobalError("Certaines données sont invalides");

        if(errorDatas !== undefined) {
            var keys = Object.keys(errorDatas);
            var fields = ["title", "description", "type", "priority", "state"];

            for(var item in fields) {
                if(keys.includes(fields[item])) {
                    setErrors(fields[item], false);
                }
            }
        }
    }
    const setErrors = (type, empty) => {
        switch (type) {
            case "title":
                if(empty) setErrorTitle("Vous devez saisir un titre");
                else setErrorTitle("Ce titre n'est pas valide");
                break;
            case "description":
                if(empty) setErrorDescription("Vous devez saisir une description");
                else setErrorDescription("Cette description n'est pas valide");
                break;
            case "type":
                if(empty) setErrorType("Vous devez saisir un type");
                else setErrorType("Ce type de ticket n'est pas valide");
                break;
            case "priority":
                if(empty) setErrorPriority("Vous devez saisir une priorite");
                else setErrorPriority("Cette priorite n'est pas valide");
                break;
            case "state":
                if(empty) setErrorState("Vous devez saisir un etat");
                else setErrorState("Cet etat n'est pas valide");
                break;
            default: break;
        }
    }

    const handleKeyEnter = event => {
        if (event.key === 'Enter')
            if(processEdit(true))
                reinitAllEdits();
    }

    const returnUpdates = controller => {
        if (controller === undefined)
            controller = new ClientTicketController();

        return controller.returnUpdatesFromCompare(ticket, {
            "title": title,
            "description": description,
            "type_id": type,
            "priority_id": priority,
            "state_id": state
        });
    }

    const handleReturnSave = (response, error) => {
        if(error) {
            if(error.response !== undefined) {
                if(error.response.status === 401)
                    handleLogout();
                else if(error.response.status === 422)
                    check422Errors(error.response.data);
            }
            else
                setGlobalError("Une erreur s'est produite lors de l'enregistrement");
        }
        else {
            handleRefresh();

            ticket.title = title;
            ticket.description = description;
            ticket.type_id = type;
            ticket.priority_id = priority;
            ticket.state_id = state;

            getTicketHistory();
        }

        setSaving(false);
    }
    const save = () => {
        reinitAllErrors();

        const controller = new ClientTicketController();
        const datas = returnUpdates(controller);

        if(Object.keys(datas).length === 0) return;

        setSaving(true);

        controller._callback = handleReturnSave;
        controller.put(ticket, datas);
    }

    const handleReturnSaveComment = (response, error) => {
        if(error) {
            if(error.response !== undefined) {
                if(error.response.status === 401)
                    handleLogout();
                else if(error.response.status === 422)
                    check422Errors(error.response.data);
            }
            else
                setGlobalError("Une erreur s'est produite lors de l'enregistrement");
        }
        else {
            setComment("");
            getTicketHistory();
        }

        setSaving(false);
    }
    const saveComment = () => {
        setSaving(true);

        let controller = new ClientTicketController();
        controller._callback = handleReturnSaveComment;
        controller.postComment(ticket.id, {
            "message": comment
        });
    }


    // Render

    return (
        <div className="conversation clientTicket">
            {
                !loadingTypes && !loadingPriorities && !loadingStates
                    ? <div className="form noPadding">
                        <p className="creatorInfo">
                            Créé par <b>{ ticket.created_by_name }</b><br />
                            { displayDate(ticket.created_at) }, { fromNow(ticket.created_at) }
                        </p>
                        <div className="scrollview">
                            {
                                !editTitle
                                    ? <NoInput classname="titleConversation" value={ ticket.title !== "" ? ticket.title : "Aucun titre" } color={ color } edit={ true } copy={ false } phone={ false } email={ false } link={ false } handleEdit={ handleEditTitle } />
                                    : <InputText color={ color } classError={ errorTitle !== "" ? "wrong" : "" } value={ title } placeholder="Titre" newItem={ false } handleChange={ handleChangeTitle } handleBlur={ handleEditTitle } handleKeyEnter={ handleKeyEnter } />
                            }
                            {
                                !editDescription
                                    ? <NoInput type="rich" classname="descriptionConversation" value={ ticket.description !== "" ? ticket.description : "Aucune description" } color={ color } edit={ true } copy={ false } phone={ false } email={ false } link={ false } handleEdit={ handleEditDescription } />
                                    : <InputRichText value={ description } placeholder="Description..." handleChange={ handleChangeDescription } handleBlur={ handleEditDescription } />
                            }
                            <InputSelect color={ color } classname="inline" classError={ errorType !== "" ? "wrong" : "" } list={ ticketTypes } value={ type } newItem={ true } handleChange={ handleChangeType } handleBlur={ null } />
                            <InputSelect color={ color } classname="inline" classError={ errorPriority !== "" ? "wrong" : "" } list={ ticketPriorities } dictionary={ dictPriorities } value={ priority } newItem={ true } handleChange={ handleChangePriority } handleBlur={ null } />
                            <InputSelect color={ color } classname="inline" classError={ errorState !== "" ? "wrong" : "" } list={ ticketStates } dictionary={ dictStates } value={ state } newItem={ true } handleChange={ handleChangeState } handleBlur={ null } />
                            <div className="clearing" />
                            {
                                loadingHistory
                                    ? <LoaderCircle display="loader logWait" hide="" strokeWidth="5" stroke="#008C4F" />
                                    : <div className="history">
                                        {
                                            histories.map(item => (
                                                <HistoryConversation key={ item.id } item={ item } />
                                            ))
                                        }
                                    </div>
                            }
                        </div>
                        <div className="write">
                            <InputRichText classname="writeComment" value={ comment } placeholder="Inscrire un commentaire..." saving={ saving } handleChange={ handleChangeComment } handleBlur={ handleSendComment } />
                        </div>
                    </div>
                    : <LoaderCircle display="loader logWait" hide="" strokeWidth="5" stroke="#008C4F" />
            }
        </div>
    );
}

export default Conversation;
