import React, {useEffect, useState} from "react";
import UserForm from "../../form/user/UserForm";
import UserController from "../../../stories/_auth/Users/UserController";
import LoaderCircle from "../../loader/LoaderCircle";

function FormUser (props) {
    const { handleCloseFormUser, handleBoxAskDeleteUser, newItem, idUser } = props;
    const [ first, setFirst ] = useState(true);
    const [ user, setUser ] = useState(null);

    const getUser = () => {
        const controller = new UserController();
        controller._callback = handleGetUser;
        controller.show(idUser);
    }
    const handleGetUser = (object, error) => {
        setFirst(false);
        setUser(object);
    }

    useEffect(() => {
        if(first && !newItem && idUser !== undefined)
            getUser();
    });

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox newItem">
                    {
                        newItem || (!newItem && user !== null)
                        ? <UserForm handleCloseFormUser={ handleCloseFormUser } handleAskDeleteUser={ handleBoxAskDeleteUser } newItem={ newItem } user={ user } />
                        : <LoaderCircle display="loader logWait" hide="" strokeWidth="5" stroke="#008C4F" />
                    }
                </div>
            </div>
        </div>
    );
}

export default FormUser;
