import React, { useEffect, useState } from "react"
import Search2Icon from "../../../icons/Search2Icon"
import ToUppercase from "../../../icons/ToUppercase"
import ToBold from "../../../icons/ToBold";
import SeeIcon from "../../../icons/SeeIcon";

const InputText = props => {
    const {
        attribute,
        returnType,
        color,
        type,
        maxChars,
        autoComplete,
        classname,
        backgroundColor,
        classError,
        value,
        bold,
        list,
        placeholder,
        autoFocus,
        readonly,
        toUppercase,
        toBold,
        handleChange,
        handleBlur,
        handleSubmit,
        handleSearch,
        handleSelect
    } = props
    const [ inputType, setInputType ] = useState(type)
    const [ listDisplayed, setListDisplayed ] = useState(list)

    const filterList = () => {
        let listFiltred = []

        for(let index in list) {
            if (list[index].value.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase().includes(value.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase())) {
                listFiltred.push(list[index])
            }
        }

        setListDisplayed(listFiltred)
    }
    const changeEvent = event => {
        let value = event.currentTarget.value

        if (maxChars !== undefined && value.length > maxChars) return

        if (handleChange === undefined || handleChange === null) return
        handleChange(attribute, returnType, value)
    }
    const blurEvent = () => {
        if (handleBlur === undefined || handleBlur === null) return
        handleBlur()
    }
    const keyDownEvent = event => {
        if (event.key === "Enter" && handleSubmit !== undefined && handleSubmit !== null) {
            event.preventDefault()
            handleSubmit()
        }
    }
    const clickOnBold = () => {
        toBold(attribute, returnType, false)
    }
    const clickOnSeePwd = () => {
        console.error("clickOnSeePwd", inputType)
        if (inputType === "password")
            setInputType("text")
        else
            setInputType("password")
    }

    useEffect(() => {
        if (list !== undefined)
            document.querySelector('.smartList').style.width = document.querySelector('.inputText input').offsetWidth + "px"
    }, [])
    useEffect(() => {
        setListDisplayed(list)
    }, [list])
    useEffect(() => {
        filterList()
    }, [value])

    return (
        <div className={ "inputText " + (classname !== undefined ? classname : "") }>
            <input
                type={ inputType !== undefined ? inputType : "text" }
                autoComplete={ autoComplete !== null ? autoComplete : "on" }
                className={ "extend " + (bold === 1 ? " bold" : "") + (classError !== undefined && classError) }
                value={ value !== null ? value : "" }
                placeholder={ placeholder }
                onChange={ changeEvent }
                onBlur={ blurEvent }
                autoFocus={ autoFocus !== null ? autoFocus : true }
                readOnly={ (readonly !== undefined && readonly) }
                onKeyDown={ keyDownEvent }
                style={{backgroundColor: ((backgroundColor !== undefined && backgroundColor !== null) ? backgroundColor + "22" : "transparent")}} />
            {
                toUppercase !== undefined
                && <ToUppercase
                    fill={ color }
                    classname="toUppercase"
                    handleClick={ toUppercase }
                />
            }
            {
                toBold !== undefined
                && <ToBold
                    fill={ color }
                    classname="toBold"
                    handleClick={ clickOnBold }
                />
            }
            {
                handleSearch !== undefined
                && <Search2Icon
                    fill={ color }
                    classname="searchInput"
                    handleClick={ handleSearch }
                />
            }
            {
                type === "password"
                && <SeeIcon classname="searchInput" click={ clickOnSeePwd } />
            }
            {
                maxChars !== undefined
                && <p className="maxChars">{value.length}/{maxChars}</p>
            }
            <div className="clearing" />
            {
                list
                && <ul className="smartList" style={{width: document.querySelector('.inputText input')+"px"}}>
                    {
                        listDisplayed.map(item => (
                            <li key={ item.key } onClick={ () => { handleSelect(item.key) } }>
                                {
                                    item.value
                                }
                            </li>
                        ))
                    }
                </ul>
            }
        </div>
    )
}

export default InputText
