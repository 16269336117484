import React, { useContext } from "react";
import { Link } from "react-router-dom";
import LoaderCircle from "../../../../loader/LoaderCircle";
import StoreContext from "../../../../../context/StoreContext";
import "../../../../../css/page/cards/Tabs.css";
import AccessIcon from "../../../../icons/AccessIcon";
import OptionProspect from "../OptionProspect";

function ProspectTabs (props) {
    const color = window.matchMedia('(prefers-color-scheme: dark)').matches ? "#FFFFFF" : "#1D1D1D";
    const states = {
        "ACTIVE": "ACTIF",
        "STANDBY": "EN VEILLE",
        "EJECT": "EJECTÉ"
    }

    const { tab, sending, handleChangeProspectState, handleManage, show, handleToClient, handleDelete } = props;
    const { store } = useContext(StoreContext);

    return (
        <div className="tabs">
            <p className="title">
                { (store.company_name !== "" ? store.company_name + " | " : "") + store.name }
                <span className={ "vignette " + store.prospect_state } onClick={ handleChangeProspectState }>{ states[store.prospect_state] }</span>
            </p>
            <nav>
                <Link to={ "/prospect/" + store.id + "/information" }><p className={ "tab " + (tab === "information" ? "selected" : "") }>Informations</p></Link>
                <Link to={ "/prospect/" + store.id + "/quote" }><p className={ "tab " + (tab === "quote" ? "selected" : "") }>Devis</p></Link>
                <div className="clearing" />
            </nav>
            {
                sending
                ? <LoaderCircle display="loader restPUT" hide="" strokeWidth="10" stroke="#008C4F" />
                : <div className="access" onClick={ handleManage }>
                    <AccessIcon fill={ color } />
                </div>
            }
            <div className="clearing" />
            <OptionProspect show={ show } handleChangeProspectState={ handleChangeProspectState } handleToClient={ handleToClient } handleDelete={ handleDelete } />
        </div>
    );
}

export default ProspectTabs;
