import Account from "./Account"
import Rest from "../../../class/tool/Rest"

class AccountController {
    _rest = new Rest()
    _callback = null
    _baseURL = "setups/{idSetup}/accounts"
    _baseURLExistAccount = "setups/{idSetup}/existaccounts"
    _baseURLTechnicialAccount = "setups/{idSetup}/technicianaccounts"

    setFormValues(object, setValues) {
        setValues(prev => ({
            ...prev,
            firstname: this.capitalizeFLetter(object.firstname.toLocaleLowerCase()),
            lastname: this.capitalizeFLetter(object.lastname.toLocaleLowerCase()),
            email: object.email.toLocaleLowerCase(),
            companyRule: this.buildRules(object.companyRules),
            rule: this.buildRules(object.rules)
        }));
    }
    returnUpdatesFromCompare(object, datas, compare = true) {
        let dataKeys = Object.keys(datas);
        let key = "";
        let updates = {};

        for(let i in dataKeys) {
            key = dataKeys[i];

            if (datas[key] !== undefined) {
                if (compare) {
                    if (Reflect.get(object, key) !== datas[key] && datas[key] !== null && (datas[key].length > 0 || datas[key] >= 0))
                        Reflect.set(updates, key, datas[key]);
                }
                else {
                    if (datas[key] !== null)
                        Reflect.set(updates, key, datas[key]);
                }
            }
        }

        return updates;
    }
    checkValues(object) {
        const params = [
            { name: "name", method: "checkName", val: object.name },
            { name: "label", method: "checkLabel", val: object.label },
            { name: "color", method: "checkColor", val: object.color },
            { name: "reference", method: "checkReference", val: object.reference },
            { name: "referenceProvider", method: "checkReferenceProvider", val: object.referenceProvider },
            { name: "provider", method: "checkProvider", val: object.provider_id },
            { name: "brand", method: "checkBrand", val: object.brand_id },
            { name: "category", method: "checkCategory", val: { id: object.category_id, type: object.category_type } },
            { name: "description", method: "checkDescription", val: object.description },
            { name: "nullStockTrigger", method: "checkNullStockTrigger", val: object.nullStockTrigger },
            { name: "printDescription", method: "checkPrintDescription", val: object.printDescription },
            { name: "soldOut", method: "checkSoldOut", val: object.soldOut },
            { name: "stockManagement", method: "checkStockManagement", val: object.stockManagement },
            { name: "stockThresholdTrigger", method: "checkStockThresholdTrigger", val: object.stockThresholdTrigger },
            { name: "unit", method: "checkUnit", val: object.unit },
            { name: "variablePrice", method: "checkVariablePrice", val: object.variablePrice }
        ];
        let errors = {};
        let method;

        for (let i in params) {
            method = AccountController[params[i].method];

            try { method.apply(null, params[i].val); }
            catch (e) { errors.params[i].name = e.message; }
        }

        if (Object.keys(errors).length > 0) throw new Error(JSON.stringify(errors));
        else return true;
    }
    capitalizeFLetter(str) {
        return str[0].toUpperCase() + str.slice(1);
    }
    buildRules(strRules) {
        if (strRules === null || strRules === undefined || strRules.length === 0)
            return []
        else
            return strRules.split('|')
    }

    indexAccounts(setup) {
        this._rest._contentType = 'application/json'
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idSetup}", setup.id),
            {},
            this.handleIndexAccount)
    }
    handleIndexAccount = (response, error, status) => {
        let listObjects = []

        if(response.data !== undefined) {
            const data = response.data

            for(let item in data)
                listObjects.push(new Account(data[item]))
        }

        this._callback(listObjects, error, status)
    }

    addAccount(setup, datas) {
        this._rest._contentType = 'application/json'
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURL.replace("{idSetup}", setup.id),
            datas,
            this.handleAddAccount
        )
    }
    handleAddAccount = (response, error, status) => {
        this._callback(response, error, status)
    }

    registerAccounts(setup, ids, rules) {
        this._rest._contentType = 'application/json'
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURLExistAccount.replace("{idSetup}", setup.id),
            {
                contacts: ids,
                companyRule: rules.companyRule,
                rule: rules.rule
            },
            this.handleRegisterAccounts
        )
    }
    handleRegisterAccounts = (response, error, status) => {
        this._callback(response, error, status)
    }

    registerTechnicianAccount(setup) {
        this._rest._contentType = 'application/json'
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURLTechnicialAccount.replace("{idSetup}", setup.id),
            {},
            this.handleRegisterTechnicianAccount
        )
    }
    handleRegisterTechnicianAccount = (response, error, status) => {
        this._callback(response, error, status);
    }

    updateAccount(setup, id, datas) {
        this._rest._contentType = 'application/json'
        this._rest.sendQuery(
            'PUT',
            '/' + this._baseURL.replace("{idSetup}", setup.id) + '/' + id,
            datas,
            this.handleUpdateAccount
        )
    }
    handleUpdateAccount = (response, error, status) => {
        this._callback(response, error, status)
    }

    disableAccount(setup, id) {
        this._rest._contentType = 'application/json'
        this._rest.sendQuery(
            'PUT',
            '/' + this._baseURL.replace("{idSetup}", setup.id) + '/' + id,
            {
                status: 427
            },
            this.handleDisableAccount
        )
    }
    handleDisableAccount = (response, error, status) => {
        this._callback(response, error, status)
    }

    enableAccount(setup, id) {
        this._rest._contentType = 'application/json'
        this._rest.sendQuery(
            'PUT',
            '/' + this._baseURL.replace("{idSetup}", setup.id) + '/' + id,
            {
                status: 200
            },
            this.handleEnableAccount
        )
    }
    handleEnableAccount = (response, error, status) => {
        this._callback(response, error, status)
    }

    removeAccount(setup, id) {
        this._rest._contentType = 'application/json'
        this._rest.sendQuery(
            'DELETE',
            '/' + this._baseURL.replace("{idSetup}", setup.id) + '/' + id,
            {},
            this.handleRemoveAccount
        )
    }
    handleRemoveAccount = (response, error, status) => {
        this._callback(response, error, status)
    }
}

export default AccountController
