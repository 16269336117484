import React from "react"
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'

const InputRichText = props => {
    const {
        attribute,
        returnType,
        classname,
        value,
        placeholder,
        handleChange,
        handleBlur
    } = props;

    const changeEvent = value => {
        if (handleChange === undefined || handleChange === null) return;
        handleChange(attribute, returnType, value);
    }
    const blurEvent = () => {
        if (handleBlur === undefined || handleBlur === null) return;
        handleBlur();
    }

    return (
        <div
            className={ "inputText " + (classname !== undefined ? classname : "") }
            onBlur={ blurEvent }>
            <ReactQuill
                value={ value }
                placeholder={ placeholder }
                modules={{toolbar: [
                        ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
                        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                        ['link'],
                        ['clean']
                    ]}}
                onChange={ changeEvent }
                theme="snow"
            />
        </div>
    );
}

export default InputRichText;
