import React from "react";
import ChoiceMultiLine from "./ChoiceMultiLine";

function InputMultiLinesChoices (props) {
    const { color, list, listSelected, handleSelectChoiceList, mini, noMarginTop } = props;

    return (
        <div className={ "inputMultiLinesChoices" + (mini !== undefined ? " minimalHeight" : "") + (noMarginTop !== undefined ? " noMarginTop" : "") }>
            {
                list.map(item => (
                    <ChoiceMultiLine key={ item.id } color={ color } item={ item } pending={ listSelected.filter(object => { return object.id === item.id && object.pending }).length > 0 } selected={ listSelected.filter(object => { return object.id === item.id }).length > 0 } handleSelectChoiceList={ handleSelectChoiceList } />
                ))
            }
        </div>
    );
}

export default InputMultiLinesChoices;
