import Rest from "../../../class/tool/Rest";
import TicketType from "./TicketType";

class TicketTypeController {
    _rest = new Rest();
    _callback = null;
    _endURL = "tickettypes";
    _baseURL = "partnerships/{idPartnership}/" + this._endURL;

    indexAll() {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._endURL,
            {},
            this.handleIndex);
    }
    index(partnershipId) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idPartnership}", partnershipId),
            {},
            this.handleIndex);
    }
    handleIndex = (response, error) => {
        let listObjects = [];

        if(response.data !== undefined) {
            const data = response.data;

            for(var item in data)
                listObjects.push(new TicketType(data[item]));
        }

        this._callback(listObjects, error);
    }

    show(partnershipId, id) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idPartnership}", partnershipId) + '/' + id,
            {},
            this.handleShow);
    }
    handleShow = (response, error) => {
        const object = !error ? new TicketType(response.data) : null;
        this._callback(object, error);
    }

    post(partnershipId, datas) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURL.replace("{idPartnership}", partnershipId),
            datas,
            this.handlePost
        );
    }
    handlePost = (response, error) => {
        this._callback(response, error);
    }

    put(partnershipId, object, datas) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'PUT',
            '/' + this._baseURL.replace("{idPartnership}", partnershipId) + '/' + object.id,
            datas,
            this.handlePut
        );
    }
    handlePut = (response, error) => {
        this._callback(response, error);
    }

    delete(partnershipId, object) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'DELETE',
            '/' + this._baseURL.replace("{idPartnership}", partnershipId) + '/' + object.id,
            {},
            this.handleDelete);
    }
    handleDelete = (response, error) => {
        this._callback(response, error);
    }

    returnUpdatesFromCompare(object, datas) {
        var dataKeys = Object.keys(datas);
        var key = "";
        var updates = {};

        for(var i in dataKeys) {
            key = dataKeys[i];

            if (datas[key] !== undefined) {
                if (Reflect.get(object, key) !== datas[key])
                    Reflect.set(updates, key, datas[key]);
            }
        }

        return updates;
    }
}

export default TicketTypeController;
