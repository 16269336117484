import React, {useContext, useEffect, useState} from "react";
import InputText from "../input/InputText";
import LoaderCircle from "../../loader/LoaderCircle";
import SessionContext from "../../../context/SessionContext";
import StockController from "../../../stories/_catalog/Stocks/StockController";
import InputMultiLinesChoices from "../input/InputMultiLinesChoices";

function StockForm (props) {
    const colorVar = window.matchMedia('(prefers-color-scheme: dark)').matches ? "#FFFFFF" : "#1D1D1D";

    const { handleClose, product, type, stocks, storage } = props;
    const { handleLogout } = useContext(SessionContext);
    const [ globalError, setGlobalError ] = useState("");
    const [ first, setFirst ] = useState(true);
    const [ saving, setSaving ] = useState(false);
    const [ quantity, setQuantity ] = useState(product.hasSerial ? "0" : "");
    const [ errorQuantity, setErrorQuantity ] = useState("");
    const [ serial, setSerial ] = useState("");
    const [ serials, setSerials ] = useState([]);
    const [ serialsSelected, setSerialsSelected ] = useState([]);

    // INIT

    const init = () => {
        setFirst(false);

        if (type === "out") {
            for (var item in stocks) {
                if (stocks[item].storage.id === storage.id) {
                    var listSerials = [];

                    for (var i in stocks[item].serials)
                        listSerials.push({
                            "id": i,
                            "main": stocks[item].serials[i]
                        });

                    setSerials(listSerials);
                }
            }
        }
    }

    // HANDLES EDITION

    const handleChangeQuantity = event => {
        setQuantity(event.currentTarget.value);
    }
    const handleChangeSerial = event => {
        setSerial(event.currentTarget.value);
    }
    const handleSelectSerial = id => {
        var idToRemove = -1;
        var list;
        var item;

        if (type === "in") {
            list = serials.slice();

            for (item in list)
                if (list[item].id === id)
                    idToRemove = item;

            if (idToRemove >= 0)
                list.splice(idToRemove, 1);

            setSerials(list);
            setSerialsSelected(list);
            setQuantity(parseInt(quantity, 10) - 1);
        }
        else {
            list = serialsSelected.slice();

            for (item in list)
                if (list[item].id === id)
                    idToRemove = item;

            if (idToRemove >= 0) {
                list.splice(idToRemove, 1);
                setQuantity(parseInt(quantity, 10) - 1);
            }
            else {
                list.push(serials[id]);
                setQuantity(parseInt(quantity, 10) + 1);
            }

            setSerialsSelected(list);
        }
    }
    const handleKeyEnterSerial = event => {
        if (event.key === 'Enter') {
            var list = serials.slice();

            list.push({
                id: list.length > 0 ? list[(list.length - 1)].id + 1 : 1,
                main: serial
            });

            setSerials(list);
            setSerialsSelected(list);
            setSerial("");
            setQuantity(parseInt(quantity, 10) + 1);
        }
    }

    // CONTROL & SAVE

    const reinitAllErrors = () => {
        setGlobalError("");
        setErrorQuantity("");
    }

    const setErrors = (type, empty) => {
        switch (type) {
            case "quantity":
                if(empty) setErrorQuantity("Vous devez saisir une quantité");
                else setErrorQuantity("Cette quantité n'est pas valide");
                break;
            default: break;
        }
    }
    const check422Errors = errorDatas => {
        setGlobalError("Certaines données sont invalides");

        if(errorDatas !== undefined) {
            var keys = Object.keys(errorDatas);
            var fields = ["quantity"];

            for(var item in fields)
                if(keys.includes(fields[item]))
                    setErrors(fields[item], false);
        }
    }

    const listSerialsToSend = () => {
        var list = [];

        for (var item in serialsSelected)
            list.push(serialsSelected[item].main);

        return list;
    }

    const handleReturnSave = (response, error) => {
        setSaving(false);

        if(error) {
            if(error.response !== undefined) {
                if(error.response.status === 401)
                    handleLogout();
                else if(error.response.status === 422)
                    check422Errors(error.response.data);
                else if(error.response.status === 403)
                    setGlobalError("Vous n'avez pas les droits d'effectuer cette action");
                else
                    setGlobalError("Une erreur s'est produite lors de l'enregistrement");
            }
            else
                setGlobalError("Une erreur s'est produite lors de l'enregistrement");
        }
        else
            handleClose();
    }
    const save = () => {
        setSaving(true);

        reinitAllErrors();

        const controller = new StockController();
        controller._callback = handleReturnSave;

        const object = {
            "storage_id": storage.id,
            "quantity": quantity,
            "serials": listSerialsToSend()
        };

        if (type === "in")
            controller.add(product, object);
        else
            controller.remove(product, object)
    }

    useEffect(() => {
        if(first)
            init();
    });

    // RENDER

    return (
        <div className="contForm">
            <div className="form margin">
                <p className="titleForm center">{ (type === "in" ? "AJOUTER DU STOCK A " : "RETIRER DU STOCK DE ") + storage.city }</p>
                {
                    globalError !== ""
                        ? <p className="globalError">{ globalError }</p>
                        : ""
                }
                <label className="label">Quantité</label>
                <InputText color={ colorVar } classError={ errorQuantity !== "" ? "wrong" : "" } value={ quantity } placeholder="Quantité" readonly={ product.hasSerial } newItem={ true } handleChange={ handleChangeQuantity } />
                {
                    errorQuantity !== ""
                        ? <p className="error">{ errorQuantity }</p>
                        : ""
                }
                {
                    product.hasSerial
                        && <div className="clearing">
                            <label className="label">Numéros de série</label>
                            {
                                type === "in" && <InputText color={ colorVar } classError={ errorQuantity !== "" ? "wrong" : "" } value={ serial } placeholder="Numéro de série" newItem={ true } focus={ true } handleChange={ handleChangeSerial } handleKeyEnter={ handleKeyEnterSerial } />
                            }
                            <InputMultiLinesChoices color={ colorVar } list={ serials } listSelected={ serialsSelected } noMarginTop={ true } handleSelectChoiceList={ handleSelectSerial } />
                        </div>
                }
                <div>
                    <button className="submit" onClick={ save }>
                        {saving ? "Enregistrement..." : "Enregistrer"}
                        <LoaderCircle display="loader submitLogin" hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                    </button>
                    <button className="cancel" onClick={ handleClose }>Annuler</button>
                    <div className="clearing" />
                </div>
            </div>
        </div>
    );
}

export default StockForm;
