import React from "react";
import CheckIcon from "../../icons/CheckIcon";
import LoaderCircle from "../../loader/LoaderCircle";

function ChoiceMultiLine (props) {
    const { item, pending, selected, handleSelectChoiceList } = props;
    const color = window.matchMedia('(prefers-color-scheme: dark)').matches ? "#FFFFFF" : "#1D1D1D";

    return (
        <div className="choiceMultiLine" onClick={ () => { handleSelectChoiceList(item.id) } }>
            <div className="contChecboxChoice">
                {
                    pending !== undefined && pending
                        ? <LoaderCircle display="loader" strokeWidth="8" stroke={ color } />
                        : <CheckIcon classname={ "checkboxChoice " + (selected ? "selected" : "") } />
                }

            </div>
            <div className="contTextChoice">
                <p className={ "main " + (item.secondary === undefined || item.secondary === "" ? "middle" : "") }>{ item.main }</p>
                {
                    (item.secondary !== undefined && item.secondary !== "") && <p className="secondary">{ item.secondary }</p>
                }
            </div>
            <div className="clearing" />
        </div>
    );
}

export default ChoiceMultiLine;
