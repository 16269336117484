import React  from "react";

import "../../../../../css/page/ToolsBar.css";

function TaxToolsBar (props) {
    const { handleNew } = props;

    return (
        <div className="toolsBarComponent">
            <p className="button" onClick={ () => { handleNew(null) } }>Ajouter une TVA</p>
            <div className="clearing"/>
        </div>
    );
}

export default TaxToolsBar;
