import React from 'react';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.bubble.css';
import LoaderCircle from "../../loader/LoaderCircle";

function InputRichText (props) {
    const { classname, placeholder, value, newItem, saving, handleChange, handleBlur } = props;

    return (
        <div className={ "inputTextarea " + (classname !== undefined ? classname : "") + (newItem ? " newItem" : "") }>
            <ReactQuill
                value={value}
                placeholder={placeholder}
                modules={{toolbar: [
                        ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
                        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                        ['link'],
                        ['clean']
                    ]}}
                bounds=".inputTextarea"
                onChange={handleChange}
                theme="bubble"
            />
            {
                saving !== undefined && saving
                    ? <LoaderCircle display="loader submitComment" strokeWidth="8" stroke="#008C4F" />
                    : <p className="validate" onClick={handleBlur}>
                        {
                            classname === "writeComment"
                                ? "ENVOYER"
                                : "TERMINER"
                        }
                    </p>
            }
        </div>
    );
}

export default InputRichText;
