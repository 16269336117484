import React from "react";

function ContractIcon (props) {
    const {fill, classname} = props;

    return (
        <svg
            height="1em"
            viewBox="0 0 62 62"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
            fill={fill}
            className={classname}
        >
            <path d="m36.028 23.232 15.09 11.548a19.312 19.312 0 0 0 1.629-8.516L41.224 14.278h-.102c-5.434 0-9.11 4.78-9.177 4.83l-6.234 6.801c-.967 1.049-1.207 2.525.013 3.558a2.243 2.243 0 0 0 3.1-.167l5.797-5.964c.504-.461 1.215-.252 1.407-.104zM43.952 4.582a3.631 3.631 0 0 0-2.63 1.136c-1.475 1.534-1.475 4.029 0 5.562l14.311 14.884c1.412 1.467 3.855 1.466 5.261.001 1.475-1.534 1.475-4.029 0-5.562L46.583 5.719a3.631 3.631 0 0 0-2.631-1.137c-.992.001.991 0 0 0zM18.049 4c-.992 0-1.926.403-2.631 1.136L1.105 20.021c-1.474 1.532-1.474 4.028.001 5.561a3.658 3.658 0 0 0 5.261-.001l14.312-14.885c1.473-1.532 1.473-4.027-.001-5.56C19.985 4.427 19.046 4 18.049 4zM13.038 35.778l-3.21 3.339a1.583 1.583 0 0 0-.001 2.174l.515.536a1.403 1.403 0 0 0 2.004 0l3.21-3.339c.577-.6.577-1.576 0-2.176l-.514-.535a1.404 1.404 0 0 0-2.004.001zM18.109 39.574c-.376 0-.732.154-1.001.435l-.001.001-3.211 3.339a1.584 1.584 0 0 0 .001 2.175l.514.535c.539.559 1.465.56 2.005-.001l3.209-3.339c.577-.6.577-1.576 0-2.176l-.513-.533a1.38 1.38 0 0 0-1.003-.436zm-1.768-.317h.01zM22.301 43.642c-.376 0-.732.154-1.001.435l-.001.001-3.211 3.339a1.584 1.584 0 0 0 0 2.174l.515.536c.539.559 1.465.56 2.005-.001l3.211-3.339a1.584 1.584 0 0 0 0-2.174l-.515-.535a1.38 1.38 0 0 0-1.003-.436zm-1.768-.317h.01zM26.354 47.857c-.376 0-.732.154-1.001.435l-.001.001-3.211 3.339a1.584 1.584 0 0 0 .001 2.175l.514.535c.539.559 1.465.56 2.005-.001l3.21-3.339c.577-.6.577-1.576 0-2.176l-.513-.532a1.38 1.38 0 0 0-1.004-.437zm-1.769-.318h.01z" />
            <path d="m28.297 53.657 2.691 2.566c.492.473.724 1.002 1.35 1.354.775.439 1.728.541 2.623.285.787-.227 1.41-.7 1.756-1.333.341-.623.422-1.452.221-2.275a1.085 1.085 0 0 1 .317-1.056 1.06 1.06 0 0 1 1.07-.224c.896.314 1.789.287 2.276-.073.703-.519 1.128-2.132.591-3.831-.135-.425.003-.89.348-1.171a1.06 1.06 0 0 1 1.206-.093c1.39.812 2.815.682 3.449.144.713-.604 1.094-2.279.463-3.931a1.087 1.087 0 0 1 .182-1.085 1.065 1.065 0 0 1 1.028-.362 2.756 2.756 0 0 0 1.69-.17c1.4-.62 1.928-2.356 1.727-3.75a3.677 3.677 0 0 0-.556-1.463l-15.24-11.664-5.136 5.284c-1.631 1.691-4.211 1.829-6.003.312a4.554 4.554 0 0 1-1.615-3.454 4.347 4.347 0 0 1 1.447-3.271l5.701-6.218c-2.228-2.608-5.431-4.087-8.917-4.087l-.211.002a7.689 7.689 0 0 0-.378.016L9.671 25.244a17.569 17.569 0 0 0 1.488 9.388l.346-.36c1.352-1.406 3.72-1.406 5.071-.001l.515.536a3.727 3.727 0 0 1 1.03 2.607 3.49 3.49 0 0 1 2.525 1.091l.513.533a3.724 3.724 0 0 1 1.028 2.447 3.493 3.493 0 0 1 2.651 1.088l.514.534a3.731 3.731 0 0 1 1.031 2.59 3.487 3.487 0 0 1 2.507 1.091l.513.532c1.376 1.432 1.376 3.758.001 5.187z" />
        </svg>
    );
}

export default ContractIcon;
