import React from "react";

import '../../../../css/page/Options.css';

const OptionProspect = props => {
    const { show, handleChangeProspectState, handleToClient, handleDelete } = props;

    return (
        <div className={"overOptions prospectOptions " + (show ? " show" : "")}>
            <nav className="options">
                <p className="item" onClick={ handleChangeProspectState }>Changer le statut</p>
                <p className="item" onClick={ handleToClient }>Basculer en client</p>
                <p className="item red" onClick={ handleDelete }>Supprimer</p>
            </nav>
        </div>
    );
}

export default OptionProspect;
