import Rest from "../../../class/tool/Rest";
import Provider from "./Provider";
import Contact from "../../_client/Contacts/Contact";

class ProviderController {
    _rest = new Rest();
    _callback = null;
    _baseURL = "providers";

    index(partnership = 0, input= "") {
        var params = "";

        if(partnership > 0)
            params += "?partnership=" + partnership;

        if(input !== "")
            params += (params.length === 0 ? "?" : "&") + "input=" + input;

        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + params,
            {},
            this.handleIndex);
    }
    handleIndex = (response, error) => {
        let listObjects = [];

        if(response.data !== undefined) {
            const data = response.data.data;

            for(var item in data)
                listObjects.push(new Provider(data[item]));
        }

        this._callback(listObjects, error);
    }

    show(id) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + '/' + id,
            {},
            this.handleShow);
    }
    handleShow = (response, error) => {
        const object = !error ? new Provider(response.data) : null;
        this._callback(object, error);
    }

    post(object) {
        const objectToSend = JSON.parse(JSON.stringify(object));

        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURL,
            objectToSend,
            this.handlePost
        );
    }
    handlePost = (response, error) => {
        this._callback(response, error);
    }

    put(object) {
        const objectToSend = JSON.parse(JSON.stringify(object));

        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'PUT',
            '/' + this._baseURL + '/' + objectToSend.id,
            objectToSend,
            this.handlePut
        );
    }
    handlePut = (response, error) => {
        this._callback(response, error);
    }

    delete(object) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'DELETE',
            '/' + this._baseURL + '/' + object.id,
            {},
            this.handleDelete);
    }
    handleDelete = (response, error) => {
        this._callback(response, error);
    }

    // Contacts

    indexContact(store, input= "") {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + '/' + store.id + '/contacts' + (input !== "" ? "?input=" + input : ""),
            {},
            this.handleIndexContact);
    }
    handleIndexContact = (response, error) => {
        let listObjects = [];

        if(response.data !== undefined) {
            const data = response.data.contacts;

            for(var item in data)
                listObjects.push( new Contact(data[item]));
        }

        this._callback(listObjects, error);
    }

    postContact(store, idContact) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'POST',
            '/' + this._baseURL + '/' + store.id + '/contacts',
            {
                "contact": idContact
            },
            this.handlePostContact
        );
    }
    handlePostContact = (response, error) => {
        this._callback(response, error);
    }

    deleteContact(store, idContact) {
        this._rest._contentType = 'application/json';
        this._rest.sendQuery(
            'DELETE',
            '/' + this._baseURL + '/' + store.id + '/contacts/' + idContact,
            {},
            this.handleDeleteContact);
    }
    handleDeleteContact = (response, error) => {
        this._callback(response, error);
    }
}

export default ProviderController;
