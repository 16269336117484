import React from "react";
import {Link} from "react-router-dom";
import AccessIcon from "../../../../icons/AccessIcon";

function ClientLine (props) {
    const isDark = window.matchMedia('(prefers-color-scheme: dark)');
    const color = isDark.matches ? "#FFFFFF" : "#1D1D1D";
    const { store } = props;

    return (
        <tr className="line">
            <td className="company left">
                <Link to={ "/client/" + store.id + "/information" }>
                    { store.company_name }
                </Link>
            </td>
            <td className="store left">
                <Link to={ "/client/" + store.id + "/information" }>
                    { store.name }
                </Link>
            </td>
            <td className="postalCode left">{ store.postalCode }</td>
            <td className="city left">{ store.city }</td>
            <td className="country left">{ store.country }</td>
            <td className="action">
                <Link to={ "/client/" + store.id + "/information" }>
                    <div className="access">
                        <AccessIcon fill={ color } />
                    </div>
                </Link>
            </td>
        </tr>
    );
}

export default ClientLine;
