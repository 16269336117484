import Entity from "../../../class/abstract/Entity";
import Company from "../Companies/Company";

class LegalData extends Entity {
    socialReason = "";
    siren = "";
    siret = "";
    ape = "";
    tvaIntra = "";

    constructor(datas = {}) {
        super(datas);

        this.setAttributes(datas);
    }

    setAttributes(datas) {
        this.id = datas.id !== undefined ? datas.id : 0;
        this.socialReason = datas.socialReason !== undefined ? datas.socialReason : "";
        this.siren = datas.siren !== undefined ? datas.siren : "";
        this.siret = datas.siret !== undefined ? datas.siret : "";
        this.ape = datas.ape !== undefined ? datas.ape : "";
        this.tvaIntra = datas.tvaIntra !== undefined ? datas.tvaIntra : "";
        this.updated_at = datas.updated_at !== undefined ? datas.updated_at : "";
        this.created_at = datas.created_at !== undefined ? datas.created_at : "";
    }
}

export default LegalData;
