import React, {useEffect, useState} from "react";
import { ChromePicker } from 'react-color';
import TrashIcon from "../../../icons/TrashIcon";

function InputColor (props) {
    const {
        attribute,
        returnType,
        classname,
        value,
        handleChange,
        handleBlur
    } = props;
    const [ box, setBox ] = useState(false);

    const showBoxColor = () => {
        setBox(true);
    }
    const handleClose = () => {
        setBox(false);
    }
    const handleRemove = () => {
        handleChange(attribute, returnType, "");
    }
    const changeEvent = (color, event) => {
        if (handleChange === undefined || handleChange === null) return;
        handleChange(attribute, returnType, color.hex);
    }

    return (
        <div className={ "inputColor " + (classname !== undefined ? classname : "") }>
            <div className="color extend">
                <p onClick={ showBoxColor }>{ value }</p>
                <span
                    className="puce"
                    style={value !== "" ? {backgroundColor: value} : {}}
                    onClick={ showBoxColor } />
                <TrashIcon classname="trash" handleClick={ handleRemove } />
            </div>
            {
                box
                && <div className="popover">
                    <div className="cover" onClick={ handleClose }/>
                    <ChromePicker
                        color={ (value !== null && value !== "") ? value : "#00A1FF" }
                        onChange={ changeEvent }
                        disableAlpha={ true }
                    />
                </div>
            }
            <div className="clearing" />
        </div>
    );
}

export default InputColor;
