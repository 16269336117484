import React, {useContext, useEffect, useState} from "react";
import PartnershipUsersSearchBar from "../table/PartnershipUsersSearchBar";
import PartnershipUsersTable from "../table/PartnershipUsersTable";
import PartnershipController from "../../../../../stories/_auth/Partnerships/PartnershipController";
import FormUser from "../../../../overbox/user/FormUser";
import DeleteUser from "../../../../overbox/user/DeleteUser";
import WhatToDoDeleteUser from "../../../../overbox/user/WhatToDoDeleteUser";
import SearchContext from "../../../../../context/SearchContext";
import SessionContext from "../../../../../context/SessionContext";
import PartnershipContext from "../../../../../context/PartnershipContext";
import "../../../../../css/page/cards/CardUsers.css";
import UserController from "../../../../../stories/_auth/Users/UserController";

function PartnershipCardUsers () {
    const { handleLogout } = useContext(SessionContext);
    const { partnership } = useContext(PartnershipContext);
    const [ first, setFirst ] = useState(true);
    const [ generalError, setGeneralError ] = useState("");
    const [ list, setList ] = useState([]);
    const [ inputSearch, setInputSearch ] = useState("");
    const [ initialLoading, setInitialLoading ] = useState(true);
    const [ loading, setLoading ] = useState(true);
    const [ formUpdateUser, setFormUpdateUser ] = useState(false);
    const [ idUserToUpdate, setIdUserToUpdate ] = useState(0);
    const [ boxAskDeleteUser, setBoxAskDeleteUser ] = useState(false);
    const [ boxDeleteUser, setBoxDeleteUser ] = useState(false);
    const [ userToDelete, setUserToDelete ] = useState({});
    const [ deleting, setDeleting ] = useState(false);
    const valueSearchContext = {
        generalError,
        list,
        inputSearch,
        initialLoading,
        loading
    };

    const handleChangeInputSearch = event => {
        let input = event.currentTarget.value;
        setInputSearch(input);
        setLoading(true);
        getPartnershipUsers(input);
    }

    const showFormUpdateUser = id => {
        setFormUpdateUser(true);
        setIdUserToUpdate(id);
    }
    const closeFormUpdateUser = () => {
        setFormUpdateUser(false);
    }

    const getPartnershipUsers = (input = "") => {
        const controller = new PartnershipController();
        controller._callback = handleGetPartnershipUsers;
        controller.indexUsers(partnership, input);
    }
    const handleGetPartnershipUsers = (list, error) => {
        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else
                setGeneralError("Impossible de récupérer les données");
        }

        setFirst(false);
        setInitialLoading(false);
        setLoading(false);
        setList(list);
    }

    const handleBoxAskDeleteUser = idUser => {
        for (var item in list) {
            if (list[item].id === idUser) {
                setUserToDelete(list[item]);
                break;
            }
        }

        setFormUpdateUser(false);
        setBoxAskDeleteUser(true);
    }
    const handleBoxDeleteUser = () => {
        setBoxAskDeleteUser(false);
        setBoxDeleteUser(true);
    }
    const handleCloseBoxAskDeleteUser = () => {
        setBoxAskDeleteUser(false);
        setIdUserToUpdate(0);
        setDeleting(false);
    }
    const handleCloseBoxDeleteUser = () => {
        setBoxDeleteUser(false);
        setIdUserToUpdate(0);
        setDeleting(false);
    }

    const handleDetachUser = () => {
        setDeleting(true);

        const controller = new PartnershipController();
        controller._callback = handleReturnDeleteUser;
        controller.detachUser(partnership, userToDelete.id);
    }
    const handleDeleteUser = () => {
        setDeleting(true);

        const controller = new UserController();
        controller._callback = handleReturnDeleteUser;
        controller.delete(userToDelete);
    }
    const handleReturnDeleteUser = (response, error) => {
        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else
                setGeneralError("Impossible de supprimer l'utilisateur");
        }
        else {
            setBoxAskDeleteUser(false);
            setBoxDeleteUser(false);
            setIdUserToUpdate(0);
            setDeleting(false);

            getPartnershipUsers();
        }
    }

    useEffect(() => {
        if(first)
            getPartnershipUsers();
    });

    return (
        <div className="cardUsers">
            <SearchContext.Provider value={valueSearchContext}>
                <PartnershipUsersSearchBar handleChangeInputSearch={ handleChangeInputSearch } />
                <PartnershipUsersTable handleShowFormUpdateUser={ showFormUpdateUser } />
                {
                    formUpdateUser && <FormUser handleCloseFormUser={ closeFormUpdateUser } handleBoxAskDeleteUser={ handleBoxAskDeleteUser } handle newItem={ false } idUser={ idUserToUpdate } />
                }
                {
                    boxAskDeleteUser && <WhatToDoDeleteUser user={ userToDelete } deleting={ deleting } handleAskDeleteUser={ handleBoxDeleteUser } handleDetachUser={ handleDetachUser } handleCloseBoxAskDeleteUser={ handleCloseBoxAskDeleteUser } />
                }
                {
                    boxDeleteUser && <DeleteUser user={ userToDelete } deleting={ deleting } handleDeleteUser={ handleDeleteUser } handleCloseBoxDeleteUser={ handleCloseBoxDeleteUser } />
                }
            </SearchContext.Provider>
        </div>
    );
}

export default PartnershipCardUsers;
