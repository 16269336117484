import React from "react"
import '../../../../../css/page/Options.css'

const OptionLicense = props => {
    const { show, idSelect, handleUpdate, handleInstall } = props

    return (
        <div className={"overOptions licenseOptions " + (show ? " show" : "")}>
            <nav className="options">
                <p className="item" onClick={ () => { handleUpdate(idSelect) } }>Modifier les informations</p>
                <p className="item" onClick={ () => { handleInstall(idSelect) } }>Générer un code d'installation</p>
            </nav>
        </div>
    )
}

export default OptionLicense
