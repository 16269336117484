import React from "react";
import {Link} from "react-router-dom";
import AccessIcon from "../../../../icons/AccessIcon";

function ProviderLine (props) {
    const isDark = window.matchMedia('(prefers-color-scheme: dark)');
    const color = isDark.matches ? "#FFFFFF" : "#1D1D1D";
    const { provider } = props;

    return (
        <tr className="line">
            <td className="name left">{ provider.name }</td>
            <td className="reference left">{ provider.reference }</td>
            <td className="partnership left">{ provider.partnership_name }</td>
            <td className="action">
                <Link to={ "/provider/" + provider.id + "/information" }>
                    <div className="access">
                        <AccessIcon fill={ color } />
                    </div>
                </Link>
            </td>
        </tr>
    );
}

export default ProviderLine;
