import React, { useContext, useEffect, useState } from "react";
import NoInput from "../input/NoInput";
import InputText from "../input/InputText";
import StoreController from "../../../stories/_client/Stores/StoreController";
import AddressController from "../../../stories/_client/Addresses/AddressController";
import StoreContext from "../../../context/StoreContext";
import LoaderCircle from "../../loader/LoaderCircle";
import Address from "../../../stories/_client/Addresses/Address";
import SessionContext from "../../../context/SessionContext";
import InputSelect from "../input/InputSelect";
import AddressTypeController from "../../../stories/_client/AddressTypes/AddressTypeController";
import ContactController from "../../../stories/_client/Contacts/ContactController";
import Contact from "../../../stories/_client/Contacts/Contact";

function AddressForm (props) {
    const color = window.matchMedia('(prefers-color-scheme: dark)').matches ? "#FFFFFF" : "#1D1D1D";
    const countries = [
        {
            key: 5,
            value: "FRANCE"
        },
        {
            key: 1,
            value: "ALLEMAGNE"
        },
        {
            key: 2,
            value: "ANGLETERRE"
        },
        {
            key: 3,
            value: "BELGIQUE"
        },
        {
            key: 4,
            value: "ESPAGNE"
        },
        {
            key: 6,
            value: "ITALIE"
        },
        {
            key: 7,
            value: "LUXEMBOURG"
        },
        {
            key: 8,
            value: "SUISSE"
        }
    ];
    const types = {
        "BILLING": "FACTURATION",
        "DELIVERY": "LIVRAISON"
    };

    const { newItem, handleClose, handleDelete, address, handleRefresh, object, setObject } = props;
    const { handleLogout } = useContext(SessionContext);
    const { store } = useContext(StoreContext);
    const [ addressObject, setAddressObject ] = useState((address === null) ? new Address() : address);
    const [ first, setFirst ] = useState(true);
    const [ loading, setLoading ] = useState(true);
    const [ saving, setSaving ] = useState(false);
    const [ globalError, setGlobalError ] = useState("");
    const [ listAddressType, setListAddressType ] = useState([]);
    const [ addressType, setAddressType ] = useState(addressObject.type_name);
    const [ editAddressType, setEditAddressType ] = useState(false);
    const [ errorAddressType, setErrorAddressType ] = useState("");
    const [ name, setName ] = useState(addressObject.name);
    const [ editName, setEditName ] = useState(false);
    const [ errorName, setErrorName ] = useState("");
    const [ street, setStreet ] = useState(addressObject.address);
    const [ editStreet, setEditStreet ] = useState(false);
    const [ errorStreet, setErrorStreet ] = useState("");
    const [ additional, setAdditional ] = useState(addressObject.additional);
    const [ editAdditional, setEditAdditional ] = useState(false);
    const [ errorAdditional, setErrorAdditional ] = useState("");
    const [ postalCode, setPostalCode ] = useState(addressObject.postalCode);
    const [ editPostalCode, setEditPostalCode ] = useState(false);
    const [ errorPostalCode, setErrorPostalCode ] = useState("");
    const [ city, setCity ] = useState(addressObject.city);
    const [ editCity, setEditCity ] = useState(false);
    const [ errorCity, setErrorCity ] = useState("");
    const [ country, setCountry ] = useState(addressObject.country !== "" ? addressObject.country : countries[0].value);
    const [ editCountry, setEditCountry ] = useState(false);
    const [ errorCountry, setErrorCountry ] = useState("");

    // INIT

    const getAddressTypes = () => {
        setFirst(false);

        const controller = new AddressTypeController();
        controller._callback = returnGetAddressTypes;

        controller.index();
    }
    const returnGetAddressTypes = (list, error) => {
        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else
                setGlobalError(true);
        }

        var addressTypes = [];

        for (var i = 0; i < list.length; i++)
            addressTypes.push({
                key: list[i].id,
                value: list[i].name
            });

        setListAddressType(addressTypes);
        setAddressType(addressTypes[0].value);
        setLoading(false);
    }

    // HANDLES EDITION

    const handleEditAddressType = () => {
        if(processEdit(editAddressType))
            setEditAddressType(!editAddressType);
    }
    const handleChangeAddressType = event => {
        setAddressType(event.currentTarget.value);
    }
    const checkAddressType = () => {
        const regxp = /^[\w ]{2,50}$/g;

        if (addressType === "") {
            setErrors("addressType", true);
            return false;
        }

        if (addressType !== "" && !regxp.test(addressType)) {
            setErrors("addressType", false);
            return false;
        }

        setErrorAddressType("");

        return true;
    }

    const handleEditName = () => {
        if(processEdit(editName))
            setEditName(!editName);
    }
    const handleChangeName = event => {
        setName(event.currentTarget.value);
    }
    const checkName = () => {
        const regxp = /^[\w ]{2,50}/g;

        if (name !== "" && !regxp.test(name)) {
            setErrors("name", false);
            return false;
        }

        setErrorName("");

        return true;
    }

    const handleEditStreet = () => {
        if(processEdit(editStreet))
            setEditStreet(!editStreet);
    }
    const handleChangeStreet = event => {
        setStreet(event.currentTarget.value);
    }
    const checkStreet = () => {
        const regxp = /^[\w ]{2,50}/g;

        if (street !== "" && !regxp.test(street)) {
            setErrors("street", false);
            return false;
        }

        setErrorStreet("");

        return true;
    }

    const handleEditAdditional = () => {
        if(processEdit(editStreet))
            setEditStreet(!editStreet);
    }
    const handleChangeAdditional = event => {
        setAdditional(event.currentTarget.value);
    }
    const checkAdditional = () => {
        const regxp = /^[\w ]{2,50}/g;

        if (additional !== "" && !regxp.test(additional)) {
            setErrors("additional", false);
            return false;
        }

        setErrorAdditional("");

        return true;
    }

    const handleEditPostalCode = () => {
        if(processEdit(editPostalCode))
            setEditPostalCode(!editPostalCode);
    }
    const handleChangePostalCode = event => {
        setPostalCode(event.currentTarget.value);
    }
    const checkPostalCode = () => {
        const regxp = /^[\w ]{2,50}/g;

        if(postalCode === "") {
            setErrors("postalCode", true);
            return false;
        }

        if (!regxp.test(postalCode)) {
            setErrors("postalCode", false);
            return false;
        }

        setErrorPostalCode("");

        return true;
    }

    const handleEditCity = () => {
        if(processEdit(editCity))
            setEditCity(!editCity);
    }
    const handleChangeCity = event => {
        setCity(event.currentTarget.value);
    }
    const checkCity = () => {
        const regxp = /^[\w ]{2,50}/g;

        if(city === "") {
            setErrors("city", true);
            return false;
        }

        if (!regxp.test(city)) {
            setErrors("city", false);
            return false;
        }

        setErrorCity("");

        return true;
    }

    const handleEditCountry = () => {
        if(processEdit(editCountry))
            setEditCountry(!editCountry);
    }
    const handleChangeCountry = event => {
        setCountry(event.currentTarget.value);
    }
    const checkCountry = () => {
        const regxp = /^[\w ]{2,50}/g;

        if(city === "") {
            setErrors("country", true);
            return false;
        }

        if (country !== "" && !regxp.test(country)) {
            setErrors("country", false);
            return false;
        }

        setErrorCountry("");

        return true;
    }

    // CONTROL & SAVE

    const processEdit = edit => {
        if(edit) {
            if(checkAddressType() && checkName() && checkStreet() && checkAdditional() && checkPostalCode() && checkCity() && checkCountry() && !newItem)
                save();
            else
                return false;
        }

        return true;
    }
    const reinitAllEdits = () => {
        setEditAddressType(false);
        setEditName(false);
        setEditStreet(false);
        setEditAdditional(false);
        setEditPostalCode(false);
        setEditCity(false);
        setEditCountry(false);
    }
    const reinitAllErrors = () => {
        setGlobalError("");
        setErrorAddressType("");
        setErrorName("");
        setErrorStreet("");
        setErrorAdditional("");
        setErrorPostalCode("");
        setErrorCity("");
        setErrorCountry("");
    }

    const check422Errors = errorDatas => {
        setGlobalError("Certaines données sont invalides");

        if(errorDatas !== undefined) {
            var keys = Object.keys(errorDatas);
            var fields = ["addressType", "name", "address", "additional", "postalCode", "city", "country"];

            for(var item in fields)
                if(keys.includes(fields[item]))
                    setErrors(fields[item], false);
        }
    }
    const setErrors = (type, empty) => {
        switch (type) {
            case "addressType":
                if(empty) setErrorAddressType("Vous devez saisir un type d'adresse");
                else setErrorAddressType("Ce type d'adresse n'est pas valide");
                break;
            case "name":
                if(empty) setErrorName("Vous devez saisir un nom");
                else setErrorName("Ce nom n'est pas valide");
                break;
            case "street":
            case "address":
                setErrorStreet("Cette adresse n'est pas valide");
                break;
            case "additional":
                setErrorAdditional("Ce complément d'adresse n'est pas valide");
                break;
            case "postalCode":
                if(empty) setErrorPostalCode("Vous devez saisir un code postal");
                else setErrorPostalCode("Ce code postal n'est pas valide");
                break;
            case "city":
                if(empty) setErrorCity("Vous devez saisir une ville");
                else setErrorCity("Cette ville n'est pas valide");
                break;
            case "country":
                if(empty) setErrorCountry("Vous devez saisir un pays");
                else setErrorCountry("Ce pays n'est pas valide");
                break;
            default: break;
        }
    }

    const handleKeyEnter = event => {
        if (event.key === 'Enter')
            if(processEdit(true))
                reinitAllEdits();
    }

    const returnUpdates = controller => {
        if (controller === undefined)
            controller = new AddressController();

        return controller.returnUpdatesFromCompare(addressObject, {
            'name': name,
            'address': street,
            'additional': additional,
            'postalCode': postalCode,
            'city': city,
            'country': country,
            'type_name': addressType,
            'partnership_id': object.partnership_id
        });
    }

    const save = () => {
        setSaving(true);

        reinitAllErrors();

        const controller = new AddressController();
        const datas = returnUpdates(controller);

        if(Object.keys(datas).length === 0) return;

        controller._callback = handleReturnSave;

        if(newItem)
            controller.post(datas);
        else
            controller.put(addressObject, datas);
    }
    const handleReturnSave = (response, error) => {
        if(error) {
            setSaving(false);

            if(error.response !== undefined) {
                if(error.response.status === 401)
                    handleLogout();
                else if(error.response.status === 422)
                    check422Errors(error.response.data);
                else
                    setGlobalError("(" + error.response.status + ") Une erreur s'est produite lors de l'enregistrement");
            }
            else
                setGlobalError("Une erreur s'est produite lors de l'enregistrement");
        }
        else {
            if(object.id > 0)
                attachToStore(response.data.id);
            else {
                setSaving(false);

                if(object.id > 0)
                    handleRefresh();
                else {
                    var objTmp = Object.assign({}, object);

                    if (addressObject.id === 0) {
                        objTmp.addresses.push(new Address({
                            partnership_id: object.partnership_id,
                            id: response.data.id,
                            name: name,
                            address: street,
                            additional: additional,
                            postalCode: postalCode,
                            city: city,
                            country: country,
                            type_name: addressType
                        }));
                    }
                    else {
                        for (var i in objTmp.addresses) {
                            if (objTmp.addresses[i].id === addressObject.id) {
                                objTmp.addresses[i].name = name;
                                objTmp.addresses[i].address = street;
                                objTmp.addresses[i].additional = additional;
                                objTmp.addresses[i].postalCode = postalCode;
                                objTmp.addresses[i].city = city;
                                objTmp.addresses[i].country = country;
                                objTmp.addresses[i].type_name = addressType;
                                break;
                            }
                        }
                    }

                    setObject(objTmp);

                    if (newItem)
                        handleClose();
                }
            }
        }
    }

    const attachToStore = idAddress => {
        const controller = new StoreController();
        controller._callback = handleReturnAttachToStore;

        controller.postAddress(store, idAddress);
    }
    const handleReturnAttachToStore = (response, error) => {
        setSaving(false);

        if(error) {
            if(error.response !== undefined) {
                if(error.response.status === 401)
                    handleLogout();
                else if(error.response.status === 422)
                    check422Errors(error.response.data);
            }
            else
                setGlobalError("Une erreur s'est produite lors de l'enregistrement");
        }
        else {
            handleClose();
            handleRefresh();
        }
    }

    // RENDER

    useEffect(() => {
        if (first)
            getAddressTypes();
    })

    return (
        <div className="contForm">
            {
                loading
                    ? <LoaderCircle display="loader logWait" hide="" strokeWidth="5" stroke="#008C4F" />
                    : <div className={ "form " + (newItem ? "margin" : "") }>
                        {
                            newItem && <p className="title">AJOUTER UNE ADRESSE</p>
                        }
                        {
                            globalError !== ""
                            ? <p className="globalError">{ globalError }</p>
                            : ""
                        }
                        <label className="label">Type de l'adresse</label>
                        {
                            !editAddressType && !newItem
                            ? <NoInput value={ types[addressType] } color={ color } edit={ true } copy={ false } phone={ false } email={ false } link={ false } handleEdit={ handleEditAddressType } />
                            : <InputSelect color={ color } classError={ errorAddressType !== "" ? "wrong" : "" } list={ listAddressType } dictionary={ types } value={ addressType } newItem={ newItem } handleChange={ handleChangeAddressType } handleBlur={ handleEditAddressType } returnTextValue={ true } />
                        }
                        {
                            errorAddressType !== ""
                            ? <p className="error">{ errorAddressType }</p>
                            : ""
                        }
                        <label className="label">Nom</label>
                        {
                            !editName && !newItem
                            ? <NoInput value={ name } color={ color } edit={ true } copy={ false } phone={ false } email={ false } link={ false } handleEdit={ handleEditName } />
                            : <InputText color={ color } classError={ errorName !== "" ? "wrong" : "" } value={ name } placeholder="Nom" newItem={ newItem } handleChange={ handleChangeName } handleBlur={ handleEditName } handleKeyEnter={ handleKeyEnter } />
                        }
                        {
                            errorName !== ""
                            ? <p className="error">{ errorName }</p>
                            : ""
                        }
                        <label className="label">Adresse</label>
                        {
                            !editStreet && !newItem
                            ? <NoInput value={ street !== "" ? street : "Aucune" } color={ color } edit={ true } copy={ false } phone={ false } email={ false } link={ false } handleEdit={ handleEditStreet } />
                            : <InputText color={ color } classError={ errorStreet !== "" ? "wrong" : "" } value={ street } placeholder="Adresse" newItem={ newItem } handleChange={ handleChangeStreet } handleBlur={ handleEditStreet } handleKeyEnter={ handleKeyEnter } />
                        }
                        {
                            errorStreet !== ""
                            ? <p className="error">{ errorStreet }</p>
                            : ""
                        }
                        <label className="label">Complément d'adresse</label>
                        {
                            !editAdditional && !newItem
                            ? <NoInput value={ additional !== "" ? additional : "Aucun" } color={ color } edit={ true } copy={ false } phone={ false } email={ false } link={ false } handleEdit={ handleEditAdditional } />
                            : <InputText color={ color } classError={ errorAdditional !== "" ? "wrong" : "" } value={ additional } placeholder="Complément d'adresse" newItem={ newItem } handleChange={ handleChangeAdditional } handleBlur={ handleEditAdditional } handleKeyEnter={ handleKeyEnter } />
                        }
                        {
                            errorAdditional !== ""
                            ? <p className="error">{ errorAdditional }</p>
                            : ""
                        }
                        <label className="label left">Code postal</label>
                        <label className="label right">Ville</label>
                        {
                            !editPostalCode && !newItem
                                ? <NoInput classname="clear left" value={ postalCode !== "" ? postalCode : "Aucun" } color={ color } edit={ true } copy={ postalCode !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditPostalCode } />
                                : <InputText color={ color } classname="left" classError={ errorPostalCode !== "" ? "wrong" : "" } value={ postalCode } placeholder="Code postal" newItem={ newItem } handleChange={ handleChangePostalCode } handleBlur={ handleEditPostalCode } handleKeyEnter={ handleKeyEnter } />
                        }
                        {
                            !editCity && !newItem
                                ? <NoInput classname="right" value={ city !== "" ? city : "Aucune" } color={ color } edit={ true } copy={ city !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditCity } />
                                : <InputText color={ color } classname="right" classError={ errorCity !== "" ? "wrong" : "" } value={ city } placeholder="Ville" newItem={ newItem } handleChange={ handleChangeCity } handleBlur={ handleEditCity } handleKeyEnter={ handleKeyEnter } />
                        }
                        <div className="clearing" />
                        {
                            errorPostalCode !== ""
                                ? <p className="error left">{ errorPostalCode }</p>
                                : ""
                        }
                        {
                            errorCity !== ""
                                ? <p className="error right">{ errorCity }</p>
                                : ""
                        }
                        <label className="label clear">Pays</label>
                        {
                            !editCountry && !newItem
                                ? <NoInput value={ country !== "" ? country : "Aucun" } color={ color } edit={ true } copy={ country !== "" } phone={ false } email={ false } link={ false } handleEdit={ handleEditCountry } />
                                : <InputSelect color={ color } classError={ errorCountry !== "" ? "wrong" : "" } list={ countries } value={ country } newItem={ newItem } handleChange={ handleChangeCountry } handleBlur={ handleEditCountry } returnTextValue={ true } />
                        }
                        {
                            errorCountry !== ""
                                ? <p className="error">{ errorCountry }</p>
                                : ""
                        }
                        {
                            newItem
                            ? <div>
                                <button className="submit" onClick={ save }>
                                    {saving ? "Enregistrement..." : "Enregistrer"}
                                    <LoaderCircle display="loader submitLogin" hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                                </button>
                                <button className="cancel" onClick={ handleClose }>Annuler</button>
                                <div className="clearing" />
                            </div>
                            : <div>
                                <button className="delete" onClick={ handleDelete }>Supprimer l'adresse</button>
                                <button className="cancel block" onClick={ handleClose }>Fermer</button>
                            </div>
                        }
                    </div>
            }
        </div>
    );
}

export default AddressForm;
