import React from "react";
import PartnershipChoose from "../../form/partnership/PartnershipChoose";

function ChoosePartnership (props) {
    const { handleClose, handleSelect } = props;

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox newItemSmall">
                    <PartnershipChoose handleClose={ handleClose } handleSelect={ handleSelect } newItem={ true } />
                </div>
            </div>
        </div>
    );
}

export default ChoosePartnership;
