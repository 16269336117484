import React, {useState, useEffect} from "react"
import AccessIcon from "../../../../icons/AccessIcon"
import OptionLicense from "./OptionLicense"
import LoaderCircle from "../../../../loader/LoaderCircle"
import MainIcon from "../../../../icons/MainIcon"
import LicenseController from "../../../../../stories/_setup/Licenses/LicenseController"
import InstallationEnabling from "../../../../../stories/_setup/InstallationEnablings/InstallationEnabling"
import moment from "moment/moment";

function SetupLicensesLine (props) {
    moment.updateLocale('fr', {
        months : 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
        monthsShort : 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
        monthsParseExact : true,
        weekdays : 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
        weekdaysShort : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
        weekdaysMin : 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
        weekdaysParseExact : true,
        longDateFormat : {
            LT : 'HH:mm',
            LTS : 'HH:mm:ss',
            L : 'DD/MM/YYYY',
            LL : 'D MMMM YYYY',
            LLL : 'D MMMM YYYY HH:mm',
            LLLL : 'dddd D MMMM YYYY HH:mm'
        },
        calendar : {
            sameDay : '[Aujourd’hui à] LT',
            nextDay : '[Demain à] LT',
            nextWeek : 'dddd [à] LT',
            lastDay : '[Hier à] LT',
            lastWeek : 'dddd [dernier à] LT',
            sameElse : 'L'
        },
        relativeTime : {
            future : '%s',
            past : '%s',
            s : 'quelques secondes',
            m : 'une minute',
            mm : '%d minutes',
            h : 'une heure',
            hh : '%d heures',
            d : 'un jour',
            dd : '%d jours',
            M : 'un mois',
            MM : '%d mois',
            y : 'un an',
            yy : '%d ans'
        },
        dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
        ordinal : function (number) {
            return number + (number === 1 ? 'er' : 'e');
        },
        meridiemParse : /PD|MD/,
        isPM : function (input) {
            return input.charAt(0) === 'M';
        },
        // In case the meridiem units are not separated around 12, then implement
        // this function (look at locale/id.js for an example).
        // meridiemHour : function (hour, meridiem) {
        //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
        // },
        meridiem : function (hours, minutes, isLower) {
            return hours < 12 ? 'PD' : 'MD';
        },
        week : {
            dow : 1, // Monday is the first day of the week.
            doy : 4  // Used to determine first week of the year.
        }
    })
    const licenseController = new LicenseController()
    const { setup, object, closeOptions, handleSetCloseOptions, handleUpdate, handleInstall } = props
    const [ license, setLicense ] = useState(object)
    const [ timer, setTimer ] = useState("")
    const [ loading, setLoading ] = useState(true)
    const [ showOptions, setShowOptions ] = useState(false)
    const [ idLicenseSelect, setIdLicenseSelect ] = useState(0)
    let timeoutRefresh
    let timeoutTimer

    const performTimer = objLicense => {
        if (objLicense.install !== undefined && objLicense.install !== null) {
            const limit = moment.utc(objLicense.install.readyDate).add(objLicense.install.validityPeriod, 'minutes')
            const now = moment()

            if (limit.local() > now)
                setTimer("Disponible pendant " + limit.fromNow())
            else
                setTimer("Terminé.")
        }

        timeoutTimer = setTimeout(() => { performTimer(objLicense) }, 1000)
    }
    const handleRefresh = (response, error) => {
        if (error) return

        setLoading(false)

        let licenseTemp = Object.assign({}, license)
        licenseTemp.type = response.type
        licenseTemp.name = response.name
        licenseTemp.main = response.main

        if(response.install !== null) {
            licenseTemp.install = new InstallationEnabling(response.install)
            clearTimeout(timeoutTimer)
            performTimer(licenseTemp)
        }
        else
            clearTimeout(timeoutTimer)

        setLicense(licenseTemp);
    }
    const refresh = () => {
        licenseController._callback = handleRefresh
        licenseController.show(setup.id, object.id)

        timeoutRefresh = setTimeout(() => { refresh() }, 5000)
    }
    const performCloseOptions = () => {
        setShowOptions(false)
        handleSetCloseOptions(false)
    }
    const handleShowOptions = id => {
        if (!showOptions) {
            setIdLicenseSelect(id)
            setShowOptions(true)
        }
        else {
            setIdLicenseSelect(0)
            setShowOptions(false)
        }
    }
    const install = () => {
        handleShowOptions()
        handleInstall(license.id)
    }
    const typeName = () => {
        switch (license.type) {
            case "POS_WINDOWS":
                return "POS Windows"
            case "POS_WINDOWS_MOBILE":
                return "Télécommande Windows"
            case "POS_IOS":
                return "POS iPad"
            case "POS_IOS_MOBILE":
                return "Télécommande iPhone"
            case "POS_ANDROID":
                return "POS Android"
            case "POS_ANDROID_MOBILE":
                return "Télécommande Android"
            case "KIOSK_ORDER":
                return "Borne de commande"
        }
    }

    useEffect(() => {
        refresh()

        return () => {
            clearTimeout(timeoutRefresh)
            clearTimeout(timeoutTimer)
        }
    }, [])
    useEffect(() => {
        if (closeOptions && showOptions)
            performCloseOptions()
    }, [closeOptions, showOptions])

    return (
        <tr className="line">
            <td className="number left">
                <p className="text">{ license.number }</p>
                {
                    loading && <LoaderCircle display="loader line" hide="" strokeWidth="10" stroke="#008C4F" />
                }
            </td>
            <td className="name left">{ license.name }</td>
            <td className="type left">{ typeName() }</td>
            <td className="main center">
                {
                    !loading && (
                        license.main
                            ? <MainIcon />
                            : ""
                    )
                }
            </td>
            <td className="code left">
                {
                    !loading && (
                        license.install !== undefined && license.install !== null
                            ? (
                                license.install.readyDate !== undefined && license.install.readyDate !== null
                                    ? <p>{ license.install.token } <span>{ timer }</span></p>
                                    : "En cours..."
                            )
                            : ""
                    )
                }
            </td>
            <td className="action">
                <div className="access" onClick={ () => { handleShowOptions(license.id) } }>
                    <AccessIcon fill="#000000" />
                </div>
                <OptionLicense show={ showOptions } idSelect={ idLicenseSelect } handleUpdate={ handleUpdate } handleInstall={ install } />
            </td>
        </tr>
    )
}

export default SetupLicensesLine