import React, {useState} from "react";
import EditIcon from "../../icons/EditIcon";
import CopyIcon from "../../icons/CopyIcon";
import PhoneIcon from "../../icons/PhoneIcon";
import EmailIcon from "../../icons/EmailIcon";

function NoInputComplex (props) {
    const { color, type, object, handleToCopy, handleToCall, handleToEdit } = props;
    const [ copy, setCopy ] = useState(false);
    const [ phone, setPhone ] = useState(false);
    const [ email, setEmail ] = useState(false);

    const formatValue = () => {
        var value = "";

        switch (type) {
            case "address":

                if (object.address.length !== null)
                    value = object.address.trim();

                if(object.postalCode.length > 0)
                    value += (value.length > 0 ? ", " : "") + object.postalCode.trim();

                if(object.city.length > 0)
                    value += (value.length > 0 ? " " : "") + object.city.trim().toUpperCase();

                if(object.country.length > 0)
                    value += (value.length > 0 ? ", " : "") + object.country.trim();

                if (value !== "")
                    if(!copy)
                        setCopy(true);

                return value;

            case "contact":

                value = ((object.gender !== "Non défini" ? object.gender : "") + " " + object.lastname + " " + object.firstname).trim()

                if (object.phone !== "" && object.phone !== null) {
                    if(!phone)
                        setPhone(true);

                    value += ", " + object.phone.trim();
                }

                if (object.mobile !== "" && object.mobile !== null) {
                    if(!phone)
                        setPhone(true);

                    value += ", " + object.mobile.trim();
                }

                if (object.email !== "" && object.email !== null) {
                    if(!email)
                        setEmail(true);
                    value += ", " + object.email.trim();
                }

                if (value !== "")
                    if(!copy)
                        setCopy(true);

                return value;

            default: return value;
        }
    }
    const formatTags = () => {
        var value;
        var dictionary = {
            "BILLING": "FACTURATION",
            "DELIVERY": "LIVRAISON"
        };

        switch (type) {
            case "address":

                if( object.type_name.length > 0 )
                    value = <div className="contTags">
                        {
                            object.type_name.length > 0
                            && <p className="tag">{ dictionary[object.type_name] }</p>
                        }
                        {
                            object.name.length > 0
                            && <p className="tag">{ object.name.toUpperCase() }</p>
                        }
                        <div className="clearing" />
                    </div>;

                return value;

            case "contact":

                if( object.tags.length > 0 ) {
                    value = <div className="contTags">
                        {
                            object.skytill_id !== null && <p className="tag skytillAccount">COMPTE SKYTILL</p>
                        }
                        {
                            object.tags.map(tag => (
                                <p key={ tag.id } className="tag">{ tag.name.toUpperCase() }</p>
                            ))
                        }
                        <div className="clearing" />
                    </div>;
                }
                else if (object.skytill_id !== null) {
                    value = <div className="contTags">
                        <p className="tag skytillAccount">COMPTE SKYTILL</p>
                        <div className="clearing" />
                    </div>;
                }

                return value;

            default: return value;
        }
    }

    return (
        <div className="noInputComplex">
            <p className="textInput">{ formatValue() }</p>
            <div className="icons">
                <EditIcon fill={ color } handleClick={ () => { handleToEdit( object.id ) } } />
                {
                    copy && <CopyIcon fill={ color } handleClick={ () => { handleToCopy( type, object.id ) } } />
                }
                {
                    phone && <PhoneIcon fill={ color } handleClick={ () => { handleToCall( object.id ) } } />
                }
                {
                    email && <a href={ "mailTo:" + object.email }><EmailIcon fill={ color } /></a>
                }
            </div>
            {
                formatTags()
            }
        </div>
    );
}

export default NoInputComplex;
