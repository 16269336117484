import React from "react";
import AddIcon from "../../icons/AddIcon";
import ChoiceSelected from "./ChoiceSelected";
import CheckIcon from "../../icons/CheckIcon";

function InputChoices (props) {
    const { color, idName, classname, input, newItem, values, dictionary, handleShowRulesList, handleBlur, handleUnselect } = props;

    return (
        <div className="contInputChoices">
            <div id={ idName } className={ "inputChoices " + (newItem ? "extend " : "") + (classname !== undefined ? classname : "") }>
                {
                    values.length > 0 && (
                        values.map(object => (
                            <ChoiceSelected key={ object.id } object={ object } dictionary={ dictionary } locked={ false } color={ color } handleUnselect={ handleUnselect } />
                        ))
                    )
                }
                {
                    input
                    ? <input type="text" />
                    : <AddIcon fill={ color } handleClick={ handleShowRulesList } />
                }
                <div className="clearing" />
            </div>
            {
                !newItem && <CheckIcon fill={ color } handleBlur={ handleBlur } classname="checkIcon" />
            }
            <div className="clearing" />
        </div>
    );
}

export default InputChoices;
