import React, {useContext, useEffect, useState} from "react";
import CompanyController from "../../../stories/_client/Companies/CompanyController";
import LoaderCircle from "../../loader/LoaderCircle";
import InputText from "../../form/input/InputText";
import InputMultiLinesChoices from "../../form/input/InputMultiLinesChoices";
import SessionContext from "../../../context/SessionContext";

function SearchCompany (props) {
    const color = window.matchMedia('(prefers-color-scheme: dark)').matches ? "#FFFFFF" : "#1D1D1D";

    const { handleCloseSearch, handleSelect, saving } = props;
    const { handleLogout } = useContext(SessionContext);
    const [ first, setFirst ] = useState(true);
    const [ searching, setSearching ] = useState(true);
    const [ listCompanies, setListCompanies ] = useState([]);
    const [ listFiltredCompanies, setListFiltredCompanies ] = useState([]);
    const [ listSelected, setlistSelected ] = useState([]);
    const [ input, setInput ] = useState("");

    const handleChangeInput = event => {
        let inputVal = event.currentTarget.value;

        let listFiltred = [];

        for (var item in listCompanies)
            if (listCompanies[item].main.toLowerCase().includes(inputVal.toLowerCase()))
                listFiltred.push(listCompanies[item]);

        setInput(inputVal);
        setListFiltredCompanies(listFiltred);
        setlistSelected([]);
    }

    const handleSelectChoiceList = id => {
        var listDatas = [];

        for (var item in listCompanies) {
            if(listCompanies[item].id === id) {
                listDatas.push({
                    id: listCompanies[item].id,
                    main: listCompanies[item].main
                });

                break;
            }
        }

        setlistSelected(listDatas);
    }

    const getCompanies = () => {
        setFirst(false);

        const controller = new CompanyController();
        controller._callback = handleGetCompanies;
        controller.index();
    }
    const handleGetCompanies = (list, error) => {
        if(error)
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();

        var listDatas = [];

        for (var item in list)
            listDatas.push({
                id: list[item].id,
                main: list[item].name
            });

        setListCompanies(listDatas);
        setListFiltredCompanies(listDatas);
        setSearching(false);
    }

    const selectCompany = () => {
        handleSelect(listSelected);
    }

    useEffect(() => {
        if(first)
            getCompanies();
    });

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox search">
                    <div className="contForm">
                        <div className="form">
                        {
                            !searching
                            ? <div>
                                <InputText color={ color } classError="" value={ input } placeholder="Recherche..." newItem={ true } handleChange={ handleChangeInput } />
                                <InputMultiLinesChoices color={ color } list={ listFiltredCompanies } listSelected={ listSelected } handleSelectChoiceList={ handleSelectChoiceList } />
                                <button className="submit" onClick={ selectCompany }>
                                    {saving ? "Ajout en cours..." : "Ajouter"}
                                    <LoaderCircle display="loader submitLogin" hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                                </button>
                                <button className="cancel" onClick={ handleCloseSearch }>Annuler</button>
                                <div className="clearing" />
                            </div>
                            : <LoaderCircle display="loader logWait" hide="" strokeWidth="5" stroke="#008C4F" />
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SearchCompany;
