import React from "react";

const InputSelect = props => {
    const {
        attribute,
        returnType,
        backgroundColor,
        classname,
        classError,
        value,
        list,
        dictionary,
        handleChange,
        handleBlur
    } = props;

    const changeEvent = event => {
        if (handleChange === undefined || handleChange === null) return;
        handleChange(attribute, returnType, event.currentTarget.value);
    }
    const blurEvent = () => {
        if (handleBlur === undefined || handleBlur === null) return;
        handleBlur(attribute);
    }

    return (
        <div className={ "inputText " + (classname !== undefined ? classname : "") }>
            <select
                value={ value !== null ? value : "" }
                className={ "extend" + (classError !== undefined ? " " + classError : "") }
                onChange={ changeEvent }
                onBlur={ blurEvent }
                autoFocus={ true }
                style={{backgroundColor: ((backgroundColor !== undefined && backgroundColor !== null) ? backgroundColor + "22" : "transparent")}}>
            {
                list.map((object, index) => (
                    <option key={ index } value={ returnType === "string" ? object.value : index }>
                        {
                            (dictionary !== null && dictionary !== undefined)
                            ? dictionary[object.value]
                            : object.value
                        }
                    </option>
                ))
            }
            </select>
            <div className="clearing" />
        </div>
    );
}

export default InputSelect;
