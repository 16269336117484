import React from "react";
import SubMenuNavItem from "./menu/SubMenuNavItem";
import AuthController from "../../stories/_auth/Auth/AuthController";
import "../../css/page/SubMenu.css";

function SubMenu (props) {
    const { page, showSubMenu, typeSubMenu, handleShowSubMenu, handleHideSubMenu } = props;
    let auth = new AuthController();

    const titleSubMenu = () => {
        switch (typeSubMenu) {
            case "client": return "Client";
            case "catalog": return "Catalogue";
            case "accountant": return "Compabilité";
            case "project": return "Projet";
            case "setting": return "Administration";
            default: return "";
        }
    }

    const accessPartnership = () => {
        const user = JSON.parse(localStorage.getItem("user"))

        if(user.partnerships.length > 1)
            return <SubMenuNavItem title="Partenaires" link="/partnership" selected={ page === 'partnership' ? "true" : "false" } handleHideSubMenu={ handleHideSubMenu } />;
        else
            return <SubMenuNavItem title={ user.partnerships[0].company } link={ "/partnership/" + user.partnerships[0].id + "/information" } selected={ page === 'partnership' ? "true" : "false" } handleHideSubMenu={ handleHideSubMenu } />;
    }

    const navListSubItems = () => {
        switch (typeSubMenu) {
            case "client":
                return <nav>
                    <SubMenuNavItem title="Prospects" link="/prospect" selected={ page === 'prospect' ? "true" : "false" } handleHideSubMenu={ handleHideSubMenu } />
                    <SubMenuNavItem title="Clients" link="/client" selected={ page === 'client' ? "true" : "false" } handleHideSubMenu={ handleHideSubMenu } />
                </nav>
            case "catalog":
                return <nav>
                    <SubMenuNavItem title="Catégories" link="/category" selected={ page === 'category' ? "true" : "false" } handleHideSubMenu={ handleHideSubMenu } />
                    <SubMenuNavItem title="Marques" link="/brand" selected={ page === 'brand' ? "true" : "false" } handleHideSubMenu={ handleHideSubMenu } />
                    <SubMenuNavItem title="Produits" link="/product" selected={ page === 'product' ? "true" : "false" } handleHideSubMenu={ handleHideSubMenu } />
                </nav>
            case "accountant":
                return <nav>
                    <SubMenuNavItem title="Devis" link="/quote" selected={ page === 'quote' ? "true" : "false" } handleHideSubMenu={ handleHideSubMenu } />
                    <SubMenuNavItem title="Bons en attente" link="/voucher" selected={ page === 'voucher' ? "true" : "false" } handleHideSubMenu={ handleHideSubMenu } />
                    <SubMenuNavItem title="Factures" link="/bill" selected={ page === 'bill' ? "true" : "false" } handleHideSubMenu={ handleHideSubMenu } />
                    <SubMenuNavItem title="Prélèvements" link="/sample" selected={ page === 'sample' ? "true" : "false" } handleHideSubMenu={ handleHideSubMenu } />
                </nav>
            case "project":
                return <nav>
                </nav>
            case "setting":
                return <nav>
                    <SubMenuNavItem title="Mes informations" link="/setting/information" selected={ page === 'setting' ? "true" : "false" } handleHideSubMenu={ handleHideSubMenu } />
                    {
                        auth.hasRules(['root'])
                        ? <SubMenuNavItem title="Partenaires" link="/partnership" selected={ page === 'partnership' ? "true" : "false" } handleHideSubMenu={ handleHideSubMenu } />
                        : accessPartnership()
                    }
                    {
                        auth.hasRules(['boss'])
                        && <div className="clearing">
                            <SubMenuNavItem title="Fournisseurs" link="/provider" selected={ page === 'provider' ? "true" : "false" } handleHideSubMenu={ handleHideSubMenu } />
                            <SubMenuNavItem title="TVA" link="/tax" selected={ page === 'tax' ? "true" : "false" } handleHideSubMenu={ handleHideSubMenu } />
                            <SubMenuNavItem title="Systèmes de caisse" link="/setup/type" selected={ page === 'setup' ? "true" : "false" } handleHideSubMenu={ handleHideSubMenu } />
                            <SubMenuNavItem title="Prospection" link="/prospecting/phase" selected={ page === 'prospecting' ? "true" : "false" } handleHideSubMenu={ handleHideSubMenu } />
                            <SubMenuNavItem title="Tickets" link="/setting/tickets/type" selected={ page === 'settingTicket' ? "true" : "false" } handleHideSubMenu={ handleHideSubMenu } />
                        </div>
                    }
                </nav>
            default: return <nav />;
        }
    }

    return (
        <div className={ "subMenu " + (showSubMenu ? "show" : "") } onMouseOver={ () => { handleShowSubMenu() } } onMouseOut={ () => { handleHideSubMenu() } }>
            <p className="title">{ titleSubMenu() }</p>
            {
                navListSubItems()
            }
        </div>
    );
}

export default SubMenu;
