import React, {useContext, useEffect, useState} from "react";
import PartnershipStoragesSearchBar from "../table/PartnershipStoragesSearchBar";
import PartnershipStoragesTable from "../table/PartnershipStoragesTable";
import FormStorage from "../../../../overbox/storage/FormStorage";
import DeleteStorage from "../../../../overbox/storage/DeleteStorage";
import SearchContext from "../../../../../context/SearchContext";
import SessionContext from "../../../../../context/SessionContext";
import PartnershipContext from "../../../../../context/PartnershipContext";
import StorageController from "../../../../../stories/_auth/Storages/StorageController";
import "../../../../../css/page/cards/CardStorages.css";

function PartnershipCardStorages () {
    const { handleLogout } = useContext(SessionContext);
    const { partnership } = useContext(PartnershipContext);
    const [ first, setFirst ] = useState(true);
    const [ generalError, setGeneralError ] = useState("");
    const [ list, setList ] = useState([]);
    const [ inputSearch, setInputSearch ] = useState("");
    const [ initialLoading, setInitialLoading ] = useState(true);
    const [ loading, setLoading ] = useState(true);
    const [ formUpdateStorage, setFormUpdateStorage ] = useState(false);
    const [ idStorageToUpdate, setIdStorageToUpdate ] = useState(0);
    const [ boxDeleteStorage, setBoxDeleteStorage ] = useState(false);
    const [ storageToDelete, setStorageToDelete ] = useState({});
    const [ deleting, setDeleting ] = useState(false);
    const valueSearchContext = {
        generalError,
        list,
        inputSearch,
        initialLoading,
        loading
    };

    const handleChangeInputSearch = event => {
        setInputSearch(event.currentTarget.value);
        setLoading(true);
        getPartnershipStorages();
    }

    const showFormUpdateStorage = id => {
        setIdStorageToUpdate(id);
        setFormUpdateStorage(true);
    }
    const closeFormUpdateStorage = () => {
        setFormUpdateStorage(false);
    }

    const getPartnershipStorages = () => {
        const controller = new StorageController();
        controller._callback = handleGetPartnershipStorages;
        controller.index(partnership, inputSearch);
    }
    const handleGetPartnershipStorages = (list, error) => {
        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else
                setGeneralError("Impossible de récupérer les données");
        }

        setFirst(false);
        setInitialLoading(false);
        setLoading(false);
        setList(list);
    }

    const handleBoxDeleteStorage = idStorage => {
        for (var item in list) {
            if (list[item].id === idStorage) {
                setStorageToDelete(list[item]);
                break;
            }
        }

        setFormUpdateStorage(false);
        setBoxDeleteStorage(true);
    }
    const handleCloseBoxDeleteStorage = () => {
        setBoxDeleteStorage(false);
        setIdStorageToUpdate(0);
        setDeleting(false);
    }

    const handleDeleteStorage = () => {
        setDeleting(true);

        const controller = new StorageController();
        controller._callback = handleReturnDeleteStorage;
        controller.delete(partnership, storageToDelete);
    }
    const handleReturnDeleteStorage = (response, error) => {
        if(error) {
            if(error.response !== undefined && error.response.status === 401)
                handleLogout();
            else
                setGeneralError("Impossible de supprimer l'établissement");
        }
        else {
            setBoxDeleteStorage(false);
            setIdStorageToUpdate(0);
            setDeleting(false);

            getPartnershipStorages();
        }
    }

    useEffect(() => {
        if(first)
            getPartnershipStorages();
    });

    return (
        <div className="cardStorages">
            <SearchContext.Provider value={valueSearchContext}>
                <PartnershipStoragesSearchBar handleChangeInputSearch={ handleChangeInputSearch } />
                <PartnershipStoragesTable handleShowFormUpdateStorage={ showFormUpdateStorage } />
                {
                    formUpdateStorage && <FormStorage handleClose={ closeFormUpdateStorage } handleDelete={ handleBoxDeleteStorage } newItem={ false } idStorage={ idStorageToUpdate } setError={ setGeneralError } handleRefresh={ getPartnershipStorages } />
                }
                {
                    boxDeleteStorage && <DeleteStorage storage={ storageToDelete } deleting={ deleting } handleDeleteStorage={ handleDeleteStorage } handleCloseBoxDeleteStorage={ handleCloseBoxDeleteStorage } />
                }
            </SearchContext.Provider>
        </div>
    );
}

export default PartnershipCardStorages;
