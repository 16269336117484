import React, {useContext, useEffect, useState} from "react";
import LoaderCircle from "../../../../loader/LoaderCircle";
import ProductContext from "../../../../../context/ProductContext";
import SessionContext from "../../../../../context/SessionContext";
import StockController from "../../../../../stories/_catalog/Stocks/StockController";
import StockProduct from "./StockProduct";
import FormStock from "../../../../overbox/stock/FormStock";

function ProductStocks () {
    const { handleLogout } = useContext(SessionContext);
    const { product, setGeneralError } = useContext(ProductContext);
    const [ first, setFirst ] = useState(true);
    const [ loadingStocks, setLoadingStocks ] = useState(true);
    const [ stocks, setStocks ] = useState([]);
    const [ stockForm, setStockForm ] = useState(false);
    const [ storageStockForm, setStorageStockForm ] = useState(null);
    const [ typeStockForm, setTypeStockForm ] = useState('');

    // INIT

    const getStocks = () => {
        setFirst(false);

        const controller = new StockController();
        controller._callback = handleGetStocks;
        controller.index(product);
    }
    const handleGetStocks = (list, error) => {
        setLoadingStocks(false);

        if(error) {
            if(error.response !== undefined) {
                if (error.response.status === 401)
                    handleLogout();
                else if (error.response.status === 404)
                    setGeneralError("Les stocks sont introuvables");
            }
            else
                setGeneralError("Impossible de récupérer les données");
        }

        setStocks(list);
    }

    // PERFORMS

    const windowStockForm = (storage, type) => {
        setStorageStockForm(storage);
        setTypeStockForm(type);
        setStockForm(true);
    }
    const closeWindowStockForm = () => {
        setStockForm(false);
        setStorageStockForm(null);
        setTypeStockForm('');
        getStocks();
    }

    // RENDER

    useEffect(() => {
        if (first)
            getStocks();
    })

    return (
        <div className="stocks">
            {
                !loadingStocks
                    ? stocks.map(object => (
                        <StockProduct
                            key={ object.storage.id }
                            object={ object }
                            handleStockForm={ windowStockForm }
                        />
                    ))
                    : <LoaderCircle display="loader logWait" hide="" strokeWidth="5" stroke="#008C4F" />
            }
            {
                stockForm && <FormStock handleClose={ closeWindowStockForm } stocks={ stocks } product={ product } type={ typeStockForm } storage={ storageStockForm } />
            }
        </div>
    );
}

export default ProductStocks;
